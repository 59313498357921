<template>
  <div>
    <template v-if="listArray.length > 0">
      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in listArray"
          :key="index"
          :icon="renderIcon(activity.avatar, activity.name, activity.avatarName)"
          hide-timestamp
        >
          <template #default>
            <div>
              <span class="name">{{ activity.name }}</span>
              <span class="time">{{ activity.finishTime }}</span>
            </div>
            <div class="content exceed-omit"
              >{{ activity.type }}&nbsp;&nbsp;
              <template v-if="activity.type === '审批'">
                <span v-if="activity.content">(审批意见：{{ activity.content }})</span>
                <span v-else>(审批意见：已审批)</span>
              </template>
              <template v-else-if="activity.type === '提交'">
                <span v-if="activity.content">(提交：{{ activity.content }})</span>
                <span v-else>(提交：已提交)</span>
              </template>
              <template v-else-if="activity.type === '填写'">
                <span v-if="activity.content">(填写：{{ activity.content }})</span>
                <span v-else>(填写：已填写)</span>
              </template>
              <template v-else-if="activity.type === '抄送'">
                <span v-if="activity.content">(抄送：{{ activity.content }})</span>
                <span v-else>(抄送：已抄送)</span>
              </template>
              <template v-else-if="activity.type === '插入数据'">
                <span v-if="activity.content">(插入数据：{{ activity.content }})</span>
                <span v-else>(插入数据：已插入)</span>
              </template>
              <template v-else-if="activity.type === '更新数据'">
                <span v-if="activity.content">(更新数据：{{ activity.content }})</span>
                <span v-else>(更新数据：已更新)</span>
              </template>
              <template v-else-if="activity.type === '终止'">
                <span v-if="activity.content">(终止：{{ activity.content }})</span>
                <span v-else>(终止：已终止)</span>
              </template>
              <template
                v-else-if="
                  activity.type === '插入数据节点异常' || activity.type === '修改数据节点异常'
                "
              >
                <el-tooltip effect="dark" :content="activity.content" placement="top">
                  <span v-if="activity.content">错误信息：{{ activity.content }}</span>
                </el-tooltip>
              </template>
            </div>
          </template>
        </el-timeline-item>
      </el-timeline>
    </template>
    <template v-else>
      <div class="desc">暂无更多流程数据</div>
    </template>
  </div>
</template>
<script lang="ts">
  export default {
    name: 'FlowTimeLine'
  }
</script>
<script lang="ts" setup>
  import avatarUrl from '../../assets/images/avatar.png'
  import { h } from 'vue'
  import AvatarCnp from './AvatarCnp.vue'
  import { ElTooltip } from 'element-plus'

  interface Activity {
    name: string
    avatarName: string
    finishTime: string
    content: string
    avatar?: string
    type?: string
  }

  // const activities: Activity[] = [
  //   {
  //     name: 'zhangs',
  //     finishTime: '2023-04-06 09:21:56',
  //     content: '已同意（审批意见：付货款撒发货单饭 达克拉韭辣啦韭辣阿里非建安路）',
  //     avatar: '',
  //     type: '同意'
  //   },
  //   {
  //     name: 'lisi',
  //     finishTime: '2023-04-07 10:21:56',
  //     content: '待审批（审批意见：等待审批中）',
  //     avatar: 'https://example.com/avatar2.png',
  //     type: '拒绝'
  //   },
  //   {
  //     name: 'wangwu',
  //     finishTime: '2023-04-08 11:21:56',
  //     content: '已拒绝（审批意见：资料不全）',
  //     avatar: avatarUrl,
  //     type: '拒绝'
  //   }
  // ]

  const props = withDefaults(defineProps<{ listArray: Activity[] }>(), {
    listArray: () => []
  })

  const renderIcon = (src: string, name: string, avatarName: string) => {
    return () => h(AvatarCnp, { src, name, avatarName })
  }
</script>
<style lang="scss" scoped>
  :deep(.el-timeline-item__tail) {
    border-left: 2px solid #b0b3b9;
    left: -5px;
  }

  :deep(.el-timeline-item__node) {
    width: 42px;
    height: 42px;
    left: -24px;
    .el-timeline-item__icon {
      width: 42px;
      height: 42px;
    }
  }
  .name {
    font-weight: 600;
    font-size: 14px;
    color: #1a1d1f;
  }
  .time {
    margin-left: 12px;
    font-size: 12px;
    color: #a1a2a5;
  }
  .content {
    margin-top: 3px;
    font-size: 14px;
    color: #606266;
  }
  .desc {
    line-height: 250px;
    text-align: center;
  }
  .exceed-omit {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
</style>
