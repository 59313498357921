<template>
  <div>
    <div v-for="(widget, index) in widgetDataList" :key="index">
      <!-- 注意: 用 v-show 会影响表单校验 -->
      <component
        :is="getCurrentComponet(widget)"
        v-if="showWidgetForFormAssociate(widget)"
        :item="widget"
      ></component>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { watch, computed } from 'vue'
  import { widgetComponents } from './widget-components'
  import { useProject } from '@haohan/clw-store'
  import { getWidgetVsibleForFormAssociate } from '@haohan/clw-utils'

  const projectStore = useProject()

  interface Props {
    widgetDataList: Widget[]
  }
  const props = withDefaults(defineProps<Props>(), {
    widgetDataList: () => []
  })
  const getCurrentComponet = (widget: Widget) => {
    const type = widget.componentType ? widget.componentType : widget.type
    return widgetComponents[type]
  }

  /**
   * 表单页的组件数据联动
   */
  const showWidgetForFormAssociate = (widget: Widget) =>
    getWidgetVsibleForFormAssociate(widget, projectStore.currentForm)
</script>
<style lang="scss" scoped></style>
