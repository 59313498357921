import { find, remove, findIndex, has, isObject, isArray } from 'lodash-es'
/**
 * 获取弹窗事件
 */
export const getDialogEvent = function (data: Widget[] = [], events: any[] = []): void {
  if (!data || data === null) {
    return
  }
  data.forEach((d: Widget) => {
    if (d.events && d.events.length > 0) {
      const runInEvent = d.events.filter(
        (e: any) => e.eventType === 'send' && e.behavior === 'dialog'
      )
      runInEvent.forEach((rie: any) => {
        events.push(rie)
      })
    }
    if (d.children && d.children.length > 0) {
      d.children.forEach((c: any) => {
        getDialogEvent(c.list, events)
      })
    }
    if (d.type && d.type.includes('TabPaneWidget')) {
      // 获得容器下得组件列表
      const options = d.dataProp?.defaultData
      if (options?.length > 0) {
        options?.forEach((op: WidgetPageInfo) => {
          op.list && getDialogEvent(op.list, events)
        })
      }
    }
  })
}
/**
 * 获取页面组件中得事件和公共信息
 * @param datas 页面组件集合
 * @param paramInfo 页面信息集合
 * @returns 返回页面信息集合
 */
export const getWigetEventAndComParamInfo = function (
  datas: Widget[],
  paramInfo: PageInfo = { sendFilters: [], receiveFilters: [], comParam: [], widgetDataIdKeys: [] }
) {
  for (let index = 0; datas && index < datas.length; index++) {
    const cmp = datas[index]
    // 保存该页面组件的key
    if (
      has(cmp, 'dataProp') &&
      has(cmp.dataProp, 'tableId') &&
      ![undefined, ''].includes(cmp.dataProp!.tableId)
    ) {
      paramInfo.widgetDataIdKeys.push(cmp.dataProp!.tableId)
    }
    if (cmp.events && cmp.events.length > 0) {
      // events = events.concat(cmp.events)
      cmp.events.forEach((e) => {
        if (e.eventType === 'send') {
          paramInfo.sendFilters.push({
            ...e,
            widgetKey: cmp.key
          })
          // 判断事件中是否绑定了公共参数
          if (e.comParam && e.comParam !== '') {
            // 存储该页面保存得公共参数
            paramInfo.comParam.push({
              key: e.comParam,
              label: e.comParamLabel ? e.comParamLabel : ''
            })
          }
        } else {
          paramInfo.receiveFilters.push({
            ...e,
            widgetKey: cmp.key
          })
        }
      })
    }
    // addAttributeEvent(cmp, paramInfo)
    const comParamAttribute = cmp.prop ? cmp.prop.comParam : undefined
    if (comParamAttribute) {
      const comParamLabelAttribute = cmp.prop?.comParamLabel
      // 存储该页面保存得公共参数
      paramInfo.comParam.push({
        key: comParamAttribute.value || comParamAttribute,
        label: comParamLabelAttribute ? comParamLabelAttribute : ''
      })
    }
    if (cmp.children && cmp.children.length > 0) {
      cmp.children.forEach((ch) => {
        ch.list && getWigetEventAndComParamInfo(ch.list, paramInfo)
      })
    }
    // if (cmp.list && cmp.list.length > 0) {
    //   getWigetEventAndComParamInfo(cmp.list, paramInfo)
    // }
    // 面板组件特殊处理
    if (cmp.type === 'HHTabPaneWidget' || cmp.title === '面板') {
      const options = cmp.dataProp?.defaultData
      if (options.length > 0) {
        options.forEach((op: WidgetPageInfo) => {
          op.list && getWigetEventAndComParamInfo(op.list, paramInfo)
        })
      }
    }
  }
  return paramInfo
}
/**
 * 获得项目中得所有页面跳转事件
 * @param data 页面组件集合
 * @param events 交互集合
 * @returns
 */
export const getRunInEvent = function (data: Widget[] = [], events: WidgetEvent[] = []) {
  if (!data || data === null) {
    return
  }
  data.forEach((d) => {
    if (d.events && d.events.length > 0) {
      // 判断是否存在需要删除得发送事件
      const runInEvent = d.events.filter((e) => e.eventType === 'send' && e.behavior === 'runIn')
      runInEvent.forEach((rie: WidgetEvent) => {
        events.push(rie)
      })
    }
    if (d.children && d.children.length > 0) {
      d.children.forEach((c: WidgetPageInfo) => {
        getRunInEvent(c.list, events)
      })
    }
    if (d.type && d.type.includes('TabPaneWidget')) {
      // 获得容器下得组件列表
      const options = d.dataProp?.defaultData
      if (options?.length > 0) {
        options.forEach((op: WidgetPageInfo) => {
          op.list && getRunInEvent(op.list, events)
        })
      }
    }
  })
}

/**
 * 返回唯一Key
 */
// export function getKey(): string {
//   return Date.parse(`${new Date()}`) + '_' + Math.ceil(Math.random() * 99999)
// }
/**
 * 更新页面中接收事件得信息
 * @param data 页面组件集合
 * @param event 发送事件
 */
export const updateReviceEventBySendEvent = (data: Widget[], event: WidgetEvent) => {
  data.forEach((d) => {
    if (d.events && d.events.length > 0) {
      // 找出所有关联得接收事件
      const updateEvents = d.events.filter((e) => e.receiveEventKey === event.key)
      updateEvents.forEach((e) => {
        // 更新事件值
        e.receiveEventName = event.name
        e.action = event.action
      })
    }
    if (d.children && d.children.length > 0) {
      d.children.forEach((c) => {
        c.list && updateReviceEventBySendEvent(c.list, event)
      })
    }
    // 面板处理
    if (d.type && d.type.includes('TabPaneWidget')) {
      const options = d.dataProp?.defaultData
      if (options.length > 0) {
        options.forEach((op: WidgetPageInfo) => {
          op.list && updateReviceEventBySendEvent(op.list, event)
        })
      }
    }
  })
}
/**
 * 删除组件中关联指定发送事件得接收事件
 * @param data 页面组件集合
 * @param sendEventKey 发送事件Key
 */
export const deleteReviceEventBySendEventKey = (data: Widget[], sendEventKey: string) => {
  data.forEach((d: Widget) => {
    if (d.events && d.events.length > 0) {
      // 判断是否存在需要删除得发送事件
      remove(d.events, (e: WidgetEvent) => {
        return e.receiveEventKey === sendEventKey
      })
    }
    if (d.children && d.children.length > 0) {
      d.children.forEach((c) => {
        c.list && deleteReviceEventBySendEventKey(c.list, sendEventKey)
      })
    }
    // 面板处理
    if (d.type && d.type.includes('TabPaneWidget')) {
      const options = d.dataProp?.defaultData
      if (options.length > 0) {
        options.forEach((op: WidgetPageInfo) => {
          op.list && deleteReviceEventBySendEventKey(op.list, sendEventKey)
        })
      }
    }
  })
}

export function getColEvents(data: Widget[] = [], events: any[] = []): void {
  if (!data || data === null) {
    return
  }
  data.forEach((d: Widget) => {
    if (d.type === 'HHTable') {
      const colEvent = d.dataProp?.cols?.filter((e: any) => e.className === 'link' && e.pageId)
      colEvent.forEach((rie: any) => {
        events.push(rie)
      })
    }
    if (d.children && d.children.length > 0) {
      d.children.forEach((c: any) => {
        getColEvents(c.list, events)
      })
    }
    if (d.type && d.type.includes('TabPaneWidget')) {
      // 获得容器下得组件列表
      const options = d.dataProp?.defaultData
      if (options?.length > 0) {
        options?.forEach((op: WidgetPageInfo) => {
          op.list && getColEvents(op.list, events)
        })
      }
    }
  })
}
/**
 * 根据绑定值类型转换值为对应类型
 * @param valueType 绑定值类型
 * @param value 值
 * @returns
 */
export const getEventValueByValueType = (valueType: string, value: any) => {
  // 如果设置了交互的值类型，则要进行特殊处理
  let _value = value
  if (!valueType || valueType === '') {
    return _value
  } else if (valueType === 'string') {
    if (isArray(_value)) {
      _value = _value.join(',')
    } else if (isObject(_value)) {
      _value = JSON.stringify(_value)
    }
    return _value
  } else if (valueType === 'array') {
    _value = isArray(_value) ? _value : [_value]
    return _value
  }
  return _value
}
