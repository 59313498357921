<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      width="900"
      class="my-initiate-dialog"
      destroy-on-close
      @close="close"
    >
      <div class="header">
        <!--我发起的页面 -->
        <!-- 20240802修改接口  现在 pageString === 'MyProcessed' 我已处理页面也是一样的了-->
        <template v-if="pageString === 'MyInitiate' || pageString === 'MyProcessed'">
          <div v-if="diaLogRow.startUserAvatar"
            ><el-avatar :size="42" :src="diaLogRow.startUserAvatar"
          /></div>
          <div v-else class="avatar" :style="{ '--bg': getRandomColor(diaLogRow.startUsername) }">
            <span class="avatar-text">{{ extractCharacters(diaLogRow.startUsername) }}</span>
          </div>
          <div class="name public">
            {{ diaLogRow.taskInfo.userName }}
          </div>
          <div class="desc">发起的</div>
          <div class="public">{{ diaLogRow.flowDef?.name }}</div>
        </template>
      </div>
      <div class="main">
        <div class="card">
          <div class="card-desc">当前状态</div>

          <!--我发起的页面 -->
          <!-- 20240802修改接口  现在 pageString === 'MyProcessed' 我已处理页面也是一样的了-->
          <template v-if="pageString === 'MyInitiate' || pageString === 'MyProcessed'">
            <div class="tag">
              <el-tag
                :type="diaLogRow.taskInfo.statusInfo.tagType"
                effect="light"
                round
                size="large"
                disable-transitions
              >
                {{ diaLogRow.taskInfo.statusInfo.info }}
              </el-tag>
            </div>
            <!-- <template v-if="diaLogRow.finishTime === null">
              <div class="tag">
                <el-tag type="warning" effect="light" round size="large" disable-transitions>
                  {{ '进行中' }}
                </el-tag>
              </div>
            </template>
            <template v-else>
              <div v-if="diaLogRow.lastComment?.type === '终止'" class="tag">
                <el-tag type="info" effect="light" round size="large" disable-transitions>
                  {{ '已终止' }}
                </el-tag>
              </div>
              <div v-else-if="diaLogRow.lastComment?.type === '同意'" class="tag">
                <el-tag type="success" effect="light" round size="large" disable-transitions>
                  {{ '已同意' }}
                </el-tag>
              </div>
              <div v-else-if="diaLogRow.lastComment?.type == '驳回'" class="tag">
                <el-tag type="danger" effect="light" round size="large" disable-transitions>
                  {{ '已拒绝' }}
                </el-tag>
              </div>
              <div class="tag">
                <el-tag type="success" effect="light" round size="large" disable-transitions>
                  {{ '已完成' }}
                </el-tag>
              </div>
            </template> -->
          </template>
        </div>

        <!--我发起的页面 -->
        <!-- 20240802修改接口  现在 pageString === 'MyProcessed' 我已处理页面也是一样的了-->
        <template v-if="pageString === 'MyInitiate' || pageString === 'MyProcessed'">
          <div class="card">
            <div class="card-desc">提交人</div>
            <div class="text">{{ diaLogRow.taskInfo.userName }}</div>
          </div>
        </template>

        <div class="card">
          <div class="card-desc">提交时间</div>
          <div class="text">{{ diaLogRow.taskInfo.time }}</div>
        </div>
      </div>
      <div class="content">
        <div class="left">
          <div class="form-title">
            <span class="form-text">表单内容</span>
          </div>
          <div class="form-list">
            <!--我发起的页面 -->
            <!-- 20240802修改接口  现在 pageString === 'MyProcessed' 我已处理页面也是一样的了-->
            <template v-if="pageString === 'MyInitiate' || pageString === 'MyProcessed'">
              <div
                v-for="(value, key, index) in diaLogRow.variables"
                :key="index"
                class="form-item-wrapper"
              >
                <div class="form-item">{{ key }} </div>
                <div class="form-value">{{ value }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="right">
          <div class="tip">
            <div class="title">流程进度</div>
            <div>
              <el-button type="primary" text plain @click="showFlow = !showFlow">
                完整流程
              </el-button></div
            >
          </div>
          <div class="progress-wrapper">
            <FlowTimeLine v-show="showFlow" :list-array="listArray" />
            <img v-show="!showFlow" class="img" :src="currentProcessImg" alt="" />
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
  export default {
    name: 'MyInitiateDiaLog'
  }
</script>
<script setup lang="ts">
  import avatar from '../../../assets/images/avatar.png'
  import { ref, nextTick, computed } from 'vue'
  import FlowTimeLine from '../../../components/FlowTimeLine/FlowTimeLine.vue'
  import { queryTasks, queryWorkflowItemImgReq } from '@haohan/clw-api'
  import { useProject } from '@haohan/clw-store'

  const props = defineProps({
    pageString: {
      type: String,
      default: ''
    }
  })
  const projectStore = useProject()
  const projectId = computed(() => {
    return projectStore.currentProject?.id
  })
  const dialogVisible = ref(false)
  const diaLogRow = ref<any>({})

  //是否显示完整流程
  const showFlow = ref(true)

  const listArray = ref([])
  /**
   * 打开弹框
   */
  function open(row) {
    diaLogRow.value = row
    // const params = {
    //   page: 1,
    //   size: 10000
    // }
    // queryTasks(projectId.value, row.id, params).then((res: any) => {
    //   console.log(res)
    //   listArray.value = res.data.data.map((item: any) => {
    //     let listAvatarName
    //     if (!item.assigneeUserName) {
    //       if (item.settings.type === 'SUBMIT') {
    //         listAvatarName = '提交'
    //       } else if (item.settings.type === 'CC') {
    //         listAvatarName = '抄送'
    //       } else if (item.settings.type === 'UPDATE_DATA') {
    //         listAvatarName = '更新数据'
    //       } else if (item.settings.type === 'INSERT_DATA') {
    //         listAvatarName = '插入数据'
    //       }
    //     }
    //     // SUBMIT, //申请
    //     //   APPROVED, //审批
    //     //   WRITE, //填写
    //     //   CC, //抄送
    //     //   INSERT_DATA, //插入数据
    //     //   UPDATE_DATA //更新数据
    //     let type
    //     if (item.settings.type === 'SUBMIT') {
    //       type = '提交'
    //     } else if (item.settings.type === 'APPROVED') {
    //       type = '审批'
    //     } else if (item.settings.type === 'WRITE') {
    //       type = '填写'
    //     } else if (item.settings.type === 'CC') {
    //       type = '抄送'
    //     } else if (item.settings.type === 'UPDATE_DATA') {
    //       type = '更新数据'
    //     } else if (item.settings.type === 'INSERT_DATA') {
    //       type = '插入数据'
    //     }
    //     if (item.comment?.type === '终止') {
    //       type = '终止'
    //     }
    //     return {
    //       name: item.assigneeUserName,
    //       avatarName: item.assigneeUserName ? item.assigneeUserName : listAvatarName,
    //       finishTime: item.finishTime,
    //       content: item.comment?.comment,
    //       avatar: item.comment?.assigneeUserAvatar,
    //       type: type
    //     }
    //   })
    //   queryImg()
    //   dialogVisible.value = true
    //   showFlow.value = true
    // })
    if (row.tasks.length > 0) {
      listArray.value = row.tasks
        .filter((r: any) => r.finishTime !== null)
        .map((item: any) => {
          let listAvatarName
          if (!item.assigneeUserName) {
            if (item.settings.type === 'SUBMIT') {
              listAvatarName = '提交'
            } else if (item.settings.type === 'CC') {
              listAvatarName = '抄送'
            } else if (item.settings.type === 'UPDATE_DATA') {
              listAvatarName = '更新数据'
            } else if (item.settings.type === 'INSERT_DATA') {
              listAvatarName = '插入数据'
            }
          }
          // SUBMIT, //申请
          //   APPROVED, //审批
          //   WRITE, //填写
          //   CC, //抄送
          //   INSERT_DATA, //插入数据
          //   UPDATE_DATA //更新数据
          let type
          if (item.settings.type === 'SUBMIT') {
            type = '提交'
          } else if (item.settings.type === 'APPROVED') {
            type = '审批'
          } else if (item.settings.type === 'WRITE') {
            type = '填写'
          } else if (item.settings.type === 'CC') {
            type = '抄送'
          } else if (item.settings.type === 'UPDATE_DATA') {
            type = '更新数据'
          } else if (item.settings.type === 'INSERT_DATA') {
            type = '插入数据'
          }
          if (item.comment?.type === '终止') {
            type = '终止'
          }
          if (
            item.comment?.type === '插入数据节点异常' ||
            item.comment?.type === '修改数据节点异常'
          ) {
            type = item.comment?.type
          }
          return {
            name: item.assigneeUserName,
            avatarName: item.assigneeUserName ? item.assigneeUserName : listAvatarName,
            finishTime: item.finishTime,
            content: item.comment?.comment,
            avatar: item.comment?.assigneeUserAvatar,
            type: type
          }
        })
    } else {
      listArray.value = []
    }
    queryImg()
    dialogVisible.value = true
    showFlow.value = true
  }
  //查询流程图片
  const currentProcessImg = ref()
  async function queryImg() {
    const respImg = await queryWorkflowItemImgReq(projectId.value, diaLogRow.value.flowDef?.id)
    currentProcessImg.value = respImg.data
  }

  const colors = ['#0176DE', '#4BCA81', '#9D7CE6', '#0DA1FD', '#EB85C1', '#FDAC23', '#DB492E']
  function getRandomColor(inputChar: string) {
    const hash = inputChar.charCodeAt(0)
    const colorIndex = hash % colors.length
    return colors[colorIndex]
  }
  /**
   * 关闭弹窗
   */
  const close = () => {
    dialogVisible.value = false
  }

  function extractCharacters(inputStr: string) {
    // 判断是否包含中文字符
    if (/[\u4e00-\u9fff]/.test(inputStr)) {
      // 取后两位
      return inputStr.slice(-2)
    }
    // 取前五位
    return inputStr.slice(0, 5)
  }
  defineExpose({ open, close })
</script>

<style lang="scss">
  .my-initiate-dialog {
    .el-dialog__close {
      color: #0076de;
    }
    .el-dialog__body {
      padding: 0 40px 40px 40px;
    }
  }
</style>

<style lang="scss" scoped>
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border-radius: 50% 50%;
    background-color: var(--bg, antiquewhite);
    color: #ffffff;
    font-size: 12px;
  }
  .header {
    display: flex;
    align-items: center;
    .name {
      margin-left: 12px;
    }
    .desc {
      margin: 0 8px;
      font-size: 14px;
      color: #606266;
    }
    .public {
      font-weight: 600;
      font-size: 14px;
      color: #1a1d1f;
    }
  }
  .main {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .card {
      .card-desc {
        font-weight: 600;
        font-size: 16px;
        color: #1a1d1f;
      }
      .tag {
        margin-top: 12px;
        :deep() {
          .el-tag {
            padding: 0 31px;
          }
        }
      }
      .text {
        margin-top: 24px;
        font-size: 14px;
        color: #606266;
      }
    }
  }
  .content {
    margin-top: 20px;
    display: flex;
    gap: 50px;
    .left {
      width: 412px;
      height: 417px;
      background: #ffffff;
      box-shadow: 0px 0px 13px 1px rgba(213, 213, 213, 0.46);
      border-radius: 20px 20px 20px 20px;
      .form-title {
        width: 412px;
        height: 50px;
        background: #0076de;
        border-radius: 20px 20px 0px 0px;
        line-height: 50px;
        .form-text {
          padding-left: 30px;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
        }
      }
      .form-list {
        box-sizing: border-box;
        padding: 0 42px 26px 42px;
        height: 367px;
        overflow-y: auto;
        .form-item-wrapper {
          margin-top: 26px;
        }
        .form-item {
          font-weight: 600;
          font-size: 14px;
          color: #1a1d1f;
        }
        .form-value {
          margin-top: 8px;
          font-size: 14px;
          color: #606266;
        }
      }
    }
    .right {
      width: 362px;
      height: 417px;
      background: #ffffff;
      box-shadow: 0px 0px 13px 1px rgba(213, 213, 213, 0.62);
      border-radius: 20px 20px 20px 20px;
      .tip {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 28px 20px 14px 30px;
        box-sizing: border-box;
        height: 74px;
        .title {
          font-weight: 600;
          font-size: 16px;
          color: #1a1d1f;
        }
      }
      .progress-wrapper {
        height: 340px;
        overflow-y: auto;
        padding-right: 8px;
        padding-left: 8px;
        padding-top: 8px;
        .img {
          text-align: center;
          width: 100%;
          height: 330px;
        }
      }
    }
  }
</style>
