// import resource from '@/utils/resource'
import { http } from '@haohan/clw-utils'
import { useGlobalStore } from '@haohan/clw-store'

const URL = '/api/v1/projects'
const DATAPATH = '/api/v1/datasources'

export function queryDataSets(projectId: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}/dataset`
  })
}

export function updateDataSet(projectId: string, data: any) {
  if (data.id) {
    return http.request({
      method: 'put',
      url: `${URL}/${projectId}/dataset/${data.id}`,
      data
    })
  }
  return http.request({
    method: 'post',
    url: `${URL}/${projectId}/dataset`,
    data
  })
}

/**
 * 数据集复制
 */
export function copyDataSet(projectId: string, datasetId: string, data: any) {
  return http.request({
    method: 'put',
    url: `${URL}/${projectId}/dataset/copy/${datasetId}`,
    data
  })
}

/**
 * 数据集重命名, 独立接口, 改造后不用 updateDataSet 接口
 */
export function renameDataSet(projectId: string, datasetId: string, data: any) {
  return http.request({
    method: 'put',
    url: `${URL}/${projectId}/dataset/${datasetId}/rename`,
    data
  })
}

export function deleteDataSet(projectId: string, datasetId: string) {
  return http.request({
    method: 'DELETE',
    url: `${URL}/${projectId}/dataset/${datasetId}`
  })
}

export function getDataSet(projectId: string, datasetId: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}/dataset/${datasetId}`
  })
}

export function getDataSetDescribe(projectId: string, datasetId: string) {
  // 现在分为原始表和数据集
  // 数据表, 拼接表的 id: 'dataSourceId;schema;name'
  const isDataTable = datasetId.includes(';')
  if (isDataTable) {
    const list = datasetId.split(';')
    const [dataSourceId, schema, name] = list
    return http.request({
      method: 'get',
      url: `${URL}/${projectId}/datasources/${dataSourceId}/tables/${schema}.${name}`
    })
  }
  // 数据集(旧版)
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}/dataset/${datasetId}/describe`
  })
}

export function moveDataSet(
  projectId: string,
  targetId: string,
  sourceId: string,
  parentId: string
) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${projectId}/dataset/move`,
    data: {
      targetId: targetId,
      sourceId: sourceId,
      parentId: parentId
    }
  })
}

export function preview(projectId: string, datasetId: string, limit = 1) {
  return http.request({
    method: 'POST',
    url: `${URL}/${projectId}/dataset/${datasetId}/preview`,
    params: {
      limit: limit
    }
  })
}

export function previewBootstrapData(projectId: string, queryDef: any) {
  return http.request({
    method: 'POST',
    url: `${URL}/${projectId}/dataset/preview`,
    data: queryDef
  })
}

export function previewBootstrapDescribe(projectId: string, queryDef: any) {
  return http.request({
    method: 'post',
    url: `${URL}/${projectId}/dataset/preview_describe`,
    data: queryDef
  })
}

/**
 * 预览表结构, 不提示错误信息
 * @param projectId
 * @param queryDef
 * @returns
 */
export function previewBootstrapDescribeOfCustomErrorHandle(projectId: string, queryDef: any) {
  return http.request({
    method: 'post',
    url: `${URL}/${projectId}/dataset/preview_describe`,
    data: queryDef,
    headers: {
      'custom-error-handler': 'true'
    }
  })
}

export function query(projectId: string, queryDef: any) {
  return http.request({
    method: 'POST',
    url: `${URL}/${projectId}/dataset/query`,
    data: queryDef
  })
}

export function queryDatasetData(projectId: string, datasetId: string, queryParam: any) {
  const globalStore = useGlobalStore()
  // 是否增加后缀
  const addSuffix = globalStore.environment.view ? '' : '?debug=true'
  // 现在分为原始表和数据集
  // 数据表, 拼接表的 id: 'dataSourceId;schema;name'
  const isDataTable = datasetId.includes(';')
  if (isDataTable) {
    const list = datasetId.split(';')
    const [dataSourceId, schema, name] = list
    return http.request({
      method: 'POST',
      url: `${URL}/${projectId}/datasources/${dataSourceId}/tables/${schema}.${name}/data/query${addSuffix}`,
      data: queryParam
    })
  }
  // 数据集(旧版)
  return http.request({
    method: 'POST',
    url: `${URL}/${projectId}/dataset/${datasetId}/query${addSuffix}`,
    data: queryParam
  })
}

export function queryBusinessRelation(projectId: string) {
  return http.request({
    method: 'GET',
    url: `api/v1/projects/${projectId}/dataset/relation`
  })
}

export function queryCategories() {
  return http.request({
    method: 'GET',
    url: `/api/v1/business_domain/categories`
  })
}

export function queryDataSourceList(queryDef: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/datasource/list`,
    data: queryDef
  })
}

export function queryDataSourceListByProject(projectId: string, queryDef: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/projects/${projectId}/datasource/list`,
    data: queryDef
  })
}

export function saveDataSource(projectId: string, dataSource: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/projects/${projectId}/datasource`,
    data: dataSource
  })
}

export function updateDataSource(projectId: string, dataSource: any) {
  return http.request({
    method: 'PUT',
    url: `/api/v1/projects/${projectId}/datasource/${dataSource.id}`,
    data: dataSource
  })
}

export function removeDataSource(projectId: string, dataSourceId: string) {
  return http.request({
    method: 'DELETE',
    url: `/api/v1/projects/${projectId}/datasource/${dataSourceId}`
  })
}

export function testDataSource(projectId: string, dataSource: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/projects/${projectId}/datasource/test`,
    data: dataSource
  })
}

// 自建数据源
export function ListDataTable(projectId: string, did: any, data: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/projects/${projectId}/datasources/${did}/table_model/list`,
    data
  })
}

export function addDataTable(projectId: string, did: any, data: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/projects/${projectId}/datasources/${did}/table_model`,
    data
  })
}
export function editDataTable(projectId: string, did: any, tid: any, data: any) {
  return http.request({
    method: 'PUT',
    url: `/api/v1/projects/${projectId}/datasources/${did}/table_model/${tid}`,
    data
  })
}
export function readExcel(projectId: string, did: any, data: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/projects/${projectId}/datasources/${did}/table_model/read_excel`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function addDataTableWidthExcel(projectId: string, did: any, data: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/projects/${projectId}/datasources/${did}/table_model/create_with_excel`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: data
  })
}

export function delDataTable(projectId: string, did: any, tid: any) {
  return http.request({
    method: 'DELETE',
    url: `/api/v1/projects/${projectId}/datasources/${did}/table_model/${tid}`
  })
}

export function ListDataTableFields(projectId: string, did: any, tid: any, data: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/projects/${projectId}/datasources/${did}/table_model/${tid}/field_model/list`,
    data
  })
}

export function addDataDetailWidthExcel(projectId: string, did: any, tid: any, data: any) {
  return http.request({
    method: 'POST',
    url: `/api/v1/projects/${projectId}/datasources/${did}/table_model/${tid}/import_excel`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
/**
 * 获取数据表详情
 */
export function detailDataTable(projectId: string, did: any, tid: any) {
  return http.request({
    method: 'GET',
    url: `/api/v1/projects/${projectId}/datasources/${did}/table_model/${tid}`
  })
}

export function addDataAssemble(pid: any, did: any, data: any) {
  // 现在分为原始表和数据集
  // 数据表, 拼接表的 id: 'dataSourceId;schema;name'
  const isDataTable = did.includes(';')
  if (isDataTable) {
    const list = did.split(';')
    const [dataSourceId, schema, name] = list
    return http.request({
      method: 'POST',
      url: `${URL}/${pid}/datasources/${dataSourceId}/tables/${schema}.${name}/data`,
      data
    })
  }
  return http.request({
    method: 'POST',
    url: `${URL}/${pid}/dataset/data/${did}`,
    data
  })
}
export function editDataAssemble(pid: any, did: any, data: any) {
  // 现在分为原始表和数据集
  // 数据表, 拼接表的 id: 'dataSourceId;schema;name'
  const isDataTable = did.includes(';')
  if (isDataTable) {
    const list = did.split(';')
    const [dataSourceId, schema, name] = list
    return http.request({
      method: 'PUT',
      url: `${URL}/${pid}/datasources/${dataSourceId}/tables/${schema}.${name}/data`,
      data
    })
  }
  return http.request({
    method: 'PUT',
    url: `${URL}/${pid}/dataset/data/${did}`,
    data
  })
}
export function delDataAssemble(pid: any, did: any, data: any) {
  // 数据表, 拼接表的 id: 'dataSourceId;schema;name'
  const isDataTable = did.includes(';')
  if (isDataTable) {
    const list = did.split(';')
    const [dataSourceId, schema, name] = list
    return http.request({
      method: 'DELETE',
      url: `${URL}/${pid}/datasources/${dataSourceId}/tables/${schema}.${name}/data`,
      data
    })
  }
  return http.request({
    method: 'DELETE',
    url: `${URL}/${pid}/dataset/data/${did}`,
    data
  })
}
export function clearDataAssemble(pid: any, did: any) {
  return http.request({
    method: 'DELETE',
    url: `${URL}/${pid}/dataset/data/${did}/clean`
  })
}
export function addAssembleWidthExcel(pid: any, did: any, data: any) {
  return http.request({
    method: 'POST',
    url: `${URL}/${pid}/dataset/data/${did}/import`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
export function upDatasetFile(pid: any, did: any, name: string, data: any) {
  return http.request({
    method: 'POST',
    url: `${URL}/${pid}/dataset/data/${did}/upload/${name}`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

/**
 * 刷新数据源
 * @param projectId
 */
export function datasetsRefresh(projectId: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}/refreshDatasets`
  })
}
