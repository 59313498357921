<template>
  <div>
    <el-scrollbar max-height="80vh">
      <el-form
        ref="createFormRef"
        :style="formPageStyle"
        label-position="top"
        :model="globalForm"
        :rules="globalRules"
        label-width="120px"
      >
        <View :key="cnpKey" :widget-data-list="showWidgetList"></View>
      </el-form>
    </el-scrollbar>
    <div v-if="widgetData?.list?.length && showOprButton" class="form-page-submit-container">
      <el-button type="primary" class="btn" :loading="formSubmitLoading" @click.stop="onFormSubmit">
        {{ formBtnProp.confirmText }}
      </el-button>
      <el-button class="btn cancle" @click.stop="closeDialog">
        {{ formBtnProp.cancelText }}
      </el-button>
    </div>
  </div>
</template>
<script lang="ts">
  export default {
    name: 'WorkflowFormPage'
  }
</script>
<script setup lang="ts">
  import { useRoute } from 'vue-router'
  import { ref, watch, computed } from 'vue'
  import { queryWorkflow, startWorkflow, queryWorkflowItemReq } from '@haohan/clw-api'
  import { useProject, useEvent, useGlobalStore } from '@haohan/clw-store'
  import { addDataAssemble } from '@haohan/clw-api'
  import { ElMessage } from 'element-plus'
  import {
    getKey,
    getFormPayloadByWidgetList,
    handleWorkFlowLimitInfo2Obj,
    handleWorkflowFormItem,
    handleWorkFlowConfig,
    getFlatFormPageWidgetDatalist,
    emitter
  } from '@haohan/clw-utils'
  import View from '../View.vue'
  import { cloneDeep } from 'lodash-es'
  import { onBeforeUnmount } from 'vue'

  interface WidgetDataInfo {
    list: Widget[]
  }
  /**
   * workflow 当前节点信息，结构为
   * formBtnProp 对应 buttonStyle
   * {
   *    id: xx,
   *    prop: {formBtnProp: {}, formLimit: {}}
   * }
   *
   */
  const props = withDefaults(
    defineProps<{
      widgetData: WidgetDataInfo
      page?: any
      isDialogPage?: boolean
      workflow?: any
      showOprButton?: boolean
      formvalue?: any
    }>(),
    {
      widgetData: () => [] as any,
      isDialogPage: false,
      showOprButton: true
    }
  )
  const emit = defineEmits<{
    (e: 'closeDialog', value: boolean): void
  }>()
  const projectStore = useProject()
  // 使用该方法强制重新渲染组件
  const cnpKey = ref(getKey())

  const currentPage = computed(() => (props.page ? props.page : projectStore?.currentPage))

  /**
   * 根据工作流权限限制显示表单信息
   */
  const showWidgetList = ref<Widget[]>([])
  const handleWidgetList = () => {
    // 获得工作流限制条件
    const formLimit =
      workflowInfo.value.prop.formLimit instanceof Array
        ? handleWorkFlowLimitInfo2Obj(workflowInfo.value.prop.formLimit)
        : handleWorkFlowConfig(workflowInfo.value.prop.formLimit)
    // 赋值
    showWidgetList.value = handleWorkflowFormItem(cloneDeep(props.widgetData.list), formLimit)
    // 初始化表单数据
    if (props.formvalue) {
      // 扁平化, 去除容器
      let flatList: any[] = []
      flatList = getFlatFormPageWidgetDatalist(showWidgetList.value, flatList)
      // 更新表单
      projectStore.updateCurrentFormByWidgetList(flatList)
      initParams(flatList)
    }
  }
  // 表单字段赋值
  const initParams = (list: Widget[]) => {
    const row = props.formvalue
    const formTemp = {} as any
    list.forEach((widget) => {
      // field, 对应 row 的 key,  比如 'id'
      const tableColumn = widget?.formProp?.tableColumn
      if (tableColumn) {
        const value = row[tableColumn]
        const key = widget.key
        if (key) {
          formTemp[key] = value
        }
      }
    })
    projectStore.updateCurrentForm(formTemp)
  }
  // 工作流信息
  const workflowInfo = ref({})
  const workflowDetail = ref()
  watch(
    () => currentPage.value.id,
    async (nv: any) => {
      if (!nv) {
        return
      }
      cnpKey.value = getKey()
      const { data } = await queryWorkflowItemReq(
        projectStore.currentProject.id,
        currentPage.value.id
      )
      workflowDetail.value = data
      if (props.workflow) {
        workflowInfo.value = props.workflow
      } else {
        workflowInfo.value = data
          ? {
              ...data.components[0],
              id: data.id
            }
          : {}
      }
      handleWidgetList()
    },
    { immediate: true }
  )
  // 表单页, padding
  const formPageStyle = computed(() => {
    const styleProp = currentPage.value.variables?.formAttribute?.styleProp
    if (styleProp) {
      return Object.keys(styleProp)
        .map((padding) => {
          return `${padding}:${styleProp[padding]}px`
        })
        .join(';')
    }
    return {}
  })
  // 表单和 rules
  const globalForm = computed(() => projectStore.currentForm)
  const globalRules = computed(() => projectStore.currentFormRulesParsed)
  watch(
    () => props.widgetData.list,
    (val) => {
      if (projectStore.isFormPage) {
        // 重置表单和 rules
        projectStore.resetCurrentFormAndRules()
        // 更新表单
        projectStore.updateCurrentFormByWidgetList(props.widgetData.list)
        // 更新 rules
        projectStore.updateCurrentFormRulesByWidgetList(props.widgetData.list)
      }
    },
    { deep: true }
  )

  const formBtnProp = computed(() => {
    return Object.keys(workflowInfo.value).length === 0
      ? {
          cancelText: '取消',
          confirmText: '确定'
        }
      : workflowInfo.value.prop.formBtnProp
  })

  const closeDialog = () => {
    if (props.isDialogPage) {
      emit('closeDialog', false)
    }
  }
  const createFormRef = ref()
  const formSubmitLoading = ref(false)
  const onFormSubmit = () => {
    // 提交工作流申请
    createFormRef?.value?.validate((valid: boolean) => {
      if (!valid) {
        ElMessage.warning('校验未通过')
        return false
      }
      const formAttribute = currentPage.value.variables?.formAttribute
      if (!formAttribute) {
        return ElMessage.warning('缺少表单属性')
      }
      const payload = getFormPayload(props.widgetData.list, globalForm.value)
      console.log('payload', payload)
      formSubmitLoading.value = true
      startWorkflow(projectStore.currentProject.id, workflowInfo.value.id, payload.data)
        .then(() => {
          emitter.emit('workflow-one-more', {
            instanceCount: workflowDetail.value.instanceCount,
            userInstanceCount: workflowDetail.value.userInstanceCount,
            submitLimit: workflowDetail.value.config.submitLimit,
            userSubmitLimit: workflowDetail.value.config.submitLimitPerUser,
            name: currentPage.value.title,
            isDialogPage: props.isDialogPage
          })
        })
        .catch((err: any) => {
          // console.log('err', err)
          const message = err.response.data.message
          if (message.includes('最大限制')) {
            emitter.emit('workflow-one-more', {
              instanceCount: workflowDetail.value.instanceCount,
              userInstanceCount: workflowDetail.value.userInstanceCount,
              submitLimit: workflowDetail.value.config.submitLimit,
              userSubmitLimit: workflowDetail.value.config.submitLimitPerUser,
              name: currentPage.value.title,
              isDialogPage: props.isDialogPage
            })
          } else {
            ElMessage({
              dangerouslyUseHTMLString: true,
              type: 'error',
              showClose: true,
              message: message
            })
          }
        })
        .finally(() => {
          formSubmitLoading.value = false
          closeDialog()
        })
    })
  }
  const getFormPayload = (list: any, form: any) => {
    const { filterNullValues } = currentPage.value.variables?.formAttribute
    return {
      data: getFormPayloadByWidgetList(list, form, filterNullValues)
    }
  }

  const getFormValue = async () => {
    return new Promise((resolve, reject) => {
      createFormRef?.value?.validate((valid: boolean) => {
        if (!valid) {
          ElMessage.warning('校验未通过')
          reject('校验未通过')
        }
        const formAttribute = currentPage.value.variables?.formAttribute
        if (!formAttribute) {
          ElMessage.warning('缺少表单属性')
          reject('缺少表单属性')
        }
        const payload = getFormPayload(props.widgetData.list, globalForm.value)
        console.log('payload', payload)
        resolve(payload.data)
      })
    })
  }

  onBeforeUnmount(() => {
    projectStore.resetCurrentFormAndRules()
  })

  // 通过该方法拿到表单值
  defineExpose({ getFormValue })
</script>
<style lang="scss" scoped>
  .form-page-submit-container {
    margin-top: 32px;
    text-align: center;
    .btn {
      width: 100px;
    }
    .cancle {
      margin-left: 80px;
    }
  }
</style>
