<template>
  <div class="message-page">
    <div class="top-search">
      <div class="query-box">
        <el-input
          v-model="listInfo.txt"
          placeholder="请输入搜索内容"
          clearable
          @input="inputDebounce"
        />
        <svg-icon class="search" icon-class="search" />
      </div>
      <el-pagination
        background
        small
        layout="prev, pager, next, total"
        :pager-count="5"
        :current-page="listInfo.page"
        :page-size="listInfo.size"
        :total="listInfo.total"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
    <main>
      <div class="ctrl-box">
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="checkAllFn">
          全选
        </el-checkbox>
        <el-button type="primary" class="btn-center" @click="batchMarkRead">标记为已读</el-button>
        <el-button type="danger" plain @click="batchDelRow">批量删除</el-button>
      </div>
      <div class="list">
        <div v-for="item in listInfo.data" :key="item.id" class="item">
          <div class="lf" :class="{ 'has-unread': item.status === 'UN_READ' }">
            <el-checkbox :model-value="item.check" @change="checkRow(item)" />
            <div class="name">{{ dealMsg(item) }}</div>
            <div class="time">{{ item.createDate }}</div>
            <span class="dot"></span>
          </div>
          <div class="rt">
            <el-link type="primary" :underline="false" @click="viewDetail(item)">查看详情</el-link>
            <el-link
              type="primary"
              :underline="false"
              :disabled="item.status !== 'UN_READ'"
              @click="markRead(item.id)"
              >标记为已读</el-link
            >
            <el-link type="danger" :underline="false" @click="delRow(item.id)">删除</el-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'AllMessage'
  }
</script>

<script lang="ts" setup>
  import { ref, reactive, onMounted, computed } from 'vue'
  import { debounce } from 'lodash-es'
  import { queryMessageListReq, makeMessageReadReq, delMessageReq } from '@haohan/clw-api'
  import { ElMessage } from 'element-plus'
  import { getTimeDifference } from '@haohan/clw-utils'

  const emit = defineEmits(['change-tab'])

  const listInfo = reactive({
    txt: '',
    data: [],
    page: 1,
    size: 10,
    total: 100,
    loading: false
  })
  const checkAll = ref(false)
  // const isIndeterminate = ref(false)
  const isIndeterminate = computed(
    () => checkRows.value.length > 0 && checkRows.value.length < listInfo.data.length
  )

  async function getList() {
    listInfo.loading = true
    const params = {
      page: listInfo.page,
      count: listInfo.size,
      orders: [
        {
          mode: 'DESC',
          expression: 'id'
        }
      ]
    } as any

    if (listInfo.txt) {
      params.where = {
        type: 'operate',
        left: 'message',
        operator: 'like',
        parameters: `'%${listInfo.txt}%'`
      }
    }

    try {
      const { data } = await queryMessageListReq(params)
      listInfo.total = +data.totalItems || 0
      listInfo.data = data.data.map((item) => ({ ...item, check: false }))
      listInfo.loading = false
      checkAll.value = false
    } catch (error) {
      listInfo.loading = false
    }
  }
  const inputDebounce = debounce(() => {
    getList()
  }, 500)
  function pageChange(val: number) {
    listInfo.page = val
    getList()
  }
  function sizeChange(val: number) {
    listInfo.size = val
    getList()
  }

  const checkRows = computed(() => listInfo.data.filter((item) => item.check))

  function checkAllFn(val: boolean) {
    listInfo.data.forEach((item) => (item.check = val))
  }
  async function checkRow(item: any) {
    // console.log(item)
    item.check = !item.check
    if (checkRows.value.length === listInfo.data.length) {
      checkAll.value = true
    } else {
      checkAll.value = false
    }
  }

  onMounted(() => {
    getList()
  })

  const viewDetail = async (item: any) => {
    try {
      await makeMessageReadReq(item.id)
      emit('change-tab', item.tag?.type)
    } catch (error) {}
  }
  const markRead = async (id: any) => {
    try {
      await makeMessageReadReq(id)
      ElMessage.success('操作成功')
      getList()
    } catch (error) {}
  }
  const delRow = async (id: any) => {
    try {
      await delMessageReq(id)
      ElMessage.success('操作成功')
      getList()
    } catch (error) {}
  }

  const batchMarkRead = () => {
    if (!checkRows.value.length) {
      // ElMessage.warning('')
      return
    }
    const ids = checkRows.value.map((item) => item.id).join(',')
    markRead(ids)
  }
  const batchDelRow = () => {
    if (!checkRows.value.length) {
      // ElMessage.warning('')
      return
    }
    const ids = checkRows.value.map((item) => item.id).join(',')
    delRow(ids)
  }

  const dealMsg = (item: any) => {
    if (item.tag.type === 'TODO_TIME') {
      const { createTime } = item.tag.variables
      const showTime = getTimeDifference(createTime)
      return item.message.replace(/{time}/, showTime)
    }
    return item.message
  }
</script>

<style lang="scss" scoped>
  .message-page {
    position: relative;
    padding: 28px 24px;
    background-color: #fff;
    border-radius: 18px;
    .el-checkbox {
      --el-checkbox-input-height: 18px;
      --el-checkbox-input-width: 18px;
    }
  }
  main {
    .ctrl-box {
      display: flex;
      align-items: center;
      margin-bottom: 35px;
      .btn-center {
        margin: 0 18px 0 40px;
      }
    }
    .list {
      .item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 68px;
      }
      .lf {
        position: relative;
        padding-left: 36px;
        color: #606266;
        .el-checkbox {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        .name {
          line-height: 1.5;
          margin-bottom: 6px;
        }
        .time {
          color: #a1a2a5;
          font-size: 12px;
        }
        .dot {
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          top: -2px;
          left: 26px;
          background-color: transparent;
        }
        &.has-unread {
          .dot {
            background-color: #f01d1d;
          }
        }
      }
      .rt {
        .el-link {
          cursor: pointer;
          & + .el-link {
            margin-left: 20px;
          }
        }
      }
    }
  }
</style>
