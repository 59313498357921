<template>
  <div class="tags-view-wrapper">
    <div class="expend-warpper" :class="{ 'expend-warpper-no-padding': !tagArrowVisible }">
      <svg-icon
        v-if="tagArrowVisible"
        icon-class="tag-left-arrow"
        class-name="left-arrow"
        @click="onTagScroll(120)"
      />
      <div
        ref="tagScrollOuterRef"
        class="scroll-wrapper"
        @mouseScroll.prevent="onTagScrollWithMouse"
        @mousewheel.prevent="onTagScrollWithMouse"
      >
        <div ref="tagScrollBodyRef" class="tags-view-content" :style="{ left: tagBodyLeft + 'px' }">
          <div
            v-for="(tag, index) in keepAliveRouteList"
            :key="tag.path"
            class="tags-view-item"
            :class="{ active: isActiveRoute(tag.key) }"
            @click.prevent.stop="onClickRouteLink(tag)"
          >
            <span>{{ tag.title }}</span>
            <svg-icon
              v-if="keepAliveRouteList.length > 1"
              class="close-icon"
              :icon-class="isActiveRoute(tag.key) ? 'close-tag-active' : 'close-tag'"
              @click.prevent.stop="closeSelectedTag(index)"
            >
            </svg-icon>
          </div>
        </div>
      </div>
      <svg-icon
        v-if="tagArrowVisible"
        icon-class="tag-right-arrow"
        class-name="right-arrow"
        @click="onTagScroll(-120)"
      />
    </div>
    <el-space :size="6">
      <el-tooltip effect="dark" content="清空缓存菜单" placement="bottom">
        <el-icon size="16"><CloseBold class="icon-btn" @click="clearAllRoute()" /></el-icon>
        <!-- <svg-icon class="icon-btn" icon-class="close" @click="clearAllRoute()" /> -->
      </el-tooltip>
      <el-divider direction="vertical" />
      <el-tooltip effect="dark" content="刷新页面" placement="bottom">
        <svg-icon class="icon-btn" icon-class="refresh" @click="router.go(0)" />
      </el-tooltip>
    </el-space>
  </div>
</template>

<script lang="ts" setup>
  import { CloseBold } from '@element-plus/icons-vue'
  import { ref, computed } from 'vue'
  import { useProject } from '@haohan/clw-store'
  import { widgetEvent, dialogEvent } from '@haohan/clw-utils'
  import { useRouter, useRoute } from 'vue-router'
  const router = useRouter()
  const route = useRoute()
  const project = useProject()

  const keepAliveRouteList = computed(() => project.previewKeepAliveRoute.routeList)
  const activatedRouteKey = computed(() => project.previewKeepAliveRoute.activatedRouteKey)

  const onTagScrollWithMouse = (e: any) => {
    onTagScroll(e.wheelDelta)
  }

  /**
   * 点击 tag 跳转路由
   */
  const onClickRouteLink = (tag: any) => {
    const { key, path, projectId, pageId } = tag
    if (key === project.previewKeepAliveRoute.activatedRouteKey) {
      return
    }

    project.updateActivatedRouteKey(key)

    router.replace({ path, params: { pageId, projectId, clickTag: 1 } }).then(() => {
      const key = route.params.pageId as string
      const nextRouteWidgetEventMap = project.previewKeepAliveRoute.widgetEventMap[key]
      const nextRouteDialogEventMap = project.previewKeepAliveRoute.dialogEventMap[key]

      // widgetEvent
      if (nextRouteWidgetEventMap) {
        widgetEvent.all.clear()

        for (const [k, v] of nextRouteWidgetEventMap.all) {
          if (Array.isArray(v)) {
            v.forEach((i) => {
              widgetEvent.on(k, i)
            })
          }
        }
      }
      // dialogEvent
      if (nextRouteDialogEventMap) {
        dialogEvent.all.clear()

        for (const [k, v] of nextRouteDialogEventMap.all) {
          if (Array.isArray(v)) {
            v.forEach((i) => {
              dialogEvent.on(k, i)
            })
          }
        }
      }
    })
  }

  const isActiveRoute = (key: string) => {
    return key === activatedRouteKey.value
  }

  /**
   * 关闭 tag 跳转路由
   */
  const closeSelectedTag = (index: number) => {
    project.deletePreviewKeepAliveRoute(index).then((lastRoute: any) => {
      const { path, pageId, projectId } = lastRoute
      router.push({ path, params: { pageId, projectId } })
    })
  }

  //清空全部路由
  const clearAllRoute = () => {
    project.clearPreviewKeepAliveRoute()
  }

  const tagArrowVisible = computed(() => {
    const outerWidth = tagScrollOuterRef.value?.offsetWidth
    const bodyWidth = tagScrollBodyRef.value?.offsetWidth
    return bodyWidth > outerWidth
  })

  const tagBodyLeft = ref(0)
  const tagScrollBodyRef = ref()
  const tagScrollOuterRef = ref()
  const onTagScroll = (offset: number) => {
    // 外部宽
    const outerWidth = tagScrollOuterRef.value.offsetWidth
    // 内部宽
    const bodyWidth = tagScrollBodyRef.value.offsetWidth
    if (offset > 0) {
      tagBodyLeft.value = Math.min(0, tagBodyLeft.value + offset)
    } else {
      tagBodyLeft.value =
        outerWidth < bodyWidth ? Math.max(tagBodyLeft.value + offset, outerWidth - bodyWidth) : 0
    }
  }
</script>

<style lang="scss" scoped>
  .tags-view-wrapper {
    display: flex;
    background-color: var(--bg-color-card);
    align-items: center;
    padding-right: 20px;
    box-sizing: border-box;
    border-top: 1px solid var(--el-border-color);

    .expend-warpper-no-padding {
      padding: 0 !important;
    }
    .expend-warpper {
      flex: 1;
      margin: 0 33px;
      padding: 0 30px;
      position: relative;
      border-radius: 0px 0px 10px 10px;

      .left-arrow {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translate(0, -50%);
      }
      .right-arrow {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translate(0, -50%);
      }
      .scroll-wrapper {
        position: relative;
        overflow: hidden;
        .tags-view-content {
          position: relative;
          height: 46px;
          text-align: center;
          display: inline-flex;
          align-items: center;
          user-select: none;
          transition: all 0.4s;

          .active {
            color: var(--el-menu-active-color) !important;
            background-color: var(--bg-color-tab);
            .close {
              color: var(--el-menu-active-color) !important;
            }
          }

          .tags-view-item {
            height: 27px;
            border-radius: 7px 7px 7px 7px;
            padding: 0 12px;
            color: #a1a2a5;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .close-icon {
              margin-left: 8px;
            }
          }
        }
      }
    }
    .icon-btn {
      cursor: pointer;
    }
  }
</style>
