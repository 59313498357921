import { has } from 'lodash-es'

interface WidgetDataInfo {
  list: Widget[]
}
/**
 * 根据工作流节点权限控制，显隐组件列表
 * @param widgetList 工作流表单组件列表
 * @param workflowLimitInfo 工作流当前节点权限控制
 */
export function handleWorkflowFormItem(widgetList: Widget[], workflowLimitInfo: any) {
  const list: Widget[] = []
  widgetList.forEach((wd: Widget) => {
    if (has(wd, 'children')) {
      wd.children?.forEach((w) => {
        w.list = handleWorkflowFormItem(w.list as Widget[], workflowLimitInfo)
      })
      list.push(wd)
    } else if (
      wd.formProp &&
      has(workflowLimitInfo, wd.formProp!.tableColumn) &&
      workflowLimitInfo[wd.formProp!.tableColumn].isView
    ) {
      // 进行权限判断
      wd.formProp!.status = workflowLimitInfo[wd.formProp!.tableColumn].isEdit
        ? 'normal'
        : 'disabled'
      list.push(wd)
    }
  })
  return list
}
/**
 * 将工作流节点节点限制信息转为对象形式
 * 工作流节点节点限制信息
 * [
  {
    "name": "姓名",
    "key": "name",
    "isView": true,
    "isEdit": false
  },
  {
    "name": "理由",
    "key": "yy",
    "isView": true,
    "isEdit": false
  }
]
 * @param workflowLimitInfo 工作流当前节点权限控制
 * @returns 对象形式节点权限控制信息
 */
export function handleWorkFlowLimitInfo2Obj(workflowLimitInfo: any[]) {
  const obj: any = {}
  workflowLimitInfo.forEach((info: any) => {
    obj[info.key] = {
      ...info
    }
  })
  return obj
}
/**
 * 将工作流节点配置信息权限控制配置转化为可用对象形式
 * "formLimit": {"name":["VIEW","EDIT"]}
 * @param workflowLimitConfig 工作流节点权限控制配置
 * @returns
 */
export function handleWorkFlowConfig(workflowLimitConfig: any) {
  const obj: any = {}
  Object.keys(workflowLimitConfig).forEach((key: string) => {
    obj[key] = {
      isView: workflowLimitConfig[key].includes('VIEW'),
      isEdit: workflowLimitConfig[key].includes('EDIT')
    }
  })
  return obj
}
