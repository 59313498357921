<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      width="575"
      class="my-approval-dialog"
      destroy-on-close
      title="审批意见"
      top="30vh"
    >
      <div class="content">
        <el-input
          v-model="textarea"
          class="textarea-input"
          :rows="4"
          type="textarea"
          placeholder="请输入..."
        />
      </div>
      <div class="footer">
        <el-button v-if="title === '同意'" type="primary" @click="handleSubmit"> 同意 </el-button>
        <el-button v-if="title === '拒绝'" type="danger" @click="handleReject"> 拒绝 </el-button>
        <el-button class="cancle" plain @click="dialogVisible = false">再想想</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
  export default {
    name: 'ApprovalDiaLog'
  }
</script>
<script setup lang="ts">
  import { ref, computed } from 'vue'
  import { ElMessageBox, ElMessage } from 'element-plus'
  import { acceptFlowTask, rejectFlowTask } from '@haohan/clw-api'
  import { useProject } from '@haohan/clw-store'

  const emits = defineEmits(['closeDialog'])
  const projectStore = useProject()
  const projectId = computed(() => {
    return projectStore.currentProject?.id
  })

  const textarea = ref('')

  const dialogVisible = ref(false)
  const itemRow = ref({}) as any
  const title = ref('')
  const variables = ref({})
  /**
   * 同意
   */
  async function handleSubmit() {
    if (!textarea.value.trim()) {
      ElMessage.error('审批意见不能为空')
      return
    }
    const params = {
      comment: textarea.value.trim()
    }

    if (variables.value) {
      params.variables = variables.value
    }

    const res = await acceptFlowTask(projectId.value, itemRow.value.id, params)
    if (res.status === 200) {
      ElMessageBox.alert('同意审批完成！', '', {
        confirmButtonText: '确认',
        type: 'success'
      })
      close()
      emits('closeDialog')
    }
  }
  /**
   * 拒绝
   */
  async function handleReject() {
    if (!textarea.value.trim()) {
      ElMessage.error('审批意见不能为空')
      return
    }
    const params = {
      comment: textarea.value.trim()
    }
    const res = await rejectFlowTask(projectId.value, itemRow.value.id, params)
    if (res.status === 200) {
      ElMessageBox.alert('拒绝审批完成！', '', {
        confirmButtonText: '确认',
        type: 'success'
      })
      close()
      emits('closeDialog')
    }
  }
  /**
   * 打开弹框
   */
  function open(row, tip, formValueObj = null) {
    itemRow.value = row
    title.value = tip
    textarea.value = ''
    if (formValueObj) {
      variables.value = formValueObj
    } else {
      variables.value = null
    }
    dialogVisible.value = true
  }

  /**
   * 关闭弹窗
   */
  const close = () => {
    dialogVisible.value = false
  }
  defineExpose({ open, close })
</script>

<style lang="scss">
  .my-approval-dialog {
    .el-dialog__close {
      color: #0076de;
    }
    .el-dialog__body {
      // padding-top: 0;
      // padding-bottom: 0;
      padding: 0 40px 40px 40px;
    }
  }
</style>

<style lang="scss" scoped>
  .content {
    margin-top: 10px;
    .textarea-input {
      :deep(.el-textarea__inner) {
        border-radius: 14px;
      }
    }
  }
  .footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .cancle {
      margin-left: 70px;
    }
  }
</style>
