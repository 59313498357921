<template>
  <div
    class="vertical-menu-wrapper"
    :class="{ 'is-collapse': isCollapse, 'vertical-menu-true': verticalMenuType === 'true' }"
  >
    <div
      v-if="project.currentProject?.settings?.verticalMenu !== 'mixed'"
      class="vertical-menu-header"
    >
      <span v-show="!isCollapse" style="font-size: 18px; font-weight: bold">{{ title }}</span>
      <el-icon style="cursor: pointer" :size="18" @click="isCollapse = !isCollapse">
        <Expand v-show="isCollapse" />
        <Fold v-show="!isCollapse" />
      </el-icon>
    </div>
    <el-menu
      :unique-opened="false"
      :default-active="activeMenu"
      :collapse-transition="false"
      router
      class="menu-list"
      :popper-class="verticalMenuType === 'true' ? 'vertical-menu-popper' : ''"
      :default-openeds="openedsArr"
      :collapse="isCollapse"
    >
      <MenuItem :menu-data="menus"></MenuItem>
    </el-menu>
    <div class="vertical-menu-bottom">
      <el-icon
        v-if="verticalMenuType === 'mixed'"
        style="cursor: pointer"
        :size="18"
        @click="isCollapse = !isCollapse"
      >
        <Expand v-show="isCollapse" />
        <Fold v-show="!isCollapse" />
      </el-icon>
      <ToolControl
        v-if="verticalMenuType == 'true'"
        :is-collapse="isCollapse"
        flex-direction="column"
      />
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'VerticalLeftMenu'
  }
</script>
<script lang="ts" setup>
  import { Expand, Fold } from '@element-plus/icons-vue'
  import { ref, computed, watch, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { filter } from 'lodash-es'
  import { Item } from '../layout'
  import MenuItem from './MenuItem.vue'
  import ToolControl from './ToolControl.vue'
  import { useProject } from '@haohan/clw-store'
  import { createRouterByMenus } from './createRouterByMenus'
  const props = defineProps<{ verticalMenu: Menu[]; title: String }>()
  const router = useRouter()
  const project = useProject()

  const isCollapse = ref(false)

  const verticalMenuType = computed(() => project.currentProject?.settings?.verticalMenu)
  const activatedRouteKey = computed(() => project.previewKeepAliveRoute.activatedRouteKey)
  const openedsArr = computed(() => {
    return menus.value.map((item) => {
      return item.path
    })
  })

  const activeMenu = computed(() => {
    const { meta, path } = router.currentRoute.value
    if (meta.matchPath2) {
      return meta.matchPath2
    }
    return path as any
  })

  const projectId = ref('')
  const route = useRoute()

  onMounted(() => {
    projectId.value = `${
      project.currentProject && project.currentProject.id
        ? project.currentProject.id
        : route.params.projectId || ''
    }`
    // projectId.value = `${route.params.projectId || ''}`
  })

  const menus = computed(() => {
    const _menus = props.verticalMenu || []
    // 过滤非菜单页面
    const _routers = createRouterByMenus(
      projectId.value,
      filter(_menus, (m) => m.name !== 'ROOT' && m.title !== '非菜单页面')
    )
    return _routers
  })

  //查找菜单中第一个页面
  function findFristPage(list: Item[]) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (item.type === 'MENU' && item.children && item.children.length > 0) {
        if (findFristPage(item.children)) {
          return findFristPage(item.children)
        }
      } else if (item.type === 'PAGE') {
        return item
      }
    }
  }

  function flattenTree(menuList: Menu[]): Menu[] {
    let flatList: Menu[] = []
    function flatten(menuList: Menu[]) {
      menuList.forEach((item) => {
        if (item.children) {
          flatten(item.children)
        }
        flatList.push(item)
      })
    }

    flatten(menuList)
    return flatList
  }

  watch(
    menus,
    (newValue) => {
      //只监听菜单双向模式下的路由变化
      if (project.currentProject?.settings?.verticalMenu === 'mixed' && newValue.length > 0) {
        const flatList = flattenTree(newValue)
        if (!flatList.find((item) => item.meta.id === activatedRouteKey.value)) {
          const page = findFristPage(newValue)
          page && router.push(page.path)
        }
      }
    },
    {
      deep: true
    }
  )
</script>

<style lang="scss" scoped>
  .vertical-menu-true {
    background: var(--custom-menu-bg-color);

    :deep(.menu-list) {
      --el-menu-hover-bg-color: var(--custom-menu-active-bg-color);
      --el-menu-hover-text-color: var(--custom-menu-active-color);
      --el-menu-active-color: var(--custom-menu-active-color);
      --el-menu-text-color: var(--custom-menu-text-color);
      --el-menu-border-color: var(--border-color-active);
      & > .el-sub-menu > .el-sub-menu__title {
        font-weight: bold;
        & > .menu-title {
          display: flex;
          flex-direction: column;
          &::after {
            content: '';
            height: 2px;
            width: 100%;
            background: var(--custom-menu-border-color);
          }
        }
      }
      // & > .is-opened .menu-title::after,
      & > .el-sub-menu:last-child .menu-title::after {
        display: none;
      }

      & > .el-sub-menu > .el-menu {
        background-color: var(--custom-menu-item-bg-color) !important;
      }
    }
    :deep(.el-menu) {
      background-color: transparent !important;
      border-right: none;

      .el-sub-menu__title {
        padding: 0 20px;
      }
      .el-menu-item.is-active {
        color: var(--custom-menu-active-color) !important;
        background-color: var(--custom-menu-active-bg-color) !important;
      }
      .el-sub-menu.is-active {
        & > .el-sub-menu__title {
          color: var(--custom-menu-active-color) !important;
        }
      }
    }

    .vertical-menu-bottom {
      color: var(--custom-menu-text-color);
    }
  }

  .is-collapse {
    width: 60px !important;
  }
  .vertical-menu-wrapper {
    width: 200px;
    height: 100%;
    overflow-x: hidden;
    z-index: 9;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    transition: all 0.4s;

    .vertical-menu-header {
      padding: 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #999;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--custom-menu-text-color);
    }

    :deep(.menu-list) {
      flex: 1;
      overflow-x: hidden;
      user-select: none;
      & > .el-sub-menu > .el-sub-menu__title {
        font-weight: bold;
      }
      &:not(.el-menu--collapse) {
        width: 200px;
      }
      & > .el-sub-menu .el-menu {
        background-color: #f7f7f7;
      }
      .el-sub-menu.is-active {
        & > .el-sub-menu__title {
          color: #0176de;
        }
      }
      .el-menu-item.is-active {
        color: #0176de;
        background-color: #ebf4fd;
      }
    }

    .vertical-menu-bottom {
      padding: 20px;
      box-sizing: border-box;
      border-top: 1px solid #999;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
</style>

<style lang="scss">
  .vertical-menu-popper {
    .el-menu--popup {
      padding: 0;
    }
    .el-menu {
      background-color: var(--custom-menu-item-bg-color);
      .el-sub-menu__title,
      .el-menu-item {
        color: var(--custom-menu-text-color);
        &:hover {
          background-color: var(--custom-menu-active-bg-color);
          color: var(--custom-menu-active-color);
        }
      }
      .el-menu-item.is-active {
        color: var(--custom-menu-active-color);
        background-color: var(--custom-menu-active-bg-color);
      }
    }
  }
</style>
