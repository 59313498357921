<script lang="ts">
  import { defineComponent, ref, computed, watchEffect, onMounted, PropType } from 'vue'
  import {
    emitter,
    buildQuery,
    buildTimeRangeWhere,
    getEventValues,
    exeCustomFun,
    buildApiQueryByEvent,
    transformInvokePayloadToFormDataFromKeys,
    handleApiAfterFun,
    getApiDataPath
  } from '@haohan/clw-utils'
  import { useProject, useEvent, useApp, useWidget, useGlobalStore } from '@haohan/clw-store'
  import { filter, cloneDeep, forEach as ForEach, get, has } from 'lodash-es'
  import {
    queryDatasetData,
    getApiMenu,
    testApiMenu,
    invokeApiMenu,
    queryLFMenuItemReq,
    invokeLogicFlow
  } from '@haohan/clw-api'
  import {
    useStyle,
    buildReceiveEvent,
    runPromptlyEvent,
    initByReceiveEvent,
    offReceiveEvent,
    usePanel
  } from '@haohan/clw-hooks'
  import { GLOBAL_CONSTANT } from './constant'
  import {
    hasProp,
    transFormQueryData2SourceData,
    handleApiResponseData,
    transformLogicFlowParams
  } from '@haohan/clw-utils'

  export default defineComponent({
    props: {
      item: {
        type: Object as PropType<Widget>,
        required: true
      }
    },
    setup(props, context) {
      // 使用store
      const globalStore = useGlobalStore()
      const project = useProject()
      const event = useEvent()
      const app = useApp()
      const widget = useWidget()
      const currentProject = computed(() => {
        return project.currentProject
      })
      const commonParameters = computed(() => {
        return event.commonParameters
      })
      const selectedDateRange = computed(() => {
        return app.selectedDateRange
      })
      const pages = computed(() => project.pages)
      const currentPage = computed(() => project.currentPage)
      const receiveEvents = computed(() => event.receiveEvents)
      const editModel = computed(() => globalStore.edieModel)
      const lastUpdateTime = computed(() => globalStore.lastUpdateTime)

      const promptlyEventValues = ref([])
      const filterRange = ref([])
      const filterWhere = ref<any>([])
      const bindComParamValue = ref('')

      // 将组件属性数组转换成对象
      // const attributes = computed(() => transformArray2Object(props.item.attributes))
      const prop = computed(() => props.item.prop)
      const formProp = computed(() => props.item.formProp as any)
      const styleProp = computed(() => props.item.styleProp)
      const dataProp = computed(() => props.item.dataProp)
      // 判断组件监听得交互事件，状态是否已变成Ok
      const isReady = computed(() => {
        const _keys =
          props.item.events
            ?.filter((e) => e.eventType === 'receive')
            .map((e) => e.receiveEventKey) || []
        let readly = true
        // 避免当切换页面时，有残留的组件进行了判断，导致请求错误问题
        if (
          readly &&
          has(props.item, 'dataProp') &&
          has(props.item.dataProp, 'tableId') &&
          ![undefined, ''].includes(props.item.dataProp!.tableId)
        ) {
          if (!event.pageInfo.widgetDataIdKeys.includes(props.item.dataProp!.tableId)) {
            readly = false
            return false
          }
        }
        ForEach(_keys, (k: any) => {
          if (event.mutualPool[k] && event.mutualPool[k].status === 'waiting') {
            readly = false
            return false
          }
        })
        return readly
      })
      // 当监听得监护中，有执行方法为 onlyReceiveEvent 时，则认为需要等到主动执行reloadByEvent时
      // 才会去出发数据得请求
      const canLoadData = ref<boolean>(false)
      onMounted(() => {
        canLoadData.value =
          props.item.events?.findIndex(
            (e: WidgetEvent) =>
              e.eventType === 'receive' && e.performOperation === 'onlyReceiveEvent'
          ) === -1
      })
      // 获取属性中全部的样式属性
      const { box } = useStyle(styleProp)

      const tableId = computed(() => dataProp.value?.tableId)
      // 组件数据的类型，可能未定义
      const dataSourceType = computed<any>(() => props.item.dataSourceType)
      // 主题
      const pageTheme = computed(() => project.currentPage.variables?.theme)
      // 组件的查询时间
      const queryRangs = computed(() => {
        const rangs = buildTimeRangeWhere(app.selectedDateRange, filterRange.value, {
          queryTimeType: dataProp.value?.queryTimeType,
          startInterval: dataProp.value?.startInterval,
          endtInterval: dataProp.value?.endtInterval
        })
        return rangs
      })
      const pageStyle = computed(() => {
        const bgColor = project?.currentPage?.variables?.bgColor
        return bgColor
      })
      function clickWidget() {
        widget.changeSelectWidget(props.item)
      }
      // 根据全局变量设置默认值
      function setComParamValue() {
        if (
          prop.value?.comParam &&
          prop.value.comParam.trim() !== '' &&
          commonParameters.value[prop.value.comParam]
        ) {
          bindComParamValue.value = commonParameters.value[prop.value.comParam].value!
        }
        if (
          formProp.value?.comParam &&
          formProp.value.comParam.trim() !== '' &&
          commonParameters.value[formProp.value.comParam]
        ) {
          bindComParamValue.value = commonParameters.value[formProp.value.comParam].value!
        }
      }
      function setDateRange(timerange: string[]) {
        return app.setDateRange(timerange)
      }
      function updateCommonParameterValue(kv: any) {
        return event.updateCommonParameterValue(kv)
      }
      const currentCompKey = computed(() => props.item.key)
      const { activePanelIx, mappingTabsKey, activePanelKey } = usePanel()

      return {
        promptlyEventValues,
        filterRange,
        filterWhere,
        bindComParamValue,
        prop,
        formProp,
        styleProp,
        dataProp,
        box,
        currentProject,
        commonParameters,
        selectedDateRange,
        tableId,
        dataSourceType,
        isReady,
        pages,
        currentPage,
        receiveEvents,
        clickWidget,
        setComParamValue,
        setDateRange,
        updateCommonParameterValue,
        pageTheme,
        editModel,
        widget,
        queryRangs,
        canLoadData,
        hasProp,
        pageStyle,
        activePanelIx,
        mappingTabsKey,
        currentCompKey,
        activePanelKey,
        lastUpdateTime
      }
    },
    data() {
      return {
        data: {
          content: [],
          page: 1,
          size: 10,
          total: 0,
          totalPages: 0
        },
        queryPage: 1,
        loading: false,
        stopLodaData: undefined as any,
        sourceData: [] as any,
        loadDataRepeatTimer: undefined as any, // 重复执行加载数据定时器
        apiResponseInfo: undefined as any // 记录API返回数据
      }
    },
    computed: {
      list() {
        if (this.dataSourceType === 'LocalData') {
          return this.data.content
        } else if (
          this.dataSourceType === 'TableSource' ||
          ((this.dataSourceType === 'CustomApi' || this.dataSourceType === 'LogicFlowSource') &&
            this.dataProp?.apiDataPath !== '')
        ) {
          const xAxisField = this.dataProp?.xAxisField
          const labelCol = this.dataProp?.labelCol || this.dataProp?.xAxisField
          if (xAxisField) {
            if (this.data.content instanceof Array) {
              return this.data.content?.map((el: any) => {
                return {
                  label: el[labelCol],
                  value: el[xAxisField],
                  children: el.children
                }
              })
            }
            return []
          }
        } else if (
          this.dataSourceType === 'CustomApi' ||
          this.dataSourceType === 'LogicFlowSource'
        ) {
          // 兼容 2.6.3之前得斑斑
          if (!has(this.dataProp, 'apiDataPath')) {
            // 当数据源为自定义API时，直接取对应属性的值
            return get(this.data.content, this.dataProp?.xAxisField)
          }
          const _data = this.data.content.data
          const xAxisField = this.dataProp?.xAxisField
          const labelCol = this.dataProp?.labelCol || this.dataProp?.xAxisField
          if (xAxisField) {
            if (_data instanceof Array) {
              return _data.map((el: any) => {
                return {
                  label: el[labelCol],
                  value: el[xAxisField],
                  children: el.children
                }
              })
            }
            return []
          }
        }
        return []
      }
    },
    watch: {
      item: {
        handler() {
          if (this.editModel) {
            this.buildOption()
          }
        },
        deep: true
      },
      'item.customDataHandle': {
        handler(nv) {
          // console.log(nv)
          this.data.content = this.handleData(this.sourceData.content)
        }
      },
      list: {
        handler(nv) {
          if (this.item.type === 'HHSelectWidget') {
            const levels = this.list.map((el) => this.calcLevel(el))
            // console.log(levels)
            this.widget.upCascaderLevel(1 + Math.max(...levels))
          }
        },
        deep: true
      },
      selectedDateRange: {
        handler(val) {
          if (!this.editModel) {
            this.loadData()
          }
        },
        deep: true
      },
      'dataProp.apiDataPath': {
        handler(nv) {
          this.handleCustomApiData()
        }
      }
    },
    mounted() {
      this.buildOption()
      this.buildOnEvent()
      // 初次加载时，需要根据组件加载顺序，执行一次监听事件
      initByReceiveEvent(this.item.events || [], this)
      this.buildSendEvent()
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this
      if (this.editModel) {
        this.stopLodaData = watchEffect(() => {
          _this.loadData()
        })
      } else {
        if (this.isReady) {
          _this.loadData()
        }
        if (this.dataSourceType === 'LocalData') {
          this.data.content = this.dataProp?.defaultData
        }
      }
      if (this.editModel) {
        emitter.on('change-dit-page', this.stopEditModelWatch)
      }
    },
    beforeMount() {
      if (this.stopLodaData) {
        this.stopLodaData()
      }
    },
    beforeUnmount() {
      if (this.editModel) {
        emitter.off('change-dit-page')
      }
      offReceiveEvent(this.item.events || [], this)
    },
    methods: {
      // 避免在steup中时，继承得子组件覆盖时，无法正常执行覆盖得方法
      buildOption() {
        this.setComParamValue()
      },
      buildSendEvent() {
        runPromptlyEvent(this.item.events || [], this)
      },
      buildOnEvent() {
        // 查找出所有得接收事件
        if (this.item.events && this.item.events.length > 0) {
          const receiveEvent = filter(this.item.events, (e) => e.eventType === 'receive')
          if (receiveEvent.length > 0) {
            buildReceiveEvent(receiveEvent, this)
          }
        }
      },
      reload() {
        if (this.tableId) {
          this.loadData()
        }
      },
      /**
       * 执行加载数据函数，继承类可重写该方法
       */
      exeReloadByEvent() {
        this.canLoadData = true
        this.reload()
      },
      /**
       * 增加避免短时间内重复执行加载数据
       */
      reloadByEvent() {
        if (this.loadDataRepeatTimer) {
          clearTimeout(this.loadDataRepeatTimer)
        }
        this.loadDataRepeatTimer = setTimeout(
          this.exeReloadByEvent,
          GLOBAL_CONSTANT.LOAD_DATA_REPEAT_DELAY
        )
      },
      // reloadByEvent() {
      //   this.canLoadData = true
      //   this.reload()
      // },

      async loadData() {
        console.log('触发 CommonForGeneral loadData...')
        // 服务器数据
        if (this.dataSourceType === 'TableSource') {
          if (
            !this.tableId ||
            !this.isReady ||
            !this.canLoadData ||
            this.dataSourceType !== 'TableSource'
          ) {
            return false
          }
          this.loading = true
          // 避免无法正确获得store中的时间值
          const appStore = useApp()
          const queryParam: any = buildQuery(
            buildTimeRangeWhere(appStore.selectedDateRange, this.filterRange, {
              queryTimeType: this.dataProp?.queryTimeType,
              startInterval: this.dataProp?.startInterval,
              endtInterval: this.dataProp?.endtInterval
            }),
            getEventValues(this.item.events || []),
            this.filterWhere
          )
          queryParam.page = this.queryPage
          queryParam.size = this.dataProp?.pageSize ? +this.dataProp?.pageSize : 10
          // console.log('[ loadData ]-207', queryParam)
          queryDatasetData(this.currentProject.id, this.tableId, queryParam)
            .then((resp: any) => {
              const _d = transFormQueryData2SourceData(resp.data)
              this.sourceData = _d
              this.data.content = this.handleData(_d.content)
            })
            .finally(() => {
              this.loading = false
            })

          // 自定义 API
        } else if (this.dataSourceType === 'CustomApi') {
          if (!this.tableId) {
            this.data = {
              content: [],
              page: 1,
              size: 10,
              total: 0,
              totalPages: 0
            }
            return false
          }
          const projectStore = useProject()
          const projectId = projectStore.currentProject?.id
          const { data } = await getApiMenu(projectId, this.tableId)
          // 拼装交互过滤参数
          const eventQueryParam = buildApiQueryByEvent(getEventValues(this.item.events || []))
          // eval 用到这个 res
          const res = await invokeApiMenu(
            projectId,
            this.tableId,
            transformInvokePayloadToFormDataFromKeys(data?.keysDefine.def, eventQueryParam),
            data.headers
          )
          // 占位, 不然生产环境 res 会被删掉
          const resTemp = cloneDeep(res)
          // 记录下接口返回数据，以便层级变化时，更改数据
          this.apiResponseInfo = {
            api: data,
            data: handleApiResponseData(handleApiAfterFun(data.postPosition, res))
          }
          this.handleCustomApiData()
          // 本地数据
        } else if (this.dataSourceType === 'LogicFlowSource') {
          if (!this.tableId) {
            this.data = {
              content: [],
              page: 1,
              size: 10,
              total: 0,
              totalPages: 0
            }
            return false
          }
          const projectStore = useProject()
          const projectId = projectStore.currentProject?.id
          // 获得逻辑流详细信息
          const { data } = await queryLFMenuItemReq(projectId, this.tableId)
          // 获得交互查询参数等信息
          const eventQueryParam = buildApiQueryByEvent(getEventValues(this.item.events || []))
          // 处理逻辑流数据
          const res = await invokeLogicFlow(
            projectId,
            this.tableId,
            transformLogicFlowParams(data.parameters, eventQueryParam)
          )
          this.apiResponseInfo = {
            api: data,
            data: handleApiResponseData(res)
          }
          this.handleCustomApiData()

          // 本地数据
        } else if (this.dataSourceType === 'LocalData') {
          this.data.content = this.dataProp?.defaultData
        }
      },
      handleData(data: any): any {
        // TODO: 子组件可覆盖刚方法进行自定义数据处理
        if (this.item.customDataHandle) {
          return exeCustomFun(this.item.customDataHandle, data)
        }
        return data
      },
      onlyReceiveEvent() {
        // 仅接收交互值，不做任何操作
      },
      calcLevel(item: any): number {
        if (item.children?.length) {
          return 1 + this.calcLevel(item.children)
        }
        return 0
      },
      stopEditModelWatch() {
        if (this.editModel && this.stopLodaData) {
          this.stopLodaData()
        }
      },
      handleCustomApiData() {
        const initData = (_d = []) => {
          const _data = {
            content: _d,
            page: 1,
            size: 10,
            total: 0,
            totalPages: 0
          }
          this.data = _data
        }
        // 兼容之前定义在API接口中的 dataPath
        // const dataPath = this.item.dataProp?.apiDataPath
        //   ? this.item.dataProp.apiDataPath
        //   : this.apiResponseInfo.api
        //   ? this.apiResponseInfo.api.responseDefine?.dataPath
        //   : ''
        const dataPath = getApiDataPath(this.item.dataProp?.apiDataPath, this.apiResponseInfo.api)
        if (dataPath || dataPath === '') {
          try {
            // 根据自定义的 dataPath 去取数据
            // const content = eval(`res.${dataPath}`)
            const content =
              dataPath === '' ? this.apiResponseInfo.data : get(this.apiResponseInfo.data, dataPath)
            if (content) {
              const dData = content
              // 执行自定义函数, 转换每行内容
              this.handleData(dData)
              const _data = {
                content: dData,
                total: dData.length,
                totalPages: 1,
                page: 1,
                size: dData.length
              }
              this.data = _data
            } else {
              initData()
            }
          } catch (error) {
            initData()
          }
        } else if (['HHText'].includes(this.item.type)) {
          // 有一些组件不需要指定层级，直接使用具体的属性
          initData(this.apiResponseInfo.data)
        } else {
          initData()
        }
      }
    }
  })
</script>
