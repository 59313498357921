import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios'
import { getToken } from '../auth'
import cache from './http-cache'
import { useMessage } from '@haohan/clw-hooks'
/**
 * 第三方API请求封装
 */
const BASE_URL = '.'
const API_CACHE_TIME = 1000
class HttpRequest {
  constructor(public baseUrl: string) {
    this.baseUrl = baseUrl
  }
  public request(options: AxiosRequestConfig): AxiosPromise {
    const instance: AxiosInstance = axios.create({
      adapter: cache({ time: API_CACHE_TIME })
    })
    options = this.mergeConfig(options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
  private interceptors(instance: AxiosInstance, url?: string) {
    instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (!config.headers) {
          // menuId 为了方便日志记录时使用
          config.headers = { authorization: '', menuId: '' }
        }
        config.headers.authorization = getToken()
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        return res
      },
      (error) => {
        console.log('API第三方接口请求错误', error)
        // 获取异常状态码
        const { status, tag } = error.response.data
        let message = ''
        if (status === 400) {
          message = '接口定义错误，请检查接口配置。'
        } else if (status === 502) {
          message = '接口调用错误，请检查调用环境'
        } else {
          message = '未定义错误，请联系管理员'
        }
        useMessage({ ...error.response.data, message, tag: '' })
        return Promise.reject(error)
      }
    )
  }
  private mergeConfig(options: AxiosRequestConfig): AxiosRequestConfig {
    return Object.assign({ baseURL: this.baseUrl }, options)
  }
}

export const interfaceService = new HttpRequest(BASE_URL)
