import { http } from '@haohan/clw-utils'

const URL = '/api/v1/projects'
/**
 * 查询树结构
 */
export function queryLFMenuTreeReq(pid: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${pid}/flowbiz/tree`
  })
}
/**
 * 新增 逻辑流
 */
export function addLFMenuItemReq(pid: string, params: any) {
  return http.request({
    method: 'post',
    url: `${URL}/${pid}/flowbiz`,
    data: params
  })
}
/**
 * 修改 逻辑流
 */
export function editLFMenuItemReq(pid: string, id: string, params: any) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${pid}/flowbiz/${id}`,
    data: params
  })
}
/**
 * 删除 逻辑流
 */
export function delLFMenuItemReq(pid: string, id: string) {
  return http.request({
    method: 'DELETE',
    url: `${URL}/${pid}/flowbiz/${id}`
  })
}
/**
 * 详情 逻辑流
 */
export function queryLFMenuItemReq(pid: string, id: string) {
  return http.request({
    method: 'GET',
    url: `${URL}/${pid}/flowbiz/${id}`
  })
}
/**
 *  逻辑流 重命名
 */
export function renameLFMenuItemReq(pid: string, id: string, params: any) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${pid}/flowbiz/rename/${id}`,
    data: params
  })
}
/**
 *  逻辑流 移动
 */
export function moveLFMenuItemReq(pid: string, params: any) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${pid}/flowbiz/move`,
    data: params
  })
}
/**
 *  逻辑流 复制
 */
export function copyLFMenuItemReq(pid: string, id: string) {
  return http.request({
    method: 'GET',
    url: `${URL}/${pid}/flowbiz/copy/${id}`
  })
}

/**
 * 测试 逻辑流
 */
export function testLogicFlow(pid: string, id: string, params: any) {
  return http.request({
    method: 'post',
    url: `${URL}/${pid}/flowbiz/${id}/test`,
    data: params
  })
}

/**
 * 运行 逻辑流
 */
export function invokeLogicFlow(pid: string, id: string, params: any) {
  return http.request({
    method: 'post',
    url: `${URL}/${pid}/flowbiz/${id}/invoke`,
    data: params
  })
}
