import { http } from '@haohan/clw-utils'

const PATH = '/api/v1/auth'
const SMS = '/api/v1/sms'
const ENV = '/api/v1/current_environment'

interface CaptchaResponse {
  id: string
  image: string
}

export function getCaptcha(): any {
  return http.request({
    url: `${PATH}/captcha`,
    method: 'GET'
  })
}
export function login(data: LoginRequest) {
  return http.request({
    url: `${PATH}/login`,
    method: 'post',
    data
  })
}
export function getInfo() {
  return http.request({
    url: `${PATH}/currentUser`,
    method: 'get'
  })
}
export function logout() {
  return http.request({
    url: `${PATH}/logout`,
    method: 'get'
  })
}
export function getRegionList() {
  return http.request({
    url: '/api/v1/region',
    method: 'get'
  })
}
/**
 * 发送验证码
 */
export function sendPhoneCodeReq(phone: string, type: string) {
  return http.request({
    url: `${SMS}/${phone}/${type}`,
    method: 'get'
  })
}
/**
 * 检查验证码
 */
export function checkCodeReq(data: any) {
  return http.request({
    url: `${SMS}/check`,
    method: 'post',
    data
  })
}
/**
 * 用户登录（短信）
 */
export function loginWithCodeReq(data: any) {
  return http.request({
    url: `${PATH}/loginSms`,
    method: 'post',
    data
  })
}
/**
 * 找回密码
 */
export function backPasswordReq(data: any) {
  return http.request({
    url: `/api/v1/users/retrievePassword`,
    method: 'PUT',
    data
  })
}
/**
 * 注册公司
 */
export function userRegisterReq(data: any) {
  return http.request({
    url: `/api/v1/company/register`,
    method: 'post',
    data
  })
}

/**
 * 获取当前环境信息
 * @returns
 */
export function envInfoReq(): any {
  return http.request({
    url: `${ENV}`,
    method: 'GET'
  })
}
