import { getKey } from '@haohan/clw-utils'
import {
  WORK_FLOW_NODE_TYPE,
  WorkFlowApplicationNode,
  WorkFlowApprovalNode,
  WorkFlowFillNode,
  WorkFlowCCNode,
  WorkFlowDataAddNode,
  WorkFlowDataUpdateNode,
  WorkFlowConditionalBranch,
  WorkFlowConditionalNode,
  WorkFlowMultipleConditionalBranch,
  WORK_FLOW_NODE_CLASS_TYPE,
  WORK_FLOW_CLASS_TYPE,
  ConditionSequenceFlow,
  WORK_FLOW_BASE_NODE_UNION_TYPE,
  BpmnNodeType,
  BpmnNodeTypes,
  BpmnStartEvent,
  BpmnEndEvent,
  BpmnExclusiveGateway,
  BpmnSequenceFlow,
  BpmnUserTask
} from '../workflow-class'
import { cloneDeep, has } from 'lodash-es'
import { useWorkflow } from '../store/workflow'
/**
 * 根据节点类型创建对应节点
 * @param type 需要创建的节点类型
 * @returns 创建好的节点
 */
export const createNodeByType = (type: WORK_FLOW_NODE_TYPE) => {
  switch (type) {
    case WORK_FLOW_NODE_TYPE.APPROVAL_NODE:
      return new WorkFlowApprovalNode()
    case WORK_FLOW_NODE_TYPE.FILL_NODE:
      return new WorkFlowFillNode()
    case WORK_FLOW_NODE_TYPE.CC_NODE:
      return new WorkFlowCCNode()
    case WORK_FLOW_NODE_TYPE.CONDITIONAL_NODE:
      return new WorkFlowConditionalNode()
    case WORK_FLOW_NODE_TYPE.DATA_ADD_NODE:
      return new WorkFlowDataAddNode()
    case WORK_FLOW_NODE_TYPE.DATA_UPDATE_NODE:
      return new WorkFlowDataUpdateNode()
    case WORK_FLOW_NODE_TYPE.CONDITIONAL_BRANCH:
      return new WorkFlowConditionalBranch()
    case WORK_FLOW_NODE_TYPE.MULTIPLE_CONDITIONAL_BRANCH:
      return new WorkFlowMultipleConditionalBranch()
    default:
      return new WorkFlowApplicationNode()
  }
}
/**
 * 根据工作流节点集合转为BPMN需要的XML信息
 * @param nodes 工作流节点集合
 * @returns BPMN XML
 */
export const getBpmnXmlByWorkFlow = (
  nodes: WORK_FLOW_NODE_CLASS_TYPE,
  bpmnProcessName = '流程'
) => {
  // Bpmn节点
  const bpmnNodes: BpmnNodeTypes = []
  parseWorkFlow2Bpmn(nodes, bpmnNodes, new ConditionSequenceFlow([], nodes, false))
  console.log('getBpmnXmlByWorkFlow nodes ===>', nodes)
  console.log('getBpmnXmlByWorkFlow bpmnNodes ===>', bpmnNodes)
  // 添加结束节点
  addBpmnEndEvent(nodes, bpmnNodes)
  // 将BPMN节点拼成最后需要的XML信息
  const xml = parseBpmnNode2Xml(bpmnNodes, bpmnProcessName)
  return xml
}
/**
 * 将工作流数组解析为Bpmn格式的xml
 * @param nodes 工作流数组
 */
export const parseWorkFlow2Bpmn = (
  nodes: WORK_FLOW_NODE_CLASS_TYPE,
  bpmnNodes: BpmnNodeTypes,
  conditionSequenceFlow: ConditionSequenceFlow
) => {
  // 解析工作流设计器内容
  nodes.forEach((node: WORK_FLOW_CLASS_TYPE, index: number) => {
    // 在不是条件节点遍历时，更新索引供后面使用
    if (!conditionSequenceFlow.isConditionSequenceFlow) {
      conditionSequenceFlow.currentWorkFlowNodeIndex = index
    }
    if (conditionSequenceFlow.isConditionSequenceFlow) {
      conditionSequenceFlow.currentConditionIndex = index
    }
    // TODO: 需要增加条件节点最后节点的处理
    if (node.type === WORK_FLOW_NODE_TYPE.APPLICATION_NODE) {
      parseApplicationNode(node as WorkFlowApplicationNode, bpmnNodes)
    } else if (node.type === WORK_FLOW_NODE_TYPE.CONDITIONAL_BRANCH) {
      parseWorkFlowConditionalBranch(
        node as WorkFlowConditionalBranch,
        bpmnNodes,
        conditionSequenceFlow
      )
    } else if (node.type === WORK_FLOW_NODE_TYPE.MULTIPLE_CONDITIONAL_BRANCH) {
      parseWorkFlowMultipleConditionalBranch(
        node as WorkFlowMultipleConditionalBranch,
        bpmnNodes,
        conditionSequenceFlow
      )
    } else {
      // 记录当前节点之前的节点信息
      conditionSequenceFlow.parentNode = index !== 0 ? nodes[index - 1] : null
      parseBaseNode(node as WORK_FLOW_BASE_NODE_UNION_TYPE, bpmnNodes, conditionSequenceFlow)
    }
  })
}
/**
 * 解析申请节点
 * @param node 申请节点
 * @param bpmnNodes BPMN 节点集合
 */
const parseApplicationNode = (node: WorkFlowApplicationNode, bpmnNodes: BpmnNodeTypes) => {
  // 创建任务节点
  const useTask = new BpmnUserTask(node.id, node.name, node.type, node.prop, node.useGroups)
  // 创建开始节点
  const startEvent = new BpmnStartEvent([])
  // 创建顺序流
  const sFlow = new BpmnSequenceFlow(startEvent.id, useTask.id, [])
  // 设置开始节点的出口
  startEvent.outId.push(sFlow.id)
  // 添加到节点中
  bpmnNodes.push(startEvent)
  bpmnNodes.push(sFlow)
  bpmnNodes.push(useTask)
}
/**
 * 解析基础节点
 * @param node 基础节点
 * @param bpmnNodes BPMN 节点集合
 * @param conditionSequenceFlow 顺序流信息
 */
const parseBaseNode = (
  node: WORK_FLOW_BASE_NODE_UNION_TYPE,
  bpmnNodes: BpmnNodeTypes,
  conditionSequenceFlow: ConditionSequenceFlow
) => {
  // 创建任务节点
  const useTask = new BpmnUserTask(node.id, node.name, node.type, node.prop, node.useGroups)
  // 创建顺序流
  createBpmnSequenceFlow(useTask.id, bpmnNodes, conditionSequenceFlow)
  bpmnNodes.push(useTask)
}

const createBpmnSequenceFlow = (
  targetId: string,
  bpmnNodes: BpmnNodeTypes,
  conditionSequenceFlow: ConditionSequenceFlow
) => {
  // 如果是条件分支下的第一个节点，则直接取条件网关的ID作为源ID
  if (
    conditionSequenceFlow.isConditionSequenceFlow &&
    conditionSequenceFlow.currentConditionIndex === 0
  ) {
    const sFlow = new BpmnSequenceFlow(
      conditionSequenceFlow.parentBpmnNode.id,
      targetId,
      conditionSequenceFlow.condition,
      conditionSequenceFlow.isConditionContrary
    )
    // 添加到节点中
    bpmnNodes.push(sFlow)
  } else if (conditionSequenceFlow.isConditionSequenceFlow) {
    if (
      conditionSequenceFlow.parentNode.type === WORK_FLOW_NODE_TYPE.CONDITIONAL_BRANCH ||
      conditionSequenceFlow.parentNode.type === WORK_FLOW_NODE_TYPE.MULTIPLE_CONDITIONAL_BRANCH
    ) {
      const lastNodesInfo: WORK_FLOW_CLASS_TYPE[] = []
      findLastNodeInfo(conditionSequenceFlow.parentNode, lastNodesInfo)
      lastNodesInfo.forEach((node: WORK_FLOW_CLASS_TYPE) => {
        // 这里忽略考虑条件分支是/否的区别
        const sFlow = new BpmnSequenceFlow(
          getBpmnNodeIdByWorkFlowNodeType(node),
          targetId,
          [],
          false
        )
        // 添加到节点中
        bpmnNodes.push(sFlow)
      })
    } else {
      const preBN = bpmnNodes[bpmnNodes.length - 1]
      const sFlow = new BpmnSequenceFlow(
        preBN.id,
        targetId,
        conditionSequenceFlow.condition,
        conditionSequenceFlow.isConditionContrary
      )
      // 添加到节点中
      bpmnNodes.push(sFlow)
    }
  } else {
    const lastNodesInfo: WORK_FLOW_CLASS_TYPE[] = []
    findLastNodeInfo(
      conditionSequenceFlow.workflowNodes[conditionSequenceFlow.currentWorkFlowNodeIndex - 1],
      lastNodesInfo
    )
    lastNodesInfo.forEach((node: WORK_FLOW_CLASS_TYPE) => {
      // 这里忽略考虑条件分支是/否的区别
      const sFlow = new BpmnSequenceFlow(getBpmnNodeIdByWorkFlowNodeType(node), targetId, [], false)
      // 添加到节点中
      bpmnNodes.push(sFlow)
    })
  }
}
/**
 * 根据工作流节点类型获取BPMN节点ID
 * @param workFlowNodes 工作流节点
 * @returns 对应BPMN节点ID
 */
const getBpmnNodeIdByWorkFlowNodeType = (workFlowNodes: WORK_FLOW_CLASS_TYPE): string => {
  if (
    workFlowNodes.type === WORK_FLOW_NODE_TYPE.CONDITIONAL_BRANCH ||
    workFlowNodes.type === WORK_FLOW_NODE_TYPE.MULTIPLE_CONDITIONAL_BRANCH
  ) {
    return `Gateway_${workFlowNodes.id}`
  }
  return `Activity_${workFlowNodes.id}`
}

/**
 * 解析条件节点
 * @param node 申请节点
 * @param bpmnNodes BPMN 节点集合
 * @param conditionSequenceFlow 顺序流信息
 */
const parseWorkFlowConditionalBranch = (
  node: WorkFlowConditionalBranch,
  bpmnNodes: BpmnNodeTypes,
  conditionSequenceFlow: ConditionSequenceFlow
) => {
  // const preBN = bpmnNodes[bpmnNodes.length - 1]
  // 创建网关
  const gateWay = new BpmnExclusiveGateway(node.id)
  // 创建上个节点到网关的顺序流
  // const sFlow = new BpmnSequenceFlow(
  //   preBN.id,
  //   gateWay.id,
  //   conditionSequenceFlow.condition,
  //   conditionSequenceFlow.isConditionContrary
  // )
  createBpmnSequenceFlow(gateWay.id, bpmnNodes, conditionSequenceFlow)
  // 条件到Bpmn节点中
  // bpmnNodes.push(sFlow)
  bpmnNodes.push(gateWay)
  // 处理是条件
  const cfN = new ConditionSequenceFlow(node.condition, conditionSequenceFlow.workflowNodes)
  cfN.parentBpmnNode = gateWay
  cfN.currentWorkFlowNodeIndex = conditionSequenceFlow.currentWorkFlowNodeIndex
  parseWorkFlow2Bpmn(node.conformNodeList, bpmnNodes, cfN)
  // 处理否条件
  const crf = new ConditionSequenceFlow(node.condition, conditionSequenceFlow.workflowNodes)
  crf.isConditionContrary = true
  crf.parentBpmnNode = gateWay
  crf.currentWorkFlowNodeIndex = conditionSequenceFlow.currentWorkFlowNodeIndex
  parseWorkFlow2Bpmn(node.rejectNodeList, bpmnNodes, crf)
}
/**
 *
 * @param node 多条件节点
 * @param bpmnNodes BPMN 节点集合
 * @param conditionSequenceFlow 顺序流信息
 */
const parseWorkFlowMultipleConditionalBranch = (
  node: WorkFlowMultipleConditionalBranch,
  bpmnNodes: BpmnNodeTypes,
  conditionSequenceFlow: ConditionSequenceFlow
) => {
  // const preBN = bpmnNodes[bpmnNodes.length - 1]
  // 创建网关
  const gateWay = new BpmnExclusiveGateway(node.id)
  // 创建上个节点到网关的顺序流
  // const sFlow = new BpmnSequenceFlow(
  //   preBN.id,
  //   gateWay.id,
  //   conditionSequenceFlow.condition,
  //   conditionSequenceFlow.isConditionContrary
  // )
  // // 条件到Bpmn节点中
  // bpmnNodes.push(sFlow)
  createBpmnSequenceFlow(gateWay.id, bpmnNodes, conditionSequenceFlow)
  bpmnNodes.push(gateWay)
  // 处理各条件
  node.conditionNodeList.forEach((n: WorkFlowConditionalNode) => {
    if (n.conditionNodeList.length > 0) {
      const csf = new ConditionSequenceFlow(n.condition, conditionSequenceFlow.workflowNodes)
      csf.parentBpmnNode = gateWay
      csf.currentWorkFlowNodeIndex = conditionSequenceFlow.currentWorkFlowNodeIndex
      parseWorkFlow2Bpmn(n.conditionNodeList, bpmnNodes, csf)
    }
  })
}
/**
 * 添加结束节点
 * @param nodes 全部节点信息
 * @param bpmnNodes BPMN 节点集合
 */
const addBpmnEndEvent = (nodes: WORK_FLOW_NODE_CLASS_TYPE, bpmnNodes: BpmnNodeTypes) => {
  // 判断最后一个工作流节点是什么类型
  const lastNode = nodes[nodes.length - 1]
  // 创建结束事件
  const endEvent = new BpmnEndEvent([])
  const lastNodesInfo: WORK_FLOW_CLASS_TYPE[] = []
  findLastNodeInfo(lastNode, lastNodesInfo)
  lastNodesInfo.forEach((n: WORK_FLOW_CLASS_TYPE) => {
    const sf = new BpmnSequenceFlow(getBpmnNodeIdByWorkFlowNodeType(n), endEvent.id, [])
    bpmnNodes.push(sf)
    endEvent.inId.push(sf.id)
  })
  bpmnNodes.push(endEvent)
}

const findLastNodeInfo = (node: WORK_FLOW_CLASS_TYPE, lastNodesInfo: WORK_FLOW_CLASS_TYPE[]) => {
  if (node.type === WORK_FLOW_NODE_TYPE.CONDITIONAL_BRANCH) {
    handleWorkFlowConditionalBranchLastNodesInfo(node as WorkFlowConditionalBranch, lastNodesInfo)
  } else if (node.type === WORK_FLOW_NODE_TYPE.MULTIPLE_CONDITIONAL_BRANCH) {
    handleWorkFlowMultipleConditionalBranchLastNodesInfo(
      node as WorkFlowMultipleConditionalBranch,
      lastNodesInfo
    )
  } else {
    lastNodesInfo.push(node)
  }
}
/**
 * 查找条件节点的最后节点信息
 * @param node 节点信息
 * @param lastNodesInfo 最后一个节点的信息
 */
const handleWorkFlowConditionalBranchLastNodesInfo = (
  node: WorkFlowConditionalBranch,
  lastNodesInfo: WORK_FLOW_CLASS_TYPE[]
) => {
  if (node.conformNodeList.length > 0) {
    const lastNode = node.conformNodeList[node.conformNodeList.length - 1]
    findLastNodeInfo(lastNode, lastNodesInfo)
  }
  if (node.rejectNodeList.length > 0) {
    const lastNode = node.rejectNodeList[node.rejectNodeList.length - 1]
    findLastNodeInfo(lastNode, lastNodesInfo)
  }
  // 考虑分支下没有节点的情况
  if (node.conformNodeList.length === 0 || node.rejectNodeList.length === 0) {
    lastNodesInfo.push(node)
  }
}
/**
 * 查找多条件节点的最后节点信息
 * @param node 节点信息
 * @param lastNodesInfo 最后一个节点的信息
 */
const handleWorkFlowMultipleConditionalBranchLastNodesInfo = (
  node: WorkFlowMultipleConditionalBranch,
  lastNodesInfo: WORK_FLOW_CLASS_TYPE[]
) => {
  if (node.conditionNodeList.length > 0) {
    let addGateWay = false
    node.conditionNodeList.forEach((n: WorkFlowConditionalNode) => {
      if (n.conditionNodeList.length > 0) {
        const lastNode = n.conditionNodeList[n.conditionNodeList.length - 1]
        findLastNodeInfo(lastNode, lastNodesInfo)
      } else {
        addGateWay = true
      }
    })
    if (addGateWay) {
      lastNodesInfo.push(node)
    }
  }
}
/**
 * 将BPMN节点转为需要的XML
 * @param bpmnNodes BPMN节点信息
 * @param bpmnProcessName BPMN流程名称
 * @returns bpmn xml
 */
const parseBpmnNode2Xml = (bpmnNodes: BpmnNodeTypes, bpmnProcessName = '流程'): string => {
  const xmls: string[] = []
  bpmnNodes.forEach((node: BpmnNodeType) => {
    xmls.push(node.getXml())
  })
  // const processId = `Process_${getKey()}`
  return `<?xml version="1.0" encoding="UTF-8"?> <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:flowable="http://flowable.org/bpmn" id="Process_${getKey()}" targetNamespace="http://flowable.org/bpmn" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd">
      <bpmn2:process id="Process_${getKey()}" name="${bpmnProcessName}" isExecutable="true">
          ${xmls.join('\n')}
      </bpmn2:process>
      <bpmndi:BPMNDiagram id="BPMNDiagram_${getKey()}"></bpmndi:BPMNDiagram>
  </bpmn2:definitions>`
}
/**
 * 递归获得表单字段结合
 * @param widgetList 表单组件集合
 * @param formFields 表单字段集合
 */
export const getFormFields = (widgetList: any[], formFields: FormField[]) => {
  // const formFields: FormField[] = []
  widgetList.forEach((wd: any) => {
    // 面板特殊处理
    if (wd.type === 'FormTabPane') {
      wd.dataProp.defaultData.forEach((_w) => getFormFields(_w.list, formFields))
    } else if (wd.type === 'HHGrid') {
      // 容器特殊处理
      wd.children.forEach((_w) => getFormFields(_w.list, formFields))
    } else if (has(wd.formProp, 'tableColumn')) {
      // 因为日期存在时间段和点的区别，这里做下特殊的处理
      const type =
        wd.type === 'FormDatetimePicker' && wd.formProp.model === 'point'
          ? 'FormDatetimePickerPoint'
          : wd.type
      formFields.push({
        name: wd.formProp.label,
        type: type,
        field: wd.formProp.tableColumn,
        dataSourceType: wd.dataSourceType,
        formProp: wd.formProp,
        dataProp: wd.dataProp
      })
    }
  })
}
/**
 * 因为表单有可能新增或修改，所以聚合表单字段权限
 * @param formLimit 当前节点已经存在的表单字段权限
 * @param defaultPermission 默认权限，不同节点默认值不一致
 * @returns 表单字段权限
 */
export const getFormLimits = (
  formLimit: FormLimitItem[],
  defaultPermission = { isView: true, isEdit: true }
) => {
  const workflowStore = useWorkflow()
  // 需要考虑表单新增或减少字段
  const _flt: FormLimitItem[] = []
  workflowStore.formFields.forEach((field: FormField) => {
    const _formLimitItem = formLimit.find((fl: FormLimitItem) => fl.key === field.field)
    if (_formLimitItem !== undefined) {
      // 如果已经存在，则添加
      _flt.push(cloneDeep(_formLimitItem))
    } else {
      // 如果不存在，则新增
      _flt.push({
        name: field.name,
        key: field.field,
        isView: defaultPermission.isView,
        isEdit: defaultPermission.isEdit
      })
    }
  })
  return _flt
}
/**
 * 根据不同的节点类型，返回默认的字段权限
 * @param type 节点类型
 * @returns 默认的字段权限
 */
export const getDefaultPermissionByNodeType = (type: WORK_FLOW_NODE_TYPE) => {
  switch (type) {
    case WORK_FLOW_NODE_TYPE.APPLICATION_NODE:
      return {
        isView: true,
        isEdit: true
      }
    case WORK_FLOW_NODE_TYPE.APPROVAL_NODE:
      return {
        isView: true,
        isEdit: false
      }
    case WORK_FLOW_NODE_TYPE.FILL_NODE:
      return {
        isView: true,
        isEdit: true
      }
    case WORK_FLOW_NODE_TYPE.CC_NODE:
      return {
        isView: true,
        isEdit: false
      }
    default:
      return {
        isView: true,
        isEdit: true
      }
  }
}
/**
 * 将字段映射集合转为字符串
 * @param mapping 字段映射集合
 * @returns bpmn xml 需要的对象格式
 */
export const buildMapping2XmlObj = (mapping: FormTableColumnMappingItem[]) => {
  const obj = {}
  mapping.forEach((mp: FormTableColumnMappingItem) => {
    obj[mp.key] = mp.value
  })
  return obj
}

/**
 * 将条件集合组装为符合通用查询条件的对象
 * @param whereParam 条件对象
 * @param whereMapping 条件集合
 * @returns 符合通用查询条件的对象
 */
export const buildWhereMapping = (
  whereParam: any = {},
  whereMapping: FormTableColumnWhereMappingItem[]
) => {
  if (whereMapping.length === 0) {
    return whereParam
  }
  // 设置参数类型
  whereParam.type = 'operate'
  if (whereMapping.length === 1) {
    // 如果只有一个条件，则直接处理
    whereParam.left = {
      type: 'literal',
      value: whereMapping[0].key
    }
    whereParam.operator = whereMapping[0].operator
    whereParam.parameters = [
      buildValueParameterByOperate(whereMapping[0].operator, whereMapping[0].value)
    ]
  } else if (whereMapping.length === 2) {
    whereParam.left = {
      type: 'operate',
      left: {
        type: 'literal',
        value: whereMapping[0].key
      },
      operator: whereMapping[0].operator,
      parameters: [buildValueParameterByOperate(whereMapping[0].operator, whereMapping[0].value)]
    }
    whereParam.operator = whereMapping[1].unionOperator
    whereParam.parameters = [
      {
        type: 'operate',
        left: {
          type: 'literal',
          value: whereMapping[1].key
        },
        operator: whereMapping[1].operator,
        parameters: [buildValueParameterByOperate(whereMapping[1].operator, whereMapping[1].value)]
      }
    ]
  } else {
    whereParam.operator = whereMapping[whereMapping.length - 1].unionOperator
    whereParam.parameters = [
      {
        type: 'operate',
        left: {
          type: 'literal',
          value: whereMapping[whereMapping.length - 1].key
        },
        operator: whereMapping[whereMapping.length - 1].operator,
        parameters: [
          buildValueParameterByOperate(
            whereMapping[whereMapping.length - 1].operator,
            whereMapping[whereMapping.length - 1].value
          )
        ]
      }
    ]
    whereMapping.splice(whereMapping.length - 1, 1)
    whereParam.left = buildWhereMapping({}, whereMapping)
  }
  return whereParam
}

function buildValueParameterByOperate(operate: any, value: any) {
  // if (operate && operate.toUpperCase() === 'IN') {
  //   const values = value.split(',')
  //   return {
  //     type: 'parameter',
  //     value: values,
  //     isList: true
  //   }
  // }
  return {
    type: 'parameter',
    name: value
  }
}
/**
 * 将权限控制数据转为bpmn xml 中需要的对象
 * @param formLimit 权限限制
 * @returns bpmn xml 中需要的对象
 */
export const buildFormLimit2XmlObj = (formLimit: FormLimitItem[]) => {
  const obj = {}
  formLimit.forEach((fl: FormLimitItem) => {
    const limit = []
    if (fl.isView) {
      limit.push('VIEW')
    }
    if (fl.isEdit) {
      limit.push('EDIT')
    }
    obj[fl.key] = limit
  })
  return obj
}
/**
 * 将条件集合转化为bpmn需要的条件字符串
 * @param condition 条件集合
 * @param isConditionContrary 是否是相反条件
 * @returns bpmn 条件字符串
 */
export const buildCondition2XmlStr = (condition: Condition[], isConditionContrary = false) => {
  const str = []
  condition.forEach((cd: Condition, index: number) => {
    const _str = []
    // 增加上下关联操作符
    if (index !== 0) {
      _str.push(` ${cd.unionOperator} `)
    }
    _str.push(getConditionStrByOperator(cd))
    if (isConditionContrary) {
      _str.unshift('!(')
      _str.push(')')
    }
    str.push(_str.join(''))
  })
  return str.join('')
}

const getConditionStrByOperator = (condition: Condition) => {
  switch (condition.operator) {
    case 'equals':
      if (condition.formItemType === 'FormDatetimePicker') {
        const _str = ['(']
        _str.push(`variables:greaterThanOrEquals('${condition.name}', '${condition.value[0]}')`)
        _str.push(` and `)
        _str.push(`var:lowerThanOrEquals('${condition.name}', '${condition.value[0]}')`)
        _str.push(')')
        return _str.join('')
      }
      return `var:equals('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value
      )})`
    case 'notEquals':
      if (condition.formItemType === 'FormDatetimePicker') {
        const _str = ['!(']
        _str.push(`variables:greaterThanOrEquals('${condition.name}', '${condition.value[0]}')`)
        _str.push(` and `)
        _str.push(`var:lowerThanOrEquals('${condition.name}', '${condition.value[0]}')`)
        _str.push(')')
        return _str.join('')
      }
      return `var:notEquals('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value
      )})`
    case 'contains':
      return `var:contains('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value.split(',').join(',')
      )})`
    case 'notContains':
      return `!(var:contains('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value.split(',').join(',')
      )}))`
    case 'containsAny':
      return `var:containsAny('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value.split(',').join(',')
      )})`
    case 'notContainsAny':
      return `!(var:containsAny('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value.split(',').join(',')
      )}))`
    case 'greaterThan':
      return `variables:greaterThan('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value
      )})`
    case 'greaterThanOrEquals':
      return `variables:greaterThanOrEquals('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value
      )})`
    case 'lowerThan':
      return `var:lowerThan('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value
      )})`
    case 'lowerThanOrEquals':
      return `var:lowerThanOrEquals('${condition.name}', ${getConditionValueByType(
        condition.formItemType,
        condition.value
      )})`
    default:
      return ''
  }
}

const getConditionValueByType = (formItemType: FormFieldType, value: any) => {
  if (formItemType === 'FormCount') {
    return `${value}`
  }
  return `'${value}'`
}

interface StatusInfo {
  // 'run' 进行中 | 'done' 已完成 | 'end' 已终止 | 'agree' 已同意 | '已拒绝'
  status: 'run' | 'done' | 'end' | 'agree' | 'refuse'
  tagType: 'info' | 'danger' | 'success' | 'warning'
  info: string
}
/**
 * 返回弹窗头部信息所需要的内容
 * @param pageString 页面类型
 * @param taskInfo 实例信息
 * @returns
 */
export const getTaskInfo = (pageString: string, taskInfo: any) => {
  return {
    userName: taskInfo.startUsername,
    time: taskInfo.createTime,
    statusInfo: getItemStatusByTaskInfo(pageString, taskInfo)
  }
}
/**
 * 根据列表项，返回状态信息
 * @param taskInfo 列表信息
 */
export function getItemStatusByTaskInfo(pageString: string, taskInfo: any): StatusInfo {
  if (taskInfo.finishTime === null) {
    return {
      status: 'run',
      tagType: 'warning',
      info: '进行中'
    }
  } else {
    if (pageString === 'MyInitiate') {
      // 我发起的页面
      // 在我发起的已完成页面中，又要细分几种情况
      // 取最新的任务节点
      const newTask = taskInfo.tasks[0]
      if (newTask.comment !== null && newTask.comment.type === '驳回') {
        return {
          status: 'refuse',
          tagType: 'danger',
          info: '已拒绝'
        }
      } else if (newTask.comment !== null && newTask.comment.type === '同意') {
        return {
          status: 'agree',
          tagType: 'success',
          info: '已同意'
        }
      } else if (newTask.comment !== null && newTask.comment.type === '终止') {
        return {
          status: 'end',
          tagType: 'info',
          info: '已终止'
        }
      } else if (newTask.comment !== null && newTask.comment.type === '抄送') {
        return {
          status: 'agree',
          tagType: 'success',
          info: '已抄送'
        }
      } else {
        return {
          status: 'agree',
          tagType: 'success',
          info: '已完成'
        }
      }
    } else if (pageString === 'MyProcessed') {
      // 我已处理的
      return {
        status: 'done',
        tagType: 'success',
        info: '已完成'
      }
    }
  }
}
