import { ref, computed } from 'vue'
import { useWidget } from '@haohan/clw-store'

export function useSelectApi() {
  const widgetStore = useWidget()
  // API选择组件需要的数据
  const responseTree = computed(() => widgetStore.apiResponseInfo.tree)
  const allColumnDeepValues = computed(() => widgetStore.apiResponseInfo.allColumnDeepValues)

  const initSelectApiInfo = async (
    interfaceId: string,
    apiDataPath: string,
    sourceType: DATA_SOURCE_TYPE = 'CustomApi'
  ) => {
    if (widgetStore.apiResponseInfo.interfaceId !== interfaceId) {
      await widgetStore.changeSelectApi(interfaceId, sourceType)
    }
    const treeNode = findTreeNodeByDeepValue(responseTree.value, apiDataPath)
    handleApiDataPathChange(treeNode)
  }
  /**
   * 选择API后的处理
   * @param data 选择的API
   */
  const apiSelectChange = async (
    interfaceId: string,
    sourceType: DATA_SOURCE_TYPE = 'CustomApi'
  ) => {
    widgetStore.changeSelectApi(interfaceId, sourceType)
  }

  /**
   * API数据层级选择后处理
   * @param data 选择后的树形节点数据
   */
  const handleApiDataPathChange = (data: any) => {
    console.log('handleApiDataPathChange val', data)
    // 获得可选择列
    const canSelectCol: any[] = []
    if (data === null) {
      // 则返回所有的可选节点的dataPath
      allColumnDeepValues.value.forEach((dc: any) => {
        canSelectCol.push({
          name: dc.key ? dc.key : dc,
          label: dc.key ? dc.key : dc
        })
      })
    } else {
      const apiDataColumn = data.children || []
      apiDataColumn.forEach((dc: any) => {
        canSelectCol.push({
          name: dc.key,
          label: dc.key
        })
      })
    }

    widgetStore.setCurrentTableCols(canSelectCol)
  }
  /**
   * 查找指定路径的节点
   * @param tree 树
   * @param deepValue 指定路径
   * @returns 指定路径的节点
   */
  const findTreeNodeByDeepValue = (tree: any, deepValue: string) => {
    // 如果路劲为空路劲，则特殊处理
    // if (deepValue === '') {
    //   tree instanceof Array && tree.length > 0 ? tree[0] : null
    // }
    for (const node of tree) {
      if (node.deepValue === deepValue) {
        // 找到匹配的对象，返回它
        return node
      }
      if (node.children && node.children.length > 0) {
        // 递归地在子节点中查找
        const find = findTreeNodeByDeepValue(node.children, deepValue)
        if (find) {
          return find // 如果在子节点中找到了，返回它
        }
      }
    }
    // 如果遍历完整个树都没有找到，返回 null 或你想要的任何“未找到”的标记
    return null
  }

  return {
    apiSelectChange,
    responseTree,
    handleApiDataPathChange,
    initSelectApiInfo
  }
}
