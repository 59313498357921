import { ElMessageBox, ElNotification } from 'element-plus'
// 必须手动引入, 不然可能没效果
import 'element-plus/es/components/base/style/css'
import 'element-plus/theme-chalk/el-notification.css'
import { recordClearAll } from '@haohan/clw-utils'

interface Options {
  timer?: number
}

class Updater {
  oldScript: string[] //存储第一次值也就是script 的hash 信息
  newScript: string[] //获取新的值 也就是新的script 的hash信息
  dispatch: Record<string, Function[]> //小型发布订阅通知用户更新了
  timer: null | NodeJS.Timer
  constructor(options?: Options) {
    this.oldScript = []
    this.newScript = []
    this.dispatch = {}
    this.timer = null
    this.init() //初始化
    this.timing(options?.timer) //轮询
  }

  async init() {
    const html: string = await this.getHtml()
    this.oldScript = this.parserScript(html)
  }

  async getHtml() {
    const html = await fetch('/').then((res) => res.text()) //读取index html
    return html
  }

  parserScript(html: string) {
    const reg = new RegExp(/<script(?:\s+[^>]*)?>(.*?)<\/script\s*>/gi) //script正则
    return html.match(reg) as string[] //匹配script标签
  }

  //发布订阅通知
  on(key: 'no-update' | 'update', fn: Function) {
    ;(this.dispatch[key] || (this.dispatch[key] = [])).push(fn)
    return this
  }

  compare(oldArr: string[], newArr: string[]) {
    const base = oldArr.length
    const arr = Array.from(new Set(oldArr.concat(newArr)))
    //如果新旧length 一样无更新
    if (arr.length === base) {
      this.dispatch['no-update']?.forEach((fn) => {
        fn()
      })
    } else {
      //否则通知更新
      this.dispatch.update?.forEach((fn) => {
        fn()
      })
    }
  }

  timing(time = 10000) {
    if (import.meta.env.VITE_ENV === 'dev') {
      return false
    }
    //轮询
    this.timer = setInterval(async () => {
      const newHtml = await this.getHtml()
      this.newScript = this.parserScript(newHtml)
      this.compare(this.oldScript, this.newScript)
    }, time)
  }

  dispose() {
    clearInterval(this.timer)
    this.timer = null
  }
}

// 确保只出现一个
let notificationLocked = false

//实例化该类
const up = new Updater({
  timer: 60000
})
//未更新通知
// up.on('no-update', () => {
//   console.log('未更新', up.newScript)
// })
//更新通知
up.on('update', () => {
  // up.dispose()
  if (import.meta.env.VITE_ENV === 'dev') {
    return false
  }

  // ElMessageBox.alert('内容更新，页面需要刷新', 'Title', {
  //   showClose: false,
  //   confirmButtonText: '确认',
  //   callback: () => {
  //     location.reload()
  //   }
  // })

  if (!notificationLocked) {
    recordClearAll()
    // 加锁
    notificationLocked = true

    ElNotification({
      title: '注意',
      message: '页面存在更新，请刷新浏览器',
      position: 'bottom-right',
      type: 'info',
      duration: 0, // 不会自动关闭
      onClose: () => {
        // 开启
        // up.timing()

        // 解锁
        notificationLocked = false
      }
    })
  }
})
