import { useUser, useProject } from '@haohan/clw-store'
/**
 * 判断是否拥有该权限
 * @param pc 权限名称
 * @returns
 */
export function havePermission(pc: string, type = 'project') {
  const userStore = useUser()
  const projectStore = useProject()
  if (userStore.isAdmin) {
    return true
  }
  let pm
  if (type === 'user') {
    pm = userStore.current.permissions.find((p: any) => p.page === pc)
  } else {
    pm =
      projectStore.currentProject.id && projectStore.currentProject.role.permissions
        ? projectStore.currentProject.role.permissions.find((p: any) => p.page === pc)
        : undefined
  }
  return pm !== undefined
}
