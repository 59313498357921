import { defineStore } from 'pinia'
import { getProject, queryMenus } from '@haohan/clw-api'
import { setTheme, getFlatFormPageWidgetDatalist, evalFn } from '@haohan/clw-utils'
import { useGlobalStore } from './global'
import { useEvent } from './events'
import { cloneDeep, difference, has } from 'lodash-es'
import { queryApiMenus } from '@haohan/clw-api'

interface PageVariable {
  theme?: string
  style?: string
  background?: string
}

/**
 * 创建项目时选的数据源类型
 */
enum PROJECT_DATA_SOURCE_TYPE {
  External = 'OUTSIDE', // 外部数据源
  NONE = 'NONE', // 无数据源
  INDUSTRY = 'INDUSTRY', // 行业定制(属于外部数据源)
  Internal = 'MYSELF' // 内部数据源
}

/**
 * 查询引擎
 */
enum QUERY_ENGINE {
  V1 = 'V1', // V1 查询引擎（旧版）
  V2 = 'V2' // V2 查询引擎
}

export const useProject = defineStore({
  id: 'project',
  state: () => {
    return {
      currentProject: <Project>{},
      pages: <Menu[]>[],
      currentPage: <Menu>{},
      pageVariable: <PageVariable>{},
      // 该项目下已被使用得行业定制表
      businessRelation: [],
      // 当前显示的页面，方便读取页面属性信息
      showPage: {},
      // 页面切换, 保存弹窗 是否点击 '保存' 按钮
      savedDialogSubmited: false,
      // 保存接口完成
      savedDialogSubmitedApiDone: false,
      // 当前页面为表单的全局 form
      currentForm: {} as any,
      currentFormRules: {} as any,
      // 预览页面 keep-alive
      previewKeepAliveRoute: {
        isOpenKeepAlive: false, // 是否开启
        max: 5, // 最大个数
        routeList: <any>[], // 路由 list
        activatedRouteKey: '', // 当前 key
        widgetEventMap: {} as any,
        dialogEventMap: {} as any
      },
      // 当前自定义 API 接口页面
      currentApiPage: {} as any,
      // 自定义API集合
      customApiList: [],
      // 当前表单, 下拉框、单选等组件(从接口获取)数据暂存, 用于在组件属性区供选择
      currentFormWidgetDataMapForAttr: {} as any,
      // 数据源列表
      dataSourceList: [],
      // LeftFunSideBar 左侧功能按钮是否可以点击(新建页面、大屏、表单、文件夹)
      leftFunBtnDisabled: false
    }
  },
  getters: {
    // 当前页面是否为表单
    isFormPage: (state) => {
      const { currentPage } = state
      return currentPage?.variables?.pageType === 'form'
    },
    // 当前页面是否是工作流表单
    isWorkFlowFormPage: (state) => {
      const { currentPage } = state
      return (
        has(currentPage.variables, 'formAttribute') &&
        has(currentPage.variables?.formAttribute, 'formType') &&
        currentPage?.variables?.formAttribute.formType === 'workflow'
      )
    },
    // currentFormRules 存的是字符串, 这里是转成校验对象
    currentFormRulesParsed: (state) => {
      const { currentFormRules } = state
      const formRule = cloneDeep(currentFormRules) as any
      const ruleKeys = Object.keys(formRule)
      if (ruleKeys.length) {
        ruleKeys.forEach((key) => {
          const ruleItem = formRule[key]
          if (Array.isArray(ruleItem)) {
            ruleItem.forEach((item) => {
              if (item.pattern) {
                try {
                  if (evalFn(item.pattern) instanceof RegExp) {
                    // 字符串转正则对象
                    item.pattern = evalFn(item.pattern)
                  } else {
                    item.pattern = ''
                  }
                } catch (error) {
                  item.pattern = ''
                }
              }
            })
          }
        })
      }
      return formRule
    },
    // 外部数据源
    isExternalDataSource(state) {
      return state?.currentProject?.datasourceType === PROJECT_DATA_SOURCE_TYPE.External
    },
    // 行业定制(属于外部数据源)
    isIndustryDataSource(state) {
      return state?.currentProject?.datasourceType === PROJECT_DATA_SOURCE_TYPE.INDUSTRY
    },
    // 无数据源
    isNoneDataSource(state) {
      return state?.currentProject?.datasourceType === PROJECT_DATA_SOURCE_TYPE.NONE
    },
    // 内部数据源
    isInternalDataSource(state) {
      return state?.currentProject?.datasourceType === PROJECT_DATA_SOURCE_TYPE.Internal
    },
    // V1 查询引擎
    isV1QueryEngine(state) {
      return state?.currentProject?.queryType === QUERY_ENGINE.V1
    },
    // V2 查询引擎
    isV2QueryEngine(state) {
      return state?.currentProject?.queryType === QUERY_ENGINE.V2
    }
  },
  actions: {
    async getProjectInfo(projectId: string) {
      try {
        if (!this.currentProject.name) {
          const resp = await getProject(projectId)
          this.currentProject = resp.data
          const pages = await queryMenus(projectId)
          this.pages = pages.data
        }
        const globalStore = useGlobalStore()
        const theme = this.currentProject?.settings?.theme
          ? this.currentProject?.settings?.theme
          : 'haohan-light'
        setTheme(theme)

        // 是否开启缓存列表
        const isOpen = this?.currentProject?.settings?.cache === 'true' ? true : false
        if (isOpen) {
          this.updateKeepAliveMaxNumber(this?.currentProject?.settings?.cacheNumber || 5)
        }
        this.openKeepAlive(isOpen)

        globalStore.changeTheme(theme)
      } catch (error) {
        console.log(error)
      }
    },
    changeCurrentProject(project: Project) {
      const globalStore = useGlobalStore()
      this.currentProject = project
      const theme = this.currentProject?.settings?.theme
        ? this.currentProject?.settings?.theme
        : 'haohan-light'
      setTheme(theme)
      globalStore.changeTheme(theme)
      const eventStore = useEvent()
      eventStore.loadCommonParameters(this.currentProject)
    },
    changePages(pages: Menu[]) {
      this.pages = pages
    },
    changeCurrentPage(page: Menu) {
      this.currentPage = page
      // 切换页面, 清空组件存储的 data.content
      this.clearCurrentFormWidgetDataMapForAttr()
    },
    changeBusinessRelation(businessRelation: any) {
      this.businessRelation = businessRelation
    },
    updatePageVariable(variable: any) {
      this.currentPage.variables = variable
    },
    updatePropPageVariable(prop: any, variable: any) {
      this.currentPage.variables[prop] = variable
    },
    updateSavedDialogSubmited(submited = false) {
      this.savedDialogSubmited = submited
    },
    updateSavedDialogSubmitedApiDone(submited = false) {
      this.savedDialogSubmitedApiDone = submited
    },
    // 更新表单
    updateCurrentForm(form = {}) {
      this.currentForm = form
    },
    // 更新表单 rules
    updateCurrentFormRules(rules = {}) {
      this.currentFormRules = rules
    },
    // 重置表单和 rules
    resetCurrentFormAndRules() {
      this.currentForm = {} as any
      this.currentFormRules = {} as any
    },
    // 更新表单
    updateCurrentFormByWidgetList(arr: Widget[]) {
      // 把 widgetList(包含容器) 扁平化
      let list = [] as Widget[]
      list = getFlatFormPageWidgetDatalist(arr, list)

      const getMenuByKey = (list: Widget[], key: string) => {
        return list.filter((menu) => menu.key === key)[0] || {}
      }
      const keys = list.map((item) => item.key)
      const form = cloneDeep(this.currentForm) as any
      const formKeys = Object.keys(form)
      const formTemp = {} as any
      keys.forEach((key) => {
        if (key) {
          const { type, formProp } = getMenuByKey(list, key) as any
          // 已经存在该 key, 取旧值
          formTemp[key] = formKeys.includes(key)
            ? form[key]
            : ['FormCheckbox', 'FormSelect'].includes(type) ||
              (type === 'FormDatetimePicker' && formProp?.type?.includes('range')) // 多选、时间的 range 类型 v-model 需要为数组 否则勾选不上报错
            ? []
            : null
          if (formKeys.includes(key)) {
            formTemp[key] = form[key]
          } else if (
            ['FormCheckbox', 'FormSelect'].includes(type) ||
            (type === 'FormDatetimePicker' && formProp?.type?.includes('range'))
          ) {
            // 多选、时间的 range 类型 v-model 需要为数组 否则勾选不上报错
            formTemp[key] = []
          } else {
            const eventStore = useEvent()
            // 需要区分是否有绑定全局变量
            if (
              formProp?.comParam &&
              formProp?.comParam !== '' &&
              eventStore.commonParameters[formProp?.comParam]
            ) {
              formTemp[key] = eventStore.commonParameters[formProp.comParam].value
            } else {
              formTemp[key] = null
            }
          }
        }
      })
      // 更新
      this.updateCurrentForm(formTemp)
    },
    // 更新表单 rules
    updateCurrentFormRulesByWidgetList(arr: Widget[]) {
      // 把 widgetList(包含容器) 扁平化
      let list = [] as Widget[]
      list = getFlatFormPageWidgetDatalist(arr, list)

      const rulesTemp = {} as any
      list.forEach((comp) => {
        if (comp.formProp) {
          const { limited, limitedRule, required, requiredRule, customRules } = comp.formProp as any
          // 自定义规则
          const formItemRules = cloneDeep(customRules)
          // 必填
          if (required) {
            formItemRules.push(requiredRule)
          }
          // 字数限制
          if (limited) {
            formItemRules.push(limitedRule)
          }

          // 所有规则(自定义规则+必填规则+限制字数规则)
          if (comp.key) {
            rulesTemp[comp.key] = formItemRules
          }
        }
      })
      // 更新
      this.updateCurrentFormRules(rulesTemp)
    },
    // 添加路由(预览页面 keep-alive)
    addPreviewKeepAliveRoute(route: any) {
      this.previewKeepAliveRoute.activatedRouteKey = route.key
      const routeList = JSON.parse(sessionStorage.getItem('previewKeepAliveRoute') || '[]')
      const { max } = this.previewKeepAliveRoute
      const had = routeList.find((item: any) => item.key === route.pageId)
      if (!had) {
        routeList.push(route)
        const leng = routeList.length
        if (leng > max) {
          this.previewKeepAliveRoute.routeList = routeList.slice(leng - max, leng)
        } else {
          this.previewKeepAliveRoute.routeList = routeList
        }
      } else {
        this.previewKeepAliveRoute.routeList = routeList
      }
    },
    openKeepAlive(open: boolean) {
      this.previewKeepAliveRoute.isOpenKeepAlive = open
    },
    updateActivatedRouteKey(key: string) {
      this.previewKeepAliveRoute.activatedRouteKey = key
    },
    updateKeepAliveMaxNumber(num: number) {
      this.previewKeepAliveRoute.max = num
    },
    updateActivatedRouteWidgetEvent(key: string, widgetEvent: any) {
      this.previewKeepAliveRoute.widgetEventMap[key] = widgetEvent
    },
    updateActivatedRouteDialogEvent(key: string, dialogEventMap: any) {
      this.previewKeepAliveRoute.dialogEventMap[key] = dialogEventMap
    },
    updateActivatedRouteTitle(pageId: string, title: string) {
      const item = this.previewKeepAliveRoute.routeList.filter((i: any) => i.pageId === pageId)[0]
      if (item) {
        item.title = title
      }
      sessionStorage.setItem(
        'previewKeepAliveRoute',
        JSON.stringify(this.previewKeepAliveRoute.routeList)
      )
    },
    deletePreviewKeepAliveRoute(index: number) {
      const list = this.previewKeepAliveRoute.routeList
      // 删除当前
      list.splice(index, 1)
      sessionStorage.setItem('previewKeepAliveRoute', JSON.stringify(list))

      const length = list.length
      const lastRoute = list[length - 1]

      if (!lastRoute) {
        return Promise.reject()
      }
      // 设置最后一个为激活
      this.previewKeepAliveRoute.activatedRouteKey = lastRoute.key

      // 用于路由跳转
      return Promise.resolve(lastRoute)
    },
    //清空路由(预览页面 keep-alive)
    clearPreviewKeepAliveRoute() {
      this.previewKeepAliveRoute.routeList = this.previewKeepAliveRoute.routeList.filter(
        (item) => item.key === this.previewKeepAliveRoute.activatedRouteKey
      )
      sessionStorage.setItem(
        'previewKeepAliveRoute',
        JSON.stringify(this.previewKeepAliveRoute.routeList)
      )
    },
    changeCurrentApiPage(page: any) {
      this.currentApiPage = page
    },
    changeCustomApiList(api: any) {
      this.customApiList = api
    },
    // 加载自定义API
    async loadCustomApiList() {
      const res = await queryApiMenus(this.currentProject.id)
      this.customApiList = res.data
    },
    updateCurrentFormWidgetDataMapForAttr(key: string, data: any) {
      this.currentFormWidgetDataMapForAttr[key] = data
    },
    getCurrentFormWidgetDataByKey(key: string) {
      return this.currentFormWidgetDataMapForAttr[key]
    },
    // 切换页面清空下(preview.vue 里清空)
    clearCurrentFormWidgetDataMapForAttr() {
      this.currentFormWidgetDataMapForAttr = {}
    },
    changeDataSourceList(list = []) {
      this.dataSourceList = list
    },
    changeLeftFunBtnDisableStatus(status: boolean) {
      this.leftFunBtnDisabled = status
    }
  }
})
