<template>
  <div v-if="prop?.iconClass?.includes('.svg')" ref="svgContainerRef" class="url-svg"></div>
  <svg v-else :class="svgClass" aria-hidden="true" v-bind="$attrs">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  const prop = defineProps<{
    iconClass?: string
    className?: string
  }>()
  const iconName = computed(() => `#icon-${prop.iconClass}`)
  const svgClass = computed(() => (prop.className ? `svg-icon ${prop.className}` : 'svg-icon'))

  const svgContainerRef = ref()

  //获取svg链接
  function getSvgByUrl(url: string) {
    fetch(url)
      .then((response) => {
        return response.status === 200 ? response.text() : null
      })
      .then((svgContent) => {
        if (!svgContent) {
          //地址请求失败，内容置空
          svgContainerRef.value.innerHTML = ''
        } else {
          const str = svgContent.replace('<svg', '<svg width="1em" height="1em"')
          svgContainerRef.value.innerHTML = str // 将SVG内容嵌入到div中
        }
      })
  }

  watch(
    () => prop.iconClass,
    (value) => {
      if (value?.includes('.svg')) {
        getSvgByUrl(value)
      }
    }
  )

  onMounted(() => {
    if (prop.iconClass?.includes('.svg')) {
      getSvgByUrl(prop.iconClass)
    }
  })
</script>

<style scoped lang="scss">
  .svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  .url-svg {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
