import { getKey } from '@haohan/clw-utils'

const tip = function (params) {
  let bgColor, headerColor, border
  if (params.data.value > 85) {
    bgColor = 'rgba(78,59,13,0.6)'
    headerColor =
      'background-image: linear-gradient(90deg, rgba(255,185,0,0.50) 0%, rgba(255,185,0,0.00) 100%);'
    border = 'border-bottom: 1px solid rgba(243,220,158,0.2);'
  } else {
    bgColor = 'rgba(67,14,14,0.6)'
    headerColor =
      'background-image: linear-gradient(90deg, rgba(255,0,0,0.40) 0%, rgba(255,0,0,0.00) 100%);'
    border = 'border-bottom: 1px solid rgba(246,162,162,0.2);'
  }
  const html = []
  html.push(`<div style='background-color: ${bgColor};padding: 0 10px;text-align: left;'>`)
  html.push(`<div style='${headerColor}padding: 10px 10px;'>京沪铁路告警信息</div>`)
  html.push(`<div style='${border}padding: 10px 0;'>id：${params.data.id}</div>`)
  html.push(`<div style='${border}padding: 10px 0;'>告警位置：${params.data.value}</div>`)
  html.push(`</div>`)
  return html.join('<br/>')
}.toString()

/**
 * 公共的组件属性，不支持选择数据集
 */
const compAttrWithoutData: WidgetAttribute = {
  isLock: false,
  events: []
}
/**
 * 公共的组件属性，支持选择数据集
 */
const compAttrWithData: WidgetAttribute = {
  isLock: false,
  events: [],
  mutualSetting: true,
  dataSourceSetting: true,
  dataSourceType: 'LocalData',
  dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
  customDataHandle: '' // 自定义数据处理函数
}

/**
 * 样式属性styleProp 公共部分
 */
const styleProp: WidgetAttribute = {
  'margin-top': 0,
  'margin-bottom': 0,
  'margin-left': 0,
  'margin-right': 0,
  'padding-top': 0,
  'padding-bottom': 0,
  'padding-left': 0,
  'padding-right': 0,
  'z-index': 100,
  top: 0,
  left: 0
}
/**
 * 数据属性dataProp 公共部分
 */
const dataProp: WidgetAttribute = {
  tableId: '',
  tableName: '',
  pageSize: 10,
  queryTimeType: 'systime',
  startInterval: 0,
  endtInterval: 0,
  apiDataPath: '' // 当数据类型为API时，记录API数据的路径
}

/**
 * 属性prop 图表组件用
 * 这个后续需要拆分，有些图表不需要legend等prop项
 */
const propForChart: WidgetAttribute = {
  legend: {
    show: true,
    type: 'plain',
    // 垂直位置
    top: 'top',
    // 水平位置
    left: 'center',
    // 方向
    orient: 'horizontal'
  },
  xAxisField: '',
  seriesType: 'fix',
  valueCols: [],
  dynamicSerieNameCol: '',
  dynamicSerieValueCol: '',
  timingOpen: false,
  timingTime: 5
}
/**
 * 属性prop 含有笛卡尔坐标系的组件用
 */
const propForGrid: WidgetAttribute = {
  grid: {
    left: 60,
    top: 48,
    right: 70,
    bottom: 20,
    containLabel: true
  },
  xAxis: [
    {
      show: true,
      position: 'bottom'
    }
  ],
  yAxis: [
    {
      show: true,
      name: '',
      position: 'left'
    }
  ]
}
/**
 * 属性prop 含有标记功能的组件用
 */
const propForMark: WidgetAttribute = {
  mark: {
    enable: true,
    type: 'line', // area
    label: {
      show: true
    },
    line: {
      max: false,
      min: false,
      average: false,
      median: false,
      list: []
    },
    area: {
      list: []
    }
  }
}

/**
 * 标题类
 */
const titleComp: Widget[] = [
  // 系统标题
  {
    title: '系统标题',
    imageSrc: 'big-screen-systemTitle',
    thumbnail: '',
    type: 'BigScreenHeaderTitle',
    mutualSetting: true,
    dataSourceSetting: false,
    prop: {
      type: 'style1',
      icon: ['turnPage', 'logout', 'fullScreen'],
      label: ''
    },
    styleProp: {
      height: 68,
      width: 1440
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  },
  // 内容标题
  {
    title: '内容标题',
    imageSrc: 'big-screen-contentTitle',
    thumbnail: '',
    type: 'BigScreenTitle',
    mutualSetting: true,
    dataSourceSetting: false,
    prop: {
      type: 'style1',
      label: ''
    },
    styleProp: {
      height: 32,
      width: 150
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  }
]
/**
 * 内容类
 */
const contentComp: Widget[] = [
  // 内容
  {
    imageSrc: 'big-screen-content',
    thumbnail: '',
    title: '内容',
    type: 'BigScreenContentBg',
    mutualSetting: true,
    dataSourceSetting: false,
    prop: {
      type: 'style1'
    },
    styleProp: {
      height: 300,
      width: 300
    },
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    },
    events: []
  },
  // 面板
  {
    imageSrc: 'big-screen-panel',
    thumbnail: '',
    title: '面板',
    type: 'BigScreenPanel',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    prop: {
      type: 'style1'
    },
    styleProp: {
      height: 300,
      width: 300
    },
    dataProp: {
      defaultData: [
        {
          label: '数据趋势',
          value: '数据趋势',
          value2: '',
          list: []
        },
        {
          label: '数据详情',
          value: '数据详情',
          value2: '',
          list: []
        }
      ]
      // options: []
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '切换显示',
            value: 'changeShowTab'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示Tab',
            value: 'currentValue'
          }
        ]
      }
    },
    events: []
  },
  // 图片
  {
    imageSrc: 'big-screen-image',
    thumbnail: '',
    title: '图片',
    type: 'BigScreenImage',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: 100,
      height: 100
    },
    prop: {
      imgUrl: '',
      // 风格
      style: 'default'
    }
  },
  // 表格
  {
    title: '表格',
    imageSrc: 'BigScreenTable',
    thumbnail: '',
    type: 'BigScreenTable',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    customDataHandle: '', // 自定义数据处理函数
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [],
        // 可绑定得值
        bindValues: []
      }
    },
    prop: {
      animateEffect: {
        enable: true,
        frequency: 500,
        showNum: 5
      },
      tHeader: {
        background: {
          enable: true,
          color: {
            type: '',
            angle: '90',
            list: [
              { color: '#088bff00', step: 0 },
              { color: '#088bff33', step: 50 },
              { color: '#088bff00', step: 100 }
            ]
          }
        },
        border: {
          enable: false,
          color: ''
        },
        font: {
          size: 16,
          weight: 'bold', // normal,bold
          color: '#79A4D4'
        }
      },
      tBody: {
        background: {
          enable: false,
          color: ''
        },
        border: {
          enable: true,
          color: '#475D72'
        },
        font: {
          size: 12,
          weight: 'normal', // normal,bold
          color: '#E5E5E5'
        }
      },
      selected: {
        enable: true,
        background: {
          enable: false,
          color: ''
        },
        border: {
          enable: true,
          color: '#FFC72E'
        },
        font: {
          size: 12,
          weight: 'normal', // normal,bold
          color: '#FFC72E'
        }
      }
    },
    styleProp: {
      height: 200,
      width: 660
    },
    dataProp: {
      cols: [],
      defaultData: [
        {
          日期: '2022-11-10',
          城市: '北京',
          数据: '1231',
          类别: '类一'
        },
        {
          日期: '2022-11-11',
          城市: '上海',
          数据: '2319',
          类别: '类二'
        },
        {
          日期: '2022-11-12',
          城市: '天津',
          数据: '6670',
          类别: '类一'
        },
        {
          日期: '2022-11-13',
          城市: '天津',
          数据: '6670',
          类别: '类一'
        },
        {
          日期: '2022-11-14',
          城市: '天津',
          数据: '6670',
          类别: '类一'
        }
      ]
    }
  },
  // 外嵌容器
  {
    imageSrc: 'BigScreenIframe',
    thumbnail: 'BigScreenIframe',
    title: '外嵌容器',
    type: 'BigScreenIframe',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: [],
        bindValues: []
      }
    },
    styleProp: {
      width: 600,
      height: 300
    },
    prop: {
      src: '' // 外嵌地址
    }
  }
]
/**
 * 图表类
 */
const chartComp: Widget[] = [
  // 柱状图
  {
    title: '柱状图',
    imageSrc: 'big-screen-bar',
    thumbnail: '',
    type: 'BigScreenBar',
    chartType: 'bar',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '会员数', col: 'value' }],
      rotate: 0
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 81 },
        { name: '二月', value: 38 },
        { name: '三月', value: 53 },
        { name: '四月', value: 20 },
        { name: '五月', value: 62 }
      ]
    }
  },
  // 趋势图
  {
    title: '趋势图',
    imageSrc: 'big-screen-trend',
    thumbnail: '',
    type: 'BigScreenLine',
    chartType: 'line',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      ...propForGrid,
      ...propForMark,
      series: [],
      manner: 'style1',
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '变化率', col: 'value' }],
      rotate: 0
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '11-01', value: 39 },
        { name: '11-02', value: 17 },
        { name: '11-03', value: 82 },
        { name: '11-04', value: 38 },
        { name: '11-05', value: 59 }
      ]
    }
  },
  // 占比图
  {
    imageSrc: 'big-screen-ratio',
    thumbnail: '',
    title: '占比图',
    type: 'BigScreenPie',
    chartType: 'pie',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      legend: {
        show: true,
        type: 'scroll',
        // 垂直位置
        top: 'middle',
        // 水平位置
        right: 20,
        // 方向
        orient: 'vertical',
        itemWidth: 10,
        itemHeight: 10,
        // itemGap: 8,
        icon: 'rect',
        align: 'left',
        textStyle: {
          color: '#fff',
          padding: [0, 30, 0, 0],
          lineHeight: 28
        },
        pageIconColor: '#BADFFF',
        pageIconInactiveColor: 'rgba(186,223,255,0.1)',
        pageIconSize: [12, 6]
      },
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      label: {
        show: true,
        formatter: '{c}'
      },
      labelUnit: ''
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 895 },
        { name: '二月', value: 350 },
        { name: '三月', value: 216 },
        { name: '四月', value: 107 },
        { name: '五月', value: 23 }
      ]
    }
  },
  // 条形图
  {
    imageSrc: 'big-screen-strip',
    thumbnail: '',
    title: '条形图',
    type: 'BigScreenStrip',
    chartType: 'bar',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '选择值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    prop: {
      series: [],
      manner: 'style2',
      grid: {
        left: 60,
        top: 48,
        right: 70,
        bottom: 20,
        containLabel: true
      },
      xAxis: [
        {
          show: true,
          // 坐标轴名称
          name: '',
          type: 'value',
          position: 'bottom'
        }
      ],
      yAxis: [
        {
          show: true,
          // 坐标轴名称
          // name: '',
          type: 'category',
          position: 'left'
        }
      ],
      xAxisField: 'name',
      valueCols: [
        { key: getKey(), name: '会员数', col: 'value' },
        { key: getKey(), name: '变化率', col: 'value' }
      ],
      rotate: 0
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 81, rate: 39 },
        { name: '二月', value: 38, rate: 17 },
        { name: '三月', value: 53, rate: 82 },
        { name: '四月', value: 20, rate: 38 },
        { name: '五月', value: 62, rate: 59 }
      ]
    }
  },
  // 排行图
  {
    imageSrc: 'big-screen-rank',
    thumbnail: '',
    title: '排行图',
    type: 'BigScreenRank',
    prop: {
      nameCol: '',
      valueCol: '',
      strokeWidth: 8,
      unit: '',
      valueFormat: 'point',
      labelPosition: 'center'
    },
    styleProp: {
      height: 620,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '手机',
          value: 5934
        },
        {
          name: '平板电脑',
          value: 5521
        },
        {
          name: 'PC',
          value: 4351
        },
        {
          name: '智能音箱',
          value: 2910
        },
        {
          name: '电视',
          value: 1076
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  },
  // 散点图
  {
    imageSrc: 'big-screen-scatter',
    thumbnail: '',
    title: '散点图',
    type: 'BigScreenScatter',
    chartType: 'scatter',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      rotate: 0
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '11-01', value: 39 },
        { name: '11-02', value: 17 },
        { name: '11-03', value: 82 },
        { name: '11-04', value: 38 },
        { name: '11-05', value: 59 }
      ]
    }
  },
  // 地图
  {
    imageSrc: 'big-screen-map',
    thumbnail: '',
    title: '地图',
    type: 'BigScreenMap',
    chartType: 'map',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '选中',
            value: 'selectItem'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          },
          {
            label: '选中',
            value: 'selectValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      legend: {
        show: true,
        type: 'plain',
        // 垂直位置
        top: 'bottom',
        // 水平位置
        left: 'left',
        // 方向
        orient: 'vertical'
      },
      baseMap: '北京市',
      areaCol: 'name',
      areaValueCol: '',
      apply: 'area', //应用类型
      lines: [], // 应用-飞线
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      hasCounty: false,
      zoom: 1,
      center: ['', ''], // 必须要设置，不然缩放平移会出错
      color: [
        '#1684F2',
        '#2AC2F2',
        '#2AC16A',
        '#35FFF4',
        '#ECA850',
        '#FDAC23',
        '#F56975',
        '#E389BD',
        '#E644A2',
        '#BE5EDB',
        '#9D7CE6',
        '#7888F4',
        '#85D1EB',
        '#7BF6D9'
      ],
      paths: [], // 应用-路径
      valueKey: '', // 值key
      pathType: 'railway', //路径类型
      infomationPoint: {
        // 判断信息点
        enable: true,
        points: []
      },
      customTipHtml: tip // 自定义提示
    },
    styleProp: {
      height: 460,
      width: 500
    },
    dataProp: {
      defaultData: [
        { name: '江西', value: 113 },
        { name: '安徽', value: 57 },
        { name: '台湾', value: 58 },
        { name: '香港', value: 202 },
        { name: '澳门', value: 220 },
        { name: '海南', value: 32 },
        { name: '广东', value: 73 },
        { name: '广西', value: 144 },
        { name: '云南', value: 48 },
        { name: '湖南', value: 65 },
        { name: '贵州', value: 32 },
        { name: '重庆', value: 131 },
        { name: '四川', value: 29 },
        { name: '湖北', value: 38 },
        { name: '福建', value: 143 },
        { name: '浙江', value: 51 },
        { name: '上海', value: 87 },
        { name: '江苏', value: 90 },
        { name: '河南', value: 170 },
        { name: '山东', value: 63 },
        { name: '黑龙江', value: 23 },
        { name: '吉林', value: 193 },
        { name: '辽宁', value: 75 },
        { name: '天津', value: 99 },
        { name: '北京', value: 199 },
        { name: '河北', value: 45 },
        { name: '山西', value: 139 },
        { name: '陕西', value: 23 },
        { name: '宁夏', value: 159 },
        { name: '内蒙古', value: 78 },
        { name: '甘肃', value: 80 },
        { name: '青海', value: 98 },
        { name: '西藏', value: 100 },
        { name: '新疆', value: 115 }
      ]
    }
  },
  // 热力图
  {
    title: '热力图',
    imageSrc: 'big-screen-heatmap',
    thumbnail: '',
    type: 'BigScreenHeatMap',
    chartType: 'heatmap',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [],
        // 可绑定得值
        bindValues: []
      }
    },
    prop: {
      series: [
        {
          name: '',
          type: 'heatmap',
          data: [],
          label: {
            show: false
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ],
      manner: 'style1',
      xField: 'xField',
      yField: 'yField',
      valueField: 'valueField',
      grid: {
        top: 60,
        left: 60,
        right: 10,
        bottom: 40,
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          position: 'top',
          data: [],
          splitArea: {
            show: true
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#fff',
            overflow: 'truncate',
            height: 40,
            interval: 0
          }
        }
      ],
      yAxis: [
        {
          type: 'category',
          data: [],
          splitArea: {
            show: true
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#fff',
            overflow: 'truncate',
            width: 50
          }
        }
      ],
      visualMap: {
        show: true,
        min: 0,
        max: 10,
        calculable: true,
        orient: 'horizontal',
        left: 'center',
        top: 'bottom',
        bottom: 5,
        inRange: {
          color: ['#E2F8FF', '#4DAFFF', '#025AB4']
        },
        outRange: {
          color: '#E2F8FF'
        },
        // text: [1000, 0],
        itemHeight: 200,
        itemWidth: 7,
        textStyle: {
          color: '#fff',
          fontSize: 12
        }
      },
      textStyle: ''
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          xField: '一月',
          yField: '北京',
          valueField: 67
        },
        {
          xField: '二月',
          yField: '北京',
          valueField: 24
        },
        {
          xField: '三月',
          yField: '北京',
          valueField: 8
        },
        {
          xField: '四月',
          yField: '北京',
          valueField: 19
        },
        {
          xField: '五月',
          yField: '北京',
          valueField: 10
        },
        {
          xField: '一月',
          yField: '上海',
          valueField: 52
        },
        {
          xField: '二月',
          yField: '上海',
          valueField: 64
        },
        {
          xField: '三月',
          yField: '上海',
          valueField: 123
        },
        {
          xField: '四月',
          yField: '上海',
          valueField: 15
        },
        {
          xField: '五月',
          yField: '上海',
          valueField: 35
        },
        {
          xField: '一月',
          yField: '广州',
          valueField: 115
        },
        {
          xField: '二月',
          yField: '广州',
          valueField: 117
        },
        {
          xField: '三月',
          yField: '广州',
          valueField: 8
        },
        {
          xField: '四月',
          yField: '广州',
          valueField: 5
        },
        {
          xField: '五月',
          yField: '广州',
          valueField: 38
        },
        {
          xField: '一月',
          yField: '深圳',
          valueField: 96
        },
        {
          xField: '二月',
          yField: '深圳',
          valueField: 117
        },
        {
          xField: '三月',
          yField: '深圳',
          valueField: 8
        },
        {
          xField: '四月',
          yField: '深圳',
          valueField: 5
        },
        {
          xField: '五月',
          yField: '深圳',
          valueField: 38
        },
        {
          xField: '一月',
          yField: '天津',
          valueField: 84
        },
        {
          xField: '二月',
          yField: '天津',
          valueField: 64
        },
        {
          xField: '三月',
          yField: '天津',
          valueField: 142
        },
        {
          xField: '四月',
          yField: '天津',
          valueField: 97
        },
        {
          xField: '五月',
          yField: '天津',
          valueField: 85
        }
      ]
    }
  },
  // 关系图
  {
    imageSrc: 'big-screen-relation-chart',
    thumbnail: '',
    title: '关系图',
    type: 'BigScreenRelationChart',
    chartType: 'graph',
    prop: {
      type: 'style1',
      label: '',
      labelVisible: false,
      labelPosition: 'inside',
      sourceKey: 'source',
      targetKey: 'target',
      rootWidth: 50,
      nodeWidth: 30,
      repulsion: 400,
      series: {
        type: 'graph'
      }
    },
    styleProp: {
      height: 300,
      width: 400
    },
    dataProp: {
      defaultData: [
        {
          source: 'a',
          target: 'a1',
          value: 113
        },
        {
          source: 'a',
          target: 'b1',
          value: 57
        },
        {
          source: 'a',
          target: 'a2',
          value: 58
        },
        {
          source: 'b',
          target: 'b1',
          value: 202
        },
        {
          source: 'b1',
          target: 'a1',
          value: 16
        },
        {
          source: 'b1',
          target: 'c',
          value: 32
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    },
    events: []
  },
  // 水波图
  {
    imageSrc: 'big-screen-liquidFill',
    thumbnail: '',
    title: '水波图',
    type: 'BigScreenLiquidFill',
    chartType: 'liquidFill',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [],
        // 可绑定得值
        bindValues: []
      }
    },
    styleProp: {
      width: 330,
      height: 330
    },
    prop: {
      manner: 'style1',
      legend: {
        show: false
      },
      levelShow: false,
      levels: [],
      color: '#1684F2',
      valueCol: 'value',
      series: {
        type: 'liquidFill',
        center: ['50%', '50%'],
        radius: '80%',
        label: {
          show: true,
          fontSize: 30,
          color: '#294D99'
        },
        data: []
      },
      unit: '%'
    },
    dataProp: {
      defaultData: [{ value: 42.76 }]
    }
  },
  // 玉玦图
  {
    imageSrc: 'big-screen-jade',
    thumbnail: '',
    title: '玉玦图',
    type: 'BigScreenJade',
    chartType: 'jade',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        bindValues: []
      },
      receive: {
        operationTypes: [],
        bindValues: []
      }
    },
    styleProp: {
      width: 330,
      height: 330
    },
    prop: {
      series: [],
      manner: 'style1',
      polar: {
        radius: ['0%', '75%'],
        center: ['50%', '50%']
      },
      radiusAxis: {
        data: []
      },
      angleAxis: {},
      hasBg: false,
      isColor: false,
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      xAxisField: 'name',
      valueShowType: 'rate' // rate,value
    },
    dataProp: {
      defaultData: [
        { name: '北京', value: 36 },
        { name: '上海', value: 24 },
        { name: '广州', value: 12 },
        { name: '深圳', value: 20 },
        { name: '天津', value: 8 }
      ]
    }
  }
]
/**
 * 新增，未分类
 */
const newChartComp: Widget[] = [
  // 线性回归
  {
    title: '线性回归',
    imageSrc: 'BigScreenLinearRegression',
    thumbnail: '',
    type: 'BigScreenLinearRegression',
    chartType: 'linearRegression',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'x',
      valueCols: [{ key: getKey(), name: '线性回归', col: 'y', xAxisIndex: 0, yAxisIndex: 0 }],
      coordinateType: 'grid'
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { x: 0.067732, y: 3.176513 },
        { x: 0.42781, y: 3.816464 },
        { x: 0.995731, y: 4.550095 },
        { x: 0.738336, y: 4.256571 },
        { x: 0.981083, y: 4.560815 },
        { x: 0.526171, y: 3.929515 },
        { x: 0.378887, y: 3.52617 },
        { x: 0.033859, y: 3.156393 },
        { x: 0.132791, y: 3.110301 },
        { x: 0.138306, y: 3.149813 },
        { x: 0.247809, y: 3.476346 }
      ]
    }
  },
  // 混合图
  {
    title: '混合图',
    imageSrc: 'BigScreenMixChart',
    thumbnail: '',
    type: 'BigScreenMixChart',
    chartType: 'mixChart',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [
        {
          name: '用户量',
          type: 'bar',
          xAxisIndex: 0,
          yAxisIndex: 0,
          barWidth: 9,
          barMaxWidth: 9,
          barMinWidth: 1,
          stack: '',
          label: { show: false, position: 'top' },
          showRadius: true
        },
        {
          name: '增长率',
          type: 'line',
          xAxisIndex: 0,
          yAxisIndex: 0,
          showSymbol: true,
          symbol: 'circle',
          lineStyle: { type: 'solid' },
          label: { show: false, position: 'top' },
          smooth: true
        }
      ],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [
        { key: getKey(), name: '用户量', col: 'value', xAxisIndex: 0, yAxisIndex: 0 },
        { key: getKey(), name: '增长率', col: 'rate', xAxisIndex: 0, yAxisIndex: 0 }
      ],
      coordinateType: 'grid',
      rotate: 0
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '一月',
          value: 81,
          rate: 39
        },
        {
          name: '二月',
          value: 38,
          rate: 17
        },
        {
          name: '三月',
          value: 53,
          rate: 77
        },
        {
          name: '四月',
          value: 20,
          rate: 66
        },
        {
          name: '五月',
          value: 62,
          rate: 32
        }
      ]
    }
  },
  // 动态折线图
  {
    title: '动态折线图',
    imageSrc: 'BigScreenDynamicLine',
    thumbnail: '',
    type: 'BigScreenDynamicLine',
    chartType: 'dynamicLine',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style2',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [
        { key: getKey(), name: '变化率', col: 'value1', xAxisIndex: 0, yAxisIndex: 0 },
        { key: getKey(), name: '名称', col: 'value2', xAxisIndex: 0, yAxisIndex: 0 },
        { key: getKey(), name: '名称2', col: 'value3', xAxisIndex: 0, yAxisIndex: 0 }
      ],
      animationDuration: 5000,
      labelUnit: '%',
      coordinateType: 'grid'
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '11-01',
          value1: 100,
          value2: 202,
          value3: 204
        },
        {
          name: '11-02',
          value1: 300,
          value2: 1821,
          value3: 806
        },
        {
          name: '11-03',
          value1: 1600,
          value2: 1911,
          value3: 2322
        },
        {
          name: '11-04',
          value1: 700,
          value2: 2343,
          value3: 3016
        },
        {
          name: '11-05',
          value1: 1800,
          value2: 2907,
          value3: 5348
        },
        {
          name: '11-06',
          value1: 3900,
          value2: 3300,
          value3: 6290
        },
        {
          name: '11-07',
          value1: 3922,
          value2: 3108,
          value3: 6330
        },
        {
          name: '11-08',
          value1: 3504,
          value2: 3509,
          value3: 6320
        },
        {
          name: '11-09',
          value1: 4079,
          value2: 2326,
          value3: 7332
        },
        {
          name: '11-10',
          value1: 3753,
          value2: 2013,
          value3: 6301
        },
        {
          name: '11-11',
          value1: 3802,
          value2: 1549,
          value3: 7334
        },
        {
          name: '11-12',
          value1: 3623,
          value2: 1902,
          value3: 7690
        },
        {
          name: '11-13',
          value1: 4250,
          value2: 3305,
          value3: 8330
        },
        {
          name: '11-14',
          value1: 4490,
          value2: 4102,
          value3: 8320
        },
        {
          name: '11-15',
          value1: 4180,
          value2: 5326,
          value3: 8932
        },
        {
          name: '11-16',
          value1: 4010,
          value2: 5014,
          value3: 8901
        },
        {
          name: '11-17',
          value1: 4249,
          value2: 6348,
          value3: 8934
        },
        {
          name: '11-18',
          value1: 4360,
          value2: 6905,
          value3: 9290
        },
        {
          name: '11-19',
          value1: 3609,
          value2: 6300,
          value3: 9330
        },
        {
          name: '11-20',
          value1: 4239,
          value2: 7202,
          value3: 9320
        }
      ]
    }
  },
  // 旋转环图
  {
    imageSrc: 'BigScreenBaseRing',
    thumbnail: '',
    title: '旋转环图',
    type: 'BigScreenBaseRing',
    chartType: 'baseRing',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      legend: {
        show: true,
        type: 'scroll',
        // 垂直位置
        top: 'middle',
        // 水平位置
        right: 20,
        // 方向
        orient: 'vertical',
        itemWidth: 10,
        itemHeight: 10,
        // itemGap: 8,
        icon: 'rect',
        align: 'left',
        textStyle: {
          color: '#fff',
          padding: [0, 30, 0, 0],
          lineHeight: 28
        },
        pageIconColor: '#BADFFF',
        pageIconInactiveColor: 'rgba(186,223,255,0.1)',
        pageIconSize: [12, 6]
      },
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      label: {
        show: true,
        formatter: '{c}'
      },
      labelUnit: '',
      isSingleSeries: true, // 是否单系列
      coordinateType: 'pieChart', // 坐标系类型 pieChart(饼图坐标系)
      hasAnimation: true,
      animationTime: 4000
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 895 },
        { name: '二月', value: 350 },
        { name: '三月', value: 216 },
        { name: '四月', value: 107 },
        { name: '五月', value: 23 }
      ]
    }
  },
  // 轮播环图
  {
    imageSrc: 'BigScreenCarouselRing',
    thumbnail: '',
    title: '轮播环图',
    type: 'BigScreenCarouselRing',
    chartType: 'carouselRing',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      legend: {
        show: true,
        type: 'scroll',
        // 垂直位置
        top: 'middle',
        // 水平位置
        right: 20,
        // 方向
        orient: 'vertical',
        itemWidth: 10,
        itemHeight: 10,
        // itemGap: 8,
        icon: 'rect',
        align: 'left',
        textStyle: {
          color: '#fff',
          padding: [0, 30, 0, 0],
          lineHeight: 28
        },
        pageIconColor: '#BADFFF',
        pageIconInactiveColor: 'rgba(186,223,255,0.1)',
        pageIconSize: [12, 6]
      },
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      label: {
        show: true,
        formatter: '{c}'
      },
      labelUnit: '',
      isSingleSeries: true, // 是否单系列
      coordinateType: 'pieChart', // 坐标系类型 pieChart(饼图坐标系)
      hasAnimation: true,
      animationTime: 4000
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 895 },
        { name: '二月', value: 350 },
        { name: '三月', value: 216 },
        { name: '四月', value: 107 },
        { name: '五月', value: 23 }
      ]
    }
  },
  // 多色玫瑰图
  {
    imageSrc: 'BigScreenPolychromeRose',
    thumbnail: '',
    title: '多色玫瑰图',
    type: 'BigScreenPolychromeRose',
    chartType: 'polychromeRose',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      legend: {
        show: true,
        type: 'scroll',
        // 垂直位置
        top: 'middle',
        // 水平位置
        right: 20,
        // 方向
        orient: 'vertical',
        itemWidth: 10,
        itemHeight: 10,
        // itemGap: 8,
        icon: 'rect',
        align: 'left',
        textStyle: {
          color: '#fff',
          padding: [0, 30, 0, 0],
          lineHeight: 28
        },
        pageIconColor: '#BADFFF',
        pageIconInactiveColor: 'rgba(186,223,255,0.1)',
        pageIconSize: [12, 6]
      },
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      label: {
        show: true,
        formatter: '{c}'
      },
      labelUnit: '',
      isSingleSeries: true, // 是否单系列
      coordinateType: 'pieChart', // 坐标系类型 pieChart(饼图坐标系)
      animationTime: 4000
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 895 },
        { name: '二月', value: 350 },
        { name: '三月', value: 216 },
        { name: '四月', value: 107 },
        { name: '五月', value: 23 }
      ]
    }
  },
  // 轮播排行条形图
  {
    imageSrc: 'BigScreenCarouselRank',
    thumbnail: '',
    title: '轮播排行条形图',
    type: 'BigScreenCarouselRank',
    prop: {
      nameCol: 'name',
      valueCol: 'value',
      strokeWidth: 8,
      unit: '/个',
      valueFormat: 'point',
      labelPosition: 'center'
    },
    styleProp: {
      height: 620,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '手机',
          value: 5934
        },
        {
          name: '平板电脑',
          value: 5521
        },
        {
          name: 'PC',
          value: 4351
        },
        {
          name: '智能音箱',
          value: 2910
        },
        {
          name: '电视',
          value: 1076
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  },
  // 漏斗图
  {
    imageSrc: 'BigScreenFunnel',
    thumbnail: '',
    title: '漏斗图',
    type: 'BigScreenFunnel',
    chartType: 'funnel',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      label: {
        show: true,
        formatter: '{c}'
      },
      labelUnit: '',
      isSingleSeries: true,
      coordinateType: 'funnelChart'
    },
    styleProp: {
      height: 400,
      width: 300
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 100 },
        { name: '二月', value: 80 },
        { name: '三月', value: 60 },
        { name: '四月', value: 40 },
        { name: '五月', value: 20 }
      ]
    }
  },
  // 基本三角形图
  {
    title: '基本三角形图',
    imageSrc: 'BigScreenBasicTriangle',
    thumbnail: '',
    type: 'BigScreenBasicTriangle',
    chartType: 'pictorialBar',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [
        { key: getKey(), name: '用户量', col: 'value', xAxisIndex: 0, yAxisIndex: 0 },
        { key: getKey(), name: '名称', col: 'rate', xAxisIndex: 0, yAxisIndex: 0 }
      ],
      coordinateType: 'grid',
      showBackground: true
    },
    styleProp: {
      height: 300,
      width: 600
    },
    dataProp: {
      defaultData: [
        {
          name: '一月',
          value: 81,
          rate: 39
        },
        {
          name: '二月',
          value: 38,
          rate: 17
        },
        {
          name: '三月',
          value: 53,
          rate: 77
        },
        {
          name: '四月',
          value: 20,
          rate: 66
        },
        {
          name: '五月',
          value: 62,
          rate: 32
        }
      ]
    }
  },
  // 象图
  {
    title: '象图',
    imageSrc: 'BigScreenPictorial',
    thumbnail: '',
    type: 'BigScreenPictorial',
    chartType: 'pictorialBar',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '用户量', col: 'value', xAxisIndex: 0, yAxisIndex: 0 }],
      coordinateType: 'grid'
    },
    styleProp: {
      height: 300,
      width: 600
    },
    dataProp: {
      defaultData: [
        {
          name: '一月',
          value: 81,
          rate: 39
        },
        {
          name: '二月',
          value: 38,
          rate: 17
        },
        {
          name: '三月',
          value: 53,
          rate: 77
        },
        {
          name: '四月',
          value: 20,
          rate: 66
        },
        {
          name: '五月',
          value: 62,
          rate: 32
        }
      ]
    }
  },
  // 斑马纹理柱状图
  {
    title: '斑马纹理柱状图',
    imageSrc: 'BigScreenZebraBar',
    thumbnail: '',
    type: 'BigScreenZebraBar',
    chartType: 'zebraBar',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '会员数', col: 'value', xAxisIndex: 0, yAxisIndex: 0 }],
      coordinateType: 'grid',
      showBackground: true
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 81 },
        { name: '二月', value: 38 },
        { name: '三月', value: 53 },
        { name: '四月', value: 20 },
        { name: '五月', value: 62 }
      ]
    }
  },
  // 预测面积图
  {
    title: '预测面积图',
    imageSrc: 'BigScreenForecastLine',
    thumbnail: '',
    type: 'BigScreenForecastLine',
    chartType: 'forecastLine',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '变化率', col: 'value', xAxisIndex: 0, yAxisIndex: 0 }],
      coordinateType: 'grid'
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '11-01', value: 39 },
        { name: '11-02', value: 17 },
        { name: '11-03', value: 82 },
        { name: '11-04', value: 38 },
        { name: '11-05', value: 59 },
        { name: '11-06', value: 70 },
        { name: '11-07', value: 99 },
        { name: '11-08', value: 33 },
        { name: '11-09', value: 55 },
        { name: '11-10', value: 87 },
        { name: '11-11', value: 14 },
        { name: '11-12', value: 59 }
      ]
    }
  },
  // 万用预测图
  {
    title: '万用预测图',
    imageSrc: 'BigScreenUniversalForecast',
    thumbnail: '',
    type: 'BigScreenUniversalForecast',
    chartType: 'universalForecast',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [
        {
          name: '用户量',
          type: 'bar',
          xAxisIndex: 0,
          yAxisIndex: 0,
          barWidth: 20,
          barMaxWidth: 20,
          barMinWidth: 1,
          stack: '',
          label: { show: false, position: 'top' },
          showRadius: true
        },
        {
          name: '增长率',
          type: 'line',
          xAxisIndex: 0,
          yAxisIndex: 0,
          showSymbol: true,
          lineStyle: { type: 'solid' },
          label: { show: false, position: 'top' },
          smooth: true
        }
      ],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [
        { key: getKey(), name: '用户量', col: 'value', xAxisIndex: 0, yAxisIndex: 0 },
        { key: getKey(), name: '增长率', col: 'rate', xAxisIndex: 0, yAxisIndex: 0 }
      ],
      coordinateType: 'grid'
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '一月',
          value: 81,
          rate: 39
        },
        {
          name: '二月',
          value: 38,
          rate: 17
        },
        {
          name: '三月',
          value: 53,
          rate: 77
        },
        {
          name: '四月',
          value: 20,
          rate: 66
        },
        {
          name: '五月',
          value: 62,
          rate: 32
        }
      ]
    }
  },
  // 层叠玫瑰图
  {
    imageSrc: 'BigScreenStackRose',
    thumbnail: '',
    title: '层叠玫瑰图',
    type: 'BigScreenStackRose',
    chartType: 'stackRose',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      coordinateType: 'polar',
      hasAnimation: true,
      animationTime: 4000,
      polar: {
        center: ['50%', '50%'],
        radius: ['0', '60%']
      }
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '一月',
          value: 895
        },
        {
          name: '二月',
          value: 550
        },
        {
          name: '三月',
          value: 816
        },
        {
          name: '四月',
          value: 607
        },
        {
          name: '五月',
          value: 323
        }
      ]
    }
  },
  // 占比排行条形图
  {
    imageSrc: 'BigScreenRatioRank',
    thumbnail: '',
    title: '占比排行条形图',
    type: 'BigScreenRatioRank',
    prop: {
      nameCol: 'name',
      valueCol: 'value',
      strokeWidth: 8,
      unit: '/个',
      valueFormat: 'point',
      labelPosition: 'center'
    },
    styleProp: {
      height: 620,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '手机',
          value: 5934
        },
        {
          name: '平板电脑',
          value: 5521
        },
        {
          name: 'PC',
          value: 4351
        },
        {
          name: '智能音箱',
          value: 2910
        },
        {
          name: '电视',
          value: 1076
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  },
  // 排行条形图
  {
    imageSrc: 'BigScreenRankV2',
    thumbnail: '',
    title: '排行条形图',
    type: 'BigScreenRankV2',
    prop: {
      nameCol: 'name',
      valueCol: 'value',
      strokeWidth: 8,
      unit: '/个',
      valueFormat: 'point',
      labelPosition: 'center'
    },
    styleProp: {
      height: 620,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '手机',
          value: 5934
        },
        {
          name: '平板电脑',
          value: 5521
        },
        {
          name: 'PC',
          value: 4351
        },
        {
          name: '智能音箱',
          value: 2910
        },
        {
          name: '电视',
          value: 1076
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  },
  // 等级占比图
  {
    imageSrc: 'BigScreenLevelRatio',
    thumbnail: '',
    title: '等级占比图',
    type: 'BigScreenLevelRatio',
    prop: {
      nameCol: 'name',
      valueCol: 'value',
      strokeWidth: 32,
      unit: '/个',
      valueFormat: 'point',
      labelPosition: 'center'
    },
    styleProp: {
      height: 620,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '手机',
          value: 5934
        },
        {
          name: '平板',
          value: 5521
        },
        {
          name: 'PC',
          value: 4351
        },
        {
          name: '音箱',
          value: 2910
        },
        {
          name: '电视',
          value: 1076
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  },
  // 矩形树图
  {
    title: '矩形树图',
    imageSrc: 'BigScreenRectangleTree',
    thumbnail: '',
    type: 'BigScreenRectangleTree',
    chartType: 'treemap',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      // idKey: '',
      // pidKey: '',
      legend: {
        show: false
      },
      xAxisField: 'name',
      series: [],
      manner: 'style1',
      valueCols: [{ key: getKey(), name: '用户量', col: 'value' }],
      isSingleSeries: true,
      coordinateType: 'treemapChart',
      cKey: 'children'
    },
    styleProp: {
      height: 400,
      width: 600
    },
    dataProp: {
      defaultData: [
        {
          name: 'nodeA',
          value: 10,
          children: [
            {
              name: 'nodeAa',
              value: 4
            },
            {
              name: 'nodeAb',
              value: 6
            }
          ]
        },
        {
          name: 'nodeB',
          value: 20,
          children: [
            {
              name: 'nodeBa',
              value: 20,
              children: [
                {
                  name: 'nodeBa1',
                  value: 20
                }
              ]
            }
          ]
        }
      ]
    }
  },
  // 和弦图
  {
    imageSrc: 'BigScreenChord',
    thumbnail: '',
    title: '和弦图',
    type: 'BigScreenChord',
    chartType: 'chord',
    prop: {
      series: [],
      manner: 'style1',
      valueCols: [
        {
          key: getKey(),
          name: '系列一',
          sourceKey: 'source',
          targetKey: 'target',
          valueKey: 'value'
        }
      ],
      coordinateType: 'chordChart'
    },
    styleProp: {
      height: 300,
      width: 400
    },
    dataProp: {
      defaultData: [
        {
          source: 'a',
          target: 'a1',
          value: 113
        },
        {
          source: 'a',
          target: 'b1',
          value: 57
        },
        {
          source: 'a',
          target: 'a2',
          value: 58
        },
        {
          source: 'b',
          target: 'b1',
          value: 202
        },
        {
          source: 'b1',
          target: 'a1',
          value: 16
        },
        {
          source: 'b1',
          target: 'c',
          value: 32
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    },
    events: []
  },
  // K线图
  {
    imageSrc: 'BigScreenCandlestick',
    thumbnail: '',
    title: 'K线图',
    type: 'BigScreenCandlestick',
    chartType: 'candlestick',
    prop: {
      ...propForGrid,
      manner: 'style1',
      series: [],
      xAxisField: 'date',
      valueCols: [
        {
          key: getKey(),
          name: '系列一',
          openKey: 'open',
          closeKey: 'close',
          lowestKey: 'lowest',
          highestKey: 'highest'
        }
      ],
      coordinateType: 'grid',
      isSingleSeries: true,
      trends: ['MA5']
    },
    styleProp: {
      height: 400,
      width: 600
    },
    dataProp: {
      defaultData: [
        {
          date: '2013/1/24',
          open: 2320.26,
          close: 2320.26,
          lowest: 2287.3,
          highest: 2362.94
        },
        {
          date: '2013/1/25',
          open: 2300,
          close: 2291.3,
          lowest: 2288.26,
          highest: 2308.38
        },
        {
          date: '2013/1/26',
          open: 2295.35,
          close: 2346.5,
          lowest: 2295.35,
          highest: 2346.92
        },
        {
          date: '2013/1/27',
          open: 2347.22,
          close: 2358.98,
          lowest: 2337.35,
          highest: 2363.8
        },
        {
          date: '2013/1/28',
          open: 2320.26,
          close: 2320.26,
          lowest: 2347.89,
          highest: 2383.76
        },
        {
          date: '2013/1/29',
          open: 2320.26,
          close: 2320.26,
          lowest: 2347.89,
          highest: 2383.76
        },
        {
          date: '2013/1/30',
          open: 2347.22,
          close: 2358.98,
          lowest: 2337.35,
          highest: 2363.8
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    },
    events: []
  },
  // 表格折线图
  {
    title: '表格折线图',
    imageSrc: 'BigScreenTableLine',
    thumbnail: '',
    type: 'BigScreenTableLine',
    chartType: 'tableLine',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      ...propForGrid,
      yAxis: [
        {
          show: false
        }
      ],
      series: [],
      manner: 'style1',
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '系列一', col: 'value', xAxisIndex: 0, yAxisIndex: 0 }],
      showCount: 4,
      coordinateType: 'grid',
      animationTime: 4000
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '一月',
          value: 81
        },
        {
          name: '二月',
          value: 38
        },
        {
          name: '三月',
          value: 53
        },
        {
          name: '四月',
          value: 20
        },
        {
          name: '五月',
          value: 62
        }
      ]
    }
  },
  // 控制折线图
  {
    title: '控制折线图',
    imageSrc: 'BigScreenCtrlLine',
    thumbnail: '',
    type: 'BigScreenCtrlLine',
    chartType: 'ctrlLine',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style2',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '变化率', col: 'value', xAxisIndex: 0, yAxisIndex: 0 }],
      coordinateType: 'grid'
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '11-01', value: 39 },
        { name: '11-02', value: 17 },
        { name: '11-03', value: 82 },
        { name: '11-04', value: 38 },
        { name: '11-05', value: 59 }
      ]
    }
  },
  // 动态条形图
  {
    title: '动态条形图',
    imageSrc: 'BigScreenDynamicsBar',
    thumbnail: '',
    type: 'BigScreenDynamicsBar',
    chartType: 'dynamicsBar',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      legend: {
        show: false
      },
      series: [],
      manner: 'style2',
      grid: {
        left: 60,
        top: 20,
        right: 70,
        bottom: 10,
        containLabel: true
      },
      xAxis: [
        {
          show: true,
          // 坐标轴名称
          name: '',
          type: 'value',
          position: 'bottom',
          axisLine: {
            show: false
          },
          axisTick: {
            show: true
          }
        }
      ],
      yAxis: [
        {
          show: true,
          // 坐标轴名称
          // name: '',
          type: 'category',
          position: 'left'
        }
      ],
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '变化率', col: 'value', xAxisIndex: 0, yAxisIndex: 0 }],
      animationTime: 2000,
      coordinateType: 'grid'
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '11-01', value: 39 },
        { name: '11-02', value: 17 },
        { name: '11-03', value: 82 },
        { name: '11-04', value: 38 },
        { name: '11-05', value: 59 }
      ]
    }
  },
  // 环状点联结柱状图
  {
    title: '环状点联结柱状图',
    imageSrc: 'BigScreenRingPointBar',
    thumbnail: '',
    type: 'BigScreenRingPointBar',
    chartType: 'ringPointBar',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      ...propForGrid,
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '用户量', col: 'value', xAxisIndex: 0, yAxisIndex: 0 }],
      coordinateType: 'grid'
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        {
          name: '一月',
          value: 81,
          rate: 39
        },
        {
          name: '二月',
          value: 38,
          rate: 17
        },
        {
          name: '三月',
          value: 53,
          rate: 77
        },
        {
          name: '四月',
          value: 20,
          rate: 66
        },
        {
          name: '五月',
          value: 62,
          rate: 32
        }
      ]
    }
  },
  // 胶囊预测图
  {
    imageSrc: 'BigScreenCapsule',
    thumbnail: '',
    title: '胶囊预测图',
    type: 'BigScreenCapsule',
    chartType: 'capsule',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '选择值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    prop: {
      series: [],
      manner: 'style2',
      grid: {
        left: 60,
        top: 48,
        right: 70,
        bottom: 20,
        containLabel: true
      },
      xAxis: [
        {
          show: true,
          // 坐标轴名称
          name: '',
          type: 'value',
          position: 'bottom'
        }
      ],
      yAxis: [
        {
          show: true,
          // 坐标轴名称
          // name: '',
          type: 'category',
          position: 'left'
        }
      ],
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '会员数', col: 'value', xAxisIndex: 0, yAxisIndex: 0 }],
      rotate: 0,
      coordinateType: 'grid'
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 81, rate: 39 },
        { name: '二月', value: 38, rate: 17 },
        { name: '三月', value: 53, rate: 82 },
        { name: '四月', value: 20, rate: 38 },
        { name: '五月', value: 62, rate: 59 }
      ]
    }
  },
  // 玫瑰图
  {
    imageSrc: 'BigScreenRose',
    thumbnail: '',
    title: '玫瑰图',
    type: 'BigScreenRose',
    chartType: 'rose',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      legend: {
        show: false
      },
      series: [],
      manner: 'style1',
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      coordinateType: 'polar',
      polar: {
        center: ['50%', '50%'],
        radius: ['0', '60%']
      },
      radiusAxis: {
        axisLabel: {
          show: true
        }
      }
    },
    styleProp: {
      height: 400,
      width: 400
    },
    dataProp: {
      defaultData: [
        {
          name: '一月',
          value: 895
        },
        {
          name: '二月',
          value: 550
        },
        {
          name: '三月',
          value: 816
        },
        {
          name: '四月',
          value: 607
        },
        {
          name: '五月',
          value: 323
        }
      ]
    }
  },
  // 旋转轮播饼图
  {
    imageSrc: 'BigScreenCarouselPie',
    thumbnail: '',
    title: '旋转轮播饼图',
    type: 'BigScreenCarouselPie',
    chartType: 'carouselPie',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      legend: {
        show: true,
        type: 'scroll',
        // 垂直位置
        top: 'middle',
        // 水平位置
        right: 20,
        // 方向
        orient: 'vertical',
        itemWidth: 10,
        itemHeight: 10,
        // itemGap: 8,
        icon: 'rect',
        align: 'left',
        textStyle: {
          color: '#fff',
          padding: [0, 30, 0, 0],
          lineHeight: 28
        },
        pageIconColor: '#BADFFF',
        pageIconInactiveColor: 'rgba(186,223,255,0.1)',
        pageIconSize: [12, 6]
      },
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      label: {
        show: true,
        formatter: '{c}'
      },
      labelUnit: '',
      isSingleSeries: true, // 是否单系列
      coordinateType: 'pieChart', // 坐标系类型 pieChart(饼图坐标系)
      hasAnimation: true,
      animationTime: 4000
    },
    styleProp: {
      height: 300,
      width: 500
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 895 },
        { name: '二月', value: 350 },
        { name: '三月', value: 216 },
        { name: '四月', value: 107 },
        { name: '五月', value: 23 }
      ]
    }
  },
  // 双层饼图
  {
    imageSrc: 'BigScreenDoublePie',
    thumbnail: '',
    title: '双层饼图',
    type: 'BigScreenDoublePie',
    chartType: 'sunburst',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      legend: {
        show: false
      },
      series: [],
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      manner: 'style1',
      xAxisField: 'name',
      isSingleSeries: true,
      coordinateType: 'sunburstChart',
      cKey: 'children',
      label: {
        show: true
      }
    },
    styleProp: {
      height: 340,
      width: 500
    },
    dataProp: {
      defaultData: [
        {
          name: '示例一',
          children: [
            {
              name: '子实例一',
              value: 5
            },
            {
              name: '子实例二',
              value: 5
            }
          ]
        },
        {
          name: '示例二',
          children: [
            {
              name: '子实例三',
              value: 1
            },
            {
              name: '子实例四',
              value: 2
            }
          ]
        },
        {
          name: '示例三',
          children: [
            {
              name: '子实例五',
              value: 1
            },
            {
              name: '子实例六',
              value: 2
            }
          ]
        }
      ]
    }
  },
  // 甜甜圈图
  {
    imageSrc: 'BigScreenDonut',
    thumbnail: '',
    title: '甜甜圈图',
    type: 'BigScreenDonut',
    chartType: 'donut',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      legend: {
        show: true,
        type: 'scroll',
        // 垂直位置
        top: 'middle',
        // 水平位置
        right: 20,
        // 方向
        orient: 'vertical',
        itemWidth: 10,
        itemHeight: 10,
        // itemGap: 8,
        icon: 'rect',
        align: 'left',
        textStyle: {
          color: '#fff',
          padding: [0, 30, 0, 0],
          lineHeight: 28
        },
        pageIconColor: '#BADFFF',
        pageIconInactiveColor: 'rgba(186,223,255,0.1)',
        pageIconSize: [12, 6]
      },
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      label: {
        show: true,
        formatter: '{c}'
      },
      labelUnit: '',
      isSingleSeries: true,
      coordinateType: 'pieChart'
    },
    styleProp: {
      height: 207,
      width: 582
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 895 },
        { name: '二月', value: 350 },
        { name: '三月', value: 216 },
        { name: '四月', value: 107 },
        { name: '五月', value: 123 }
      ]
    }
  },
  // 摩天轮
  {
    imageSrc: 'BigScreenFerrisWheel',
    thumbnail: '',
    title: '摩天轮',
    type: 'BigScreenFerrisWheel',
    chartType: 'ferrisWheel',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          },
          {
            label: '排序值',
            value: 'dataSort'
          },
          {
            label: '过滤值',
            value: 'dataFilter'
          },
          {
            label: '数据查询页',
            value: 'pageValue'
          }
        ]
      }
    },
    prop: {
      series: [],
      manner: 'style1',
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      isSingleSeries: true,
      coordinateType: 'polar',
      polar: {
        center: ['50%', '50%'],
        radius: ['0', '60%']
      }
    },
    styleProp: {
      height: 400,
      width: 400
    },
    dataProp: {
      defaultData: [
        { name: '一月', value: 895 },
        { name: '二月', value: 350 },
        { name: '三月', value: 216 },
        { name: '四月', value: 107 },
        { name: '五月', value: 23 },
        { name: '六月', value: 295 },
        { name: '七月', value: 550 },
        { name: '八月', value: 716 },
        { name: '九月', value: 307 },
        { name: '十月', value: 223 },
        { name: '十一月', value: 395 },
        { name: '十二月', value: 450 }
      ]
    }
  },
  // 双层轮播饼图
  {
    imageSrc: 'BigScreenDoubleCarousel',
    thumbnail: '',
    title: '双层轮播饼图',
    type: 'BigScreenDoubleCarousel',
    chartType: 'doubleCarousel',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '名称',
            value: 'clickParamName'
          },
          {
            label: '值',
            value: 'clickParamValue'
          },
          {
            label: '点击对象',
            value: 'clickParams'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '显示隐藏',
            value: 'showOrHide'
          },
          {
            label: '切换数据',
            value: 'changeCol'
          },
          {
            label: '切换显示值',
            value: 'changeShowValue'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: []
      }
    },
    prop: {
      legend: {
        show: true
      },
      series: [],
      manner: 'style1',
      xAxisField: 'name',
      valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
      label: {
        show: true,
        formatter: '{c}'
      },
      labelUnit: '',
      pKey: 'parent', // 父级字段 pKey, 子级字段 cKey
      isSingleSeries: true, // 是否单系列
      coordinateType: 'pieChart', // 坐标系类型 pieChart(饼图坐标系)
      animationTime: 4000
    },
    styleProp: {
      height: 520,
      width: 318
    },
    dataProp: {
      defaultData: [
        { value: 20, parent: '移动端', name: '安卓' },
        { value: 15, parent: '移动端', name: '苹果' },
        { value: 5, parent: '移动端', name: '其他' },
        { value: 10, parent: '论坛', name: '百度' },
        { value: 5, parent: '论坛', name: '新浪' },
        { value: 5, parent: '论坛', name: '其他' },
        { value: 10, parent: '新闻', name: '腾讯新闻' },
        { value: 10, parent: '新闻', name: '网易新闻' },
        { value: 10, parent: '新闻', name: '百度新闻' },
        { value: 5, parent: '新闻', name: '搜狐新闻' },
        { value: 5, parent: '新闻', name: '其他' }
      ]
    }
  }
]
/**
 * 指标类
 */
const quotaComp: Widget[] = [
  // 文字
  {
    imageSrc: 'big-screen-text',
    thumbnail: '',
    title: '文字',
    type: 'BigScreenText',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'TableSource',
    dataSourceTypes: ['TableSource', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    styleProp: {
      height: 32,
      width: 150
      // color: '#1186fd'
    },
    prop: {
      labelKey: '',
      style: 'base', // base,level
      comParam: '',
      label: '',
      font: {
        size: 16,
        weight: 'normal', // normal,bold
        color: '#1F96FF',
        space: 0
      },
      levels: [{ start: 0, end: 100, color: '#35FFF4' }],
      HAlign: 'flex-start',
      VAlign: 'center',
      background: {
        enable: false,
        color: ''
      },
      border: {
        enable: true,
        color: '#1F96FC',
        width: 1
      },
      shadow: {
        enable: true,
        color: '#E5E5E5',
        x: 0,
        y: 0,
        b: 8
      }
    },
    dataProp: {
      defaultData: []
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  },
  // 翻牌器
  {
    title: '翻牌器',
    imageSrc: 'big-screen-flop',
    thumbnail: '',
    type: 'BigScreenFlop',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    customDataHandle: '',
    prop: {
      style: 'chart',
      icon: '',
      label: '',
      subTitle: '较前一周环比',
      unit: '单位',
      valueCol: '',
      xField: '',
      yField: '',
      color: '#35FFF4',
      judgements: [
        {
          threshold: 0,
          judgement: '>',
          showText: '正常',
          color: '#44CB7F',
          bgColor: '#44CB7F'
        },
        {
          threshold: 0,
          judgement: '<',
          showText: '异常',
          color: '#DB3135',
          bgColor: '#DB3135'
        }
      ],
      timingOpen: false,
      timingTime: 5
    },
    styleProp: {
      height: 100,
      width: 200
    },
    dataProp: {
      defaultData: [
        {
          value: 33,
          xField: '北京',
          yField: 123
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  }
]
/**
 * 交互类
 */
const interactComp: Widget[] = [
  // 时间选择器
  {
    title: '时间选择器',
    imageSrc: 'big-screen-time',
    thumbnail: '',
    type: 'BigScreenTime',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '开始时间-结束时间',
            value: 'dateTime'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: []
      }
    },
    prop: {
      type: 'date',
      model: 'range'
    },
    styleProp: {
      height: 32,
      width: 288
    }
  },
  // 告警滚动条
  {
    imageSrc: 'big-screen-marquee',
    thumbnail: '',
    title: '告警滚动条',
    type: 'BigScreenMarquee',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    customDataHandle: '',
    prop: {
      type: 'style1',
      label: '',
      interval: 1000,
      color: '#eca850',
      valueField: ''
    },
    styleProp: {
      height: 35,
      width: 500
    },
    dataProp: {
      defaultData: [
        {
          value: '总数超出警戒上限45.22%（67.33）'
        },
        {
          value: '类别一出现故障数量超出上限值'
        },
        {
          value: '类别一34.11%'
        },
        {
          value: '北京等三城市均在类别一的数据连续3天异常'
        },
        {
          value: '数据出现空现象且告警'
        }
      ]
    },
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    }
  },
  // 选项卡轮播
  {
    imageSrc: 'big-screen-carousel',
    thumbnail: '',
    title: '选项卡轮播',
    type: 'BigScreenCarousel',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    customDataHandle: '',
    prop: {
      type: 'style1',
      label: '',
      autoplay: true,
      interval: 3000
    },
    styleProp: {
      height: 100,
      width: 400
    },
    dataProp: {
      defaultData: [
        {
          label: '北京',
          value: '北京',
          value2: ''
        },
        {
          label: '上海',
          value: '上海',
          value2: ''
        },
        {
          label: '天津',
          value: '天津',
          value2: ''
        }
      ],
      // options: [],
      xAxisField: '',
      labelCol: ''
    },
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '当前值',
            value: 'currentValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    }
  },
  // 标签页
  {
    imageSrc: 'big-screen-tabs',
    thumbnail: '',
    title: '标签页',
    type: 'BigScreenTabs',
    mutualSetting: true,
    dataSourceSetting: true,
    hideCompCover: true,
    dataSourceType: 'LocalData',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '页签变化',
            value: 'tabChange'
          }
        ],
        bindValues: [
          {
            label: '当前页签',
            value: 'currentValue'
          },
          {
            label: '值2',
            value: 'currentValue2'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: 300,
      height: 46
    },
    prop: {
      span: 24,
      showType: 'button',
      textAlign: 'left',
      hasTurnPage: false,
      pageSize: 10
    },
    dataProp: {
      defaultData: [
        {
          label: '数据趋势',
          value: '数据趋势',
          value2: '',
          list: []
        },
        {
          label: '数据详情',
          value: '数据详情',
          value2: '',
          list: []
        }
      ]
    }
  }
]

// 对各分类的组件分别遍历，增加相关的公共属性和特殊属性
for (let i = 0, len = titleComp.length; i < len; i++) {
  const item = titleComp[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  titleComp[i] = { ...compAttrWithoutData, ...item }
}
for (let i = 0, len = contentComp.length; i < len; i++) {
  const item = contentComp[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  if (item.dataProp) {
    item.dataProp = { ...dataProp, ...item.dataProp }
  }
  contentComp[i] = { ...compAttrWithoutData, ...item }
}
for (let i = 0, len = chartComp.length; i < len; i++) {
  const item = chartComp[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  item.prop = { ...propForChart, ...item.prop }
  if (item.dataProp) {
    item.dataProp = { ...dataProp, ...item.dataProp }
  }
  chartComp[i] = { ...compAttrWithData, ...item }
}
for (let i = 0, len = quotaComp.length; i < len; i++) {
  const item = quotaComp[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  if (item.dataProp) {
    item.dataProp = { ...dataProp, ...item.dataProp }
  }
  quotaComp[i] = { ...compAttrWithoutData, ...item }
}
for (let i = 0, len = interactComp.length; i < len; i++) {
  const item = interactComp[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  if (item.dataProp) {
    item.dataProp = { ...dataProp, ...item.dataProp }
  }
  interactComp[i] = { ...compAttrWithoutData, ...item }
}
for (let i = 0, len = newChartComp.length; i < len; i++) {
  const item = newChartComp[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  item.prop = { ...propForChart, ...item.prop }
  if (item.dataProp) {
    item.dataProp = { ...dataProp, ...item.dataProp }
  }
  newChartComp[i] = { ...compAttrWithData, ...item }
}

export const titleCompList = titleComp
export const contentCompList = contentComp
export const chartCompList = chartComp.concat(newChartComp)
export const quotaCompList = quotaComp
export const interactCompList = interactComp
