import { defineAsyncComponent } from 'vue'

export * from './src/button'
export * from './src/chain-comparison'
export * as SvgIcon from './src/svg-icon/index'
export * as SvgIconWithText from './src/svg-icon-with-text/index'
export * from './src/widget-components'
export * from './src/preview-router'
export * from './src/layout/index'
export * from './src/views/preview/index'
export * as SketchRule from './src/sketch-rule/index'
export * as SlideValidate from './src/slide-validate/index'
export const WorkflowFormPage = defineAsyncComponent(
  () => import('./src/form-page/WorkflowFormPage.vue')
)
export const CustomDialog = defineAsyncComponent(
  () => import('./src/custom-dialog/CustomDialog.vue')
)

export const WidgetLayout = defineAsyncComponent(
  () => import('./src/widget-layout/index.vue')
)
// import Button from './button/button.vue';
// // ... 其他组件

// // 全局注册组件
// const install = Vue => {
//   Vue.component(Button.name, Button)
//   // ... 其他组件
// }

// /**
//  * 有可能组件会通过script标签引入，如<script src='https://xxx/zyl-ui'></script>
//  */
// if (typeof Window.Vue !== 'undefined') {
//   install(Vue) // 全局直接通过script 引用的方式会默认调用install方法
// }

// export default {
//   install
// }
