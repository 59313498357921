<template>
  <div class="g-custom-form-dialog-page">
    <el-dialog
      v-model="showDialog"
      class="dialog-page"
      width="80vw"
      height="80vh"
      :title="title"
      :append-to-body="false"
      :close-on-click-modal="false"
      destroy-on-close
      draggable
      align-center
      show-close
      close-on-press-escape
      :before-close="closeDialog"
    >
      <template #header="{ titleId, titleClass }">
        <div :id="titleId" :class="titleClass">{{ title }}</div>
        <el-tooltip placement="top" content="一键截图" :show-after="300" :hide-after="500">
          <svg-icon icon-class="screenshot_1" class="screen-shot" @click="screenShotFn" />
        </el-tooltip>
      </template>
      <div id="dialog-page-id">
        <!-- 表单 -->
        <template v-if="isFormPage">
          <FormPage
            :key="cnpKey"
            :widget-data="widgetData"
            :page="currentPage"
            :is-dialog-page="true"
            @close-dialog="closeDialog"
          />
        </template>
        <!-- 非表单 -->
        <View v-else :key="cnpKey" v-model:widget-data-list="widgetData.list" />
      </div>
    </el-dialog>
  </div>
</template>
<script setup lang="ts">
  import { ref, computed, reactive, watch, watchEffect } from 'vue'
  import View from './View.vue'
  import {
    getKey,
    getWigetEventAndComParamInfo,
    getFlatFormPageWidgetDatalist,
    emitter,
    getFormPayloadByWidgetList,
    removeEmpty,
    transformInvokePayloadToFormDataFromKeys,
    handleApiAfterFun,
    handleApiResponseData
  } from '@haohan/clw-utils'
  import { getMenu } from '@haohan/clw-api'
  import { useProject, useEvent } from '@haohan/clw-store'
  import { addDataAssemble, getApiMenu, invokeApiMenu } from '@haohan/clw-api'
  import { ElMessage } from 'element-plus'
  import FormPage from './form-page/index.vue'
  import { has } from 'lodash-es'
  import { domToJpeg } from 'modern-screenshot'

  const projectStore = useProject()
  const eventStore = useEvent()
  const props = withDefaults(defineProps<{ dialogVisible: boolean; pageId: string }>(), {
    dialogVisible: false,
    pageId: ''
  })
  const emit = defineEmits<{
    (e: 'update:dialog-visible', value: boolean): void
  }>()
  const showDialog = computed({
    get: () => props.dialogVisible,
    set: (val) => {
      emit('update:dialog-visible', val)
    }
  })
  const closeDialog = () => {
    emit('update:dialog-visible', false)
    eventStore.deleteMutualPoolBySendEvents(sendEvents.value)
    eventStore.deleteReceiveEvents(receiveEvents.value)
    widgetData.list = []
  }
  // 使用该方法强制重新渲染组件
  const title = ref('')
  const cnpKey = ref(getKey())
  const widgetData = reactive({
    list: []
  })

  const pageLoading = ref(false)
  watchEffect(() => {
    if (props.dialogVisible && props.pageId !== '') {
      pageLoading.value = true
      getMenu(projectStore.currentProject.id, props.pageId as string)
        .then((res) => {
          // 赋值
          currentPage.value = res.data

          title.value = res.data.title
          widgetData.list = res?.data?.page?.components || []
          cnpKey.value = getKey()
          // 弹窗的交互处理
          handleDialogMutual()

          // 初始化表单参数(编辑)
          setTimeout(() => {
            // 重置校验
            createFormRef.value?.resetFields()

            initFormParams(widgetData.list)
          }, 1000)
        })
        .finally(() => {
          setTimeout(() => {
            pageLoading.value = false
          }, 1100)
        })
    }
  })

  const initFormParams = (list: Widget[]) => {
    let data = eventStore.formParamsFromTableCustomAction[props.pageId] as any
    // 非表单, 不含参数, 没有组件
    if (!isFormPage.value || !data?.params || !Object.keys(data.params) || !list?.length) {
      // 这里需要针对表单绑定全局参数做一些处理
      const _d: any = handleFormValueOnCommonParam(list)
      if (Object.keys(_d).length === 0) {
        return false
      }
      data = {
        params: _d
      }
    }
    const row = data?.params
    // 容器组件没有 formProp
    let flatList: any[] = []
    flatList = getFlatFormPageWidgetDatalist(list, flatList)
    const formTemp = {} as any
    flatList.forEach((widget) => {
      // field, 对应 row 的 key,  比如 'id'
      const tableColumn = widget?.formProp?.tableColumn
      if (tableColumn) {
        const value = row[tableColumn]
        const key = widget.key
        if (key) {
          formTemp[key] = value
        }
        // 如果无法从表单获取到值, 则判断是否从公共参数中获取，避免回显时，无法正确显示值
        if (
          (!formTemp[key] || formTemp[key] === '') &&
          widget.formProp.comParam &&
          widget.formProp.comParam !== ''
        ) {
          formTemp[key] = eventStore.commonParameters[widget.formProp.comParam]
            ? eventStore.commonParameters[widget.formProp.comParam].value
            : ''
        }
      }
    })
    projectStore.updateCurrentForm(formTemp)
  }

  const handleFormValueOnCommonParam = (list: Widget[]) => {
    const _d: any = {}
    if (list.length !== 0) {
      // 容器组件没有 formProp
      let _flatList: any[] = []
      _flatList = getFlatFormPageWidgetDatalist(list, _flatList)
      _flatList.forEach((wd: any) => {
        if (wd.formProp && wd.formProp.comParam && wd.formProp.comParam !== '') {
          _d[wd.formProp.tableColumn] = eventStore.commonParameters[wd.formProp.comParam]
            ? eventStore.commonParameters[wd.formProp.comParam].value
            : ''
        }
      })
    }
    return _d
  }

  const sendEvents = ref<WidgetEvent[]>([])
  const receiveEvents = ref<WidgetEvent[]>([])
  const handleDialogMutual = () => {
    const pageInfo = getWigetEventAndComParamInfo(widgetData.list)
    // 向交互池中插入发送交互
    sendEvents.value = pageInfo.sendFilters
    receiveEvents.value = pageInfo.receiveFilters
    eventStore.insertMutualPoolBySendEvents(pageInfo.sendFilters)
    // 插入接收事件
    eventStore.insertReceiveEvents(pageInfo.receiveFilters)
    // 避免因为解决切换页面请求残留问题，导致的无法正常请求数据
    eventStore.insertWidgetDataIdKeys(pageInfo.widgetDataIdKeys)
  }

  // 表单类型 ========================================================

  const currentPage = ref({}) as any

  // 表单页
  const isFormPage = computed(() => {
    const pageType = currentPage.value?.variables?.pageType
    return pageType === 'form'
  })
  // 表单页, padding
  const formPageStyle = computed(() => {
    const styleProp = currentPage.value?.variables?.formAttribute?.styleProp
    if (styleProp) {
      return Object.keys(styleProp)
        .map((padding) => {
          return `${padding}:${styleProp[padding]}px`
        })
        .join(';')
    }
    return {}
  })
  // 表单和 rules
  const globalForm = computed(() => projectStore.currentForm)
  const globalRules = computed(() => projectStore.currentFormRulesParsed)

  watch(
    () => widgetData.list,
    (val) => {
      if (isFormPage.value) {
        // 重置表单和 rules
        projectStore.resetCurrentFormAndRules()
        // 更新表单
        projectStore.updateCurrentFormByWidgetList(widgetData.list)
        // 更新 rules
        projectStore.updateCurrentFormRulesByWidgetList(widgetData.list)
      }
    },
    { deep: true }
  )

  const formSubmitLoading = ref(false)
  const createFormRef = ref()

  const getFlatArr = (arr: any) => {
    return arr.reduce((a: any, item: any) => {
      let flatArr = [...a, item]
      if (item.children) {
        flatArr = [...flatArr, ...getFlatArr(item.children)]
      }
      return flatArr
    }, [])
  }
  const getFormPayload = (list: any, form: any) => {
    const { filterNullValues, filterExtraValues } = currentPage.value?.variables?.formAttribute
    let payload = getFormPayloadByWidgetList(list, form, filterNullValues)
    if (!filterExtraValues) {
      // 从表格行点击, 编辑时带有id等其他参数, 虽然不在表单修改范围, 但是不可缺少
      const data = eventStore.formParamsFromTableCustomAction[props.pageId] as any
      if (data?.params) {
        const params = data.params
        if (params && Object.keys(params)) {
          payload = { ...params, ...payload }
        }
      }
    }
    // 只有为 true 才过滤 null 和 ''
    if (filterNullValues) {
      payload = removeEmpty(payload)
    }
    return {
      data: payload
    }
  }

  /**
   * 表单 - 未配数据源, 隐藏提交按钮
   */
  const formButtonVisible = computed(() => {
    const tableId =
      currentPage.value?.variables?.formAttribute?.tableId ||
      currentPage.value?.variables?.formAttribute?.apiUrl
    return Boolean(tableId)
  })

  /**
   * 表单 - 数据集方式
   */
  const datasourceFormSubmit = () => {
    // 默认为数据集, 不存在则为自定义 API
    const tableId =
      currentPage.value?.variables?.formAttribute?.tableId ||
      currentPage.value?.variables?.formAttribute?.apiUrl
    // 数据表, 拼接表的 id: 'dataSourceId;schema;name'
    const isDataTable = tableId.includes(';')
    const projectId = currentPage.value.projectId
    const payload = getFormPayload(widgetData.list, globalForm.value)
    if (!tableId) {
      return ElMessage.warning('还未绑定数据集')
    }
    if (!isDataTable) {
      return ElMessage.warning('绑定的数据集不是原始表, 不支持该种方式')
    }
    formSubmitLoading.value = true
    addDataAssemble(projectId, tableId, payload)
      .then(() => {
        ElMessage.success('提交成功')
        // 关闭弹窗
        closeDialog()

        // 刷新表格
        onEmitCustomActionCallback()
      })
      .finally(() => {
        formSubmitLoading.value = false
      })
  }

  /**
   * 表单 - 自定义 API
   */
  const customApiFormSubmit = async () => {
    const { tableId } = currentPage.value?.variables?.formAttribute || {}
    const projectId = projectStore.currentProject?.id
    const payload = getFormPayload(widgetData.list, globalForm.value)

    if (!tableId) {
      return ElMessage.warning('还未绑定数据集')
    }

    formSubmitLoading.value = true

    // 详情
    const { data } = await getApiMenu(projectId, tableId)
    // 提交
    invokeApiMenu(
      projectId,
      tableId,
      transformInvokePayloadToFormDataFromKeys(data?.keysDefine.def, payload.data),
      data.headers
    )
      .then((res: any) => {
        res = handleApiResponseData(handleApiAfterFun(data.postPosition, res))
        if (
          res?.status === 200 &&
          has(res, 'data') &&
          has(res.data, 'status') &&
          res.data.status !== 200 &&
          res.data.status !== 0
        ) {
          const message =
            res?.data.message || res?.data.tag || '数据提交失败，请检查提交数据是否正确'
          ElMessage.error(message)
          return false
        }
        // 后端可能不返回内容, status 为 200 也表示成功, 即前端 {data: ''}
        if (res?.status === 0 || res?.status === 200 || (!res?.status && !res.data)) {
          ElMessage.success('提交成功')
          // 关闭弹窗
          closeDialog()

          // 刷新表格
          onEmitCustomActionCallback()
        } else {
          const message = res?.message || res?.tag || '数据提交失败，请检查提交数据是否正确'
          ElMessage.error(message)
        }
      })
      .finally(() => {
        formSubmitLoading.value = false
      })
  }

  enum FORM_DATA_TYPE {
    datasource = 'datasource',
    api = 'api'
  }
  /**
   * 表单页提交
   */
  const onFormSubmit = () => {
    createFormRef?.value?.validate(async (valid: boolean) => {
      if (!valid) {
        ElMessage.warning('校验未通过')
        return false
      }
      const formAttribute = currentPage.value?.variables?.formAttribute
      if (!formAttribute) {
        return ElMessage.warning('缺少表单属性')
      }
      const { dataType } = formAttribute
      // 表单 - 数据集方式 | 自定API地址
      dataType === FORM_DATA_TYPE.datasource ? datasourceFormSubmit() : customApiFormSubmit()
    })
  }

  /**
   * 表格自定义操作触发的表格刷新操作
   */
  const onEmitCustomActionCallback = () => {
    // 从表格行点击, 编辑时带有id等其他参数, 虽然不在表单修改范围, 但是不可缺少
    const data = eventStore.formParamsFromTableCustomAction[props.pageId] as any
    if (data?.emitterKey) {
      emitter.emit(data?.emitterKey)
    }
  }

  async function screenShotFn() {
    // 截图名称
    const exportName = title.value

    const preview = document.querySelector('#preview')
    const dialogCommonPage = preview?.querySelector('#dialog-page-id > div')
    const dialogFormPage = preview?.querySelector('#dialog-page-id .el-form')
    const app = (isFormPage.value ? dialogFormPage : dialogCommonPage) as HTMLElement
    // console.log(app)
    if (app) {
      domToJpeg(app, { backgroundColor: '#fff' }).then((dataUrl) => {
        const link = document.createElement('a')
        link.download = `${exportName}.jpg`
        link.href = dataUrl
        link.click()
      })
    }
  }
</script>

<style lang="scss" scoped>
  :deep(.dialog-page) {
    border-radius: 16px !important;
  }

  .form-page-submit-container {
    margin-top: 32px;
    text-align: center;
    .btn {
      width: 100px;
    }
    .cancle {
      margin-left: 80px;
    }
  }

  // :deep(.el-dialog) {
  //   max-height: 870px;
  // }
  // :deep(.el-dialog__body) {
  //   overflow-y: auto;
  //   height: 680px;
  // }
  #dialog-page-id {
    overflow-y: auto;
    max-height: 80vh;
  }
  .screen-shot {
    position: absolute;
    top: 16px;
    right: 48px;
    cursor: pointer;
    outline: none;
  }
</style>
