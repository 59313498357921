/**
 * 将HSB颜色值转换为RGB颜色值
 * @param h - 色调（0-360）
 * @param s - 饱和度（0-100）
 * @param b - 明度（0-100）
 * @returns RGB颜色值
 */
export function hsbToRGB(h: number, s: number, b: number): { R: number; G: number; B: number } {
  s /= 100
  b /= 100
  const k = (n: number) => (n + h / 60) % 6
  const f = (n: number) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)))
  const R = Math.round(255 * f(5))
  const G = Math.round(255 * f(3))
  const B = Math.round(255 * f(1))
  return { R, G, B }
}
/**
 * RGB颜色转hsb颜色
 * @param r 红
 * @param g 绿
 * @param b 蓝
 * @returns
 */
export function RGBTohsb(r: number, g: number, b: number) {
  r = r / 255
  g = g / 255
  b = b / 255
  let h: number, s: number, v: number
  const min = Math.min(r, g, b)
  const max = (v = Math.max(r, g, b))
  const l = (min + max) / 2
  const difference = max - min

  if (max === min) {
    h = 0
  } else {
    switch (max) {
      case r:
        h = (g - b) / difference + (g < b ? 6 : 0)
        break
      case g:
        h = 2.0 + (b - r) / difference
        break
      case b:
        h = 4.0 + (r - g) / difference
        break
      default:
    }
    h = Math.round(h * 60)
  }
  if (max === 0) {
    s = 0
  } else {
    s = 1 - min / max
  }
  s = Math.round(s * 100)
  v = Math.round(v * 100)
  return { h, s, b: v }
}

//RGB转十六进制
export const RGBToHex = (r, g, b) => ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0')

// 十六进制转RGB
export function hexToRGBA(hex: string) {
  if (!hex) {
    return { R: 0, G: 0, B: 0, A: 255 }
  }
  const R = parseInt(hex.slice(1, 3), 16)
  const G = parseInt(hex.slice(3, 5), 16)
  const B = parseInt(hex.slice(5, 7), 16)
  const A = hex.slice(7, 9) ? parseInt(hex.slice(7, 9), 16) : 255
  return { R, G, B, A }
}

/**
 * 将rgb颜色字符串转换为具有每种颜色值的对象
 * @param rgbStr
 * @returns
 */
export function toRGBObject(rgbStr: string) {
  const [R, G, B] = rgbStr.match(/\d+/g).map(Number)
  return { R, G, B }
}
