export enum ROUTE_PATH_CONSTANT {
  ViewLayout = '/view',
  Home = '/home',
  EditAPI = '/edit-api',
  EditPage = '/edit-page', // 普通页面和表单
  DBLayout = '/db',
  EditDB = '/edit-db',
  DashBoard = '/dashboard',

  InternalDataSourceConfig = '/internal/data-source', // 内部数据源-数据源配置
  InternalDataSetLayout = '/internal/data-set/layout', // 内部数据源-数据集(欢迎页)
  InternalDataSetTypeSelect = '/internal/data-set/type', // 内部数据源-数据集(创建方式选择)
  InternalDataSet = '/internal/data-set', // 内部数据源-数据集
  InternalDataTableDetail = '/internal/data-table/detail', // 内部数据源-数据集-数据表(详情编辑页)
  InternalDataSetDetail = '/internal/data-set/detail', // 内部数据源-数据集-数据集(详情编辑页)

  ExternalDataSource = '/external/data-source', // 外部数据源-数据源
  ExternalDataSetLayout = '/external/data-set/layout', // 外部数据源-数据集(欢迎页)
  ExternalDataSet = '/external/data-set', // 外部数据源-数据集
  ExternalDataSetDetail = '/external/data-set/detail', // 外部数据源-数据集(详情编辑页)
  ExternalDataTableDetail = '/external/data-table/detail', // 外部数据源-数据表(表格展示列表)

  IndustryDataSource = '/external/data-source', // 行业定制-数据源
  IndustryDataSetLayout = '/external/data-set/layout', // 行业定制-数据集(欢迎页)
  IndustryDataSet = '/external/data-set', // 行业定制-数据集
  IndustryDataSetDetail = '/external/data-set/detail', // 行业定制-数据集(详情编辑页)
  IndustryDataTableDetail = '/external/data-table/detail', // 行业定制-数据表(表格展示列表)
  // IndustryDataSource = '/industry/data-source', // 行业定制-数据源
  // IndustryDataSetLayout = '/industry/data-set/layout', // 行业定制-数据集(欢迎页)
  // IndustryDataSet = '/industry/data-set', // 行业定制-数据集
  // IndustryDataSetDetail = '/industry/data-set/detail', // 行业定制-数据集(详情编辑页)
  // IndustryDataTableDetail = '/industry/data-table/detail', // 行业定制-数据表(表格展示列表)

  CustomAPILayout = '/api/layout', // 自定义 API (欢迎页)
  CustomAPI = '/api', // 自定义 API
  CustomAPIDetail = '/api/detail', // 自定义 API 详情
  StandardApi = '/api/standard-api', // api 标准界面

  BigScreen = '/big-screen-edit-page', // 大屏

  FormNavigation = '/form-navigation', // 表单创建导航页
  WorkFlow = '/workflow', // 工作流首页
  LogicFlow = '/logicflow', // 逻辑流首页
  LogicFlowDetail = '/logicflow/detail', // 逻辑流详情页
  UniverReport = '/report' // 逻辑流首页
}
