<template>
  <NormalFormPage
    v-if="!isWorkflowForm"
    :widget-data="widgetData"
    :page="currentPage"
    :is-dialog-page="isDialogPage"
    @close-dialog="closeDialog"
  >
  </NormalFormPage>
  <WorkflowFormPage
    v-else
    :widget-data="widgetData"
    :page="currentPage"
    :is-dialog-page="isDialogPage"
    @close-dialog="closeDialog"
  >
  </WorkflowFormPage>
</template>
<script lang="ts">
  export default {
    name: 'FormPage'
  }
</script>
<script setup lang="ts">
  import { computed } from 'vue'
  import { useProject, useEvent, useGlobalStore } from '@haohan/clw-store'
  import NormalFormPage from './NormalFormPage.vue'
  import WorkflowFormPage from './WorkflowFormPage.vue'
  import { has } from 'lodash-es'

  interface WidgetDataInfo {
    list: Widget[]
  }
  // const props = defineProps<{
  //   widgetData: WidgetDataInfo
  //   page?: any
  //   showScrollbar?: any
  // }>()
  interface Props {
    widgetData: WidgetDataInfo
    page?: any
    isDialogPage?: any
  }
  const props = withDefaults(defineProps<Props>(), {
    widgetData: () => [] as any,
    isDialogPage: false
  })
  const emit = defineEmits<{
    (e: 'closeDialog', value: boolean): void
  }>()
  const projectStore = useProject()

  const currentPage = computed(() => (props.page ? props.page : projectStore?.currentPage))

  const isWorkflowForm = computed(() => {
    return (
      has(currentPage.value.variables, 'formAttribute') &&
      has(currentPage.value.variables?.formAttribute, 'formType') &&
      currentPage.value.variables?.formAttribute.formType === 'workflow'
    )
  })

  const closeDialog = (value: boolean) => {
    emit('closeDialog', value)
  }
</script>
<style lang="scss" scoped></style>
