import {
  login,
  getInfo,
  logout,
  getRegionList,
  loginWithCodeReq,
  userRegisterReq,
  backPasswordReq
} from '@haohan/clw-api'
import { getToken, setToken, removeToken, removeTheme } from '@haohan/clw-utils'
import { findLast, intersection } from 'lodash-es'
import { defineStore } from 'pinia'
import axios from 'axios'

interface State {
  token?: string
  current: any
  regions: any
}

export const useUser = defineStore({
  id: 'user',
  getters: {
    anonymous: (state) => !state.current.username,
    isGranted: (state) => {
      if (!state.current.username || state.current.username === '') {
        return (item: any) => item.meta && item.meta.anonymous
      }
      return (item: any) => {
        const m = findLast(item.matched, (ii) => ii.meta && ii.meta.roles)
        return !m || intersection(m.meta.roles, state.current.roles).length > 0
      }
    },
    isAdmin: (state) => {
      return state.current.username === 'admin'
    },
    // 当前套餐下的限制
    limit: (state) => {
      // return {
      //   userCount: 1, // 用户数 Done
      //   projectCount: 1, // 项目数 Done
      //   pageCountPerProject: 10, // 每项目页面数 Done
      //   templateCount: 2, // 模板数
      //   datasetCountPerProject: 1, // 每项目数据集数 Done
      //   insideDatabaseCountPerProject: 1, // 每项目自建数据源数
      //   tableCountPerDatasource: 2, // 每数据源可建表数
      //   recordCountPerTable: 10000, // 每张表可建记录数
      //   storageSizePerProject: 20 * 1024 * 1024, // 每项目存储空间大小
      //   api: false, // API功能 Done
      //   offlineGenerate: false, // 离线发布功能
      //   customComponent: false, // 定制组件功能
      //   customDevelop: false, // 定制开发功能
      //   alarm: false, // 告警功能
      //   userManager: false, // 成员管理功能
      //   roleManager: false, // 角色管理功能
      //   projectLimitManager: false, // 项目权限管理功能
      //   accountSecure: false, // 账号安全设置功能
      //   log: false // 操作日志查看功能
      // }

      return state.current.limit
    }
  },
  state: (): State => {
    return {
      token: getToken(),
      current: {},
      regions: {}
    }
  },
  actions: {
    async login(loginForm: any, isAuto = false, type = 'password') {
      try {
        if (type === 'password') {
          const resp = await login(loginForm)
          console.log('login resp', resp)
          const token = resp.data.access_token
          this.token = token
          setToken(token, isAuto)
        } else {
          const resp = await loginWithCodeReq(loginForm)
          const token = resp.data.access_token
          this.token = token
          setToken(token, isAuto)
        }
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    async register(loginForm: any) {
      try {
        const resp = await userRegisterReq(loginForm)
        const token = resp.data.token.access_token
        this.token = token
        setToken(token)
      } catch (error) {
        // console.log(error)
        // throw error
      }
    },
    async backPassword(form: any) {
      try {
        const resp = await backPasswordReq(form)
        const token = resp.data.access_token
        this.token = token
        setToken(token)
      } catch (error) {
        // console.log(error)
        // throw error
      }
    },
    async getInfo() {
      try {
        const resp = await getInfo()
        this.current = {
          ...resp.data
          // 后端已经返回真实头像了
          // avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
        }
      } catch (error) {
        this.current = {}
        removeToken()
        removeTheme()
        throw error
      }
    },
    async logout() {
      await logout()
      this.current = {}
      removeToken()
      removeTheme()
      window.location.reload()
    },
    async getRegionInfo() {
      try {
        const resp = await getRegionList()
        resp.data.forEach((d: any) => {
          this.regions[d.name] = { ...d }
        })
      } catch (error) {
        this.regions = {}
      }
    },
    /**
     * 传入 currentUser 中 permissions 的 page, 如 'CompanyManager' 检查是否有权限
     * @param pageName
     * @returns
     */
    hasPermissionByPageName(pageName: string) {
      if (!pageName) {
        return false
      }
      const permissions = this.current?.permissions
      if (permissions?.length) {
        const permission = permissions.filter((p: any) => p.page === pageName)[0]
        if (permission) {
          return true
        }
      }
      return false
    },
    /**
     * 根据 url 中 query 的 token 去接口检测是否正确
     * @param token
     * @returns
     */
    checkToken(token: string) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/v1/auth/currentUser`,
          headers: {
            authorization: token
          }
        })
          .then((res) => {
            // 移除旧的
            this.current = {}
            removeToken()
            removeTheme()

            // 返回新的
            resolve(res)
          })
          .catch((error) => {
            this.current = {}
            removeToken()
            removeTheme()
            reject(error)
          })
      })
    }
  }
})
