export enum BUSINESS_MANAGEMENT_ROUTE {
  manage = '/manage',
  profile = '/manage/profile',
  dashboard = '/manage/dashboard',
  enterprise = '/manage/enterprise',
  project = '/manage/project',
  role = '/manage/role',
  security = '/manage/security',
  user = '/manage/user',
  userList = '/manage/user/list',
  userAdd = '/manage/user/add',
  userEdit = '/manage/user/edit',
  monitor = '/manage/monitor',
  scan = '/manage/scan',
  scanTrigger = '/manage/scan/trigger',
  scanDetail = '/manage/scan/detail',
  log = '/manage/log',
  securityLog = '/manage/log/security',
  operationLog = '/manage/log/operation',
  group = '/manage/group'
}
