<template>
  <div>
    <el-scrollbar max-height="80vh">
      <el-form
        ref="createFormRef"
        :style="formPageStyle"
        label-position="top"
        :model="globalForm"
        :rules="globalRules"
        label-width="120px"
      >
        <View :key="cnpKey" :widget-data-list="widgetData.list"></View>
      </el-form>
    </el-scrollbar>
    <div v-if="widgetData?.list?.length && formButtonVisible" class="form-page-submit-container">
      <el-button type="primary" class="btn" :loading="formSubmitLoading" @click="onFormSubmit">
        确定
      </el-button>
      <el-button class="btn cancle" @click="closeDialog"> 取消 </el-button>
    </div>
    <!-- <DialogPage v-model:dialog-visible="dialogVisible" :page-id="pageId"></DialogPage>
    <MultipleJump></MultipleJump> -->
  </div>
</template>
<script lang="ts">
  export default {
    name: 'NormalFormPage'
  }
</script>
<script setup lang="ts">
  import { useRoute } from 'vue-router'
  import { ref, watch, computed } from 'vue'
  import { getApiMenu, invokeApiMenu, queryLFMenuItemReq, invokeLogicFlow } from '@haohan/clw-api'
  import { useProject, useEvent, useGlobalStore } from '@haohan/clw-store'
  import { addDataAssemble } from '@haohan/clw-api'
  import { ElMessage } from 'element-plus'
  import {
    getKey,
    getFormPayloadByWidgetList,
    transformInvokePayloadToFormDataFromKeys,
    emitter,
    removeEmpty,
    handleApiAfterFun,
    transformLogicFlowParams
  } from '@haohan/clw-utils'
  import View from '../View.vue'
  import { has } from 'lodash-es'
  import { useMessage } from '@haohan/clw-hooks'

  interface WidgetDataInfo {
    list: Widget[]
  }
  const props = defineProps<{ widgetData: WidgetDataInfo; page?: any; isDialogPage?: boolean }>()
  const emit = defineEmits<{
    (e: 'closeDialog', value: boolean): void
  }>()
  const projectStore = useProject()
  const eventStore = useEvent()
  // 使用该方法强制重新渲染组件
  const cnpKey = ref(getKey())

  // 弹窗处理
  // const dialogVisible = ref(false)
  // const pageId = ref('')
  // watch(
  //   () => eventStore.allPageDialogEvents,
  //   () => {
  //     dialogEvent.all.clear()
  //     eventStore.allPageDialogEvents.forEach((e: any) => {
  //       dialogEvent.on(e.key, (d: any) => {
  //         pageId.value = d
  //         dialogVisible.value = true
  //       })
  //     })

  //     // 表格自定义事件 - 显示弹窗
  //     dialogEvent.on('table-custom-action-for-dialog', (d: any) => {
  //       pageId.value = d
  //       dialogVisible.value = true
  //     })
  //   },
  //   { immediate: true, deep: true }
  // )
  // 表单页, 是否展示面包屑
  // const isFormPage = computed(() => {
  //   const pageType = projectStore?.currentPage?.variables?.pageType
  //   return pageType === 'form'
  // })

  const currentPage = computed(() => (props.page ? props.page : projectStore?.currentPage))
  // 表单页, padding
  const formPageStyle = computed(() => {
    const styleProp = currentPage.value.variables?.formAttribute?.styleProp
    if (styleProp) {
      return Object.keys(styleProp)
        .map((padding) => {
          return `${padding}:${styleProp[padding]}px`
        })
        .join(';')
    }
    return {}
  })

  // 表单和 rules
  const globalForm = computed(() => projectStore.currentForm)
  const globalRules = computed(() => projectStore.currentFormRulesParsed)

  watch(
    () => props.widgetData.list,
    (val) => {
      if (projectStore.isFormPage) {
        // 重置表单和 rules
        projectStore.resetCurrentFormAndRules()
        // 更新表单
        projectStore.updateCurrentFormByWidgetList(props.widgetData.list)
        // 更新 rules
        projectStore.updateCurrentFormRulesByWidgetList(props.widgetData.list)
      }
    },
    { deep: true }
  )

  const formSubmitLoading = ref(false)
  const createFormRef = ref()
  const getFormPayload = (list: any, form: any) => {
    const { filterNullValues, filterExtraValues } = currentPage.value?.variables?.formAttribute
    let payload = getFormPayloadByWidgetList(list, form, filterNullValues)
    if (!filterExtraValues) {
      // 从表格行点击, 编辑时带有id等其他参数, 虽然不在表单修改范围, 但是不可缺少
      const data = eventStore.formParamsFromTableCustomAction[currentPage.value.id] as any
      if (data?.params) {
        const params = data.params
        if (params && Object.keys(params)) {
          payload = { ...params, ...payload }
        }
      }
    }
    // 只有为 true 才过滤 null 和 ''
    if (filterNullValues) {
      payload = removeEmpty(payload)
    }
    return {
      data: payload
    }

    // const { filterNullValues } = currentPage.value.variables?.formAttribute
    // return {
    //   data: getFormPayloadByWidgetList(list, form, filterNullValues)
    // }
  }

  /**
   * 表单 - 未配数据源, 隐藏提交按钮
   */
  const formButtonVisible = computed(() => {
    const tableId =
      currentPage.value.variables?.formAttribute?.tableId ||
      currentPage.value.variables?.formAttribute?.apiUrl
    return Boolean(tableId)
  })

  /**
   * 表单 - 数据集方式
   */
  const datasourceFormSubmit = () => {
    // 默认为数据集, 不存在则为自定义 API
    const tableId =
      currentPage.value.variables?.formAttribute?.tableId ||
      currentPage.value.variables?.formAttribute?.apiUrl
    // 数据表, 拼接表的 id: 'dataSourceId;schema;name'
    const isDataTable = tableId.includes(';')
    const projectId = projectStore.currentProject.id
    const payload = getFormPayload(props.widgetData.list, globalForm.value)
    if (!tableId) {
      return ElMessage.warning('还未绑定数据集')
    }
    if (!isDataTable) {
      return ElMessage.warning('绑定的数据集不是原始表, 不支持该种方式')
    }
    formSubmitLoading.value = true
    addDataAssemble(projectId, tableId, payload)
      .then(() => {
        ElMessage.success('新增成功')
        // 关闭弹窗
        closeDialog()

        // 刷新表格
        onEmitCustomActionCallback()
      })
      .finally(() => {
        formSubmitLoading.value = false
      })
  }
  /**
   * 表单 - 自定义 API
   */
  const customApiFormSubmit = async () => {
    const { tableId } = currentPage.value.variables?.formAttribute || {}
    const projectId = projectStore.currentProject?.id
    const payload = getFormPayload(props.widgetData.list, globalForm.value)

    if (!tableId) {
      return ElMessage.warning('还未绑定数据集')
    }

    formSubmitLoading.value = true

    // 详情
    const { data } = await getApiMenu(projectId, tableId)
    // 提交
    invokeApiMenu(
      projectId,
      tableId,
      transformInvokePayloadToFormDataFromKeys(data?.keysDefine.def, payload.data)
    )
      .then((res: any) => {
        res = handleApiAfterFun(data.postPosition, res)
        if (
          res?.status === 200 &&
          has(res, 'data') &&
          has(res.data, 'status') &&
          res.data.status !== 200 &&
          res.data.status !== 0
        ) {
          const message =
            res?.data.message || res?.data.tag || '数据提交失败，请检查提交数据是否正确'
          ElMessage.error(message)
          return false
        }
        // 后端可能不返回内容, status 为 200 也表示成功, 即前端 {data: ''}
        if (res?.status === 0 || res?.status === 200 || (!res?.status && !res.data)) {
          ElMessage.success('提交成功')
          // 关闭弹窗
          closeDialog()

          // 刷新表格
          onEmitCustomActionCallback()
        } else {
          const message = res?.message || res?.tag || '数据提交失败，请检查提交数据是否正确'
          ElMessage.error(message)
        }
      })
      // .catch((error: any) => {
      //   // ElMessage.error('数据提交失败，请检查提交数据是否正确')
      //   if (error?.response?.data || error?.response?.data.message) {
      //     useMessage(error.response.data)
      //   }
      // })
      .finally(() => {
        formSubmitLoading.value = false
      })
  }

  const customLogicFlowSubmit = async () => {
    const { tableId } = currentPage.value.variables?.formAttribute || {}
    const projectId = projectStore.currentProject?.id
    const payload = getFormPayload(props.widgetData.list, globalForm.value)

    if (!tableId) {
      return ElMessage.warning('还未绑定逻辑流')
    }

    formSubmitLoading.value = true

    // 获得逻辑流详细信息
    const { data } = await queryLFMenuItemReq(projectId, tableId)

    // 处理逻辑流数据
    invokeLogicFlow(projectId, tableId, transformLogicFlowParams(data.parameters, payload.data))
      .then((res: any) => {
        if (
          res?.status === 200 &&
          has(res, 'data') &&
          has(res.data, 'status') &&
          res.data.status !== 200 &&
          res.data.status !== 0
        ) {
          const message =
            res?.data.message || res?.data.tag || '数据提交失败，请检查提交数据是否正确'
          ElMessage.error(message)
          return false
        }
        // 后端可能不返回内容, status 为 200 也表示成功, 即前端 {data: ''}
        if (res?.status === 0 || res?.status === 200 || (!res?.status && !res.data)) {
          ElMessage.success('提交成功')
          // 关闭弹窗
          closeDialog()

          // 刷新表格
          onEmitCustomActionCallback()
        } else {
          const message = res?.message || res?.tag || '数据提交失败，请检查提交数据是否正确'
          ElMessage.error(message)
        }
      })
      .catch((error: any) => {
        if (error?.response?.data || error?.response?.data.message) {
          useMessage(error.response.data)
        }
      })
      .finally(() => {
        formSubmitLoading.value = false
      })
  }
  /**
   * 表格自定义操作触发的表格刷新操作
   */
  const onEmitCustomActionCallback = () => {
    // 从表格行点击, 编辑时带有id等其他参数, 虽然不在表单修改范围, 但是不可缺少
    const data = eventStore.formParamsFromTableCustomAction[currentPage.value.id] as any
    if (data?.emitterKey) {
      emitter.emit(data?.emitterKey)
    }
  }

  enum FORM_DATA_TYPE {
    datasource = 'datasource',
    api = 'api',
    logicFlowSource = 'LogicFlowSource'
  }
  /**
   * 表单页提交
   */
  const onFormSubmit = () => {
    createFormRef?.value?.validate((valid: boolean) => {
      if (!valid) {
        ElMessage.warning('校验未通过')
        return false
      }
      const formAttribute = currentPage.value.variables?.formAttribute
      if (!formAttribute) {
        return ElMessage.warning('缺少表单属性')
      }
      const { dataType } = formAttribute
      // 表单 - 数据集方式 | 自定API地址
      if (dataType === FORM_DATA_TYPE.datasource) {
        datasourceFormSubmit()
      } else if (dataType === FORM_DATA_TYPE.api) {
        customApiFormSubmit()
      } else {
        customLogicFlowSubmit()
      }
      // dataType === FORM_DATA_TYPE.datasource ? datasourceFormSubmit() : customApiFormSubmit()
    })
  }

  const closeDialog = () => {
    if (props.isDialogPage) {
      emit('closeDialog', false)
    }
  }
</script>
<style lang="scss" scoped>
  .form-page-submit-container {
    margin-top: 32px;
    text-align: center;
    .btn {
      width: 100px;
    }
    .cancle {
      margin-left: 80px;
    }
  }
  .show {
    // max-height: calc(100vh - 300px);
  }
</style>
