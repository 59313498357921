import { useUser } from '@haohan/clw-store'
import { havePermission } from '@/utils/permission'
/**
 * {
 *  type: 'havaManager'
 * }
 * type:
 * havaManager  是否有后台管理权限
 * havePermission 是否拥有指定权限
 * isPersonal 当前项目是否是个人项目
 */
export default {
  mounted: (el, binding) => {
    const user = useUser()
    // console.log('permission el', el, binding, user)
    const type = binding.value.type || ''
    console.log('我进行权限判断')
    // 超级管理员拥有所有权限
    // if (user.isAdmin) {
    //   return
    // }
    if (type === 'havaManager') {
      // 找寻当前用户是否有管理中心相关得权限
      const companyPermission = user.current.permissions.filter((p) => p.type === 'Company')
      if (
        companyPermission.length === 0 ||
        (!user.current.limit.userManager && !user.current.limit.roleManager)
      ) {
        el.remove()
      }
    } else if (type === 'havePermission') {
      // 是否拥有指定权限
      const permission = binding.value.permission || ''
      const type = binding.value.permissionType || 'project'
      if (!havePermission(permission, type)) {
        el.remove()
      }
    } else if (type === 'isPersonal') {
      const userId = binding.value.userId || ''
      if (userId !== user.current.id) {
        el.remove()
      }
    }
  }
}
