<template>
  <div class="button-widge" :style="box">
    <el-button
      v-if="prop?.model === 'base'"
      class="base"
      :type="btnType"
      :size="prop?.size"
      :[prop?.style]="true"
      :plain="!prop?.fill"
      :class="{ underline: prop?.underline }"
      :style="btnStyle"
      :link="isText"
      @click="click"
    >
      <svg-icon v-if="prop?.icon" :icon-class="prop?.icon" />
      <template v-if="isStatusBtn">{{ showTxt(status) }}</template
      >{{ prop?.txt }}
    </el-button>
    <template v-else>
      <el-button-group
        class="group"
        :type="btnType"
        :size="prop?.size"
        :link="isText"
        :class="{ underline: prop?.underline }"
      >
        <template v-for="v in prop?.options" :key="v.value">
          <el-button
            :[prop?.style]="true"
            :style="btnStyle"
            :plain="!prop?.fill"
            @click="groupClick(v.value)"
          >
            <svg-icon v-if="v.icon" :icon-class="v.icon" />
            {{ v.label }}
          </el-button>
        </template>
      </el-button-group>
    </template>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import CommonForGeneral from '../CommonForGeneral.vue'
  import { sendEventByTriggerType } from '@haohan/clw-hooks'
  import { find, hasIn } from 'lodash-es'
  import { widgetEvent, buildApiQueryByEvent, getEventValues } from '@haohan/clw-utils'

  export default defineComponent({
    extends: CommonForGeneral,
    setup(props, context) {
      return {
        // 可以解决被继承组件中setup不执行问题
        ...CommonForGeneral.setup!(props, context)
      }
    },
    data() {
      return {
        status: true,
        currentValue: null
      }
    },
    computed: {
      btnStyle() {
        if (!this.prop?.border) {
          return {
            border: 'none'
          }
        }
        return {}
      },
      isStatusBtn() {
        const e = find(
          this.item.events,
          (et) => et.eventType === 'send' && et.triggerType === 'status'
        )
        return !!e
      },
      btnType() {
        if (this.prop?.type !== 'text') {
          return this.prop?.type
        }
        return 'primary'
      },
      isText() {
        return this.prop?.type === 'text'
      },
      eventValue() {
        // 所有绑定的接收事件
        return buildApiQueryByEvent(getEventValues(this.item.events || []))
      }
    },
    mounted() {
      if (hasIn(this.prop, 'status')) {
        this.status = this.prop!.status
      }
    },
    methods: {
      showTxt(bool: boolean) {
        return bool ? '隐藏' : '显示'
      },
      // 自身的发送&接收事件
      click() {
        const e = find(this.item.events, (et) => et.eventType === 'send')
        if (e) {
          if (e.triggerType === 'status') {
            this.status = !this.status
            widgetEvent.emit(e.key, this.status)
          } else {
            sendEventByTriggerType(this.item.events!, e.triggerType!, this)
          }
        }
      },
      groupClick(val: any) {
        this.currentValue = val
        const e = find(this.item.events, (et) => et.eventType === 'send')
        if (e) {
          sendEventByTriggerType(this.item.events!, e.triggerType!, this)
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
  .button-widge {
    .base {
      width: 100%;

      &.el-button--large {
        --el-button-size: var(--haohan-size-large);
        font-size: 16px;
      }

      &.el-button--default {
        height: var(--haohan-size-default);
      }

      &.el-button--small {
        height: var(--haohan-size-small);
        font-size: 12px;
      }

      :deep(.svg-icon) {
        margin-right: 6px;
      }

      &.underline {
        :deep(span) {
          text-decoration: underline;
        }
      }
    }

    .group {
      :deep(.svg-icon) {
        font-size: inherit;
        margin-right: 6px;
      }
    }

    .el-select {
      width: 178px;
    }
  }
</style>
