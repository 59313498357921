import { has } from 'lodash-es'

export function format(row: any, column: any, value: any, index: any) {
  if (typeof value === 'number') {
    value = value.toString()
    let p = value.indexOf('.')
    if (p === -1) {
      p = value.length
    }
    let i = p % 3 || 3
    let r = value.substr(0, i)
    for (; i < p; i += 3) {
      r += ','
      r += value.substr(i, 3)
    }
    r += value.substr(p)
    return r
  }
  return value
}

export function fmdate(row: any, column: any, val: string, index: number) {
  return val.split('.')[0]
}

export function fmstatus(row: any, column: any, str: string, index: number) {
  return str === 'ENABLED' ? '可用' : '锁定'
}

export function fmaction(row: any, column: any, str: string, index: number): string {
  const action = {
    Add: '新增',
    Modify: '修改',
    Delete: '删除'
  }
  return action[str]
}
/**
 * 将Query接口返回的数据转换为表格实际使用的
 * @param data Query接口返回的数据
 */
export function transFormQueryData2SourceData(data: any) {
  // 因为数据集查询跟原始表查询返回的结果不相同，所以需要进行一些处理
  return {
    ...data,
    content: data.content || data.data,
    page: data.pageIndex || data.pageIndex,
    size: data.size || data.pageSize,
    total: data.total || data.totalItems,
    totalPages: data.totalPages || data.totalPages
  }
  // return {
  //   content: data.content,
  //   page: data.pageIndex,
  //   size: data.pageSize,
  //   total: data.totalItems,
  //   totalPages: data.totalPages
  // }
}

const STREAM_CONTENT_TYPES = [
  'application/xml',
  'application/octet-stream',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]
/**
 * 根据接口返回的数据进行判断，是否需要进行文件下载
 * @param response 接口返回的数据
 * @returns
 */
export function handleApiResponseData(response: any) {
  // test()
  if (
    has(response, 'headers') &&
    has(response.headers, 'content-type') &&
    response.headers['content-type'] !== ''
  ) {
    // 获得响应返回中的头部信息
    const headers = response.headers
    // 获得响应类型
    const contentType = headers['content-type'].split(';')[0] || ''
    // 根据流判断是否需要下载文件
    if (STREAM_CONTENT_TYPES.includes(contentType)) {
      // 进行文件下载
      let fileName = headers['content-disposition']
        ? parseFileName(headers['content-disposition'])
        : ''
      if (
        response.config &&
        (response.config.headers.fileName || response.config.headers.filename)
      ) {
        fileName = decodeURIComponent(
          response.config.headers.fileName || response.config.headers.fileName
        )
      } else if (headers.fileName || headers.filename) {
        fileName = decodeURIComponent(headers.fileName || headers.filename)
      }
      fileName = fileName === '' ? generateFileNameByContentType(contentType) : fileName
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(
        new Blob([response.data], { type: headers['content-type'] })
      )
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      // 下载完成后清除 Blob URL
      requestAnimationFrame(() => URL.revokeObjectURL(link.href))
      return response
    }
    return transformResponse(response)
  }
  return transformResponse(response)
}
/**
 * 将 application/json 进行格式化
 * @param response invoke返回数据
 * @returns
 */
function transformResponse(response: any) {
  if (typeof response.data === 'object') {
    return { response, ...response.data }
  }
  return { ...response }
}

function convertStringToBlob(contentType: string, str: string) {
  const bytes = new Uint8Array(str.length)
  for (let i = 0; i < str.length; i++) {
    bytes[i] = str.charCodeAt(i)
  }
  return new Blob([bytes], { type: contentType })
}
/**
 * 解析 content-disposition 中的文件名
 * @param contentDisposition content-disposition 中的值
 * @returns 文件名
 */
function parseFileName(contentDisposition: string) {
  const parts = contentDisposition.split(';')
  for (let part of parts) {
    part = part.trim()
    if (part.startsWith('filename=')) {
      // 找到 filename* 参数
      const encodedFileName = part.substring('filename='.length)
      // 解码文件名
      return decodeURIComponent(encodedFileName)
    }
  }
  // 如果没有找到 filename* 参数，则返回空字符串或默认文件名
  return ''
}

function generateFileNameByContentType(contentType: string) {
  if (contentType === 'application/xml') {
    return new Date().getTime() + '.xml'
  } else if (contentType === 'application/octet-stream') {
    return new Date().getTime()
  } else if (contentType === 'application/pdf') {
    return new Date().getTime() + '.pdf'
  } else if (
    contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    contentType === 'application/vnd.ms-excel;charset=utf8'
  ) {
    return new Date().getTime() + '.xlsx'
  }
  return new Date().getTime()
}
