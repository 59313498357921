import { ElMessage } from 'element-plus'
import 'element-plus/theme-chalk/el-message.css' // 解决 useMessage.ts 不显示 message
import { isObject } from 'lodash-es'

type ResponseData = {
  message: string
  status?: string
  type?: any
  data?: any
  tag?: string
}

export const useMessage = (data: ResponseData) => {
  if (!data) {
    return
  }
  if (typeof data === 'object') {
    const { status, message, tag, type, data: errData } = data

    // message 标题: tag 内容
    // 旧版: tag || message || errData?.error || ''
    const tagStr = isObject(tag) ? JSON.stringify(tag) : tag
    const msgStr = isObject(message) ? JSON.stringify(message) : message
    let msg = ''
    if (msgStr && tagStr) {
      msg = `
              <div style="color:#f56c6c">${msgStr}</div>
              <div style="color:#f56c6c">${tagStr}</div>
            `
    } else {
      msg = `<div style="color:#f56c6c">${tagStr || msgStr}</div>`
    }
    if (msg) {
      ElMessage({
        dangerouslyUseHTMLString: true,
        type: type || 'error',
        showClose: true,
        message: msg,
        grouping: true,
        repeatNum: 1
      })
    }
  }
}
