<template>
  <el-menu
    v-if="menus.length"
    mode="horizontal"
    menu-trigger="hover"
    class="horizontal-menu"
    popper-class="horizontal-menu-popper"
    :ellipsis="true"
    :router="true"
    :default-active="isMixedMenu ? props.active : activeMenu"
  >
    <template v-if="isMixedMenu">
      <el-menu-item v-for="v in menus" :key="v.path" :index="v.path" @click="mixedMenuClick(v)">
        <span>{{ v.meta.title }}</span>
      </el-menu-item>
    </template>
    <template v-else>
      <template v-for="(item, index) in menus" :key="index">
        <el-sub-menu
          v-if="item.children && item.children.length > 0 && !item.meta.hidden"
          :key="item.path"
          :index="item.path"
        >
          <template #title>
            <span>{{ item.meta.title }}</span>
          </template>
          <MenuItem :menu-data="item.children"></MenuItem>
        </el-sub-menu>
        <template v-else>
          <el-menu-item
            v-if="!item.meta.hidden"
            :key="item.id"
            :index="item.path"
            :disabled="item.disabled"
          >
            <template #title>
              <span> {{ item.meta.title }}</span>
            </template>
          </el-menu-item>
        </template>
      </template>
    </template>
  </el-menu>
</template>

<script setup lang="ts">
  import { useRoute } from 'vue-router'
  import { ref, computed, onBeforeMount } from 'vue'
  import MenuItem from './MenuItem.vue'
  import { useProject } from '@haohan/clw-store'
  import { filter } from 'lodash-es'
  import { createRouterByMenus } from './createRouterByMenus'

  const project = useProject()
  const projectId = ref('')
  const route = useRoute()

  const props = defineProps<{ horizontalMenu: Menu[]; active: string }>()

  const isMixedMenu = computed(() => project.currentProject?.settings?.verticalMenu === 'mixed')

  const emits = defineEmits(['mixedMenuClick'])
  function mixedMenuClick(item: Menu) {
    emits('mixedMenuClick', item)
  }

  onBeforeMount(() => {
    projectId.value = `${
      project.currentProject && project.currentProject.id
        ? project.currentProject.id
        : route.params.projectId || ''
    }`
  })

  // 是否开启 keep-alive
  const isOpenKeepAlive = computed(() => project.previewKeepAliveRoute.isOpenKeepAlive)
  const activeMenu = computed(() => {
    if (isMixedMenu.value) {
      return false
    }
    const { meta, path, params } = route
    let customPath = path

    // 兼容 keep-alive 高亮
    if (isOpenKeepAlive.value && path.includes('/preview_')) {
      customPath = `/preview/${params.projectId}/${params.pageId}`
    }

    if (meta.activeMenu) {
      return `${meta.activeMenu}`
    }
    return customPath
  })

  const menus = computed(() => {
    const _menus = props.horizontalMenu || []
    // 过滤非菜单页面
    const _routers = createRouterByMenus(
      projectId.value,
      filter(_menus, (m) => m.name !== 'ROOT' && m.title !== '非菜单页面')
    )
    return _routers
  })
</script>

<style lang="scss" scoped>
  .horizontal-menu {
    flex: 1;
    margin: 0 100px;
    background: transparent;
    border-bottom: none !important;
    z-index: 10;
    overflow: hidden;
    --el-menu-hover-bg-color: var(--custom-menu-active-bg-color);
    --el-menu-hover-text-color: var(--custom-menu-active-color);
    --el-menu-active-color: var(--custom-menu-active-color);
    --el-menu-text-color: var(--custom-menu-text-color);
    --el-menu-border-color: var(--border-color-active);
    :deep() {
      .el-sub-menu__icon-arrow {
        display: none;
      }
      .el-sub-menu__title {
        padding: 0 20px !important;
      }
      .el-sub-menu {
        &:hover {
          .el-sub-menu__title {
            background: var(--custom-menu-active-bg-color);
            color: var(--custom-menu-text-color);
          }
        }
      }
    }
  }
</style>

<style lang="scss">
  .horizontal-menu-popper {
    .el-menu--popup {
      padding: 0;
    }
    .el-menu {
      background-color: var(--custom-menu-item-bg-color);
      .el-sub-menu__title,
      .el-menu-item {
        color: var(--custom-menu-text-color);
        background-color: var(--custom-menu-item-bg-color);
        &:hover {
          background-color: var(--custom-menu-active-bg-color);
          color: var(--custom-menu-active-color);
        }
      }
      .el-menu-item.is-active {
        color: var(--custom-menu-text-color) !important;
        background-color: var(--custom-menu-active-bg-color);
        &:hover {
          background-color: var(--custom-menu-active-bg-color);
          color: var(--custom-menu-active-color);
        }
      }
      .el-sub-menu.is-active > .el-sub-menu__title {
        color: var(--custom-menu-text-color);
        &:hover {
          background-color: var(--custom-menu-active-bg-color);
          color: var(--custom-menu-active-color);
        }
      }
    }
  }
</style>
