<template>
  <!-- 这里可以作为theme应用的元素 -->
  <el-container id="preview">
    <!-- 头部 -->
    <HeaderSection
      v-if="!hideMenu && currentProject?.settings?.verticalMenu !== 'true'"
      :title="title"
    >
      <HorizontalMenu
        :horizontal-menu="menuList.horizontalMenu"
        :active="mixedHorizontalActive"
        @mixed-menu-click="changeVerticalMenu"
      ></HorizontalMenu>
    </HeaderSection>

    <div class="main-wrapper">
      <!-- 左侧垂直菜单 -->
      <VerticalMenu
        v-show="!hideMenu && menuList.verticalMenu.length"
        :vertical-menu="menuList.verticalMenu"
        :title="title"
      />
      <el-main v-show="showMain" :style="[pageStyle]">
        <!-- 缓存列表 -->
        <KeepAliveRoutes v-if="isOpenKeepAlive"></KeepAliveRoutes>
        <div class="router-warpper">
          <router-view v-slot="{ Component }">
            <Transition name="fade">
              <keep-alive>
                <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path" />
              </keep-alive>
            </Transition>

            <Transition name="fade">
              <component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.path" />
            </Transition>
          </router-view>
        </div>
      </el-main>
      <!-- <el-footer>版权所有 &copy; {{ getCurrentYear() }} 北京浩瀚深度信息技术股份有限公司</el-footer> -->
    </div>
  </el-container>
</template>

<script setup lang="ts">
  import { ref, computed, watch, onBeforeMount, reactive, nextTick } from 'vue'
  import { useRoute } from 'vue-router'
  import HorizontalMenu from './components/HorizontalMenu.vue'
  import VerticalMenu from './components/VerticalMenu.vue'
  import KeepAliveRoutes from './components/KeepAlive.vue'
  import HeaderSection from './components/HeaderSection.vue'
  import { useProject } from '@haohan/clw-store'
  import { getProject, queryMenus } from '@haohan/clw-api'
  import { setMenuColor } from './setMenuColor'
  setMenuColor()

  const project = useProject()
  const route = useRoute()
  const title = ref('')
  const projectId = ref('')
  const activatedRouteKey = computed(() => project.previewKeepAliveRoute.activatedRouteKey)

  //菜单--------开始
  const menuList = reactive({
    verticalMenu: [],
    horizontalMenu: []
  })

  const horizontalFlattenMenu = ref([]) //菜单扁平化，方便后续查找根节点菜单

  async function getProjectMenus() {
    //移除隐藏的菜单
    function removeHideMenu(menulist: Menu[]) {
      return menulist.filter((menu) => {
        if (menu.hidden || menu.title === '非菜单页面') {
          return false
        }
        if (menu.children) {
          menu.children = removeHideMenu(menu.children)
        }
        return true
      })
    }

    if (projectId.value) {
      const { data } = await queryMenus(projectId.value)
      const originMenus = data || []
      const showMenu = removeHideMenu(originMenus)
      const menus = showMenu.filter((menu: Menu) => {
        // 过滤掉没有页面的空文件夹
        return !(menu.type === 'MENU' && (!menu.children || menu.children.length === 0))
      })
      const verticalMenu = currentProject.value?.settings?.verticalMenu
      if (verticalMenu === 'true') {
        menuList.verticalMenu = menus
        menuList.horizontalMenu = []
      } else if (['false', 'mixed'].includes(verticalMenu)) {
        menuList.horizontalMenu = menus
        horizontalFlattenMenu.value = flattenTree(
          JSON.parse(JSON.stringify(menuList.horizontalMenu))
        )
        setVerticalMenu(activatedRouteKey.value)
      }
    }
  }

  //mixed模式下，点击头部菜单，切换左侧菜单
  function changeVerticalMenu(menu: any) {
    if (menu.meta.id) {
      const cur = menuList.horizontalMenu.find((item) => {
        return item.id === menu.meta.id
      })
      if (cur) {
        mixedHorizontalActive.value = `/preview/${cur.projectId}/${cur.id}`
        menuList.verticalMenu = cur?.children || []
      }
    } else {
      menuList.verticalMenu = []
    }
  }
  //菜单--------结束

  const currentProject = computed(() => {
    return project.currentProject
  })

  // 表单页, 是否展示面包屑
  const isFormPage = computed(() => {
    const pageType = project?.currentPage?.variables?.pageType
    return pageType === 'form'
  })

  watch(projectId, async () => {
    const resp = await getProject(projectId.value)
    title.value = resp.data.title
    getProjectMenus()
  })
  watch(activatedRouteKey, (val) => {
    setVerticalMenu(val)
  })

  const mixedHorizontalActive = ref('')
  const showMain = ref(currentProject.value?.settings?.verticalMenu === 'mixed' ? false : true)
  function setVerticalMenu(pageId: string) {
    const id = pageId ? pageId : route.params?.pageId
    if (
      currentProject.value?.settings?.verticalMenu === 'mixed' &&
      horizontalFlattenMenu.value.length > 0
    ) {
      showMain.value = false
      function getParents(menu: Menu[], id: string) {
        const cur = menu.find((item) => {
          return item.id === id
        })
        if (cur?.parentId != '0') {
          return getParents(menu, cur.parentId)
        }
        return cur
      }
      const parent = id
        ? getParents(horizontalFlattenMenu.value, id)
        : horizontalFlattenMenu.value[0]
      mixedHorizontalActive.value = `/preview/${parent.projectId}/${parent.id}`
      menuList.verticalMenu = parent?.children || []
      nextTick(() => {
        showMain.value = true
      })
    }
  }

  function flattenTree(menuList: Menu[]): Menu[] {
    let flatList: Menu[] = []

    function flatten(menuList: Menu[]) {
      menuList.forEach((item) => {
        if (item.children) {
          flatten(item.children)
        }
        flatList.push(item)
      })
    }

    flatten(menuList)
    return flatList
  }

  onBeforeMount(() => {
    projectId.value = `${route.params.projectId || project.currentProject.id} `
  })

  const pageStyle = computed(() => {
    const bgColor = project?.currentPage?.variables?.bgColor
    // 表单页
    if (!bgColor && bgColor !== '' && isFormPage.value) {
      return { backgroundColor: '#fff' }
    }
    return bgColor && bgColor !== '' ? { backgroundColor: bgColor } : {}
  })

  // 是否开启 keep-alive
  const isOpenKeepAlive = computed(() => project.previewKeepAliveRoute.isOpenKeepAlive)
  /**
   * watch route.params
   * /preview 则增加自定义路由 用于 keep_alive
   * /preview_ 则不处理
   * @param projectId
   * @param pageId
   */
  const addCustomRoute = () => {
    const { projectId, pageId } = route.params
    const { path } = route
    project.addPreviewKeepAliveRoute({
      pageId,
      projectId,
      key: pageId,
      path: path,
      fullPath: path
    })
  }

  watch(
    () => project.currentPage,
    () => {
      if (project?.currentPage?.id) {
        // 更新 store, tag 标题
        project.updateActivatedRouteTitle(String(route.params?.pageId), project.currentPage.title)
      }
    }
  )

  watch(
    () => route.query,
    () => {
      // 开启了缓存列表
      if (isOpenKeepAlive.value || sessionStorage.getItem('isKeepAlive')) {
        if (route.query.reload) {
          location.reload()
        } else {
          // 添加自定义路由
          addCustomRoute()
        }
      }
    },
    { immediate: true }
  )

  /**
   * 版权所有当前年份
   */
  // const getCurrentYear = () => new Date().getFullYear()

  // 菜单是否隐藏
  const hideMenu = ref(false)
  watch(
    () => route.query,
    () => {
      hideMenu.value = route.query?.hideMenu === 'true'
    },
    { immediate: true }
  )
</script>

<style scoped lang="scss">
  .el-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    --el-menu-horizontal-height: 52px;

    .main-wrapper {
      flex: 1;
      display: flex;
      overflow: hidden;
    }

    .el-main {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 0;
      .router-warpper {
        flex: 1;
        padding: 10px;
        box-sizing: border-box;
        overflow: scroll;
      }
    }
    .el-footer {
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 12px;
      color: var(--font-color-tip);
    }
  }

  .open-keep-alive {
    padding-top: 12px !important;
  }
</style>
