import { has } from 'lodash-es'

/**
 * 根据对象生成Schema对象
 */
export const generateSchemaFromObject = (obj: any) => {
  const schema = {
    type: Array.isArray(obj) ? 'array' : typeof obj,
    properties: {},
    required: []
  }
  if (!obj) {
    return schema
  }
  Object.keys(obj).forEach((key) => {
    const property = obj[key]
    let propertySchema = {
      type: Array.isArray(property) ? 'array' : typeof property,
      value: ''
    }

    if (Array.isArray(property)) {
      propertySchema.items = generateSchemaFromObject(property[0]) // 假设数组中所有元素类型相同
    } else if (property && typeof property === 'object') {
      propertySchema = generateSchemaFromObject(property)
    }

    // 如果属性不是 null 或 undefined，则认为是必需的
    if (property !== null && property !== undefined) {
      schema.required.push(key)
    }
    if (propertySchema.type === 'array') {
      propertySchema.value = property.length
    } else {
      propertySchema.value = property
    }
    schema.properties[key] = propertySchema
  })

  return schema
}
/**
 * 将JSONSchema对象转换为树形结构，供树形控件使用
 * @param jsonSchema 需要转换的JSONSchema对象
 * @returns 转换后的树形结构数据
 */
export const transformJsonSchema2Tree = (jsonSchema: any, deepValue = '') => {
  const tree: any = []
  const allColumnDeepValues: any = []
  if (has(jsonSchema, 'properties')) {
    if (jsonSchema.type === 'array' && deepValue === '') {
      // 如果数据直接返回的就是数组，那么需要针对数据进行特殊处理
      const key = ''
      const newDeepValue = key
      allColumnDeepValues.push(newDeepValue)
      const treeItem = {
        key,
        label: key,
        type: jsonSchema.type,
        value: Object.keys(jsonSchema.properties).length,
        deepValue: newDeepValue,
        children: []
      }
      if (jsonSchema.properties.length !== 0) {
        const properties = jsonSchema.properties[0]
        const _result = transformJsonSchema2Tree(properties, newDeepValue)
        treeItem.children = _result.tree
        allColumnDeepValues.push(..._result.allColumnDeepValues)
      }
      tree.push(treeItem)
      return {
        tree,
        allColumnDeepValues
      }
    }
    Object.keys(jsonSchema.properties).forEach((key) => {
      const property = jsonSchema.properties[key]
      const newDeepValue = deepValue === '' ? key : `${deepValue}.${key}`
      allColumnDeepValues.push(newDeepValue)
      const treeItem = {
        key,
        label: key,
        type: property.type,
        value: property.value,
        deepValue: newDeepValue,
        children: []
      }
      if (property.type === 'array' || property.type === 'object') {
        // treeItem.children = transformJsonSchema2Tree(property.items, treeItem.deepValue)
        const _jsonSchema = property.type === 'array' ? property.items : property
        const _result = transformJsonSchema2Tree(_jsonSchema, treeItem.deepValue)
        treeItem.children = _result.tree
        allColumnDeepValues.push(..._result.allColumnDeepValues)
      }
      tree.push(treeItem)
    })
  }
  return {
    tree,
    allColumnDeepValues
  }
}
/**
 * 获得用户只当的数据访问层级
 * @param apiDataPath dataProp中的apiDataPath值
 * @param api API接口
 * @returns 数据访问层级
 */
export const getApiDataPath = (apiDataPath: string, api: any) => {
  let dataPath = ''
  if (apiDataPath && apiDataPath !== '') {
    dataPath = apiDataPath
  } else if (api && api.responseDefine?.dataPath) {
    dataPath = api.responseDefine?.dataPath
  } else {
    dataPath = apiDataPath
  }
  return dataPath
}
