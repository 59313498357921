<template>
  <div class="svg-container">
    <div class="svg-wrapper" :class="{ 'svg-active': active }">
      <svg :class="svgClass" class="svg" aria-hidden="true" v-bind="$attrs">
        <use :xlink:href="iconName" />
      </svg>
    </div>
    <div class="text" :class="{ active: active }">{{ text }}</div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  const prop = defineProps<{
    iconClass?: string
    className?: string
    active?: boolean
    text?: string
  }>()
  const iconName = computed(() => `#icon-${prop.iconClass}`)
  const svgClass = computed(() => (prop.className ? `svg-icon ${prop.className}` : 'svg-icon'))
</script>

<style lang="scss" scoped>
  .svg-container {
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;

    .svg-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .svg {
      width: 80%;
      height: 80%;
      fill: currentColor;
    }
    .text {
      position: absolute;
      bottom: -10px;
      white-space: nowrap;
      color: #9b9ea3;
      font-size: 12px;
      transform: scale(0.7);
      text-align: center;
    }
    .active {
      color: #c3d86f;
      opacity: 0.6;
    }
    .svg-active {
      color: #c3d86f;
    }
  }
</style>
