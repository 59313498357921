import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios'
import cache from './http-cache'
import { getToken } from '../auth'
import jsonBig from 'json-bigint'
import { useMessage } from '@haohan/clw-hooks'
import { getMenuIdByPath } from '../utils'
import { has } from 'lodash-es'

const BASE_URL = '.'
const API_CACHE_TIME = 1000

function transformResponse(response: any) {
  if (Object.prototype.hasOwnProperty.call(response, 'body')) {
    const { body, ...rest } = response
    return { ...rest, data: body }
  }
  return response
}

class HttpRequest {
  constructor(public baseUrl: string) {
    this.baseUrl = baseUrl
  }
  public request(options: AxiosRequestConfig): AxiosPromise {
    const instance: AxiosInstance = axios.create({
      adapter: cache({ time: API_CACHE_TIME }),
      transformResponse: [
        function (data) {
          // console.log('axios.create', data)
          try {
            // 如果转换失败，则包装为统一数据格式并返回
            const json = jsonBig({
              storeAsString: true
            })
            return json.parse(data)
          } catch (error) {
            return {
              data
            }
          }
        }
      ]
    })
    options = this.mergeConfig(options)
    this.interceptors(instance, options.url)
    return instance(options)
  }
  private interceptors(instance: AxiosInstance, url?: string) {
    instance.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (!config.headers) {
          // menuId 为了方便日志记录时使用
          config.headers = { authorization: '', menuId: '' }
        }
        // console.log(JSON.parse(JSON.stringify(config)))
        if ((config.method === 'get' || config.method === 'delete') && !config.data) {
          config.data = true
          config.headers['Content-Type'] = 'application/json'
        }
        config.headers.authorization = getToken()
        // 根据Path获取菜单ID
        // const route = useRoute()
        config.headers.menuId = getMenuIdByPath(window.location.href)
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
    instance.interceptors.response.use(
      (res: AxiosResponse) => {
        const responseData = transformResponse(res.data)
        // console.log('responseData', responseData)
        return { ...res, ...responseData }
      },
      (error) => {
        if (error?.response?.data) {
          // console.log('请求异常 ===', error.response.data, location)
          const { status, message } = has(error?.response?.data, 'data')
            ? error?.response?.data.data
            : error?.response?.data
          // status === 403, 后端很多接口返回 403, 套餐限制
          if (status === 401 && !location.href.includes('login')) {
            // 跳转至登录页
            window.location.reload()
            // 携带该请求头, 不提示错误信息(界面自行处理)
          } else if (status === 417) {
            // 数据集错误
            useMessage({ ...error.response.data, message: '数据集查询错误' })
            console.error('数据集错误：', message)
          } else if (!error.config.headers['custom-error-handler']) {
            useMessage(error.response.data)
          }
        }
        return Promise.reject(error)
      }
    )
  }
  private mergeConfig(options: AxiosRequestConfig): AxiosRequestConfig {
    return Object.assign({ baseURL: this.baseUrl }, options)
  }
}
export const http = new HttpRequest(BASE_URL)
// export default  new HttpRequest(BASE_URL)

export interface ResponseData {
  code: number
  data?: any
  msg: string
}
