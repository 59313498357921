import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { previewRoutes } from '@haohan/clw-components'
import { cvViewsRoutes } from './cv-views'
import { BUSINESS_MANAGEMENT_ROUTE } from '@/constant/business-management-route'
import UnAuth from '@haohan/clw-components/src/views/401/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/layout/HomeLayout.vue'),
    children: [
      {
        path: 'index',
        name: 'index',
        redirect: '/dashboard',
        component: () => import('@/views/home/index.vue')
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index.vue')
      },
      {
        path: 'template-center',
        name: 'TemplateCenter',
        component: () => import('@/views/template-center/index.vue')
      },
      {
        path: 'course',
        name: 'course',
        component: () => import('@/views/course/index.vue')
      },
      {
        path: 'test-tool',
        name: 'test-tool',
        component: () => import('@/views/test-tool/index.vue')
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('@/views/about/index.vue')
      },
      {
        path: 'question',
        name: 'question',
        component: () => import('@/views/question/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  ...previewRoutes,
  ...cvViewsRoutes,
  // 管理中心 - 个人资料
  {
    path: BUSINESS_MANAGEMENT_ROUTE.profile,
    name: 'ManagementProfile',
    component: () => import('@/views/business-management/views/profile/index.vue')
  },
  // 管理中心
  {
    path: BUSINESS_MANAGEMENT_ROUTE.manage,
    redirect: BUSINESS_MANAGEMENT_ROUTE.enterprise,
    component: () => import('@/views/business-management/App.vue'),
    children: [
      {
        path: BUSINESS_MANAGEMENT_ROUTE.dashboard, // 数据看板
        name: 'ManagementDashboard',
        component: () => import('@/views/business-management/views/dashboard/index.vue')
      },
      {
        path: BUSINESS_MANAGEMENT_ROUTE.enterprise, // 企业信息
        name: 'ManagementEnterprise',
        component: () => import('@/views/business-management/views/enterprise/index.vue')
      },
      {
        path: BUSINESS_MANAGEMENT_ROUTE.project, // 项目配置
        name: 'ManagementProject',
        component: () => import('@/views/business-management/views/project/index.vue')
      },
      {
        path: BUSINESS_MANAGEMENT_ROUTE.role, // 角色管理
        name: 'ManagementRole',
        component: () => import('@/views/business-management/views/role/index.vue')
      },
      {
        path: BUSINESS_MANAGEMENT_ROUTE.security, // 账号安全设置
        name: 'ManagementSecurity',
        component: () => import('@/views/business-management/views/security/index.vue')
      },
      {
        path: BUSINESS_MANAGEMENT_ROUTE.user, // 用户管理
        name: 'ManagementUser',
        redirect: BUSINESS_MANAGEMENT_ROUTE.userList,
        children: [
          {
            path: BUSINESS_MANAGEMENT_ROUTE.userList,
            name: 'ManagementUserList',
            component: () => import('@/views/business-management/views/user/index.vue')
          },
          {
            path: BUSINESS_MANAGEMENT_ROUTE.userAdd,
            name: 'ManagementUserAdd',
            component: () => import('@/views/business-management/views/user-add/index.vue')
          },
          {
            path: BUSINESS_MANAGEMENT_ROUTE.userEdit,
            name: 'ManagementUserEdit',
            component: () => import('@/views/business-management/views/user-edit/index.vue')
          }
        ]
      },
      {
        path: BUSINESS_MANAGEMENT_ROUTE.monitor, // 项目监测
        name: 'ManagementMonitor',
        component: () => import('@/views/business-management/views/monitor/index.vue')
      },
      {
        path: BUSINESS_MANAGEMENT_ROUTE.scan, // 项目安全扫描
        name: 'ManagementScan',
        redirect: BUSINESS_MANAGEMENT_ROUTE.scanTrigger,
        children: [
          {
            path: BUSINESS_MANAGEMENT_ROUTE.scanTrigger,
            name: 'ManagementScanTrigger',
            component: () => import('@/views/business-management/views/scan/index.vue')
          },
          {
            path: BUSINESS_MANAGEMENT_ROUTE.scanDetail,
            name: 'ManagementScanDetail',
            component: () => import('@/views/business-management/views/scan-detail/index.vue')
          }
        ]
      },
      {
        path: BUSINESS_MANAGEMENT_ROUTE.log,
        name: 'ManagementLog',
        redirect: BUSINESS_MANAGEMENT_ROUTE.securityLog,
        children: [
          {
            path: BUSINESS_MANAGEMENT_ROUTE.securityLog, // 安全日志
            name: 'ManagementSecurityLog',
            component: () => import('@/views/business-management/views/log/SecurityLog.vue')
          },
          {
            path: BUSINESS_MANAGEMENT_ROUTE.operationLog, // 操作日志
            name: 'ManagementOperationLog',
            component: () => import('@/views/business-management/views/log/OperationLog.vue')
          }
        ]
      },
      {
        path: BUSINESS_MANAGEMENT_ROUTE.group, // 组织架构
        name: 'ManagementGroup',
        component: () => import('@/views/business-management/views/group/index.vue')
      }
    ]
  },
  {
    path: '/401',
    name: 'UnAuth',
    component: UnAuth,
    meta: {
      anonymous: true
    }
  },
  {
    path: '/404',
    name: 'notFound',
    component: () => import('@/views/404/index.vue'),
    meta: {
      title: '页面找不到了'
    }
  },
  {
    path: '/test/drop',
    name: 'Test',
    component: () => import('@/views/demos/html5drop/index.vue') // 建议进行路由懒加载，优化访问性能
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'fallback',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
