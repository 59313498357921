import { RouteRecordRaw } from 'vue-router'
import { ROUTE_PATH_CONSTANT } from '@/constant/route-path'
import { DataVEditPage } from '@haohan/cloud_view_datav'
import { StandardPageEditPage } from '@haohan/cloud_view_spage'


export const cvViewsRoutes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_PATH_CONSTANT.DBLayout,
    name: 'ViewDBLayout',
    // redirect: ROUTE_PATH_CONSTANT.EditDB,
    component: () => import('@/views/edit-db/index.vue'),
    children: [
      // ------------- 外部数据源 -------------

      // 外部数据源-数据源
      {
        path: ROUTE_PATH_CONSTANT.ExternalDataSource,
        name: 'ExternalDataSource',
        component: () => import('@/views/edit-db/external-data-source/data-source/index.vue'),
        meta: { title: '外部数据源-数据源配置' }
      },
      // 外部数据源-数据集
      {
        path: ROUTE_PATH_CONSTANT.ExternalDataSetLayout,
        name: 'ExternalDataSetLayout',
        component: () => import('@/views/edit-db/external-data-source/data-set/index.vue'),
        meta: { title: '外部数据源-数据集' },
        children: [
          {
            path: ROUTE_PATH_CONSTANT.ExternalDataSet,
            name: 'ExternalDataSet',
            component: () =>
              import('@/views/edit-db/external-data-source/data-set/blank/index.vue'),
            meta: { title: '内部数据源-数据集-欢迎页' }
          },
          {
            path: ROUTE_PATH_CONSTANT.ExternalDataSetDetail,
            name: 'ExternalDataSetDetail',
            component: () =>
              import('@/views/edit-db/external-data-source/data-set/data-set-detail/index.vue'),
            meta: { title: '内部数据源-数据集-详情' }
          },
          {
            path: ROUTE_PATH_CONSTANT.ExternalDataTableDetail,
            name: 'ExternalDataTableDetail',
            component: () =>
              import('@/views/edit-db/external-data-source/data-set/data-table-detail/index.vue'),
            meta: { title: '内部数据源-数据集-详情' }
          }
        ]
      },

      // ------------- 行业定制 -------------

      // 行业定制-数据源
      {
        path: ROUTE_PATH_CONSTANT.IndustryDataSource,
        name: 'IndustryDataSource',
        component: () => import('@/views/edit-db/industry-data-source/data-source/index.vue'),
        meta: { title: '行业定制-数据源配置' }
      },
      // 行业定制-数据集
      {
        path: ROUTE_PATH_CONSTANT.IndustryDataSetLayout,
        name: 'IndustryDataSetLayout',
        component: () => import('@/views/edit-db/industry-data-source/data-set/index.vue'),
        meta: { title: '行业定制-数据集' },
        children: [
          {
            path: ROUTE_PATH_CONSTANT.IndustryDataSet,
            name: 'IndustryDataSet',
            component: () =>
              import('@/views/edit-db/industry-data-source/data-set/blank/index.vue'),
            meta: { title: '内部数据源-数据集-欢迎页' }
          },
          {
            path: ROUTE_PATH_CONSTANT.IndustryDataSetDetail,
            name: 'IndustryDataSetDetail',
            component: () =>
              import('@/views/edit-db/industry-data-source/data-set/data-set-detail/index.vue'),
            meta: { title: '内部数据源-数据集-详情' }
          },
          {
            path: ROUTE_PATH_CONSTANT.IndustryDataTableDetail,
            name: 'IndustryDataTableDetail',
            component: () =>
              import('@/views/edit-db/industry-data-source/data-set/data-table-detail/index.vue'),
            meta: { title: '内部数据源-数据集-详情' }
          }
        ]
      },

      // ------------- 内部数据源 -------------

      // 内部数据源-数据源
      {
        path: ROUTE_PATH_CONSTANT.InternalDataSourceConfig,
        name: 'InternalDataSourceConfig',
        component: () => import('@/views/edit-db/internal-data-source/data-source/index.vue'),
        meta: { title: '内部数据源-数据源配置' }
      },
      // 内部数据源-数据集
      {
        path: ROUTE_PATH_CONSTANT.InternalDataSetLayout,
        name: 'InternalDataSetLayout',
        redirect: ROUTE_PATH_CONSTANT.InternalDataSet,
        component: () => import('@/views/edit-db/internal-data-source/data-set/index.vue'),
        meta: { title: '内部数据源-数据集' },
        children: [
          {
            path: ROUTE_PATH_CONSTANT.InternalDataSet,
            name: 'InternalDataSet',
            component: () =>
              import('@/views/edit-db/internal-data-source/data-set/blank/index.vue'),
            meta: { title: '内部数据源-数据集-欢迎页' }
          },
          {
            path: ROUTE_PATH_CONSTANT.InternalDataSetTypeSelect,
            name: 'InternalDataSetTypeSelect',
            component: () =>
              import('@/views/edit-db/internal-data-source/data-set/type-select/index.vue'),
            meta: { title: '内部数据源-数据集-创建方式选择' }
          },
          {
            path: ROUTE_PATH_CONSTANT.InternalDataTableDetail,
            name: 'InternalDataTableDetail',
            component: () =>
              import('@/views/edit-db/internal-data-source/data-set/data-table-detail/index.vue'),
            meta: { title: '内部数据源-数据集-数据表-详情' }
          },
          {
            path: ROUTE_PATH_CONSTANT.InternalDataSetDetail,
            name: 'InternalDataSetDetail',
            component: () =>
              import('@/views/edit-db/internal-data-source/data-set/data-set-detail/index.vue'),
            meta: { title: '内部数据源-数据集-数据表-详情' }
          }
        ]
      },

      // 自定义 API(不区分内部、外部数据源)
      {
        path: ROUTE_PATH_CONSTANT.CustomAPILayout,
        name: 'CustomAPILayout',
        redirect: ROUTE_PATH_CONSTANT.CustomAPI,
        component: () => import('@/views/edit-db/data-api/index.vue'),
        meta: { title: '自定义API' },
        children: [
          {
            path: ROUTE_PATH_CONSTANT.CustomAPI,
            name: 'CustomAPI',
            component: () => import('@/views/edit-db/data-api/blank/index.vue'),
            meta: { title: '自定义API-欢迎页' }
          },
          {
            path: ROUTE_PATH_CONSTANT.CustomAPIDetail,
            name: 'CustomAPIDetail',
            component: () => import('@/views/edit-db/data-api/api-detail/index.vue'),
            meta: { title: '自定义API-详情' }
          },
          {
            path: ROUTE_PATH_CONSTANT.StandardApi,
            name: 'standard-api',
            component: () => import('@/views/edit-db/data-api/standard/StandardApi.vue'),
            meta: { title: 'api标准界面' }
          }
        ]
      },
      {
        path: ROUTE_PATH_CONSTANT.LogicFlow,
        name: 'LogicFlowLayout',
        component: () => import('@/views/logic-flow/LogicFlow.vue'),
        children: [
          {
            path: '',
            name: 'LogicFlow',
            component: () => import('@/views/logic-flow/EmptyPage.vue'),
            meta: { title: '欢迎页' }
          },
          {
            path: ROUTE_PATH_CONSTANT.LogicFlowDetail,
            name: 'LogicFlowDetail',
            component: () => import('@/views/logic-flow/Detail.vue'),
            meta: { title: '详情' }
          }
        ]
      }
    ]
  },
  {
    path: ROUTE_PATH_CONSTANT.ViewLayout,
    name: 'ViewLayout',
    redirect: ROUTE_PATH_CONSTANT.Home,
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: ROUTE_PATH_CONSTANT.Home,
        name: 'ViewHome',
        component: () => import('@/views/BlankView.vue')
      },
      {
        path: ROUTE_PATH_CONSTANT.EditPage,
        name: 'EditPage',
        component: StandardPageEditPage
      },
      {
        path: ROUTE_PATH_CONSTANT.EditAPI,
        name: 'EditAPI',
        component: () => import('@/views/edit-api/index.vue')
      },
      {
        path: ROUTE_PATH_CONSTANT.BigScreen,
        name: 'BigScreenEditPage',
        component: DataVEditPage
      },
      {
        path: ROUTE_PATH_CONSTANT.FormNavigation,
        name: 'FormNavigation',
        component: () => import('@/views/form-navigation/index.vue')
      },
      {
        path: ROUTE_PATH_CONSTANT.WorkFlow,
        name: 'WorkFlowEditPage',
        component: () => import('@/views/work-flow/index.vue')
      },
      // TODO: 后面需要删除
      {
        path: ROUTE_PATH_CONSTANT.UniverReport,
        name: 'UniverReportPage',
        component: () => import('@/views/univer-report/index.vue')
      }
      // {
      //   path: '/datasource-manager',
      //   name: 'DatasourceManager',
      //   component: () => import('@/views/datasource-manager/index.vue'),
      //   meta: { title: '数据集管理' }
      // },
      // {
      //   path: ROUTE_PATH_CONSTANT.EditDB,
      //   name: 'EditDb',
      //   component: () => import('@/views/edit-db/index.vue'),
      //   meta: { title: '数据集管理' }
      // }
    ]
  }
]
