import { compAttr, styleProp, dataProp, getValueTypes } from './common-comp-prop'

const list = [
  // 容器
  {
    imageSrc: 'row-container',
    thumbnail: '',
    title: '容器',
    type: 'HHGrid',
    mutualSetting: true,
    dataSourceSetting: false,
    children: [
      {
        list: [],
        span: '24'
      }
    ],
    eventOption: {
      send: {},
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '显示隐藏',
            value: 'showOrHide'
          }
        ],
        bindValues: []
      }
    },
    prop: {
      hideBg: false,
      bgColor: '',
      radiusShow: 'all'
    },
    styleProp: {
      'border-top-left-radius': 12,
      'border-top-right-radius': 12,
      'border-bottom-right-radius': 12,
      'border-bottom-left-radius': 12
    }
  },
  // 面包屑
  {
    imageSrc: 'crumbs',
    thumbnail: '',
    title: '面包屑',
    type: 'HHBreadCrumb',
    mutualSetting: false,
    dataSourceSetting: false,
    prop: {
      // 风格 base,line,href
      style: 'base',
      // 带链接
      link: '0'
    },
    styleProp: {
      width: null,
      height: null,
      color: null
    }
  },
  // 通用按钮
  {
    imageSrc: 'button',
    thumbnail: '',
    title: '通用按钮',
    type: 'HHButtonWidge',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          },
          // 事件类型 的 状态切换 可以被图表组件接收
          {
            label: '状态切换',
            value: 'status'
          },
          {
            label: '弹窗',
            value: 'dialog'
          }
        ],
        bindValues: [
          // 目前在按钮组中使用
          {
            label: '当前值',
            value: 'currentValue'
          },
          {
            label: '交互值',
            value: 'eventValue'
          }
        ]
      },
      receive: {
        operationTypes: [
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ]
      }
    },
    prop: {
      // 类型 base,group
      model: 'base',
      // 文案
      txt: '默认按钮',
      // 尺寸
      size: 'large',
      // 功能
      type: 'primary',
      // 风格
      style: '',
      // 图标
      icon: '',
      // 填充
      fill: true,
      // 边框
      border: false,
      // 下划线
      underline: false,
      // 内容
      options: [],
      // 内容
      status: true
    },
    styleProp: {
      width: null,
      height: null
    }
  },
  // 时间
  {
    imageSrc: 'time',
    thumbnail: '',
    title: '时间',
    type: 'HHDatetimePicker',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '开始时间-结束时间',
            value: 'dateTime',
            valueTypes: getValueTypes(['string', 'array'])
          },
          {
            label: '开始时间',
            value: 'startTime',
            valueTypes: getValueTypes(['string'])
          },
          {
            label: '结束时间',
            value: 'endTime',
            valueTypes: getValueTypes(['string'])
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '时间范围切换',
            value: 'changeTimeDuration'
          },
          {
            label: '显示类型切换',
            value: 'changeShowType'
          }
        ]
      }
    },
    prop: {
      overall: true, // 全局时间
      type: 'date', // 时间选择的粒度
      model: 'range', // 类型 range/point
      timeValueFormat: '',
      range: {
        start: 0,
        end: 0
      },
      timeLimit: '',
      limitRange: {
        enable: false
      }
    },
    styleProp: {
      width: null,
      height: null
    }
  },
  // 文字
  {
    imageSrc: 'text',
    thumbnail: '',
    title: '文字',
    type: 'HHText',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'TableSource',
    dataSourceTypes: ['TableSource', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          },
          {
            label: '点击',
            value: 'clickText'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示值',
            value: 'showText'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '设置值',
            value: 'setValue'
          },
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示文本',
            value: 'eventValue'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: 40,
      'text-align': 'left',
      color: '#0076DE',
      'background-color': '',
      'font-weight': 'normal'
    },
    prop: {
      type: 'title',
      value: '默认内容',
      'font-size': 16,
      comParam: '',
      comParamLabel: ''
    },
    dataProp: {
      cols: [],
      defaultData: []
    }
  },
  // 文本框
  {
    imageSrc: 'input',
    thumbnail: '',
    title: '文本框',
    type: 'HHInputWidge',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          },
          {
            label: '失去焦点',
            value: 'blurChange'
          },
          {
            label: '清空',
            value: 'clear'
          }
        ],
        bindValues: [
          {
            label: '输入值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      // 类型 text,password,search
      type: 'text',
      placeholderTitle: '请输入',
      // 风格，simple,withlabel,withicon,withbutton
      style: 'simple',
      // 图标
      icon: '',
      // 标题内容
      label: '',
      comParam: '',
      comParamLabel: '',
      defaultValue: ''
    }
  },
  // 文本域
  {
    imageSrc: 'textarea',
    thumbnail: '',
    title: '文本域',
    type: 'HHTextareaWidge',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '输入值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: 110
    },
    prop: {
      placeholderTitle: '请输入',
      limit: false,
      limitNum: 100
    }
  },
  // 表格
  {
    imageSrc: 'table',
    thumbnail: '',
    title: '表格',
    type: 'HHTable',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击列',
            value: 'colClick'
          },
          {
            label: '排序',
            value: 'sortChange'
          },
          {
            label: '过滤',
            value: 'filterChange'
          },
          {
            label: '点击行',
            value: 'rowClick'
          },
          {
            label: '翻页',
            value: 'pageChange'
          },
          {
            label: '事件回调',
            value: 'eventCallback'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '点击值',
            value: 'clickColValue'
          },
          {
            label: '选中行',
            value: 'clickRow'
          },
          {
            label: '排序值',
            value: 'sortValue'
          },
          {
            label: '过滤值',
            value: 'filterValue'
          },
          {
            label: '页码',
            value: 'pageValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '导出',
            value: 'export'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          },
          {
            label: '新增记录',
            value: 'onCreateRow'
          },
          {
            label: '批量删除',
            value: 'onDeleteBatch'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      // 表格类型，默认为server，增加前端展示类型
      tableShowType: 'server',
      // 表格类型，默认为normal，normal/row2col(行转列类型) 增加前端展示类型
      tableType: 'normal',
      // 行转列信息
      row2ColInfo: {
        fixCol: '', // 固定列
        fixColName: '', // 固定列名称
        rowCol: '', // 转成列得列
        valueCol: '', // 值列
        order: 'desc'
      },
      exportName: '',
      selectedByDefault: false,
      pagi: 'complete',
      isMergeCol: false,
      mergeCol: '',
      // 固定列方向
      fixedDirection: '',
      fixedLeftNumber: 1,
      fixedRightNumber: 1,
      exportNumber: 1000,
      showFilter: false
    },
    dataProp: {
      cols: [],
      defaultData: [
        {
          日期: '2022-11-17',
          城市: '北京',
          数据: '1231',
          类别: '类一'
        },
        {
          日期: '2022-11-15',
          城市: '上海',
          数据: '2319',
          类别: '类二'
        },
        {
          日期: '2022-11-17',
          城市: '天津',
          数据: '6670',
          类别: '类一'
        }
      ]
    },
    formProp: {
      primaryKey: 'id', // 表格自带的编辑、删除用到的主键, 默认是 id, 需要自定义 _id 之类
      showCheckbox: false, //  复选框
      // showEdit: true, // 编辑
      // showDelete: true, // 删除
      // showDetail: true, // 查看详情
      customActions: [
        // {
        //   label: '编辑',
        //   icon: 'edit',
        //   enabled: false,
        //   builtIn: true,
        //   key: TABLE_CUSTOM_ACTION_KEY.ShowEdit
        // },
        // {
        //   label: '删除',
        //   icon: 'delete',
        //   enabled: false,
        //   builtIn: true,
        //   key: TABLE_CUSTOM_ACTION_KEY.ShowDelete
        // },
        // {
        //   label: '查看详情',
        //   icon: 'custom-action-1',
        //   enabled: false,
        //   builtIn: true,
        //   key: TABLE_CUSTOM_ACTION_KEY.ShowDetail
        // }
      ], // 自定义事件
      id: '', // 绑定表单页 id

      add: true, // 可操作项 - 新增
      update: true, // 可操作项 - 修改
      batchUpdate: false, // 可操作项 - 批量修改
      delete: true, // 可操作项 - 删除
      batchDelete: false // 可操作项 - 批量删除
    }
  },
  // 单选
  {
    imageSrc: 'radio',
    thumbnail: '',
    title: '单选',
    type: 'HHRadioWidge',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '选择值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      // 布局 horizontal,vertical
      layout: 'horizontal',
      // 默认选项
      hasDefault: true,
      comParam: '',
      comParamLabel: '',
      style: 'default'
    },
    dataProp: {
      // 数据部分
      // options: [],
      defaultData: [
        {
          label: '北京',
          value: '北京',
          value2: ''
        },
        {
          label: '上海',
          value: '上海',
          value2: ''
        }
      ],
      labelCol: '',
      cols: []
    }
  },
  // 多选
  {
    imageSrc: 'checkbox',
    thumbnail: '',
    title: '多选',
    type: 'HHCheckboxWidge',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '选择值',
            value: 'inputValue',
            valueTypes: getValueTypes(['string', 'array'])
          },
          {
            label: '选择值的字符串形式',
            value: 'stringValue',
            valueTypes: getValueTypes(['string'])
          }
        ]
      },
      receive: {
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      // 最多可选项
      max: null,
      defaultCheckAll: false,
      // 布局 horizontal,vertical
      layout: 'horizontal',
      // 默认选项
      hasDefault: true,
      style: 'default'
    },
    dataProp: {
      defaultData: [
        {
          label: '北京',
          value: '北京',
          value2: ''
        },
        {
          label: '上海',
          value: '上海',
          value2: ''
        },
        {
          label: '天津',
          value: '天津',
          value2: ''
        }
      ],
      // 数据部分
      // options: [],
      labelCol: '',
      cols: []
    }
  },
  // 下拉
  {
    imageSrc: 'select',
    thumbnail: '',
    title: '下拉',
    type: 'HHSelectWidget',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '选择值',
            value: 'selectValue',
            valueTypes: getValueTypes(['string', 'array'])
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '设置值',
            value: 'setValue'
          },
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: 36
    },
    prop: {
      // 拉组件类型，normal 普通下拉，search 可搜索的下拉组件
      selectType: 'normal',
      searchKey: '', //默认搜索字段
      // 默认值
      // defaultValue:'',
      placeholderTitle: '请选择',
      // 默认选择
      hasDefault: '0',
      collapseTags: false,
      multipleLimit: 3,
      comParam: '',
      comParamLabel: '',
      // 类型，默认单选 single ，多选 multiple
      type: 'single',
      //  带边框
      hasBorder: true,
      // 可清空
      hasClearable: true,
      title: ''
    },
    dataProp: {
      defaultData: [
        {
          label: '北京',
          value: '北京',
          value2: ''
        },
        {
          label: '上海',
          value: '上海',
          value2: ''
        }
      ],
      // 本地数据源
      // options: [],
      labelCol: '',
      cols: []
    }
  },
  // 计数器
  {
    imageSrc: 'count',
    thumbnail: '',
    title: '计数器',
    type: 'HHCountWidge',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '当前值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      // 步长
      step: 1,
      // 精度
      precision: 0,
      // 位置 right,
      position: 'right',
      // 默认值
      defaultValue: 0
    }
  },
  // 标签页
  {
    imageSrc: 'tab',
    thumbnail: '',
    title: '标签页',
    type: 'HHTabsWidge',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '页签变化',
            value: 'tabChange'
          }
        ],
        bindValues: [
          {
            label: '当前页签',
            value: 'currentValue'
          },
          {
            label: '值2',
            value: 'currentValue2'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: 46
    },
    prop: {
      span: 24,
      showType: 'button',
      textAlign: 'left',
      hasTurnPage: false,
      pageSize: 10
    },
    dataProp: {
      defaultData: [
        {
          label: '数据趋势',
          value: '数据趋势',
          value2: '',
          list: []
        },
        {
          label: '数据详情',
          value: '数据详情',
          value2: '',
          list: []
        }
      ]
      // options: []
    }
  },
  // 图片
  {
    imageSrc: 'image-widget',
    thumbnail: '',
    title: '图片',
    type: 'HHImgWidge',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null,
      'text-align': 'left'
    },
    prop: {
      imgWidth: null,
      imgHeight: null,
      imgUrl: '',
      // 风格
      style: 'default'
    }
  },
  // 分隔
  {
    imageSrc: 'separate',
    thumbnail: '',
    title: '分隔',
    type: 'HHSeparateWidge',
    mutualSetting: false,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: [],
        bindValues: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      // 线长
      spWidth: '',
      // 线宽
      spHeight: 1,
      // 颜色
      bgColor: '#E5E5E5',
      // 风格
      spStyle: 'solid',
      // 是否显示文字
      showText: false,
      // 文字
      spText: '',
      // 文字位置
      spTextPosition: 'left',
      direction: 'H'
    }
  },
  // 面板
  {
    imageSrc: 'pane',
    thumbnail: '',
    title: '面板',
    type: 'HHTabPaneWidget',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '切换显示',
            value: 'changeShowTab'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示Tab',
            value: 'currentValue'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {},
    dataProp: {
      defaultData: [
        {
          label: '数据趋势',
          value: '数据趋势',
          value2: '',
          list: []
        },
        {
          label: '数据详情',
          value: '数据详情',
          value2: '',
          list: []
        }
      ]
      // options: []
    }
  },
  // 下拉菜单
  {
    imageSrc: 'dropdown',
    thumbnail: '',
    title: '下拉菜单',
    type: 'HHDropdown',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '点击',
            value: 'command'
          }
        ],
        bindValues: [
          {
            label: '选择项',
            value: 'selectValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    prop: {
      // 文案
      txt: '主要按钮',
      // 图标
      icon: '',
      // 尺寸
      size: 'default',
      // 功能
      type: 'primary',
      // 填充
      fill: true,
      // 边框
      border: false,
      // 内容
      options: []
    },
    styleProp: {
      width: null,
      height: null
    }
  },
  // 穿梭框
  {
    imageSrc: 'transfer',
    thumbnail: '',
    title: '穿梭框',
    type: 'HHTransfer',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['LocalData', 'TableSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '选择值',
            value: 'selectValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '设置值',
            value: 'setValue'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: 320
    },
    prop: {
      filterable: true,
      titleLeft: '左侧列表',
      titleRight: '右侧列表',
      buttonTextLeft: '',
      buttonTextRight: '',
      groupOnly: false,
      childOnly: false
    },
    dataProp: {
      defaultData: [
        {
          label: '北京',
          value: '北京'
        },
        {
          label: '上海',
          value: '上海'
        }
      ],
      labelCol: ''
    }
  },
  // base64
  /*
  {
    imageSrc: 'text',
    thumbnail: '',
    title: '图片流',
    type: 'HHBase64',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'TableSource',
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          },
          {
            label: '点击',
            value: 'clickText'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示值',
            value: 'showText'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '设置值',
            value: 'setValue'
          },
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示文本',
            value: 'eventValue'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: 200
    },
    prop: {
      type: 'img' // video
    },
    dataProp: {
      cols: [],
      defaultData: []
    }
  },
  */
  // 树结构
  {
    imageSrc: 'tree',
    thumbnail: '',
    title: '树结构',
    type: 'HHTree',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'nodeClick'
          },
          {
            label: '拖拽结束',
            value: 'nodeDrop'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示列',
            value: 'labelValue'
          },
          {
            label: '数据列',
            value: 'dataValue'
          },
          {
            label: '拖拽信息',
            value: 'dragInfo'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: 360
    },
    prop: {
      idKey: '',
      pidKey: '',
      checkType: 'single',
      styleType: 'angle',
      draggable: false,
      customAction: []
    },
    dataProp: {
      // 本地数据源
      defaultData: [
        {
          label: 'Level one 1',
          children: [
            {
              label: 'Level two 1-1',
              children: [
                {
                  label: 'Level three 1-1-1'
                }
              ]
            }
          ]
        },
        {
          label: 'Level one 2',
          children: [
            {
              label: 'Level two 2-1',
              children: [
                {
                  label: 'Level three 2-1-1'
                }
              ]
            },
            {
              label: 'Level two 2-2',
              children: [
                {
                  label: 'Level three 2-2-1'
                }
              ]
            }
          ]
        }
      ],
      labelCol: 'label',
      xAxisField: 'label'
    }
  },
  // 消息滚动
  {
    imageSrc: 'scroll',
    thumbnail: '',
    title: '消息滚动',
    type: 'HHScrollWidge',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '过滤时间',
            value: 'filterRange'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: 200
    },
    prop: {
      title: '默认标题'
    },
    dataProp: {
      defaultData: [
        {
          time: '2022-11-20 ',
          info: '总数超出警戒上限45.22%（67.33）'
        },
        {
          time: '2022-11-17',
          info: '类别一出现故障数量超出上限值'
        },
        {
          time: '2022-11-13',
          info: '类别一34.11%'
        },
        {
          time: '2022-11-09',
          info: '北京等三城市均在类别一的数据连续3天异常'
        },
        {
          time: '2022-10-23',
          info: '数据出现空现象且告警'
        }
      ],
      // 时间列
      time: '',
      // 详情列
      info: ''
    }
  },
  // 状态监测
  {
    imageSrc: 'status-change',
    thumbnail: '',
    title: '状态监测',
    type: 'HHStatusChange',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: []
      }
    },
    styleProp: {
      width: null,
      height: 20
    },
    prop: {
      shape: 'circle',
      showTemplate: 'type-1',
      judgements: [
        {
          threshold: 0,
          judgement: '>',
          showText: '正常',
          color: '#44CB7F',
          bgColor: '#44CB7F'
        },
        {
          threshold: 0,
          judgement: '<',
          showText: '异常',
          color: '#DB3135',
          bgColor: '#DB3135'
        }
      ]
    },
    dataProp: {
      defaultData: [
        {
          currentValue: 89
        }
      ],
      currentValue: ''
    }
  },
  // 上传
  {
    imageSrc: 'HHUpload',
    thumbnail: '',
    title: '上传',
    type: 'HHUpload',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'TableSource',
    dataSourceTypes: ['TableSource', 'CustomApi'],
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '上传成功',
            value: 'uploadSuccess'
          }
        ],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      tableId: '',
      tableName: '',
      showType: 'default',
      showTxt: '上传',
      limit: 1,
      fileType: [],
      fileSize: '',
      buttonSize: 'default'
    }
  },
  // 自定义查询
  {
    imageSrc: 'custom-query',
    thumbnail: '',
    title: '条件筛选',
    type: 'HHCustomQuery',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '搜索',
            value: 'search'
          }
        ],
        bindValues: [
          {
            label: '表单值',
            value: 'filterForm',
            type: 'form',
            valueTypes: getValueTypes(['form'])
          }
        ]
      },
      receive: {
        operationTypes: [],
        bindValues: []
      }
    },
    prop: {
      perLine: 4,
      showTemplate: true,
      showQuery: true,
      conditions: []
    },
    styleProp: {
      width: null,
      height: null,
      'padding-top': 30,
      'padding-bottom': 16,
      'padding-left': 10,
      'padding-right': 32
    }
  },
  // Iframe框架
  {
    imageSrc: 'custom-iframe',
    thumbnail: '',
    title: '外嵌容器',
    type: 'HHIframe',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: [],
        bindValues: []
      }
    },
    prop: {
      src: ''
    },
    styleProp: {
      width: null,
      height: null,
      'padding-top': 0,
      'padding-bottom': 0,
      'padding-left': 0,
      'padding-right': 0
    }
  }
]

for (let i = 0, len = list.length; i < len; i++) {
  const item = list[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  if (item.dataProp) {
    item.dataProp = { ...dataProp, ...item.dataProp }
  }
  list[i] = { ...compAttr, ...item }
}

export default list
