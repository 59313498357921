<template>
  <el-avatar v-if="src" :size="42" :src="src" />
  <!-- <el-avatar v-else :size="42" :src="avatarUrl" /> -->
  <div v-else class="avatar" :style="{ '--bg': getRandomColor(avatarName) }">
    <span>{{ extractCharacters(avatarName) }}</span>
  </div>
</template>
<script lang="ts">
  export default {
    name: 'AvatarCnp'
  }
</script>
<script setup lang="ts">
  import avatarUrl from '../../assets/images/avatar.png'
  const props = defineProps({
    src: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    avatarName: {
      type: String,
      default: ''
    }
  })
  const colors = ['#0176DE', '#4BCA81', '#9D7CE6', '#0DA1FD', '#EB85C1', '#FDAC23', '#DB492E']
  function getRandomColor(inputChar: string) {
    const hash = inputChar.charCodeAt(0)
    const colorIndex = hash % colors.length
    return colors[colorIndex]
  }
  function extractCharacters(inputStr: string) {
    // 判断是否包含中文字符
    if (/[\u4e00-\u9fff]/.test(inputStr)) {
      // 取后两位
      return inputStr.slice(-2)
    }
    // 取前五位
    return inputStr.slice(0, 5)
  }
</script>

<style lang="scss" scoped>
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border-radius: 50% 50%;
    background-color: var(--bg, antiquewhite);
    color: #ffffff;
    font-size: 12px;
    font-style: normal;
  }
</style>
