import { Directive } from 'vue'

let resizeObserver: any
/** 权限指令 */
export const resize: Directive = {
  mounted(el, binding) {
    const { value } = binding
    resizeObserver = new ResizeObserver((entries: any) => {
      value()
    })
    resizeObserver.observe(el)
  },
  beforeUnmount(el, binding) {
    resizeObserver.unobserve(el)
  }
}
