import { defineStore } from 'pinia'
import { getTheme, setTheme } from '@haohan/clw-utils'
import { envInfoReq } from '@haohan/clw-api'
import { has } from 'lodash-es'

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      theme: getTheme() || 'haohan-light',
      edieModel: false,
      pageLock: false,
      queryTxt: '',
      // 公网环境，项目只能选择V2查询引擎，不能选择行业定制。
      // 选择V1查询引擎（旧版），数据源类型只能选择POSTGRESQL,CLICKHOUSE
      environment: {
        view: true //true-生成后的系统，false-平台
      },
      // 全局的取色器
      colorPicker: {
        show: false, // 取色器是否显示
        color: null, // 触发取色器dom的color
        target: null, // 触发取色器dom
        scroll: '', //scroll_
        upColor: null //上一次取色的颜色
      } as any,
      lastUpdateTime: null // 上次更新时间
    }
  },
  getters: {
    isPlatform: (state: any) => {
      if (has(state.environment, 'view') && !state.environment.view) {
        return true
      }
      return false
    },
    isPrivate: (state: any) => has(state.environment, 'public') && !state.environment.public
  },
  actions: {
    changeTheme(theme: string) {
      this.theme = theme
      setTheme(theme)
      document.body.setAttribute('class', theme)
    },
    changeEdieModel(edieModel: boolean) {
      this.edieModel = edieModel
    },
    changePageLock(param: boolean) {
      this.pageLock = param
    },
    upQueryTxt(txt: string) {
      this.queryTxt = txt
    },
    upEnv(params: any) {
      this.environment = params
    },
    upColorPickerProp(prop: any, value: any) {
      this.colorPicker[prop] = value
    },
    getEnvInfo() {
      // if (this.environment === null) {
      //   envInfoReq().then((resp: any) => {
      //     this.upEnv(resp.data)
      //   })
      // }
      envInfoReq().then((resp: any) => {
        this.upEnv(resp.data)
      })
    },
    updateTime(time: any) {
      this.lastUpdateTime = time
    }
  }
})
