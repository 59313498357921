import * as THREE from 'three'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js'

const gl = new GLTFLoader()
const fl = new FontLoader()
const fbxl = new FBXLoader()
const ttl = new THREE.TextureLoader()

/**
 * gltf promise 加载函数
 * @param path
 * @returns
 */
export function gltfloader(path: string) {
  return new Promise((resolve, reject) => {
    gl.load(path, resolve, (process: any) => {}, reject)
  })
}

/**
 * font promise 加载函数
 * @param path 路径
 * @returns
 */
export function fontloader(path: string) {
  return new Promise((resolve, reject) => {
    fl.load(path, resolve, (process: any) => {}, reject)
  })
}

/**
 * fbx promise 加载函数
 * @param path 路径
 * @returns
 */
export function fbxloader(path: string) {
  return new Promise((resolve, reject) => {
    fbxl.load(path, resolve, (process: any) => {}, reject)
  })
}

/**
 * texture  加载函数
 * @param path 路径
 * @returns
 */
export function textureloader(path: string) {
  return ttl.load(path)
}

/**
 * texture  加载函数
 * @param path 路径
 * @returns
 */
export function textureloaderAsync(path: string) {
  return new Promise((resolve, reject) => {
    ttl.load(path, resolve, (process: any) => {}, reject)
  })
}

/**
 * 请求返回的blob转datauri 用于three.js的texture
 * @param
 * @returns
 */
export function blob2datauriAsync(blob: Blob) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.readAsDataURL(blob)
    fr.onload = () => {
      resolve(fr.result)
    }
  })
}
