import { createApp, Directive } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router/index'
import { installFocusDirective, resize } from '@haohan/clw-directives'
import { getTheme } from '@haohan/clw-utils'

import 'element-plus/dist/index.css'
import 'driver.js/dist/driver.min.css'
import '../../../libs/clw-style/theme.scss'
import '@/style/theme-var.css'
import '@/assets/iconfont/iconfont.css'
import '@/style/common.scss'
import '@/style/element.scss'
import '@/style/db-edit-element-plus.scss'
import '@/style/update.scss'

import { SvgIcon, SvgIconWithText, SketchRule, SlideValidate } from '@haohan/clw-components'
console.log('SvgIcon', SvgIcon)
import 'virtual:svg-icons-register'

// 引入标尺
import 'vue3-sketch-ruler/lib/style.css'

const app = createApp(App)
const pinia = createPinia()
app.use(pinia)
app.use(router)
app.use(SvgIcon.default)
app.use(SvgIconWithText.default)
app.use(SketchRule.default)
app.use(SlideValidate.default)

// 注册指令
const directives = { resize }
Object.keys(directives).forEach((key) => {
  app.directive(key, (directives as { [key: string]: Directive })[key])
})

import './permission'
// 通知用户刷新页面
import './notice'
import './userWorker'

if (getTheme()) {
  document.body.setAttribute('class', getTheme()!)
}
// 自定义指令
installFocusDirective(app)
// 全局异常处理器，更清晰的输出错误内容和调用栈信息
app.config.errorHandler = (err, vm, info) => {
  console.error('[全局异常]', err, vm, info)
}
// 自定义权限指令
import Permission from '@/directives/permission'
import Stretch from '@/directives/stretch'
app.directive('permission', Permission)
app.directive('stretch', Stretch)

app.mount('#app')
