<template>
  <div class="to-do-message message-page">
    <div class="top-search">
      <div class="query-box">
        <el-input
          v-model="searinfo.txt"
          placeholder="请输入搜索内容"
          clearable
          @input="inputDebounce"
        />
        <svg-icon class="search" icon-class="search" />
      </div>
      <el-pagination
        background
        small
        layout="prev, pager, next, total"
        :pager-count="5"
        :current-page="searinfo.page"
        :page-size="searinfo.size"
        :total="searinfo.total"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
    <ResultList
      v-if="searinfo?.data?.length"
      v-loading="searinfo.loading"
      :data-list="searinfo.data"
      :hide-status="true"
      :page-string="'ToDoMessage'"
      @go-detail="handleOpen"
    >
      <template #default="defaultProps">
        <el-button
          v-if="
            defaultProps.settings?.type === 'WRITE' || defaultProps.settings?.type === 'APPROVED'
          "
          type="primary"
          @click="handleOpen(defaultProps)"
          >打开</el-button
        >
      </template>
    </ResultList>
    <EmptyBox v-else :title="searinfo.txt"></EmptyBox>
    <ApprovalDiaLog ref="ApprovalDiaLogRef" @close-dialog="getList"></ApprovalDiaLog>

    <ToDoMessageWrite
      ref="ToDoMessageWriteRef"
      @write-dialog="getList"
      @show-reject-approval-dia-log="showRejectApprovalDiaLog"
      @show-agree-approval-dia-log="showAgreeApprovalDiaLog"
    ></ToDoMessageWrite>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'ToDoMessage'
  }
</script>

<script setup lang="ts">
  import ResultList from './components/ResultList.vue'
  import ApprovalDiaLog from './components/ApprovalDiaLog.vue'
  import ToDoMessageWrite from './components/ToDoMessageWrite.vue'
  import EmptyBox from '../../components/EmptyBox/EmptyBox.vue'

  import { debounce } from 'lodash-es'
  import { ref, reactive, onBeforeMount, computed } from 'vue'
  import { queryTodos, rejectFlowTask, acceptFlowTask } from '@haohan/clw-api'
  import { useProject } from '@haohan/clw-store'
  import { ElMessageBox, ElMessage } from 'element-plus'

  const projectStore = useProject()
  const projectId = computed(() => {
    return projectStore.currentProject?.id
  })

  const ApprovalDiaLogRef = ref(null)
  function showRejectApprovalDiaLog(row) {
    ApprovalDiaLogRef?.value.open(row, '拒绝')
  }
  function showAgreeApprovalDiaLog(row, formValueObj) {
    ApprovalDiaLogRef?.value.open(row, '同意', formValueObj)
  }

  const ToDoMessageWriteRef = ref(null)

  //打开详情弹框
  function handleOpen(row) {
    // todo:打开详情的时候和按钮权限保持一致，
    if (row.settings?.type === 'APPROVED' || row.settings?.type === 'WRITE') {
      ToDoMessageWriteRef?.value.open(row)
    }
  }

  const searinfo = reactive({
    txt: '',
    data: [],
    page: 1,
    size: 10,
    total: 0,
    loading: false
  })
  function getList() {
    searinfo.loading = true
    const params = {
      keyword: searinfo.txt,
      page: searinfo.page,
      size: searinfo.size
    } as any
    queryTodos(projectId.value, params).then((res) => {
      // console.log(res)
      const { data } = res
      searinfo.total = data.totalItems || 0
      searinfo.data = data.data
      searinfo.loading = false
    })
  }
  const inputDebounce = debounce(() => {
    searinfo.page = 1
    getList()
  }, 200)
  function pageChange(val: number) {
    searinfo.page = val
    getList()
  }
  function sizeChange(val: number) {
    searinfo.size = val
    getList()
  }

  onBeforeMount(() => {
    getList()
  })
</script>

<style scoped lang="scss">
  .message-page {
    position: relative;
  }
  .top-search {
    position: absolute;
    right: 0px !important;
  }
  .query-box {
    position: relative;
    width: 326px;
    margin-right: 36px;
    :deep() {
      .el-input {
        height: 36px;
        overflow: hidden;
      }
      .el-input__wrapper {
        width: 100%;
        height: 100%;
        background-color: #fff !important;
        padding-left: 34px;
        box-shadow: none;
      }
      .el-input__inner {
        color: #1a1d1f;
      }
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
</style>
