import { defineStore } from 'pinia'
import { find, findIndex } from 'lodash-es'
import { useProject } from './project'
import {
  getDataSetDescribe,
  getApiMenu,
  queryLatestApiResponse,
  queryLFMenuItemReq
} from '@haohan/clw-api'
import {
  parseColumns,
  updateReviceEventBySendEvent,
  deleteReviceEventBySendEventKey,
  getFlatFormPageWidgetDatalist,
  generateSchemaFromObject,
  transformJsonSchema2Tree,
  getApiDataPath,
  getCompChildren
} from '@haohan/clw-utils'
import dayjs from 'dayjs'
import { useSelectApi } from '@haohan/clw-hooks'

function rmNodeByKey(trees: Widget[], key: any, rmKey: any): void {
  function traverseAndRemoveInPlace(nodes: Widget[], key: any): boolean {
    for (let i = nodes.length - 1; i >= 0; i--) {
      const node = nodes[i]
      if (node.key === key) {
        rmKey.key = node.key
        nodes.splice(i, 1)
        return true
      }
      const children = getCompChildren(node)
      if (children.length) {
        for (const childObj of children) {
          const isEnd = traverseAndRemoveInPlace(childObj.list, key)
          if (isEnd) {
            return true
          }
        }
      }
    }
    return false
  }
  traverseAndRemoveInPlace(trees, key)
}

export const useWidget = defineStore({
  id: 'widge',
  getters: {
    // 不能与 state中得selectWidget同名
    currentSelectWidget: (state) => {
      const wg = state.selectWidget
      // 消除已有页面的属性存在类型差异
      if (
        wg &&
        (wg.type === 'MixChart' ||
          wg.componentType === 'MixChart' ||
          wg.componentType === 'SankeyChart')
      ) {
        for (const key in wg.prop) {
          if (Object.prototype.hasOwnProperty.call(wg.prop, key)) {
            let el = wg.prop[key]
            if ((key === 'xAxis' || key === 'yAxis') && !Array.isArray(el)) {
              el = [].concat(el)
            }
          }
        }
      }
      return wg
    },
    // 前进按钮 禁用逻辑
    forwardDisabled: (state) => {
      const { undoPosition, undoList, undoLocked } = state
      const leng = undoList.length
      if (!leng) {
        return true
      }
      if (undoPosition === leng - 1 || undoLocked) {
        return true
      }
      return false
    },
    // 后退按钮 禁用逻辑
    backwardDisabled: (state) => {
      const { undoPosition, undoList, undoLocked } = state
      const leng = undoList.length
      if (!leng) {
        return true
      }
      if (undoPosition === 0 || undoLocked) {
        return true
      }
      return false
    },
    // 历史按钮 禁用逻辑
    historyDisabled: (state) => {
      const { historyMap } = state
      const keys = Object.keys(historyMap)
      return keys.length ? false : true
    },
    // 除去容器后的扁平化组件列表
    flatWidgetDataList: (state) => {
      const getFlatArr = (arr: any) => {
        return arr.reduce((a: any, item: any) => {
          let flatArr = [...a, item]
          if (item.children) {
            flatArr = [...flatArr, ...getFlatArr(item.children)]
          }
          return flatArr
        }, [])
      }
      let flatList: any[] = []
      flatList = getFlatFormPageWidgetDatalist(state.widgetDataList, flatList)
      return flatList
    }
  },
  state: () => {
    return {
      selectWidget: <Widget>{ type: 'Blank' },
      dragWidget: <Widget>{ type: 'Blank' },
      dragInfo: { x: 0, y: 0 },
      widgetDataList: <Widget[]>[],
      // 当前所选数据集列信息
      currentTableCols: <TableColInfo[]>[],
      // 数据集中过滤参数集合
      queryPrefixLiteral: <TableColInfo[]>[],
      // 历史
      historyMap: {} as any,
      currentHistoryKey: '',
      //  撤销
      undoLocked: false,
      undoList: [] as any,
      undoPosition: 0,
      // '组合' 按钮是否可点击
      composeUsable: false,
      // 组件列表中, 最大 最小的 z-index 值
      maxZIndex: 100,
      minZIndex: 100,
      // 缩放比例
      scale: 100,
      // 上次缩放比例
      lastTimeScale: 100,
      // 缩放比例, 画布数据
      canvasStyleData: {
        width: 1440,
        height: 900,
        scale: 100
      },
      cascaderLevel: 1,
      // 记录当前组件绑定的API的信息
      apiResponseInfo: {
        interfaceId: '', // 绑定API ID
        tree: [], // 根据返回信息生成的选择树
        allColumnDeepValues: []
      },
      isWidgetDraging: false // 组件拖拽状态
    }
  },
  actions: {
    changeWidgetDraging(isDraging: boolean) {
      this.isWidgetDraging = isDraging
    },
    changeLastTimeScale(scale: number) {
      this.lastTimeScale = scale
    },
    changeScale(scale: number) {
      if (scale < 0) return
      this.scale = scale
    },
    changeMaxZIndex(zIndex: number) {
      this.maxZIndex = zIndex
    },
    changeMinZIndex(zIndex: number) {
      this.minZIndex = zIndex
    },
    changeComposeUsable(flag: boolean) {
      this.composeUsable = flag
    },
    changeSelectWidget(widget: Widget) {
      this.selectWidget = widget
      // 切换选中组件时，加载列信息
      this.loadTableColsInfo()
      // 跳转值属性功能区
      // emitter.emit('change-right-fun-sidebar', 'attribute')
    },
    changeDragWidget(widget: Widget) {
      this.dragWidget = widget
    },
    changeDragInfo(position: any) {
      this.dragInfo = position
    },
    deleteSelectWidget() {
      this.selectWidget = { type: 'Blank' }
      this.setCurrentTableCols([])
    },
    updateWidgetAttr(propType: PROP_TYPE, attrName: string, val: any) {
      // const attr = find(this.selectWidget.attributes, (at) => at.name === attrName)
      // if (attr) {
      //   attr.value = val
      // }
      const attr = this.selectWidget[propType]
      if (attr) {
        attr[attrName] = val
      }
    },
    async loadTableColsInfo() {
      // const tableIdAttr = find(this.selectWidget.attributes, (at) => at.name === 'tableId')
      const tableIdAttr = this.selectWidget.dataProp?.tableId
      if (tableIdAttr) {
        const projectStore = useProject()
        // 自定义接口不请求 describe 接口
        if (this.selectWidget?.dataSourceType === 'CustomApi') {
          const { data } = await getApiMenu(projectStore.currentProject.id, tableIdAttr)
          const { initSelectApiInfo } = useSelectApi()
          const dataPath = getApiDataPath(this.selectWidget?.dataProp?.apiDataPath, data)
          initSelectApiInfo(tableIdAttr, dataPath)
        } else if (this.selectWidget?.dataSourceType === 'TableSource') {
          getDataSetDescribe(projectStore.currentProject.id, tableIdAttr).then((res) => {
            this.setCurrentTableCols(parseColumns(res.data.columns))
            const queryPrefixLiteral: TableColInfo[] = (res.data.prefixLiterals || []).map(
              (d: string) => {
                return {
                  value: d,
                  label: d
                }
              }
            )
            this.setQueryPrefixLiteral(queryPrefixLiteral)
          })
        }
      } else {
        this.setCurrentTableCols([])
      }
    },
    setCurrentTableCols(tableCols: TableColInfo[]) {
      this.currentTableCols = tableCols
    },
    setQueryPrefixLiteral(queryPrefixLiteral: TableColInfo[]) {
      this.queryPrefixLiteral = queryPrefixLiteral
    },
    changeWidgetDataList(widgetDataList: Widget[]) {
      this.widgetDataList = widgetDataList
    },
    updateWidgetProp(prop: string, val: any) {
      // if (isValidKey(prop, this.selectWidget)) {
      //   this.selectWidget[prop] = val as never
      // }
      this.selectWidget[prop] = val as never
    },
    addEvent(event: WidgetEvent) {
      this.selectWidget.events?.push(event)
    },
    deleteEvent(event: WidgetEvent) {
      const eventIndex = findIndex(this.selectWidget.events, (e) => e.key === event.key)
      if (eventIndex !== -1) {
        this.selectWidget.events?.splice(eventIndex, 1)
        if (event.eventType === 'send') {
          deleteReviceEventBySendEventKey(this.widgetDataList, event.key)
        }
      }
    },
    deleteEventByKey(key: string) {
      const eventIndex = findIndex(this.selectWidget.events, (e) => e.key === key)
      if (eventIndex !== -1) {
        this.selectWidget.events?.splice(eventIndex, 1)
        deleteReviceEventBySendEventKey(this.widgetDataList, key)
      }
    },
    editEvent(event: WidgetEvent) {
      const e = find(this.selectWidget.events, (e) => e.key === event.key)
      if (e) {
        Object.keys(event).forEach((eKey) => {
          // if (isValidKey(eKey, e)) {
          //   e[eKey] = event[eKey]
          // }
          e[eKey] = event[eKey]
        })
        if (e.eventType === 'send') {
          updateReviceEventBySendEvent(this.widgetDataList, e)
        }
      }
    },
    // 删除当前选中的组件
    deleteSelectWidgetFromWidgetDataList(widget?: Widget) {
      const select = widget || this.selectWidget
      const list = this.widgetDataList
      if (!select || !list?.length) {
        return
      }
      const rmKey = { key: '' }
      rmNodeByKey(list, select.key, rmKey)
      if (this.selectWidget.key === rmKey.key) {
        this.deleteSelectWidget()
      }
    },
    // 重置历史记录
    initHistory() {
      this.historyMap = {}
    },
    setCurrentHistoryKey(key: string) {
      this.currentHistoryKey = key
    },
    // 保存历史
    saveHistory(k: any, v: any) {
      if (!k || !v) {
        return
      }
      this.historyMap[k] = JSON.parse(JSON.stringify(v))
      const len = Object.keys(this.historyMap).length
      const MAX_LENGTH = 5

      if (len > MAX_LENGTH) {
        // 时间排序, 取最后5个
        const newObj = {} as any
        const keys = Object.keys(this.historyMap)
          .map((time) => dayjs(time).valueOf())
          .sort()
          .slice(-MAX_LENGTH)
          .forEach((time) => {
            const oldKey = dayjs(time).format('YYYY-MM-DD HH:mm:ss')
            newObj[oldKey] = this.historyMap[oldKey]
          })
        this.historyMap = newObj
      }
      console.log('historyMap ===', this.historyMap)
    },
    // 重置 前进/后退 记录
    initUndoList() {
      this.undoList = []
      this.undoPosition = 0
    },
    // 增加 前进/后退 记录
    addUndoItem(item: any) {
      this.undoList.push(JSON.parse(JSON.stringify(item)))
      const MAX_LENGTH = 10
      const len = this.undoList.length
      if (len > MAX_LENGTH) {
        // 取最后5个
        this.undoList = this.undoList.slice(-MAX_LENGTH)
      }
      this.undoPosition =
        this.undoPosition < this.undoList.length - 1
          ? this.undoPosition + 1
          : this.undoList.length - 1
    },
    // 前进
    onUndoForward() {
      if (this.undoPosition < this.undoList.length - 1) {
        this.undoPosition += 1
      } else {
        this.undoPosition = this.undoList.length - 1
      }
      console.log('前进', this.undoPosition, this.undoList)
      return this.undoList[this.undoPosition]
    },
    // 后退
    onUndoBackward() {
      if (this.undoPosition > 1) {
        this.undoPosition -= 1
      } else {
        this.undoPosition = 0
      }
      console.log('后退', this.undoPosition, this.undoList)
      return this.undoList[this.undoPosition]
    },
    // 点击 前进/后退 加锁, 不触发 addUndoItem
    lockUndoTemporary() {
      this.undoLocked = true
      setTimeout(() => {
        this.undoLocked = false
      }, 200)
    },
    upCascaderLevel(level: number) {
      this.cascaderLevel = level
    },
    /**
     * 接口ID变化时，生成返回信息的树
     * @param interfaceId 组件绑定的接口ID
     */
    async changeSelectApi(interfaceId: string, sourceType: DATA_SOURCE_TYPE = 'CustomApi') {
      const projectStore = useProject()
      // 获得当前API的历史查询数据
      // TODO，需要根据逻辑流替换接口
      const res =
        sourceType === 'CustomApi'
          ? await queryLatestApiResponse(projectStore.currentProject.id, interfaceId)
          : await queryLFMenuItemReq(projectStore.currentProject.id, interfaceId)
      let responseTree = []
      let allColumnDeepValues = []
      try {
        const _body = sourceType === 'CustomApi' ? res.data.body : res.data.latest.result
        const body = typeof _body === 'string' ? JSON.parse(_body) : _body
        if (body) {
          const _schema = generateSchemaFromObject(body)
          const _r = transformJsonSchema2Tree(_schema)
          responseTree = _r.tree
          allColumnDeepValues = _r.allColumnDeepValues
        }
      } catch (error) {
        console.log(error)
      }
      this.apiResponseInfo.interfaceId = interfaceId
      this.apiResponseInfo.tree = responseTree
      this.apiResponseInfo.allColumnDeepValues = allColumnDeepValues
    }
  }
})
