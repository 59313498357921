<template>
  <div class="result-list">
    <div v-for="item in showResultList" :key="item.id" class="result-item">
      <template v-if="showDot"> <span class="dot"></span></template>
      <div class="list">
        <div class="flex">
          <div class="left" @click="goDetail(item)">
            <div class="exceed-omit">
              <!-- 我的待办页面的头像显示不一致 -->
              <template v-if="pageString === 'ToDoMessage'">
                <div v-if="item.flowInst?.startUserAvatar"
                  ><el-avatar :size="76" :src="item.flowInst?.startUserAvatar"
                /></div>
                <div
                  v-else
                  class="avatar"
                  :style="{ '--bg': getRandomColor(item.flowInst?.startUsername) }"
                >
                  <span class="avatar-text">{{
                    extractCharacters(item.flowInst?.startUsername)
                  }}</span>
                </div>
                <div class="text">{{ item.flowInst?.startUsername || '' }}</div>
              </template>

              <!--我发起的页面 -->
              <!-- 20240802修改接口  现在 pageString === 'MyProcessed' 我已处理页面也是一样的了-->
              <template v-if="pageString === 'MyInitiate' || pageString === 'MyProcessed'">
                <div v-if="item.startUserAvatar"
                  ><el-avatar :size="76" :src="item.startUserAvatar"
                /></div>
                <div v-else class="avatar" :style="{ '--bg': getRandomColor(item.startUsername) }">
                  <span class="avatar-text">{{ extractCharacters(item.startUsername) }}</span>
                </div>
                <div class="text">{{ item.startUsername || '' }}</div>
              </template>
            </div>
            <div class="info">
              <div class="info-box">
                <span class="title">"{{ item.flowDef?.name }}"申请</span>
                <template v-if="!hideStatus">
                  <!--我发起的页面 -->
                  <!-- 20240802修改接口  现在 pageString === 'MyProcessed' 我已处理页面也是一样的了-->
                  <template v-if="pageString === 'MyInitiate' || pageString === 'MyProcessed'">
                    <template v-if="item.finishTime === null">
                      <span class="tag">
                        <el-tag
                          type="warning"
                          effect="light"
                          round
                          size="large"
                          disable-transitions
                        >
                          {{ '进行中' }}
                        </el-tag>
                      </span>
                    </template>
                    <template v-else>
                      <!-- <span v-if="item.lastComment?.type === '终止'" class="tag">
                        <el-tag type="info" effect="light" round size="large" disable-transitions>
                          {{ '已终止' }}
                        </el-tag>
                      </span>
                      <span v-else-if="item.lastComment?.type === '同意'" class="tag">
                        <el-tag
                          type="success"
                          effect="light"
                          round
                          size="large"
                          disable-transitions
                        >
                          {{ '已同意' }}
                        </el-tag>
                      </span>
                      <span v-else-if="item.lastComment?.type == '驳回'" class="tag">
                        <el-tag type="danger" effect="light" round size="large" disable-transitions>
                          {{ '已拒绝' }}
                        </el-tag>
                      </span> -->
                      <span class="tag">
                        <el-tag
                          :type="item.statusInfo.tagType"
                          effect="light"
                          round
                          size="large"
                          disable-transitions
                        >
                          {{ item.statusInfo.info }}
                        </el-tag>
                      </span>
                      <!-- <span class="tag">
                        <el-tag
                          type="success"
                          effect="light"
                          round
                          size="large"
                          disable-transitions
                        >
                          {{ '已完成' }}
                        </el-tag>
                      </span> -->
                    </template>
                  </template>
                </template>
              </div>
              <div class="time">提交时间：{{ item.createTime || '-' }}</div>
            </div>
          </div>
          <div class="right">
            <slot v-bind="item"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  export default {
    name: 'ResultList'
  }
</script>
<script setup lang="ts">
  import { computed } from 'vue'
  import { getItemStatusByTaskInfo } from '../../../utils/workflow-tool'
  import avatar from '../../../assets/images/avatar.png'
  interface StatusInfo {
    // 'run' 进行中 | 'done' 已完成 | 'end' 已终止 | 'agree' 已同意 | '已拒绝'
    status: 'run' | 'done' | 'end' | 'agree' | 'refuse'
    tagType: 'info' | 'danger' | 'success' | 'warning'
    info: string
  }
  const props = defineProps({
    dataList: {
      type: Array as any,
      default: () => []
    },
    hideStatus: {
      type: Boolean,
      default: false
    },
    showDot: {
      type: Boolean,
      default: false
    },
    pageString: {
      type: String,
      default: ''
    }
  })
  function extractCharacters(inputStr: string) {
    // 判断是否包含中文字符
    if (/[\u4e00-\u9fff]/.test(inputStr)) {
      // 取后两位
      return inputStr.slice(-2)
    }
    // 取前五位
    return inputStr.slice(0, 5)
  }
  const colors = ['#0176DE', '#4BCA81', '#9D7CE6', '#0DA1FD', '#EB85C1', '#FDAC23', '#DB492E']
  function getRandomColor(inputChar: string) {
    const hash = inputChar.charCodeAt(0)
    const colorIndex = hash % colors.length
    return colors[colorIndex]
  }
  const emits = defineEmits(['goDetail'])
  function goDetail(row) {
    emits('goDetail', row)
  }

  const showResultList = computed(() => {
    return props.dataList.map((d: any) => {
      const _d = { ...d }
      // 增加状态信息
      if (props.pageString === 'MyInitiate' || props.pageString === 'MyProcessed') {
        _d.statusInfo = getItemStatusByTaskInfo(props.pageString, d)
      }
      return _d
    })
  })
</script>

<style scoped lang="scss">
  .result-list {
    // overflow-y: auto;
    // max-height: 86vh;
  }
  .exceed-omit {
    display: flex;
    flex-direction: column;
    // align-items: center;
  }
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 76px;
    width: 76px;
    border-radius: 50% 50%;
    background-color: var(--bg, antiquewhite);
    color: #ffffff;
    font-size: 18px;
  }
  .result-item {
    position: relative;
    .dot {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: 20px;
      left: 20px;
      background-color: #f01d1d;
    }
    .list {
      margin-bottom: 12px;
      height: 142px;
      background: #ffffff;
      border-radius: 18px 18px 18px 18px;
      .flex {
        display: flex;
        align-items: center;
        padding: 20px 30px 20px 60px;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .text {
          padding-top: 6px;
          font-size: 14px;
          color: #606266;
          text-align: center;
        }
        .info {
          margin-left: 30px;
          margin-top: -10px;
          .info-box {
            height: 32px;
            line-height: 28px;
          }
          .title {
            font-weight: 600;
            font-size: 16px;
            color: #1a1d1f;
          }
          .tag {
            margin-left: 12px;
            :deep() {
              .el-tag {
                padding: 0 31px;
              }
            }
          }
          .time {
            padding-top: 8px;
            font-size: 14px;
            color: #606266;
          }
        }
        .right {
          justify-self: flex-end;
        }
      }
    }
  }
</style>
