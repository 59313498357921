import { filter, forEach as ForEach, has, isNull, isUndefined } from 'lodash-es'
import { useEvent, useProject } from '@haohan/clw-store'
import dayjs from 'dayjs'
import { toRaw, markRaw } from 'vue'
/**
 * 解析columns列中得列名
 */
export function parseColumns(columns: any[]): TableColInfo[] {
  const cols: TableColInfo[] = []
  columns.forEach((c) => {
    if (c.name.indexOf(' as ') !== -1) {
      // 获得最后一个as 后得内容
      const colName = c.name.substring(c.name.lastIndexOf(' as ') + 2, c.name.length)
      const colValue = c.name.substring(0, c.name.lastIndexOf(' as '))
      cols.push({
        name: colName.trim(),
        label: colName.trim(),
        value: colValue.trim(),
        type: c.type,
        comment: c.comment || ''
      })
    } else {
      cols.push({
        name: c.name,
        label: c.name,
        value: c.name,
        type: c.type,
        comment: c.comment || ''
      })
    }
  })
  return cols
}

export function buildQueryParams(
  selectedDateRange: any,
  promptlyEventValues: any,
  otherwheres = []
) {
  // 获得时间查询参数
  const dateRangeQueryParams = getDateRangeQueryParams(selectedDateRange)
  // promptlyEventValues 转化为查询参数
  const wheres = transReceiveEvent2Wheres(promptlyEventValues)
  const createWheres = JSON.parse(JSON.stringify(otherwheres)).concat(wheres)
  const queryWheres = buildWhereByEventValues({}, createWheres)
  const queryParam = {
    ...dateRangeQueryParams
  }
  if (queryWheres) {
    queryParam.where = queryWheres
  }
  return queryParam
}
// 根据查询时间得类型，转化查询时间值为 begin,end参数
export function getDateRangeQueryParams(selectedDateRange: any): QueryDateRangeParam {
  if (selectedDateRange instanceof String) {
    return {
      begin: <string>selectedDateRange,
      end: <string>selectedDateRange
    }
  } else if (selectedDateRange instanceof Array && selectedDateRange.length !== 0) {
    if (selectedDateRange.length === 1) {
      return {
        begin: selectedDateRange[0],
        end: selectedDateRange[0]
      }
    }
    return {
      begin: selectedDateRange[0],
      end: selectedDateRange[1]
    }
  }
  return { begin: '', end: '' }
}
/**
 * 将接收到得事件值转化为wheres数组
 * @param [{}] promptlyEventValues
 */
export function transReceiveEvent2Wheres(
  promptlyEventValues: MutualQueryEventValueData
): QueryWhereParam[] {
  const wheres: QueryWhereParam[] = []
  Object.keys(promptlyEventValues).forEach((key) => {
    const v = promptlyEventValues[key]
    // 增加 v.filterOperatorType !== '' 是为了过滤掉，排序等非正常过滤交互得内容
    if (
      !isUndefined(v.value) &&
      String(v.value).trim() !== '' &&
      v.filterOperatorType !== '' &&
      v.filterOperatorType.toLocaleUpperCase() === 'BETWEEN' &&
      (!v.filterInsertCol || v.filterInsertCol.trim() === '')
    ) {
      // 判断值类型
      let startValue = v.value
      let endValue = v.value
      if (v.value instanceof Array) {
        startValue = v.value[0]
        endValue = v.value.length < 2 ? v.value[0] : v.value[1]
      }
      wheres.push({
        name: v.filterProp,
        operator: '>=',
        type: v.filterValueType,
        value: startValue
      })
      wheres.push({
        name: v.filterProp,
        operator: '<=',
        type: v.filterValueType,
        value: endValue
      })
    } else if (
      !isUndefined(v.value) &&
      String(v.value).trim() !== '' &&
      v.filterOperatorType !== '' &&
      (!v.filterInsertCol || v.filterInsertCol.trim() === '')
    ) {
      //  && (!v.filterInsertCol || v.filterInsertCol.trim() === '') 过滤掉内置过滤
      wheres.push({
        name: v.filterProp,
        operator: v.filterOperatorType,
        type: v.filterValueType,
        value: v.value
      })
    }
  })
  return wheres
}
export function buildWhereByEventValues(
  whereParam: any = {},
  eventWheres: QueryWhereParam[] = []
): any {
  if (eventWheres.length === 0) {
    return undefined
  }
  whereParam.type = 'operate'
  const eventWhereLength = eventWheres.length
  if (eventWhereLength === 1) {
    whereParam.left = {
      type: 'literal',
      value: eventWheres[0].name
    }
    whereParam.operator = eventWheres[0].operator
    whereParam.parameters = [
      buildValueParameterByOperate(eventWheres[0].operator, eventWheres[0].value)
    ]
  } else if (eventWhereLength === 2) {
    whereParam.left = {
      type: 'operate',
      left: {
        type: 'literal',
        value: eventWheres[0].name
      },
      operator: eventWheres[0].operator,
      parameters: [buildValueParameterByOperate(eventWheres[0].operator, eventWheres[0].value)]
    }
    whereParam.operator = 'and'
    whereParam.parameters = [
      {
        type: 'operate',
        left: {
          type: 'literal',
          value: eventWheres[1].name
        },
        operator: eventWheres[1].operator,
        parameters: [buildValueParameterByOperate(eventWheres[1].operator, eventWheres[1].value)]
      }
    ]
  } else if (eventWhereLength > 2) {
    whereParam.operator = 'and'
    whereParam.parameters = [
      {
        type: 'operate',
        left: {
          type: 'literal',
          value: eventWheres[eventWhereLength - 1].name
        },
        operator: eventWheres[eventWhereLength - 1].operator,
        parameters: [
          buildValueParameterByOperate(
            eventWheres[eventWhereLength - 1].operator,
            eventWheres[eventWhereLength - 1].value
          )
        ]
      }
    ]
    eventWheres.splice(eventWhereLength - 1, 1)
    whereParam.left = buildWhereByEventValues({}, eventWheres)
  }
  return whereParam
}
function buildValueParameterByOperate(operate: any, value: any) {
  if (operate && operate.toUpperCase() === 'IN' && value instanceof String) {
    const values = value.split(',')
    return {
      type: 'parameter',
      value: values,
      isList: true
    }
  }
  return {
    type: 'parameter',
    value: value
  }
}
/**
 * 将组件属性集合转为对象格式
 * @param attributes 组件属性集合
 * @returns 组件属性对象
 */
export function transformArray2Object(attributes?: WidgetAttribute[]) {
  if (!attributes || attributes.length === 0) {
    return {}
  }
  const attributeObj: any = {}
  attributes.forEach((ab) => {
    attributeObj[ab.name] = ab.value
  })
  return attributeObj
}

export function updateAttributeByOption(propOption: any, attributes?: WidgetAttribute[]) {
  if (!attributes) {
    return false
  }
  Object.keys(propOption).forEach((key: string) => {
    const ix = attributes.findIndex((el: WidgetAttribute) => el.name === key)
    if (ix < 0) {
      attributes.push({
        name: key,
        type: 'variable',
        value: propOption[key]
      })
    } else {
      attributes[ix].value = propOption[key]
    }
  })
}

function buildParametersValues(promptlyEventValues: MutualQueryEventValueData): any {
  const parameters = {}
  Object.keys(promptlyEventValues).forEach((key) => {
    const info = promptlyEventValues[key]
    if (info.filterInsertCol && info.filterInsertCol.trim() !== '') {
      if (!info.filterInsertColType || info.filterInsertColType === 'nullValue') {
        parameters[info.filterInsertCol] = (
          String(info.value) === ''
            ? 'OPR_IGNORE'
            : getFilterInsertValueByType(info.value, info.filterInsertValueType)
        ) as never
      } else if (
        info.filterInsertColType &&
        info.filterInsertColType === 'defaultValue' &&
        String(info.value) !== ''
      ) {
        parameters[info.filterInsertCol] = getFilterInsertValueByType(
          info.value,
          info.filterInsertValueType
        ) as never
      }
    }
  })
  return parameters
}
function getFilterInsertValueByType(value: any, type = 'string'): string {
  if (type === 'string') {
    return value
  } else if (type === 'arrary') {
    return value.split(',')
  }
  return value
}
export function buildTimeRangeWhere(
  selectedDateRange: string[] = [],
  filterRange: any[] = [],
  attributeObj: any = {}
): QueryDateRangeParam[] {
  const ranges: QueryDateRangeParam[] = []
  let _dataRange = getDateRangeQueryParams(selectedDateRange)
  if (
    selectedDateRange.length > 0 &&
    attributeObj.queryTimeType &&
    attributeObj.queryTimeType === 'fixed'
  ) {
    _dataRange = getDataRangeByQueryTimeByFixed(attributeObj)
  }
  if (selectedDateRange.length > 0) {
    ranges.push(_dataRange)
  }
  if (filterRange.length > 0) {
    ranges.push(getDateRangeQueryParams(filterRange))
  } else {
    // 如果不传，默认取前天的时间
    ranges.push(_dataRange)
    // ranges.push(getLastWeekDateRange(_dataRange))
  }
  return ranges
}
/**
 * 返回固定时间查询时间类型时得查询时间段
 * @param {*} attributeObj
 * @returns
 */
export function getDataRangeByQueryTimeByFixed(attributeObj: any): QueryDateRangeParam {
  const startInterval = attributeObj.startInterval
  const endtInterval = attributeObj.endtInterval
  let end = ''
  if (Number(endtInterval) < 0) {
    end = `${dayjs().subtract(Math.abs(endtInterval), 'day').format('YYYYMMDD')}000000`
  } else {
    end = `${dayjs().add(Math.abs(endtInterval), 'day').format('YYYYMMDD')}000000`
  }
  let begin = ''
  if (Number(startInterval) < 0) {
    begin = `${dayjs().subtract(Math.abs(startInterval), 'day').format('YYYYMMDD')}000000`
  } else {
    begin = `${dayjs().add(Math.abs(startInterval), 'day').format('YYYYMMDD')}000000`
  }
  return {
    begin,
    end
  }
}
export function addPromptlyEventValues(promptlyEventValues: any, receiveInfo: any) {
  if (receiveInfo instanceof Array) {
    // 获得接收到得事件中为数据过滤得事件
    const promptlyEvents = _.filter(receiveInfo, (e) => e.receiveEvent.action === 'promptlyFilters')
    promptlyEvents.forEach((e) => {
      promptlyEventValues[e.receiveEvent.key] = {
        value: e.data,
        time: new Date().getTime(),
        filterProp: e.receiveEvent.filterProp,
        filterOperatorType: e.receiveEvent.filterOperatorType,
        filterValueType: e.receiveEvent.filterValueType,
        filterInsertCol: e.receiveEvent.filterInsertCol || '',
        filterInsertColType: e.receiveEvent.filterInsertColType || 'nullValue',
        filterInsertValueType: e.receiveEvent.filterInsertValueType || 'string'
      }
    })
  } else if (receiveInfo.receiveEvent.action === 'promptlyFilters') {
    promptlyEventValues[receiveInfo.receiveEvent.key] = {
      value: receiveInfo.data,
      time: new Date().getTime(),
      filterProp: receiveInfo.receiveEvent.filterProp,
      filterOperatorType: receiveInfo.receiveEvent.filterOperatorType,
      filterValueType: receiveInfo.receiveEvent.filterValueType,
      filterInsertCol: receiveInfo.receiveEvent.filterInsertCol || '',
      filterInsertColType: receiveInfo.receiveEvent.filterInsertColType || 'nullValue',
      filterInsertValueType: receiveInfo.receiveEvent.filterInsertValueType || 'string'
    }
  }
}
/**
 * 执行自定义函数，相当于eval，但是比eval更安全
 * @param {*} customFun 自定义函数字符串
 * @param {*} params 函数参数
 * @returns
 */
export function exeCustomFun(customFun: string, params: any) {
  // TODO 函数格式得检查等内容
  const jsCode = Function(`"use strict";return (${customFun})`)()
  if (params) {
    return jsCode(params)
  }
  const result = jsCode()
  console.log('result', result)
  return result
}

/**
 * 返回绑定得接收事件得值
 * @param events 需要获得值得组件
 * @returns 事件得值
 */
export const getEventValues = (events: WidgetEvent[]): MutualQueryEventValueData => {
  const eventStore = useEvent()
  // 使用 toRaw 将一个由reactive生成的响应式对象转为普通对象 避免响应式监听导致的重复请求
  const pageParams = toRaw(eventStore.pageParams)
  const mutualPool = toRaw(eventStore.mutualPool)
  const eventValue: MutualQueryEventValueData = {}
  filter(events, (e: WidgetEvent) => e.eventType === 'receive' && e.behavior === 'runIn').forEach(
    (e: WidgetEvent) => {
      if (has(pageParams, e.receiveEventKey!)) {
        // TODO: 需要处理重复过来字段，比如文本组件监听跳转得值，本身又监听图点击发送，都是过滤同个字段
        const _pageParam = pageParams[e.receiveEventKey!]
        eventValue[e.receiveEventKey!] = {
          value: _pageParam.value,
          filterProp: e.filterProp || '',
          filterOperatorType: e.filterOperatorType || '',
          filterValueType: e.filterValueType || '',
          filterInsertCol: e.filterInsertCol,
          filterInsertColType: e.filterInsertColType,
          filterInsertValueType: e.filterInsertValueType,
          type: 'runIn'
        }
      }
    }
  )
  filter(events, (e: WidgetEvent) => e.eventType === 'receive').forEach((e: WidgetEvent) => {
    // TODO: 注意是否存在绑定同个事件时，值被空置覆盖得问题
    // 获得交互池中存储得对应发送事件对象
    const mutualPoolData = mutualPool[e.receiveEventKey!]
    if (mutualPoolData && mutualPoolData.value !== '') {
      // 当交互类型为表单过滤并且绑定得值类型为form时，才会遍历添加
      if (e.behavior === 'formFilter' && e.bindValueType === 'form') {
        Object.keys(mutualPoolData.value).forEach((key: string) => {
          if (
            !isNull(mutualPoolData.value[key].value) &&
            !isUndefined(mutualPoolData.value[key].value) &&
            mutualPoolData.value[key].value !== '' &&
            !mutualPoolData.value[key].isIgnore
          ) {
            eventValue[`${e.receiveEventKey!}_${key}`] = {
              value: mutualPoolData.value[key].value,
              filterProp: key,
              filterOperatorType: mutualPoolData.value[key].filterOperatorType,
              filterValueType: mutualPoolData.value[key].filterValueType || '',
              filterInsertCol: mutualPoolData.value[key].isFilterInsertCol
                ? key
                : e.filterInsertCol,
              filterInsertColType: mutualPoolData.value[key].filterInsertColType,
              filterInsertValueType: mutualPoolData.value[key].filterInsertValueType
            }
          }
        })
      } else {
        eventValue[e.receiveEventKey!] = {
          value: mutualPoolData.value,
          filterProp: e.filterProp || '',
          filterOperatorType: e.filterOperatorType || '',
          filterValueType: e.filterValueType || '',
          filterInsertCol: e.filterInsertCol,
          filterInsertColType: e.filterInsertColType,
          filterInsertValueType: e.filterInsertValueType
        }
      }
    }
    // 处理跳转类型事件值
  })
  return eventValue
}
/**
 * 组件查询条件对象
 * @param dateRange 时间集合
 * @param eventValues 事件值
 * @param filterWheres 过滤集合
 * @returns 查询对象
 */
export const buildQuery = (
  dateRange: QueryDateRangeParam[],
  eventValues: MutualQueryEventValueData,
  filterWheres: QueryWhereParam[]
): QueryParam => {
  const projectStore = useProject()
  const isFilterTime =
    projectStore.currentProject.settings.filterTime &&
    projectStore.currentProject.settings.filterTime === 'false'
      ? false
      : true
  const queryParam: QueryParam = {
    times: isFilterTime ? dateRange : []
  }
  const wheres = transReceiveEvent2Wheres(eventValues)
  const createWheres = JSON.parse(JSON.stringify(filterWheres)).concat(wheres)
  const queryWheres = buildWhereByEventValues({}, createWheres)
  // 创建内置参数值
  const parameters = buildParametersValues(eventValues)
  if (queryWheres) {
    queryParam.where = queryWheres
  }
  if (Object.keys(parameters).length > 0) {
    queryParam.parameters = parameters
  }
  return queryParam
}

export function getRow2ColData(datas: any, row2ColInfo: any) {
  if (!datas || datas.length === 0) {
    return []
  }
  const resultObj = {}
  // 记录数据顺序
  const colSortSet = new Set()
  datas.forEach((d: any) => {
    if (!resultObj[row2ColInfo.fixCol]) {
      resultObj[row2ColInfo.fixCol] = new Set()
    }
    const valueKey = d[row2ColInfo.fixCol] + '_' + d[row2ColInfo.rowCol]
    resultObj[row2ColInfo.fixCol].add(d[row2ColInfo.fixCol])
    resultObj[valueKey] = d[row2ColInfo.valueCol]
    // 存储值列在原始数据中出现的顺序
    colSortSet.add(d[row2ColInfo.rowCol])
  })
  const results: any = []
  // 将字段顺序转为对象数据，以便方便查找
  const colSortArray = Array.from(colSortSet)
  resultObj[row2ColInfo.fixCol].forEach((name) => {
    const result = {}
    result[row2ColInfo.fixCol] = name
    Object.keys(resultObj).forEach((key) => {
      if (key !== row2ColInfo.fixCol) {
        const keys = key.split('_')
        if (keys[0] === name) {
          result[keys[1]] = resultObj[key]
          result[`${keys[1]}_clw_index`] = colSortArray.indexOf(keys[1])
        }
      }
    })
    results.push(result)
  })
  return results
}

export function getRow2ColColumns(datas: any, row2ColInfo: any) {
  if (!datas || datas.length === 0) {
    return []
  }
  const columns = []
  // const colNames = new Set()
  const cols: any = []
  // 找出数据中最多属性得行，做为表格列信息
  const _datas = JSON.parse(JSON.stringify(datas))
  // 记录所有列信息
  const allColInfo: any = {}
  _datas.forEach((d: any) => {
    Object.keys(d).forEach((key) => {
      if (!has(allColInfo, key)) {
        allColInfo[key] = {
          value: d[key],
          index: d[`${key}_clw_index`] || 0
        }
      }
    })
  })
  // 将列信息存储到对象中
  Object.keys(allColInfo).forEach((key: any) => {
    if (key !== row2ColInfo.fixCol && !key.includes('_clw_index')) {
      cols.push({
        name: key,
        value: allColInfo[key].value,
        index: allColInfo[key].index
      })
    }
  })
  // 添加固定列
  columns.push({
    prop: row2ColInfo.fixCol,
    label: row2ColInfo.fixColName,
    align: 'center'
  })
  // 按值做排序
  cols.sort((a: any, b: any) => {
    if (!row2ColInfo.order || (row2ColInfo.order && row2ColInfo.order === '')) {
      // 按照数据里得index排序
      return a.index - b.index
    } else if (!row2ColInfo.order || row2ColInfo.order === 'desc') {
      return b.value - a.value
    }
    return a.value - b.value
  })
  // 添加列信息
  cols.forEach((c: any) => {
    columns.push({
      prop: c.name,
      label: c.name,
      align: 'center'
      // TODO 增加行转列排序支持，考虑属性加在哪个位置，可行性已验证
      // sortable: true
    })
  })
  return columns
}

export function buildWhereByFilters(whereParam: any = {}, eventWheres: any = []) {
  if (eventWheres.length === 0) {
    return undefined
  }
  whereParam.type = 'operate'
  const eventWhereLength = eventWheres.length
  if (eventWhereLength === 1) {
    whereParam.left = eventWheres[0].name
    whereParam.operator = eventWheres[0].operator
    whereParam.parameters = `'%${eventWheres[0].value}%'`
  } else if (eventWhereLength === 2) {
    whereParam.left = {
      type: 'operate',
      left: eventWheres[0].name,
      operator: eventWheres[0].operator,
      parameters: `'%${eventWheres[0].value}%'`
    }
    whereParam.operator = 'and'
    whereParam.parameters = [
      {
        type: 'operate',
        left: eventWheres[1].name,
        operator: eventWheres[1].operator,
        parameters: `'%${eventWheres[1].value}%'`
      }
    ]
  } else if (eventWhereLength > 2) {
    whereParam.operator = 'and'
    whereParam.parameters = [
      {
        type: 'operate',
        left: eventWheres[eventWhereLength - 1].name,
        operator: eventWheres[eventWhereLength - 1].operator,
        parameters: `'%${eventWheres[eventWhereLength - 1].value}%'`
      }
    ]
    eventWheres.splice(eventWhereLength - 1, 1)
    whereParam.left = buildWhereByFilters({}, eventWheres)
  }
  return whereParam
}
export function buildWhereByFiltersWithout(whereParam: any = {}, eventWheres: any = []) {
  if (eventWheres.length === 0) {
    return undefined
  }
  whereParam.type = 'operate'
  const eventWhereLength = eventWheres.length
  if (eventWhereLength === 1) {
    const item1 = eventWheres[0]
    whereParam.left = eventWheres[0].name
    whereParam.operator = eventWheres[0].operator
    whereParam.parameters = item1.nodot ? `${item1.value}` : `'${item1.value}'`
  } else if (eventWhereLength === 2) {
    const item1 = eventWheres[0]
    const item2 = eventWheres[1]
    whereParam.left = {
      type: 'operate',
      left: eventWheres[0].name,
      operator: eventWheres[0].operator,
      parameters: item1.nodot ? `${item1.value}` : `'${item1.value}'`
    }
    whereParam.operator = 'and'
    whereParam.parameters = [
      {
        type: 'operate',
        left: eventWheres[1].name,
        operator: eventWheres[1].operator,
        parameters: item2.nodot ? `${item2.value}` : `'${item2.value}'`
      }
    ]
  } else if (eventWhereLength > 2) {
    const itemn = eventWheres[eventWhereLength - 1]
    whereParam.operator = 'and'
    whereParam.parameters = [
      {
        type: 'operate',
        left: eventWheres[eventWhereLength - 1].name,
        operator: eventWheres[eventWhereLength - 1].operator,
        parameters: itemn.nodot ? `${itemn.value}` : `'${itemn.value}'`
      }
    ]
    eventWheres.splice(eventWhereLength - 1, 1)
    whereParam.left = buildWhereByFilters({}, eventWheres)
  }
  return whereParam
}
function dealFormat(operator: any, value: any) {
  if (operator === 'like') {
    return `'%${value}%'`
  }
  return `'${value}'`
}
export function buildWhereByFilters2(whereParam: any = {}, eventWheres: any = []) {
  if (eventWheres.length === 0) {
    return undefined
  }
  whereParam.type = 'operate'
  const eventWhereLength = eventWheres.length
  if (eventWhereLength === 1) {
    whereParam.left = eventWheres[0].name
    whereParam.operator = eventWheres[0].operator
    whereParam.parameters = dealFormat(eventWheres[0].operator, eventWheres[0].value)
  } else if (eventWhereLength === 2) {
    whereParam.left = {
      type: 'operate',
      left: eventWheres[0].name,
      operator: eventWheres[0].operator,
      parameters: dealFormat(eventWheres[0].operator, eventWheres[0].value)
    }
    whereParam.operator = 'and'
    whereParam.parameters = [
      {
        type: 'operate',
        left: eventWheres[1].name,
        operator: eventWheres[1].operator,
        parameters: dealFormat(eventWheres[1].operator, eventWheres[1].value)
      }
    ]
  } else if (eventWhereLength > 2) {
    whereParam.operator = 'and'
    whereParam.parameters = [
      {
        type: 'operate',
        left: eventWheres[eventWhereLength - 1].name,
        operator: eventWheres[eventWhereLength - 1].operator,
        parameters: dealFormat(
          eventWheres[eventWhereLength - 1].operator,
          eventWheres[eventWhereLength - 1].value
        )
      }
    ]
    eventWheres.splice(eventWhereLength - 1, 1)
    whereParam.left = buildWhereByFilters({}, eventWheres)
  }
  return whereParam
}

export function getSort(item: TableSort) {
  return {
    mode: item.order === 'ascending' ? 'ASC' : 'DESC',
    expression: {
      type: 'literal',
      value: item.prop
    }
  }
}

/**
 * 根据事件过滤条件构建API查询参数
 * @param eventWheres 事件过滤条件对象
 * @returns 构建的API查询参数对象
 */
export function buildApiQueryByEvent(eventWheres: any) {
  const where = {}
  Object.keys(eventWheres).forEach((key) => {
    const p = eventWheres[key]
    if (p.filterInsertCol && p.filterInsertCol.trim() !== '') {
      where[p.filterInsertCol] = p.value
    } else {
      where[p.filterProp] = p.value
    }
  })
  return where
}
