import { defineStore } from 'pinia'

export const useWorkflow = defineStore({
  id: 'workflow',
  getters: {
    // 是否编辑表单页面
    isEditFormPage: (state) => {
      return state.currentEditTab === 1
    },
    isActive: (state) => {
      return state.runStatus === 'ACTIVE'
    },
    isNodeListStatus: (state) => {
      return state.nodeListStatus
    }
  },
  state: (): WorkFlowStore => {
    return {
      currentEditTab: 1, // 当前编辑项
      runStatus: 'STOP', // 运行状态
      currentEditNode: {}, // 当前编辑节点
      showAttributeDrawer: false, // 是否显示属性面板
      formFields: [],
      nodeListStatus: false
    }
  },
  actions: {
    /**
     * 初始化工作流编辑工作状态
     */
    initWorkStatus() {
      this.currentEditTab = 1
      this.showAttributeDrawer = false
      this.formFields = []
      this.currentEditNode = {}
    },
    changeCurrentEditTab(tab: number) {
      this.currentEditTab = tab
    },
    changeRunStatus(status?: RUN_STATUS) {
      if (status) {
        this.runStatus = status
      } else {
        this.runStatus = this.runStatus === 'ACTIVE' ? 'STOP' : 'ACTIVE'
      }
    },
    changeShowAttributeDrawer(show: boolean) {
      this.showAttributeDrawer = show
      const ele = document.getElementById('workflowDesign')
      if (ele) {
        if (show) {
          ele.style.overflow = 'hidden'
        } else {
          ele.style.overflow = 'auto'
        }
      }
    },
    changeCurrentEditNode(node: any) {
      this.currentEditNode = node
    },
    changeFormFields(fields: FormField[]) {
      this.formFields = fields
    },
    changeNodeListStatus(status: boolean) {
      this.nodeListStatus = status
    }
  }
})
