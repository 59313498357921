import { defineAsyncComponent } from 'vue'
export const widgetComponents: any = {
  // 普通页面组件
  HHText: defineAsyncComponent(() => import('./text/HHText.vue')),
  HHGrid: defineAsyncComponent(() => import('./grid/HHGrid.vue')),
  HHSelectWidget: defineAsyncComponent(() => import('./select/HHSelectWidget.vue')),
  HHRefreshBtn: defineAsyncComponent(() => import('./refresh/HHRefreshBtn.vue')),
  HHInputWidge: defineAsyncComponent(() => import('./input/HHInputWidge.vue')),
  HHTextareaWidge: defineAsyncComponent(() => import('./textarea/HHTextareaWidge.vue')),
  HHSeparateWidge: defineAsyncComponent(() => import('./separate/HHSeparateWidge.vue')),
  HHRadioWidge: defineAsyncComponent(() => import('./radio/HHRadioWidge.vue')),
  HHCheckboxWidge: defineAsyncComponent(() => import('./checkbox/HHCheckboxWidge.vue')),
  HHCountWidge: defineAsyncComponent(() => import('./count/HHCountWidge.vue')),
  HHExportBtn: defineAsyncComponent(() => import('./export/HHExportBtn.vue')),
  HHScreenshotBtn: defineAsyncComponent(() => import('./screenshot/HHScreenshotBtn.vue')),
  HHBreadCrumb: defineAsyncComponent(() => import('./bread-crumb/HHBreadCrumb.vue')),
  HHButtonWidge: defineAsyncComponent(() => import('./button/HHButtonWidge.vue')),
  HHScrollWidge: defineAsyncComponent(() => import('./scroll/HHScrollWidge.vue')),
  HHImgWidge: defineAsyncComponent(() => import('./image/HHImgWidge.vue')),
  HHTable: defineAsyncComponent(() => import('./table/HHTable.vue')),
  HHDatetimePicker: defineAsyncComponent(() => import('./date-picker/HHDatetimePicker.vue')),
  HHTabPaneWidget: defineAsyncComponent(() => import('./panel/HHTabPaneWidget.vue')),
  HHTabsWidge: defineAsyncComponent(() => import('./tabs/HHTabsWidge.vue')),
  HHTurnPage: defineAsyncComponent(() => import('./turn-page/HHTurnPage.vue')),
  HHChainComparison: defineAsyncComponent(() => import('./chain-comparison/HHChainComparison.vue')),
  HHSortWidge: defineAsyncComponent(() => import('./sort/HHSortWidge.vue')),
  HHStatusChange: defineAsyncComponent(() => import('./status-change/HHStatusChange.vue')),
  HHMixChart: defineAsyncComponent(() => import('./chart/HHMixChart.vue')),
  HHSanKeyChart: defineAsyncComponent(() => import('./chart/HHSanKeyChart.vue')),
  HHScatterChart: defineAsyncComponent(() => import('./chart/HHScatterChart.vue')),
  HHMapChart: defineAsyncComponent(() => import('./chart/HHMapChart.vue')),
  HHHeatMapChart: defineAsyncComponent(() => import('./chart/HHHeatMap.vue')),
  HHCircleSortWidge: defineAsyncComponent(() => import('./sort/HHCircleSortWidge.vue')),
  HHJadeChart: defineAsyncComponent(() => import('./chart/HHJadeChart.vue')),
  HHProcess: defineAsyncComponent(() => import('./process/HHProcess.vue')),
  HHLiquidChart: defineAsyncComponent(() => import('./chart/HHLiquidChart.vue')),
  HHUpload: defineAsyncComponent(() => import('./upload/HHUpload.vue')),
  HHGraphChart: defineAsyncComponent(() => import('./chart/HHGraphChart.vue')),
  HHGaugeChart: defineAsyncComponent(() => import('./chart/HHGaugeChart.vue')),
  HHRadarChart: defineAsyncComponent(() => import('./chart/HHRadarChart.vue')),
  HHPieChart: defineAsyncComponent(() => import('./chart/HHPieChart.vue')),
  HHDropdown: defineAsyncComponent(() => import('./dropdown/HHDropdown.vue')),
  HHCustomQuery: defineAsyncComponent(() => import('./custom-query/HHCustomQuery.vue')),
  HHTransfer: defineAsyncComponent(() => import('./transfer/HHTransfer.vue')),
  HHRelationChart: defineAsyncComponent(() => import('./chart/HHRelationChart.vue')),
  HHAssetsWidge: defineAsyncComponent(() => import('./assets-down/HHAssetsWidge.vue')),
  // HHBase64: defineAsyncComponent(() => import('./HHBase64/HHBase64.vue')),
  HHTree: defineAsyncComponent(() => import('./tree/HHTree.vue')),
  HHStep: defineAsyncComponent(() => import('./standard-page/HHStep.vue')),
  // 大屏页面组件
  BigScreenBar: defineAsyncComponent(() => import('./big-screen/BigScreenBar.vue')),
  BigScreenLine: defineAsyncComponent(() => import('./big-screen/BigScreenLine.vue')),
  BigScreenPie: defineAsyncComponent(() => import('./big-screen/BigScreenPie.vue')),
  BigScreenHeaderTitle: defineAsyncComponent(() => import('./big-screen/BigScreenHeaderTitle.vue')),
  BigScreenScatter: defineAsyncComponent(() => import('./big-screen/BigScreenScatter.vue')),
  BigScreenTitle: defineAsyncComponent(() => import('./big-screen/BigScreenTitle.vue')),
  BigScreenMap: defineAsyncComponent(() => import('./big-screen/BigScreenMap.vue')),
  BigScreenContent: defineAsyncComponent(
    () => import('./big-screen/big-screen-content/BigScreenContent.vue')
  ),
  BigScreenHeatMap: defineAsyncComponent(() => import('./big-screen/BigScreenHeatMap.vue')),
  BigScreenFlop: defineAsyncComponent(
    () => import('./big-screen/big-screen-flop/BigScreenFlop.vue')
  ),
  BigScreenRank: defineAsyncComponent(() => import('./big-screen/BigScreenRank.vue')),
  BigScreenStrip: defineAsyncComponent(() => import('./big-screen/BigScreenStrip.vue')),
  BigScreenText: defineAsyncComponent(() => import('./big-screen/BigScreenText.vue')),
  BigScreenTime: defineAsyncComponent(() => import('./big-screen/BigScreenTime.vue')),
  BigScreenMarquee: defineAsyncComponent(
    () => import('./big-screen/big-screen-marquee/BigScreenMarquee.vue')
  ),
  BigScreenRelationChart: defineAsyncComponent(
    () => import('./big-screen/big-screen-relation-chart/BigScreenRelationChart.vue')
  ),
  BigScreenCarousel: defineAsyncComponent(
    () => import('./big-screen/big-screen-carousel/BigScreenCarousel.vue')
  ),
  BigScreenTabs: defineAsyncComponent(() => import('./big-screen/BigScreenTabs.vue')),
  BigScreenContentBg: defineAsyncComponent(() => import('./big-screen/BigScreenContentBg.vue')),
  BigScreenLiquidFill: defineAsyncComponent(() => import('./big-screen/BigScreenLiquidFill.vue')),
  BigScreenPanel: defineAsyncComponent(
    () => import('./big-screen/big-screen-panel/BigScreenPanel.vue')
  ),
  BigScreenImage: defineAsyncComponent(() => import('./big-screen/BigScreenImage.vue')),
  BigScreenJade: defineAsyncComponent(() => import('./big-screen/BigScreenJade.vue')),
  BigScreenThreeEarth: defineAsyncComponent(() => import('./3d/earth/Earth.vue')),
  BigScreenThreePictureList: defineAsyncComponent(
    () => import('./3d/BigScreenThreePictureList.vue')
  ),
  BigScreenThreeCircleCard: defineAsyncComponent(() => import('./3d/BigScreenThreeCircleCard.vue')),
  BigScreenLinearRegression: defineAsyncComponent(
    () => import('./big-screen/BigScreenLinearRegression.vue')
  ),
  BigScreenMixChart: defineAsyncComponent(() => import('./big-screen/BigScreenMixChart.vue')),
  BigScreenDynamicLine: defineAsyncComponent(() => import('./big-screen/BigScreenDynamicLine.vue')),
  BigScreenBaseRing: defineAsyncComponent(() => import('./big-screen/BigScreenBaseRing.vue')),
  BigScreenCarouselRing: defineAsyncComponent(
    () => import('./big-screen/BigScreenCarouselRing.vue')
  ),
  BigScreenPolychromeRose: defineAsyncComponent(
    () => import('./big-screen/BigScreenPolychromeRose.vue')
  ),
  BigScreenCarouselRank: defineAsyncComponent(
    () => import('./big-screen/BigScreenCarouselRank.vue')
  ),
  BigScreenFunnel: defineAsyncComponent(() => import('./big-screen/BigScreenFunnel.vue')),
  BigScreenBasicTriangle: defineAsyncComponent(
    () => import('./big-screen/BigScreenBasicTriangle.vue')
  ),
  BigScreenPictorial: defineAsyncComponent(() => import('./big-screen/BigScreenPictorial.vue')),
  BigScreenZebraBar: defineAsyncComponent(() => import('./big-screen/BigScreenZebraBar.vue')),
  BigScreenForecastLine: defineAsyncComponent(
    () => import('./big-screen/BigScreenForecastLine.vue')
  ),
  BigScreenUniversalForecast: defineAsyncComponent(
    () => import('./big-screen/BigScreenUniversalForecast.vue')
  ),
  BigScreenStackRose: defineAsyncComponent(() => import('./big-screen/BigScreenStackRose.vue')),
  BigScreenRatioRank: defineAsyncComponent(() => import('./big-screen/BigScreenRatioRank.vue')),
  BigScreenRankV2: defineAsyncComponent(() => import('./big-screen/BigScreenRankV2.vue')),
  BigScreenLevelRatio: defineAsyncComponent(() => import('./big-screen/BigScreenLevelRatio.vue')),
  BigScreenRectangleTree: defineAsyncComponent(
    () => import('./big-screen/BigScreenRectangleTree.vue')
  ),
  BigScreenChord: defineAsyncComponent(() => import('./big-screen/BigScreenChord.vue')),
  BigScreenCandlestick: defineAsyncComponent(() => import('./big-screen/BigScreenCandlestick.vue')),
  BigScreenTableLine: defineAsyncComponent(() => import('./big-screen/BigScreenTableLine.vue')),
  BigScreenCtrlLine: defineAsyncComponent(() => import('./big-screen/BigScreenCtrlLine.vue')),
  BigScreenDynamicsBar: defineAsyncComponent(() => import('./big-screen/BigScreenDynamicsBar.vue')),
  BigScreenRingPointBar: defineAsyncComponent(
    () => import('./big-screen/BigScreenRingPointBar.vue')
  ),
  BigScreenCapsule: defineAsyncComponent(() => import('./big-screen/BigScreenCapsule.vue')),
  BigScreenRose: defineAsyncComponent(() => import('./big-screen/BigScreenRose.vue')),
  BigScreenCarouselPie: defineAsyncComponent(() => import('./big-screen/BigScreenCarouselPie.vue')),
  BigScreenDoublePie: defineAsyncComponent(() => import('./big-screen/BigScreenDoublePie.vue')),
  BigScreenDonut: defineAsyncComponent(() => import('./big-screen/BigScreenDonut.vue')),
  BigScreenFerrisWheel: defineAsyncComponent(() => import('./big-screen/BigScreenFerrisWheel.vue')),
  BigScreenDoubleCarousel: defineAsyncComponent(
    () => import('./big-screen/BigScreenDoubleCarousel.vue')
  ),
  BigScreenTable: defineAsyncComponent(() => import('./big-screen/BigScreenTable.vue')),
  BigScreenIframe: defineAsyncComponent(() => import('./big-screen/BigScreenIframe.vue')),
  // 表单
  FormInput: defineAsyncComponent(() => import('./form-components/FormInput.vue')),
  FormTextarea: defineAsyncComponent(() => import('./form-components/FormTextarea.vue')),
  FormCount: defineAsyncComponent(() => import('./form-components/FormCount.vue')),
  FormDatetimePicker: defineAsyncComponent(
    () => import('./form-components/FormDatetimePicker.vue')
  ),
  FormRadio: defineAsyncComponent(() => import('./form-components/FormRadio.vue')),
  FormCheckbox: defineAsyncComponent(() => import('./form-components/FormCheckbox.vue')),
  FormSelect: defineAsyncComponent(() => import('./form-components/FormSelect.vue')),
  FormTitle: defineAsyncComponent(() => import('./form-components/FormTitle.vue')),
  FormTransfer: defineAsyncComponent(() => import('./form-components/FormTransfer.vue')),
  FormUpload: defineAsyncComponent(() => import('./form-components/FormUpload.vue')),
  HHIframe: defineAsyncComponent(() => import('./iframe/HHIframe.vue')),
  FormCondition: defineAsyncComponent(() => import('./form-components/FormCondition/index.vue')),
  FormTabs: defineAsyncComponent(() => import('./form-components/FormTabs.vue')),
  FormTabPane: defineAsyncComponent(() => import('./form-components/FormTabPane.vue')),
  FormStep: defineAsyncComponent(() => import('./form-components/FormStep.vue')),
  HHOlMap: defineAsyncComponent(() => import('./ol-map/HHOlMap.vue')),
  HHReport: defineAsyncComponent(() => import('./report/HHReport.vue'))
}
