<template>
  <div v-if="show" class="jump" :style="positionInfoStyle">
    <!-- <div class="jump-header">
      可跳转页面
      <i class="el-icon-circle-close close" @click="close"></i>
    </div> -->
    <ul class="page-ul">
      <li
        v-for="(page, index) in showPages"
        :key="index"
        class="page-ul-li"
        @click="jumpPage(page)"
      >
        {{ page.title }}
      </li>
    </ul>
    <div class="angle"></div>
  </div>
</template>
<script setup lang="ts">
  import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { useEvent, useProject } from '@haohan/clw-store'
  import { dialogEvent } from '@haohan/clw-utils'
  const eventStore = useEvent()
  const projectStore = useProject()
  const router = useRouter()
  const pageIds = ref<string[]>([])
  const showPages = ref<any[]>([])
  const positionInfoStyle = computed(() => {
    const { x, y } = eventStore.showMultipJumpInfo
    const st = document.getElementById('preview')!.scrollTop
    // console.log()
    return {
      top: `${y + st - 25}px`,
      left: `${x + 40}px`
    }
  })
  const show = computed(() => {
    const { isSame, sameNum } = eventStore.showMultipJumpInfo
    // return eventStore.showMultipJumpDialog
    return !!(
      eventStore.showMultipJumpDialog &&
      ((isSame !== undefined && !isSame) || (isSame && sameNum % 2 === 0))
    )
  })
  watch(
    () => eventStore.jumpPageIds,
    () => {
      if (eventStore.jumpPageIds.trim() !== '') {
        pageIds.value = eventStore.jumpPageIds.split(',')
        setJumpPages()
      }
    },
    { immediate: true, deep: true }
  )
  function setJumpPages() {
    const _showPages: any = []
    projectStore.pages.forEach((p) => {
      findShowPage(_showPages, p)
    })
    showPages.value = _showPages
  }
  function findShowPage(showPages: any, p: any) {
    if (pageIds.value.includes(p.id)) {
      showPages.push(p)
    }
    if (p.children && p.children.length > 0) {
      p.children.forEach((page: any) => {
        findShowPage(showPages, page)
      })
    }
  }
  function jumpPage(page: any) {
    eventStore.changeShowMultipJumpDialog(false)
    // 避免打开弹窗后，再点击表格列时，需要点击两次才能出现的问题
    eventStore.showMultipJumpInfo.sameNum = eventStore.showMultipJumpInfo.sameNum + 1
    if (eventStore.showMultipJumpType === 'dialog') {
      for (const key in eventStore.pageParams) {
        if (Object.hasOwnProperty.call(eventStore.pageParams, key)) {
          const ele = eventStore.pageParams[key]
          if (eventStore.jumpPageIds === ele.targetPageId) {
            dialogEvent.emit(key, page.id)
          }
        }
      }
    } else {
      let url = 'preview'
      if (page.variables && page.variables.pageType === 'bigScreen') {
        url = 'bigScreen'
      }
      router.push({
        path: `/${url}/${projectStore.currentProject.id}/${page.id}`
      })
    }
  }
  function close() {
    eventStore.changeShowMultipJumpDialog(false)
  }
  function hideJumpDialog(e: any) {
    if (e.target?.className !== 'page-ul-li') {
      eventStore.changeShowMultipJumpDialog(false)
    }
  }
  watch(
    () => eventStore.showMultipJumpDialog,
    () => {
      if (eventStore.showMultipJumpDialog) {
        // 解决跳转弹窗无法显示问题
        setTimeout(() => {
          window.addEventListener('click', hideJumpDialog)
        }, 300)
      } else {
        window.removeEventListener('click', hideJumpDialog)
      }
    },
    { deep: true }
  )
</script>
<style scoped lang="scss">
  .jump {
    position: absolute;
    background-color: var(--bg-color-tab);
    // padding: 10px;
    color: var(--font-color-content);
    font-size: 16px;
    z-index: 100;
    border-radius: 3px;
    min-width: 100px;
    padding: 20px 32px 20px 36px;
    font-size: 13px;
    border-radius: 8px;
    box-shadow: 0 0 4px 4px rgba($color: var(--border-color-base), $alpha: 1);
    .jump-header {
      font-size: 12px;
      height: 20px;
      line-height: 20px;
      position: relative;
      background-color: #0077de;
      color: #fff;
      padding: 0 5px;
      .close {
        position: absolute;
        right: 2px;
        width: 14px;
        height: 14px;
        cursor: pointer;
        top: 3px;
      }
    }
    .page-ul {
      list-style: none;
      margin: 0;
      padding: 0;
      & li {
        line-height: 30px;
        height: 30px;
        cursor: pointer;
        &:hover {
          color: var(--font-color-clickable);
        }
      }
    }
    .angle {
      position: absolute;
      top: 20px;
      left: -6px;
      width: 12px;
      height: 12px;
      box-shadow: -4px -4px 4px 0px rgba($color: var(--border-color-base), $alpha: 0.16);
      transform: rotate(-45deg);
      background-color: var(--bg-color-tab);
    }
  }
</style>
