<template>
  <div v-loading="loading" class="widget-wrapper" :style="box">
    <template v-if="showTemplate === 'type-1'">
      <div class="widget-header title">
        <div class="widget-header-title">{{ title }}</div>
        <div class="date-str-span">{{ showDataStr }}</div>
      </div>
      <div class="value-div" :style="{ color: prop?.valueColor }">
        {{ valueObj.currentValue }}
      </div>
      <div v-if="chainRatioValue" class="widget-footer">
        <span class="sub-title">较前一周环比</span>
        <span class="point-span sub-title" :class="getChainRatioI(valueObj.chainRatioValue)"
          ><svg-icon :icon-class="valueIcon" class="point-i" />{{ valueObj.chainRatioValue }}%</span
        >
      </div>
    </template>
    <template v-else>
      <div class="type-2-wrapper">
        <div class="type-2-layout-wrapper title">
          <div class="title-text-wrapper">{{ title }}</div>
          <div class="current-value-wrapper" :style="{ color: prop?.valueColor }">
            {{ valueObj.currentValue }}%
          </div>
        </div>
        <div v-if="chainRatioValue" class="type-2-layout-wrapper title">
          <div class="tip-text-wrapper sub-title"> 较前一周环比 </div>
          <div
            class="ratio-value-wrapper sub-title"
            :class="getChainRatioI(valueObj.chainRatioValue)"
          >
            <svg-icon :icon-class="valueIcon" class="point-i" />{{ valueObj.chainRatioValue }}%
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import CommonForGeneral from '../CommonForGeneral.vue'

  export default defineComponent({
    extends: CommonForGeneral,
    setup(props, context) {
      return {
        // 可以解决被继承组件中setup不执行问题
        ...CommonForGeneral.setup!(props, context)
      }
    },
    data() {
      return {}
    },
    computed: {
      showTemplate: function () {
        return this.prop?.showTemplate ? this.prop.showTemplate : 'type-1'
      },
      title: function () {
        return this.prop?.title
      },
      valueObj: function () {
        const result = {
          currentValue: 0,
          chainRatioValue: 0
        }
        if (this.data && this.data.content.length > 0) {
          result.currentValue = this.data.content[0][this.prop?.currentValue] || 0
          result.chainRatioValue = this.data.content[0][this.prop?.chainRatioValue] || 0
        }
        return result
      },
      valueIcon() {
        try {
          if (Number(this.valueObj.chainRatioValue) < 0) {
            return 'down-triangle'
          }
          return 'up-triangle'
        } catch (e: any) {
          return 'up-triangle'
        }
      },
      chainRatioValue() {
        return this.prop?.chainRatioValue
      },
      subTitleColor() {
        return this.prop?.subTitleColor
      },
      titleColor() {
        return this.prop?.titleColor
      },
      showDataStr() {
        if (this.queryRangs.length === 0) {
          return ''
        }
        return this.getQueryDayStr(this.queryRangs[0])
      }
    },
    methods: {
      getChainRatioI(value: number) {
        if (value < 0) {
          return {
            'down-color': true,
            'el-icon-caret-bottom': true
          }
        }
        return {
          'up-color': true,
          'el-icon-caret-top': true
        }
      },
      getQueryDayStr(rang: any) {
        if (rang.begin === rang.end) {
          return `${rang.begin.substring(4, 6)}-${rang.begin.substring(6, 8)}`
        }
        return `${rang.begin.substring(4, 6)}-${rang.begin.substring(6, 8)}至${rang.end.substring(
          4,
          6
        )}-${rang.end.substring(6, 8)}`

        // if (dateStr instanceof Array) {
        //   return `${dateStr[0].substring(
        //     dateStr[0].length - 5,
        //     dateStr[0].length
        //   )} 至 ${dateStr[1].substring(dateStr[1].length - 5, dateStr[1].length)}`
        // }
        // return dateStr.substring(dateStr.length - 5, dateStr.length)
      }
    }
  })
</script>

<style lang="scss" scoped>
  .widget-wrapper {
    /*border-radius: 4px;*/
    // border: 1px solid #e6ebf5;
    // background-color: var(--bg-color-card);
    overflow: hidden;
    color: var(--font-color-content);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    padding: 15px 10px 10px 10px;
    width: 100%;

    // -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .widget-header {
      font-size: 14px;
      display: flex;
      .widget-header-title {
        flex-grow: 1;
      }
    }
    .widget-footer {
      font-size: 12px;
      // & span {
      //   color: #9c9a9a;
      // }
      .point-span {
        display: block;
        float: right;
      }
    }
    .point-i {
      font-size: 12px;
      margin-right: 5px;
    }
    .date-str-span {
      // color: #9c9a9a;
      font-size: 12px;
      line-height: 20px;
    }
    .value-div {
      text-align: center;
      font-size: 28px;
      line-height: 70px;
    }
    .up-color {
      color: #32b067 !important;
    }
    .down-color {
      color: #dd2e3d !important;
    }
    .type-2-wrapper {
      .type-2-layout-wrapper {
        display: flex;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
        .title-text-wrapper {
          font-size: 14px;
        }
        .current-value-wrapper {
          font-size: 15px;
        }
        .tip-text-wrapper,
        .ratio-value-wrapper {
          font-size: 12px;
          // color: #9c9a9a;
        }
        .title-text-wrapper,
        .tip-text-wrapper {
          text-align: left;
        }
        .current-value-wrapper,
        .ratio-value-wrapper {
          text-align: right;
          flex-grow: 1;
        }
      }
    }
    // .title {
    //   color: var(--font-color-title);
    // }
    // .sub-title {
    //   color: var(--font-color-tip);
    // }
  }
</style>
