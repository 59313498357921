import router from './router'
import { delay, has, isEmpty } from 'lodash-es'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, setToken } from '@haohan/clw-utils'
import { menu, getMenu } from '@haohan/clw-api'
import { useUser, useProject, useEvent, useApp, useGlobalStore } from '@haohan/clw-store'
import { setClwPortalToken } from '@haohan/clw-utils'
import { ROUTE_PATH_CONSTANT } from '@/constant/route-path'

NProgress.configure({ showSpinner: false })
const ppath = ['/preview', '/bigscreen', '/sys', '/userinfo']
const homepath = [
  ROUTE_PATH_CONSTANT.Home,
  ROUTE_PATH_CONSTANT.BigScreen,
  ROUTE_PATH_CONSTANT.EditPage,
  ROUTE_PATH_CONSTANT.EditDB,
  ROUTE_PATH_CONSTANT.FormNavigation,
  ROUTE_PATH_CONSTANT.WorkFlow
]

router.beforeEach(async (to, from) => {
  NProgress.start()
  const user = useUser()
  const project = useProject()
  const event = useEvent()
  const globalStore = useGlobalStore()
  const app = useApp()
  const hasToken = getToken()
  const haspath = ppath.findIndex((el) => to.path.indexOf(el) !== -1)

  // 缓存处理时，去除 reload 参数
  if (has(to.query, 'reload')) {
    // 去除地址中的reload 参数
    delete to.query.reload
  }
  //  ---------- 401 start ----------
  if (to.path === '/401') {
    return true
  }
  if (haspath !== -1) {
    // 从 url 设置 token
    const queryToken = to?.query?.token || ''
    let pageUnAuth = false
    if (queryToken) {
      await user
        .checkToken(String(queryToken))
        .then(async (res) => {
          try {
            // !这里动态设置了 token, 但是无法更新到变量 hasToken, 所以下面的需要 getToken() 不能直接用 hasToken
            setToken(String(queryToken))
            await user.getInfo()
            // 获得环境信息
            globalStore.getEnvInfo()
            if (isEmpty(user.regions)) {
              await user.getRegionInfo()
            }
            if (to.params) {
              await project.getProjectInfo(<string>to.params.projectId)
              event.loadCommonParameters(project.currentProject)
              event.initAllPageDialogEvent()
            }
            // 去除 url 的 token 并刷新
            const queryString = location.href.split('?')[1]
            if (queryString) {
              const queryParams = new URLSearchParams(queryString)
              queryParams.delete('token')
              const updatedQueryString = queryParams.toString()
              const updatedUrl =
                location.href.split('?')[0] + (updatedQueryString ? `?${updatedQueryString}` : '')
              // 重新访问
              setTimeout(() => {
                window.location.href = updatedUrl
              }, 1000)
            }
          } catch (e) {
            pageUnAuth = true
            to.query.token = ''
          }
        })
        .catch(() => {
          pageUnAuth = true
        })

      if (pageUnAuth) {
        return {
          path: '/401',
          query: { hideMenu: to?.query?.hideMenu === 'true' ? 'true' : 'false' }
        }
      }
    }
  }
  //  ---------- 401 end ----------

  // event.changeShowMultipJumpDialog(false)
  if (haspath !== -1) {
    // 需要实时获取
    if (getToken()) {
      if (to.path === '/login') {
        return { path: '/' }
      }
      if (user.anonymous) {
        try {
          await user.getInfo()
          // 获得环境信息
          globalStore.getEnvInfo()
          if (isEmpty(user.regions)) {
            await user.getRegionInfo()
          }
          // console.log('to', to)
          if (to.params) {
            await project.getProjectInfo(<string>to.params.projectId)
            event.loadCommonParameters(project.currentProject)
            event.initAllPageDialogEvent()
          }
        } catch (e) {
          return { path: '/login?redirect=' + to.path }
        }
      }
      if (user.isGranted(to)) {
        if (!project.currentProject.id && to.params.projectId) {
          await project.getProjectInfo(<string>to.params.projectId)
          event.loadCommonParameters(project.currentProject)
          event.initAllPageDialogEvent()
        }
        return true
      }
      return { path: '/404' }
    }
    /* has no token*/
    if (to.meta && to.meta.anonymous) {
      // 允许匿名登录
      return true
    }
    console.log('1', hasToken, getToken())
    return `/login?redirect=${to.path}`
    // 需要实时获取
  } else if (getToken()) {
    try {
      // 不重复请求用户信息数据
      if (!user.current.id) {
        await user.getInfo()
        // 获得环境信息
        globalStore.getEnvInfo()
      }
      if (isEmpty(user.regions)) {
        await user.getRegionInfo()
      }
    } catch (e) {
      return false
    }
    if (to.fullPath.indexOf('/login') > -1) {
      // return false
      // TODO: 7.28 添加, 修复登录后界面停留在 /#/login 白屏
      return { path: '/' }
    }

    // 动态添加的预览页面路由, 刷新浏览器, 会 404, 需要判断 redirectedFrom
    if (
      (project?.previewKeepAliveRoute?.isOpenKeepAlive || sessionStorage.getItem('isKeepAlive')) &&
      from?.path === '/' &&
      to?.path?.includes('/404')
    ) {
      // redirectedFrom
      const beforeRoute = to?.redirectedFrom
      if (beforeRoute) {
        // pathMatch: ['preview_787311532395597824', '785579418222465024', '787311532395597824']
        if (beforeRoute?.params?.pathMatch?.length) {
          const keyList = beforeRoute?.params?.pathMatch
          const bProjectId = keyList[1]
          const bPageId = keyList[2]
          console.log('beforeRoute========', beforeRoute)
          if (bPageId) {
            return {
              name: 'previewLayout',
              // 这里 reload: 1 到 layout.vue 触发 location.reload() 加载页面信息
              params: { projectId: bProjectId, pageId: bPageId },
              query: { ...beforeRoute.query, reload: 1 },
              replace: true
            }
          }
        }
      }
    }

    // 保证当前必须打开某个项目
    if (homepath.includes(to.path) && !project.currentProject.id) {
      return { path: '/dashboard' }
    }
    return true
    // if (to.path === '/home' || to.path === '/dashboard') {
    //   return true
    // }
    // if (user.isGranted(to)) {
    //   if (project.currentProject && project.currentProject.id) {
    //     return true
    //   }
    //   return { path: '/' }
    // }
    // return { path: '/' }
  }
  console.log('2')
  if (to.path.indexOf('portal') !== -1) {
    setClwPortalToken()
    return true
  } else if (to.path !== '/login') {
    console.log('22')
    return { path: '/login' }
  }
  return true
})

router.afterEach((to, from) => {
  const hasToken = getToken()
  NProgress.done()
  if (to.path !== '/login' && hasToken) {
    // delay(() => {
    //   if (to.name === '/preview') {
    //     getMenu(<string>to.params.projectId, <string>to.params.pageId).then((response) => {
    //       menu(to.path, response.data.title)
    //     })
    //   } else {
    //     menu(to.path, (to.meta && to.meta.title) || to.name)
    //   }
    // }, 0)
  }
})
