<template>
  <div>
    <el-dialog
      v-model="dialogVisible"
      width="900"
      class="to-do-message-dialog"
      destroy-on-close
      @close="close"
    >
      <div class="header">
        <div v-if="diaLogRow.flowInst?.startUserAvatar"
          ><el-avatar :size="42" :src="diaLogRow.flowInst?.startUserAvatar"
        /></div>
        <!-- <div v-else class="avatar"><el-avatar :size="42" :src="avatar" /></div> -->
        <div
          v-else
          class="avatar"
          :style="{ '--bg': getRandomColor(diaLogRow.flowInst?.startUsername) }"
        >
          <span class="avatar-text">{{
            extractCharacters(diaLogRow.flowInst?.startUsername)
          }}</span>
        </div>
        <div class="name public">
          {{ diaLogRow.flowInst?.startUsername }}
        </div>
        <div class="desc">发起的</div>
        <div class="public">{{ diaLogRow.flowDef?.name }}</div>
      </div>
      <div class="main">
        <div class="card">
          <div class="card-desc">当前状态</div>
          <div class="tag">
            <el-tag type="warning" effect="light" round size="large" disable-transitions>
              {{ '进行中' }}
            </el-tag>
          </div>
        </div>
        <div class="card">
          <div class="card-desc">提交人</div>
          <div class="text">{{ diaLogRow.flowInst?.startUsername }}</div>
        </div>
        <div class="card">
          <div class="card-desc">提交时间</div>
          <div class="text">{{ diaLogRow.flowInst?.createTime }}</div>
        </div>
      </div>
      <div class="content">
        <div class="left">
          <div class="form-title">
            <span class="form-text">表单内容</span>
          </div>
          <div class="form-list">
            <WorkflowFormPage
              ref="workflowFormPageRef"
              :widget-data="widgetData"
              :page="page"
              :workflow="workflow"
              :show-opr-button="false"
              :formvalue="formValue"
              class="form-item-wrapper"
            ></WorkflowFormPage>
            <!-- <div v-for="list in 10" :key="list" class="form-item-wrapper">
              <div class="form-item">请假人 </div>
              <div class="form-value">小月</div>
            </div> -->
            <!-- <div
              v-for="(value, key, index) in diaLogRow.flowInst?.variables"
              :key="index"
              class="form-item-wrapper"
            >
              <div class="form-item">{{ key }} </div>
              <div class="form-value">{{ value }}</div>
            </div> -->
          </div>
        </div>
        <div class="right">
          <div class="tip">
            <div class="title">流程进度</div>
            <div>
              <el-button type="primary" text plain @click="showFlow = !showFlow">
                完整流程
              </el-button></div
            >
          </div>
          <div class="progress-wrapper">
            <FlowTimeLine v-show="showFlow" :list-array="listArray" />
            <img v-show="!showFlow" class="img" :src="currentProcessImg" alt="" />
          </div>
        </div>
      </div>
      <div class="footer">
        <template v-if="diaLogRow.settings?.type === 'APPROVED'">
          <!-- 审批- 同意拒绝按钮 -->
          <el-button type="primary" @click="handleAgree">
            {{ formBtnProp.confirmText }}
          </el-button>
          <el-button class="cancle" plain @click="handleReject">{{
            formBtnProp.cancelText
          }}</el-button>
        </template>
        <template v-else-if="diaLogRow.settings?.type === 'WRITE'">
          <!-- 填写- 填写取消按钮 -->
          <el-button type="primary" @click="handleWrite">
            {{ formBtnProp.confirmText }}
          </el-button>
          <el-button class="cancle" plain @click="close">{{ formBtnProp.cancelText }}</el-button>
        </template>
      </div>
    </el-dialog>
  </div>
</template>
<script lang="ts">
  export default {
    name: 'ToDoMessageDiaLog'
  }
</script>
<script setup lang="ts">
  import avatar from '../../../assets/images/avatar.png'
  import { ref, computed, reactive } from 'vue'
  import FlowTimeLine from '../../../components/FlowTimeLine/FlowTimeLine.vue'
  import { useProject } from '@haohan/clw-store'
  import {
    queryTasks,
    queryWorkflowItemImgReq,
    addWrite,
    getMenu,
    rejectFlowTask,
    acceptFlowTask
  } from '@haohan/clw-api'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { WorkflowFormPage } from '@haohan/clw-components'

  const projectStore = useProject()
  const projectId = computed(() => {
    return projectStore.currentProject?.id
  })

  const emit = defineEmits(['writeDialog', 'showRejectApprovalDiaLog', 'showAgreeApprovalDiaLog'])
  const dialogVisible = ref(false)
  const diaLogRow = ref({}) as any

  //是否显示完整流程
  const showFlow = ref(true)

  const listArray = ref([])
  // 工作流页面
  const workflowFormPageRef = ref()

  const workflow = ref({
    id: '',
    prop: { formBtnProp: {}, formLimit: {} }
  })
  const page = ref({})
  const widgetData = reactive({
    list: []
  })
  // 表单对象，回显表单值
  const formValue = ref({})
  /**
   * 打开弹框
   */
  async function open(row) {
    diaLogRow.value = row

    // 处理表单对象值
    formValue.value = row.processVariable
    // 组织当前工作流信息
    workflow.value.id = row.id
    workflow.value.prop.formBtnProp = row.settings.buttonStyle
    workflow.value.prop.formLimit = row.settings.formLimit
    // 查询当前页面
    const _page = await getMenu(projectId.value, row.menuId)
    page.value = _page.data
    widgetData.list = _page?.data?.page?.components || []
    const params = {
      page: 1,
      size: 10000
    }
    queryTasks(projectId.value, row.flowInstId, params).then((res: any) => {
      // console.log(res)

      // if (row.comment?.assigneeUserAvatar) {
      // }
      listArray.value = res.data.data
        .filter((r: any) => r.finishTime !== null)
        .map((item: any) => {
          let listAvatarName
          if (!item.assigneeUserName) {
            if (item.settings.type === 'SUBMIT') {
              listAvatarName = '提交'
            } else if (item.settings.type === 'CC') {
              listAvatarName = '抄送'
            } else if (item.settings.type === 'UPDATE_DATA') {
              listAvatarName = '更新数据'
            } else if (item.settings.type === 'INSERT_DATA') {
              listAvatarName = '插入数据'
            }
          }
          // SUBMIT, //申请
          //   APPROVED, //审批
          //   WRITE, //填写
          //   CC, //抄送
          //   INSERT_DATA, //插入数据
          //   UPDATE_DATA //更新数据
          let type
          if (item.settings.type === 'SUBMIT') {
            type = '提交'
          } else if (item.settings.type === 'APPROVED') {
            type = '审批'
          } else if (item.settings.type === 'WRITE') {
            type = '填写'
          } else if (item.settings.type === 'CC') {
            type = '抄送'
          } else if (item.settings.type === 'UPDATE_DATA') {
            type = '更新数据'
          } else if (item.settings.type === 'INSERT_DATA') {
            type = '插入数据'
          }
          if (item.comment?.type === '终止') {
            type = '终止'
          }
          if (
            item.comment?.type === '插入数据节点异常' ||
            item.comment?.type === '修改数据节点异常'
          ) {
            type = item.comment?.type
          }
          return {
            name: item.assigneeUserName,
            avatarName: item.assigneeUserName ? item.assigneeUserName : listAvatarName,
            finishTime: item.finishTime,
            content: item.comment?.comment,
            avatar: item.comment?.assigneeUserAvatar,
            type: type
          }
        })
      queryImg()
      dialogVisible.value = true
      showFlow.value = true
    })
  }
  //查询流程图片
  const currentProcessImg = ref()
  async function queryImg() {
    const respImg = await queryWorkflowItemImgReq(projectId.value, diaLogRow.value.flowDef?.id)
    currentProcessImg.value = respImg.data
  }

  interface IFormBtnProp {
    cancelText: string
    confirmText: string
  }
  const formBtnProp = computed(() => {
    return Object.keys(workflow.value).length === 0
      ? {
          cancelText: '取消',
          confirmText: '确定'
        }
      : (workflow.value.prop.formBtnProp as IFormBtnProp)
  })

  /**
   * 2024-07-31  合并按钮修改及弹框统一修改
   */
  //填写按钮
  async function handleWrite() {
    const formValueObj = await workflowFormPageRef.value.getFormValue()
    // console.log('formValueObj', formValueObj)
    const params = {
      comment: '',
      variables: formValueObj
    }
    const res = await addWrite(projectId.value, diaLogRow.value.id, params)
    if (res.status === 200) {
      ElMessage.success('提交成功')
      close()
      emit('writeDialog')
    }
  }
  //同意按钮
  async function handleAgree() {
    const row = diaLogRow.value
    console.log(row)
    const isQuick = row.settings?.remark
    const formValueObj = await workflowFormPageRef.value.getFormValue()

    if (!isQuick) {
      ElMessageBox.confirm(`您确认要同意“${row.flowDef?.name}”吗？`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success'
      }).then(async () => {
        const params = {
          comment: '',
          variables: formValueObj
        }
        const res = await acceptFlowTask(projectId.value, row.id, params)
        if (res.status === 200) {
          ElMessageBox.alert('同意审批完成！', '', {
            confirmButtonText: '确认',
            type: 'success'
          })
          emit('writeDialog')
          close()
        }
      })
    } else {
      emit('showAgreeApprovalDiaLog', diaLogRow.value, formValueObj)
      close()
    }
  }
  //拒绝按钮
  function handleReject() {
    const row = diaLogRow.value
    console.log(row)
    const isQuick = row.settings?.remark
    if (!isQuick) {
      ElMessageBox.confirm(`您确认要拒绝“${row.flowDef?.name}”吗?`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          comment: ''
        }
        const res = await rejectFlowTask(projectId.value, row.id, params)
        if (res.status === 200) {
          ElMessageBox.alert('拒绝审批完成！', '', {
            confirmButtonText: '确认',
            type: 'success'
          })
          emit('writeDialog')
          close()
        }
      })
    } else {
      emit('showRejectApprovalDiaLog', diaLogRow.value)
      close()
    }
  }
  /**
   * 关闭弹窗
   */
  function close() {
    dialogVisible.value = false
  }
  const colors = ['#0176DE', '#4BCA81', '#9D7CE6', '#0DA1FD', '#EB85C1', '#FDAC23', '#DB492E']
  function getRandomColor(inputChar: string) {
    const hash = inputChar.charCodeAt(0)
    const colorIndex = hash % colors.length
    return colors[colorIndex]
  }
  function extractCharacters(inputStr: string) {
    // 判断是否包含中文字符
    if (/[\u4e00-\u9fff]/.test(inputStr)) {
      // 取后两位
      return inputStr.slice(-2)
    }
    // 取前五位
    return inputStr.slice(0, 5)
  }
  defineExpose({ open, close })
</script>

<style lang="scss">
  .to-do-message-dialog {
    .el-dialog__close {
      color: #0076de;
    }
    .el-dialog__body {
      // padding-top: 0;
      // padding-bottom: 0;
      padding: 0 40px 40px 40px;
    }
  }
</style>

<style lang="scss" scoped>
  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    border-radius: 50% 50%;
    background-color: var(--bg, antiquewhite);
    color: #ffffff;
    font-size: 12px;
  }
  .header {
    display: flex;
    align-items: center;
    .name {
      margin-left: 12px;
    }
    .desc {
      margin: 0 8px;
      font-size: 14px;
      color: #606266;
    }
    .public {
      font-weight: 600;
      font-size: 14px;
      color: #1a1d1f;
    }
  }
  .main {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .card {
      .card-desc {
        font-weight: 600;
        font-size: 16px;
        color: #1a1d1f;
      }
      .tag {
        margin-top: 12px;
        :deep() {
          .el-tag {
            padding: 0 31px;
          }
        }
      }
      .text {
        margin-top: 24px;
        font-size: 14px;
        color: #606266;
      }
    }
  }
  .content {
    margin-top: 20px;
    display: flex;
    gap: 50px;
    .left {
      width: 412px;
      height: 417px;
      background: #ffffff;
      box-shadow: 0px 0px 13px 1px rgba(213, 213, 213, 0.46);
      border-radius: 20px 20px 20px 20px;
      .form-title {
        width: 412px;
        height: 50px;
        background: #0076de;
        border-radius: 20px 20px 0px 0px;
        line-height: 50px;
        .form-text {
          padding-left: 30px;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
        }
      }
      .form-list {
        box-sizing: border-box;
        padding: 0 42px 26px 42px;
        height: 367px;
        overflow-y: auto;
        .form-item-wrapper {
          margin-top: 26px;
        }
        .form-item {
          font-weight: 600;
          font-size: 14px;
          color: #1a1d1f;
        }
        .form-value {
          margin-top: 8px;
          font-size: 14px;
          color: #606266;
        }
      }
    }
    .right {
      width: 362px;
      height: 417px;
      background: #ffffff;
      box-shadow: 0px 0px 13px 1px rgba(213, 213, 213, 0.62);
      border-radius: 20px 20px 20px 20px;
      .tip {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 28px 30px 14px 42px;
        box-sizing: border-box;
        height: 74px;
        .title {
          font-weight: 600;
          font-size: 16px;
          color: #1a1d1f;
        }
      }
      .progress-wrapper {
        height: 340px;
        overflow-y: auto;
        padding-right: 8px;
        padding-left: 8px;
        padding-top: 8px;
        .img {
          text-align: center;
          width: 100%;
          height: 330px;
        }
      }
    }
  }
  .footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .cancle {
      margin-left: 70px;
    }
  }
</style>
