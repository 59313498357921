import { http } from '@haohan/clw-utils'

const URL = '/api/v1/projects'

/**
 * 查询工作流列表
 * @param projectId 项目ID
 * @param data 查询参数
 * @returns
 */
export const queryWorkflow = (projectId: string, data: any): any => {
  return http.request({
    url: `${URL}/${projectId}/flowDefine/query`,
    data,
    method: 'POST'
  })
}
/**
 * 创建或更新工作流
 * @param projectId 项目ID
 * @param data 需要保存的工作流
 * @returns
 */
export const createOrUpdateWorkflow = (projectId: string, data: any, id: string): any => {
  if (id === null) {
    return http.request({
      url: `${URL}/${projectId}/flowDefine`,
      data,
      method: 'POST'
    })
  }
  return http.request({
    url: `${URL}/${projectId}/flowDefine/${id}`,
    data,
    method: 'PUT'
  })
}

/**
 * 更新工作流
 * @param projectId 项目ID
 * @param data 需要保存的工作流
 * @returns
 */
export const updateWorkflow = (projectId: string, data: any): any => {
  return http.request({
    url: `${URL}/${projectId}/flowDefine/${data.id}`,
    data,
    method: 'POST'
  })
}
/**
 * 删除工作流
 * @param projectId 项目ID
 * @param workflowId 工作流ID
 * @returns
 */
export const deleteWorkflow = (projectId: string, workflowId: string) => {
  return http.request({
    url: `${URL}/${projectId}/flowDefine/${workflowId}`,
    method: 'DELETE'
  })
}
/**
 * 工作流定义-详情
 * @param projectId 项目ID
 * @param menuId 菜单ID
 * @returns
 */
export function getWorkflowInfo(projectId: string, menuId: string) {
  return http.request({
    method: 'GET',
    url: `${URL}/${projectId}/flowDefine/${menuId}`,
    headers: {
      'custom-error-handler': 'true'
    }
  })
}

/**
 * 更新工作流定义状态
 * @param projectId 项目ID
 * @param workflowId 工作流ID
 * @param status 状态
 * @returns
 */
export function changeWorkflowDefineStatus(projectId: string, workflowId: string, status: string) {
  return http.request({
    method: 'GET',
    url: `${URL}/${projectId}/flowDefine/${workflowId}/${status.toLocaleLowerCase()}`
  })
}

/**
 * 通过菜单ID删除工作流
 * @param projectId 项目ID
 * @param menuId 页面ID
 * @returns
 */
export const deleteWorkflowByMenuId = (projectId: string, menuId: string) => {
  return http.request({
    url: `${URL}/${projectId}/flowDefine/menu/${menuId}`,
    method: 'DELETE'
  })
}

/**
 * 工作流定义-列表
 */
export const queryWorkflowListReq = (pid: string, params: any) => {
  return http.request({
    url: `${URL}/${pid}/flowDefine/query`,
    method: 'POST',
    data: params
  })
}
/**
 * 工作流定义-详情
 */
export const queryWorkflowItemReq = (pid: string, id: string) => {
  return http.request({
    url: `${URL}/${pid}/flowDefine/${id}`,
    method: 'GET'
  })
}
/**
 * 工作流定义-图片
 */
export const queryWorkflowItemImgReq = (pid: string, id: string) => {
  return http.request({
    url: `${URL}/${pid}/flowDefine/${id}/image`,
    method: 'GET'
  })
}
/**
 * 工作流定义-激活
 */
export const runWorkflowItemReq = (pid: string, id: string) => {
  return http.request({
    url: `${URL}/${pid}/flowDefine/${id}/active`,
    method: 'GET'
  })
}
/**
 * 工作流定义-停止
 */
export const stopWorkflowItemReq = (pid: string, id: string) => {
  return http.request({
    url: `${URL}/${pid}/flowDefine/${id}/stop`,
    method: 'GET'
  })
}
/**
 * 工作流角色-查询
 */
export const queryWorkflowRoleListReq = (pid: string, flowDefId: string, params: any) => {
  return http.request({
    url: `${URL}/${pid}/flowDefine/${flowDefId}/roles/query`,
    method: 'POST',
    data: params
  })
}
/**
 * 工作流角色-修改（分配用户）
 */
export const editWorkflowRoleItemReq = (pid: string, flowDefId: string, id: any, params: any) => {
  return http.request({
    url: `${URL}/${pid}/flowDefine/${flowDefId}/roles/${id}`,
    method: 'PUT',
    data: params
  })
}
/**
 * 开始工作流
 * @param projectId 项目ID
 * @param workflowId 工作流ID
 * @param data 查询参数
 * @returns
 */
export const startWorkflow = (projectId: string, workflowId: string, data: any): any => {
  return http.request({
    url: `${URL}/${projectId}/flowDefine/${workflowId}/start`,
    method: 'POST',
    data,
    headers: {
      'custom-error-handler': 'true'
    }
  })
}
/**
 * 工作流定义-重命名
 */
export const renameWorkflowItemReq = (projectId: string, workflowId: string, data: any): any => {
  return http.request({
    url: `${URL}/${projectId}/flowDefine/rename/${workflowId}`,
    method: 'PUT',
    data
  })
}
