<template>
  <template v-for="(item, index) in props.menuData" :key="index">
    <el-sub-menu
      v-if="item.children && item.children.length > 0 && !item.meta.hidden"
      :key="item.path"
      :index="item.path"
    >
      <template #title>
        <svg-icon v-if="verticalMenuType !== 'false'" :icon-class="getMenuSvgClass(item)" />
        <el-tooltip
          v-if="item.meta.title.length > 8 && verticalMenuType !== 'false'"
          class="item"
          effect="dark"
          :content="item.meta.title"
          placement="right-start"
        >
          <div class="menu-title"> {{ item.meta.title }}</div>
        </el-tooltip>
        <div v-else class="menu-title"> {{ item.meta.title }}</div>
      </template>
      <MenuItem :menu-data="item.children"></MenuItem>
    </el-sub-menu>
    <template v-else>
      <el-menu-item
        v-if="!item.meta.hidden"
        :key="item.id"
        :index="item.path"
        :disabled="item.disabled"
      >
        <svg-icon v-if="verticalMenuType !== 'false'" :icon-class="getMenuSvgClass(item)" />
        <template #title>
          <el-tooltip
            v-if="item.meta.title.length > 8 && verticalMenuType !== 'false'"
            class="item"
            effect="dark"
            :content="item.meta.title"
            placement="right-start"
          >
            <span class="menu-title"> {{ item.meta.title }}</span>
          </el-tooltip>
          <span v-else class="menu-title"> {{ item.meta.title }}</span>
        </template>
      </el-menu-item>
    </template>
  </template>
</template>

<script lang="ts">
  export default {
    name: 'MenuItem'
  }
</script>
<script lang="ts" setup>
  import { computed } from 'vue'
  import { useProject } from '@haohan/clw-store'
  import MenuItem from './MenuItem.vue'

  const project = useProject()
  const verticalMenuType = computed(() => project.currentProject?.settings?.verticalMenu)
  const props = defineProps({
    menuData: {
      type: Array,
      default: [] as any
    }
  })

  //兼容老项目没有icon字段问题
  const getMenuSvgClass = (item: any) => {
    if (item?.meta?.icon) {
      return item?.meta?.icon
    }
    if (item.type === 'MENU') {
      // 文件夹
      return 'folder'
    }
    // 大屏
    if (item.type === 'bigScreen') {
      return 'big-screen-page'
    }
    // 表单
    if (item.type === 'form') {
      return 'form'
    }
    // 文件
    return 'file'
  }
</script>

<style lang="scss" scoped>
  .menu-title {
    margin-left: 10px;
    width: 100%; /* 或者指定一个具体的宽度 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .el-menu--collapse > .el-sub-menu > .el-sub-menu__title > .menu-title {
    display: none !important;
  }
</style>
