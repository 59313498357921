<template>
  <el-dialog
    v-model="visible"
    class="workflow-tip-dialog"
    width="570px"
    :append-to-body="true"
    :close-on-click-modal="false"
    destroy-on-close
    :show-close="false"
  >
    <div class="body">
      <svg-icon v-if="info!.submitLimit >= 0 && userLimit < 0" icon-class="invalid" />
      <svg-icon v-else icon-class="valid" />
      {{ txt }}
    </div>
    <div class="footer">
      <el-button v-if="isMore" type="primary" @click="again"> 再填一份 </el-button>
      <el-button class="btn cancle" @click="goList"> 返回列表 </el-button>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
  import { ref, computed } from 'vue'
  import { useRouter, useRoute } from 'vue-router'

  const props = defineProps<{
    info?: {
      instanceCount: number // 工作流已提交次数
      userInstanceCount: number // 当前用户已提交工作流次数
      submitLimit: number // 工作流限制次数
      userSubmitLimit: number // 当前用户限制提交次数
      name: string
    }
  }>()
  const emits = defineEmits(['again'])

  const router = useRouter()
  const route = useRoute()

  const visible = ref(false)
  const limit = computed(() => props.info!.submitLimit - (props.info!.instanceCount + 1))
  const userLimit = computed(
    () => props.info!.userSubmitLimit - (props.info!.userInstanceCount + 1)
  )
  const isMore = computed(() => {
    const { submitLimit, userSubmitLimit } = props.info!
    return (submitLimit < 0 && userSubmitLimit < 0) || userLimit.value > 0
  })
  const txt = computed(() => {
    const { submitLimit, userSubmitLimit, name } = props.info!
    if (submitLimit < 0 || limit.value >= 0) {
      if (userSubmitLimit < 0 || userLimit.value > 0) {
        return `您的“${name}”提交成功！${
          userLimit.value > 0 ? `（根据流程设置，您最多还可申请${userLimit.value}次）` : ''
        }`
      } else if (userLimit.value === 0) {
        return `您的“${name}”提交成功！（根据流程设置，您已达到申请次数上限）`
      }
      return `根据流程设置，您已达到申请次数上限。无法再次申请！`
    }
    return '抱歉，根据系统设置，该流程的申请次数已用尽，您无法提交申请！'
  })

  function again() {
    emits('again')
  }
  function goList() {
    router.push({
      name: 'UserMessage',
      params: { projectId: route.params.projectId, pageId: route.params.pageId }
    })
  }
  function open() {
    visible.value = true
  }
  function close() {
    visible.value = false
  }

  defineExpose({ open, close })
</script>
<style lang="scss" scoped>
  .workflow-tip-dialog {
    border-radius: 20px;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .body {
      margin-bottom: 30px;
      padding-left: 63px;
      .svg-icon {
        font-size: 28px;
        margin-right: 12px;
      }
      display: flex;
      align-items: center;
    }
    .footer {
      display: flex;
      justify-content: center;
      gap: 53px;
      .el-button {
        width: 126px;
        margin: 0;
        height: 36px;
      }
    }
  }
</style>
