import { useProject } from '@haohan/clw-store'

const colorMap = new Map([
  [
    'white',
    {
      bg: '#fff', //背景颜色
      itemBg: '#F7F7F7', //菜单项背景颜色
      active: '#0176DE', //激活颜色
      activeBg: '#EBF4FD', //激活背景颜色
      text: '#000', //文字颜色
      border: 'linear-gradient(0deg, #F7F7F7 0%, #E5E5E5 100%)' //边框颜色
    }
  ],
  [
    'light',
    {
      bg: 'linear-gradient( 137deg, #2379E8 0%, #0E329B 100%)',
      itemBg: '#195AB9',
      active: '#fff',
      activeBg: '#437DD5',
      text: '#D6DAE1',
      border: 'linear-gradient(0deg, #FFFFFF24 0%, #00000024 100%)'
    }
  ],
  [
    'dark',
    {
      bg: 'linear-gradient( 129deg, #0B2740 0%, #020C15 100%)',
      itemBg: '#05121E',
      active: '#fff',
      activeBg: '#31445C',
      text: '#B2BFD1',
      border: 'linear-gradient(0deg, #1F3042 0%, #000000 100%)'
    }
  ]
])

//根据当前项目配置，返回菜单背景色和文字色
export function setMenuColor() {
  const styleInstance = document.documentElement.style
  const { currentProject } = useProject()
  const menuBgType = currentProject.settings?.menuBg || 'white'
  const color = colorMap.get(menuBgType)
  if (color) {
    styleInstance.setProperty(`--custom-menu-bg-color`, color.bg)
    styleInstance.setProperty(`--custom-menu-item-bg-color`, color.itemBg)
    styleInstance.setProperty(`--custom-menu-text-color`, color.text)
    styleInstance.setProperty(`--custom-menu-active-color`, color.active)
    styleInstance.setProperty(`--custom-menu-active-bg-color`, color.activeBg)
    styleInstance.setProperty(`--custom-menu-border-color`, color.border)
  }
}
