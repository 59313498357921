import { reactive, watchEffect, computed } from 'vue'
import {
  filter,
  forEach as ForEach,
  find,
  isString,
  hasIn,
  isEmpty,
  isObject,
  isArray,
  has
} from 'lodash-es'
import { WatchStopHandle } from 'vue'
import {
  widgetEvent,
  dialogEvent,
  isValidKey,
  transformInvokePayloadToFormDataFromKeys,
  handleApiResponseData,
  handleApiAfterFun,
  transformLogicFlowParams,
  handleApiUploadFile,
  getEventValueByValueType
} from '@haohan/clw-utils'
import { useEvent, useProject } from '@haohan/clw-store'
import { invokeApiMenu, getApiMenu, queryLFMenuItemReq, invokeLogicFlow } from '@haohan/clw-api'
import { ElMessageBox, ElMessage } from 'element-plus'
import 'element-plus/es/components/message-box/style/css'
import { useMessage } from './useMessage'
/**
 * 交互事件存储池
 * 存储发送事件发送得值
 */
export const mutualPool = reactive<MutualPool>({})
/**
 * 从交互池中删除指定key内容
 * @param key 发送事件key
 */
export const removeMutualPool = (key: string) => {
  delete mutualPool[key]
}
/**
 * 清空交互池中得内容
 */
export const clearMutualPool = () => {
  const keys = Object.keys(mutualPool)
  ForEach(keys, (k) => {
    delete mutualPool[k]
  })
}

/**
 * 将发送事件初始化到交互事件池中
 * @param components 页面组件信息
 */
export const initMutualPoolByPageComponents = (components: Widget[]) => {
  const sendEvents: WidgetEvent[] = []
  readSendEvents(components, sendEvents)
  initMutualPool(sendEvents)
  console.log('initMutualPoolByPageComponents', sendEvents, mutualPool)
}

/**
 * 将发送事件初始化到交互事件池中
 * @param sendEvents 页面组件发送事件信息
 */
export const initMutualPoolBySendEvents = (sendEvents: WidgetEvent[]) => {
  initMutualPool(sendEvents)
}
/**
 * 读取页面组件中得发送事件信息
 * @param components 页面组件信息
 * @param sendEvents 发送事件集合
 */
export const readSendEvents = (components: Widget[], sendEvents: WidgetEvent[]) => {
  ForEach(components, (cp) => {
    if (cp.type === 'HHGrid' || cp.type === 'grid') {
      if (cp.children!.length > 0) {
        ForEach(cp.children, (cd) => {
          readSendEvents(cd.list, sendEvents)
        })
      }
    } else if (cp.type === 'HHTabPaneWidget' || cp.type === 'TabPaneWidget') {
      // 查找到attributes中得 options值
      const optionsAttr = cp.dataProp?.defaultData
      if (optionsAttr.length > 0) {
        ForEach(optionsAttr, (cd) => {
          readSendEvents(cd.list, sendEvents)
        })
      }
    } else if (cp.events && cp.events.length > 0) {
      const _se = cp.events.filter((e) => e.eventType === 'send')
      _se.forEach((e) => sendEvents.push(e))
    }
  })
}
/**
 * 将发送事件初始化到交互事件池中
 * @param events 发送事件集合
 */
export const initMutualPool = (events: WidgetEvent[]) => {
  // 清空交互池
  clearMutualPool()
  ForEach(events, (e) => {
    mutualPool[e.key] = {
      key: e.key,
      value: '',
      // 不是立即触发得，则默认都是ok
      status: e.action === 'promptlyFilters' ? 'waiting' : 'ok',
      sendEvent: e
    }
  })
}
/**
 * 更新交互池中对应发送事件得值
 * @param key 发送事件key
 * @param value 发送事件得值
 * @param status 发送事件状态
 */
export const updateMutualPool = (
  key: string,
  value: string,
  status: MutualPoolDataType = 'ok'
): void => {
  if (mutualPool[key]) {
    mutualPool[key].status = status
    mutualPool[key].value = value
  }
}
/**
 * 获得交互池中对应key得发送事件
 * @param key 发送事件key
 * @returns 发送事件
 */
export const getMutualData = (key: string): MutualPoolData => {
  return mutualPool[key]
}
/**
 * 包装返回需要监听得计算属性值
 * @param keys 需要监听得发送事件key集合
 * @returns 计算属性值
 */
export const isReadly = (key: string[]): boolean => {
  let readly = true
  ForEach(key, (k) => {
    if (mutualPool[k] && mutualPool[k].status === 'waiting') {
      readly = false
      return false
    }
  })
  return readly
}

export const getReadly = (): any => {
  const eventStore = useEvent()
  return computed(() => (_keys: string[]) => {
    let readly = true
    ForEach(_keys, (k) => {
      if (eventStore.mutualPool[k] && eventStore.mutualPool[k].status === 'waiting') {
        readly = false
        return false
      }
    })
    console.log('getReadly _keys', _keys, JSON.parse(JSON.stringify(eventStore.mutualPool)), readly)
    return readly
  })
}
export const isReadlyComputedFun = (): any => {
  const eventStore = useEvent()
  return () => (_keys: string[]) => {
    console.log('isReadlyComputedFun _keys', _keys)
    let readly = true
    ForEach(_keys, (k) => {
      if (eventStore.mutualPool[k] && eventStore.mutualPool[k].status === 'waiting') {
        readly = false
        return false
      }
    })
    return readly
  }
}
/**
 * 监听发送事件是否准备完毕，准备完毕执行回调函数
 * @param eventKeys 需要监听发送事件ID
 * @param callBack 发送事件准备完毕后执行得回调方法
 * @param stopWef 上个监听handle
 * @returns 监听handle
 */
export const watchMutual = (
  eventKeys: string[],
  callBack: any,
  stopWef?: WatchStopHandle
): WatchStopHandle => {
  const readly = getReadly()
  if (stopWef) {
    stopWef()
  }
  // 组件销毁时，会自动清除
  const wef = watchEffect(() => {
    if (readly.value(eventKeys)) {
      callBack()
    }
  })
  return wef
}
/**
 * 建立监听绑定
 * @param events 需要监听得事件集合
 * @param callBack 监听到事件后得回调方法
 */
export const buildReceiveEvent = (events: WidgetEvent[], _this: any) => {
  events
    .filter((e) => e.eventType === 'receive')
    .forEach((e) => {
      // 这里不能清楚监听，要不会导致页面中多个组件监听同个事件时，出现事件无效的问题，清除交给页面来做
      // widgetEvent.off(e.receiveEventKey!)
      widgetEvent.on(e.receiveEventKey!, (receiveData: any) => {
        // console.log('buildReceiveEvent ==>', _this.item, e)
        if (_this[e.performOperation!]) {
          // 赋值绑定得值
          if (e.reviceBindValue && e.reviceBindValue.trim() !== '') {
            _this[e.reviceBindValue] = receiveData
          }
          // 执行绑定得方法
          _this[e.performOperation!].bind(_this)({
            data: receiveData,
            receiveEvent: e
          })
        }
      })
    })
}
/**
 * 去除事件监听
 * @param events 组件事件集合
 */
export const offReceiveEvent = (events: WidgetEvent[], _this: any) => {
  // console.log('offReceiveEvent ==>', _this.item, events)
  events
    .filter((e) => e.eventType === 'receive')
    .forEach((e) => {
      widgetEvent.off(e.receiveEventKey!)
    })
}
/**
 * 根据触发事件更新对应影响组件立即触发事件得交互池状态
 * 1. 根据触发事件查找影响到得组件
 * 2. 查找影响得组件对应得立即触发事件
 * 3. 更新影响组件触发事件交互池状态
 * @param event 触发事件
 */
const updateMutualBySendEvent = (event: WidgetEvent) => {
  const eventStore = useEvent()
  // 1. 根据触发事件查找影响到得组件
  const receiveEvents = eventStore.receiveEvents.filter(
    (e: WidgetEvent) => e.receiveEventKey === event.key
  )
  if (receiveEvents.length > 0) {
    eventStore.sendEvents.filter((e: WidgetEvent) => {
      // 2. 查找影响得组件对应得立即触发事件
      if (
        e.action === 'promptlyFilters' &&
        receiveEvents.filter((rs: WidgetEvent) => rs.widgetKey === e.widgetKey).length > 0
      ) {
        // 3. 更新影响组件触发事件交互池状态
        eventStore.updateMutualPoolStatus(e.key, 'waiting')
      }
    })
  }
}
/**
 * 发送事件，并更新store中对应得值
 * @param event 事件
 * @param val 值
 */
export const sendEvent = (event: WidgetEvent, _this: any) => {
  const eventStore = useEvent()
  const projectStore = useProject()
  // 需要查找出这个事件影响得组件，将组件得立即执行事件得交互池状态变更
  updateMutualBySendEvent(event)
  let val = ''
  const bindValue = event.bindValue!
  let oriBindValue, level: any
  if (_this.item && _this.item.type === 'HHSelectWidget' && bindValue.indexOf('-') > -1) {
    ;[oriBindValue, level] = bindValue.split('-')
  } else {
    oriBindValue = bindValue
  }
  if (isValidKey(oriBindValue, _this)) {
    if (level === undefined) {
      val = _this[oriBindValue]
    } else if (_this.item.prop.type === 'multiple') {
      val = _this[oriBindValue].map((el: any) => el[level])
    } else {
      const rt = _this[oriBindValue]
      val = Array.isArray(rt) ? rt[level] : ''
    }
    // val = level === undefined ? _this[oriBindValue] : _this[oriBindValue][level]
  } else if (hasIn(_this, 'eventData') && hasIn(_this.eventData, oriBindValue)) {
    val = _this.eventData[oriBindValue]
  } else if (hasIn(eventStore.pageWidgetParam, oriBindValue)) {
    val =
      eventStore.pageWidgetParam[oriBindValue].value === ''
        ? ''
        : JSON.stringify(eventStore.pageWidgetParam[oriBindValue].value)
  }
  // 根据绑定值类型，处理值
  val = getEventValueByValueType(event.bindValueType, val)
  // console.log('[ sendEvent ]-188', event, val)
  if (event.eventType === 'send' && event.comParam && event.comParam !== '') {
    if (eventStore.commonParameters[event.comParam]) {
      eventStore.updateCommonParameters(event.comParam, val)
    }
  }
  // 高级处理
  if (event.highLevelFun && event.highLevelFun !== '') {
    splitBindGloablFun(val)
  }
  // 更新线程池中得值
  eventStore.updateMutualPool(event.key, val)
  // TODO: 如果是跳转和弹窗方法，需要特殊处理
  if (event.behavior && event.behavior === 'runIn') {
    // 获得当前页面ID
    const sourcePageId = projectStore.currentPage.id
    eventStore.changePageParams(event.key, {
      sourcePageId: sourcePageId,
      targetPageId: event.pageId,
      value: val
    })
    // 判断当前状态
    if (
      window.location.href.indexOf('preview') !== -1 ||
      window.location.href.indexOf('bigscreen') !== -1
    ) {
      const pageIds = event.pageId.split(',')
      if (pageIds.length === 1) {
        let url = 'preview'
        if (event.pageType && event.pageType === 'bigScreen') {
          url = 'bigScreen'
        }
        _this.$router.push({
          path: `/${url}/${projectStore.currentProject.id}/${event.pageId}`
        })
      } else {
        // TODO: 弹窗下钻选择
        // 如果绑定了多个页面，则弹出页面选择框
        eventStore.changeJumpPageIds(event.pageId)
        eventStore.changeJumpPageTypes(event.pageType)
        eventStore.changeShowMultipJumpType('runIn')
        eventStore.changeShowMultipJumpDialog(true)
      }
    } else {
      // TODO: 暂不支持在编辑模式下使用
    }
  } else if (event.behavior && event.behavior === 'dialog') {
    // TODO: 弹窗处理
    // 获得当前页面ID
    const sourcePageId = projectStore.currentPage.id
    eventStore.changePageParams(event.key, {
      sourcePageId: sourcePageId,
      targetPageId: event.pageId,
      value: val
    })
    // 处于预览或者发布状态
    const _pageIds = event.pageId.split(',')
    if (_pageIds.length === 1) {
      // 处于预览或者发布状态
      dialogEvent.emit(event.key, event.pageId)
    } else {
      // 如果绑定了多个页面，则弹出页面选择框
      eventStore.changeJumpPageIds(event.pageId)
      eventStore.changeShowMultipJumpType('dialog')
      eventStore.changeShowMultipJumpDialog(true)
    }
  } else if (event.behavior && event.behavior === 'api') {
    // 访问API处理
    if (event.apiParam && event.apiParam?.apiId) {
      // ((isString(val) && val.trim() === '') || (isArray(val) && val.length === 0) || isEmpty(val))
      if (
        event.apiParam?.emptyTip &&
        event.apiParam?.emptyTip !== '' &&
        (isEmpty(val) || val === '[]')
      ) {
        return ElMessage.warning(event.apiParam?.emptyTip)
      }
      // 提示
      if (event.apiParam?.tip !== '') {
        ElMessageBox.confirm(event.apiParam?.tip, '是否执行', {
          confirmButtonText: '执行',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            exeInvokeApiEvent(event, val)
          })
          .catch(() => {})
      } else {
        exeInvokeApiEvent(event, val)
      }
    }
  } else if (event.behavior && event.behavior === 'logicFlow') {
    // 访问API处理
    if (event.apiParam && event.apiParam?.apiId) {
      // ((isString(val) && val.trim() === '') || (isArray(val) && val.length === 0) || isEmpty(val))
      if (
        event.apiParam?.emptyTip &&
        event.apiParam?.emptyTip !== '' &&
        (isEmpty(val) || val === '[]')
      ) {
        return ElMessage.warning(event.apiParam?.emptyTip)
      }
      // 提示
      if (event.apiParam?.tip !== '') {
        ElMessageBox.confirm(event.apiParam?.tip, '是否执行', {
          confirmButtonText: '执行',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            exeInvokeLogicFlowEvent(event, val)
          })
          .catch(() => {})
      } else {
        exeInvokeLogicFlowEvent(event, val)
      }
    }
  } else if (event.behavior && event.behavior === 'toOutUrl') {
    if (
      window.location.href.indexOf('preview') !== -1 ||
      window.location.href.indexOf('bigscreen') !== -1
    ) {
      // 访问外部链接
      if (event.openOutUrlType === 'currentPage') {
        window.location.href = event.outUrl || ''
      } else {
        window.open(event.outUrl || '', '_blank')
      }
    }
  } else {
    // 触发方法
    widgetEvent.emit(event.key, val)
  }
}
/**
 * 执行接口调用
 */
const exeInvokeApiEvent = async (event: WidgetEvent, val: any) => {
  const projectStore = useProject()
  const projectId = projectStore.currentProject.id || ''
  const apiId = event.apiParam?.apiId || ''
  let param: any = {}
  if (event.apiParam.bindApiParam) {
    try {
      param[event.apiParam.bindApiParam] = val
    } catch (error) {
      // param[event.apiParam.bindApiParam] = val
    }
  } else {
    try {
      // 需要增加类型判断，如果是对象则不再执行转换
      if (val && typeof val === 'string') {
        param = JSON.parse(val)
      } else {
        param = val
      }
    } catch (error) {
      console.error('exeInvokeApiEvent 转换参数值错误', error)
    }
  }

  // 批量删除时, param 拿到的格式: {body: [{}, {}]}
  const { data } = await getApiMenu(projectId, apiId)
  const formData = transformInvokePayloadToFormDataFromKeys(data?.keysDefine.def, param)
  // 处理上传文件
  await handleApiUploadFile(data, formData)
  // let payload = {}
  // try {
  //   payload = JSON.parse(val)
  // } catch (error) {}
  // console.log(param, payload)
  invokeApiMenu(projectId, apiId, formData, data.headers).then((res: any) => {
    // console.log('res', res)
    res = handleApiAfterFun(data.postPosition, res)
    const _res = handleApiResponseData(res)
    if (
      res?.status === 200 &&
      has(res, 'data') &&
      has(res.data, 'status') &&
      res.data.status !== 200 &&
      res.data.status !== 0
    ) {
      const message = res?.data.message || res?.data.tag || '数据提交失败，请检查提交数据是否正确'
      ElMessage.error(message)
      return false
    }
    // 后端可能不返回内容, status 为 200 也表示成功, 即前端 {data: ''}
    if (_res?.status === 0 || _res?.status === 200 || (!_res?.status && !_res.data)) {
      console.log(' 执行成功 ', _res)
      widgetEvent.emit(event.key, val)
      ElMessage({
        type: 'success',
        message: '执行成功'
      })
    } else {
      const message = _res?.message || _res?.tag || '执行失败'
      ElMessage({
        type: 'error',
        message: message
      })
    }
  })
}

/**
 * 执行逻辑流调用
 */
const exeInvokeLogicFlowEvent = async (event: WidgetEvent, val: any) => {
  const projectStore = useProject()
  const projectId = projectStore.currentProject.id || ''
  const apiId = event.apiParam?.apiId || ''
  let param: any = {}
  if (event.apiParam.bindApiParam) {
    try {
      param[event.apiParam.bindApiParam] = val
    } catch (error) {
      // param[event.apiParam.bindApiParam] = val
    }
  } else {
    try {
      param = JSON.parse(val)
    } catch (error) {}
  }

  // 批量删除时, param 拿到的格式: {body: [{}, {}]}
  const { data } = await queryLFMenuItemReq(projectId, apiId)
  invokeLogicFlow(projectId, apiId, transformLogicFlowParams(data.parameters, param))
    .then((res: any) => {
      // 后端可能不返回内容, status 为 200 也表示成功, 即前端 {data: ''}
      if (res?.status === 0 || res?.status === 200 || (!res?.status && !res.data)) {
        console.log(' 执行成功 ', res)
        widgetEvent.emit(event.key, val)
        ElMessage({
          type: 'success',
          message: '执行成功'
        })
      } else {
        const message = res?.message || res?.tag || '执行失败'
        ElMessage({
          type: 'error',
          message: message
        })
      }
    })
    .catch((error: any) => {
      console.error(' 执行失败 ', error)
      ElMessage({
        type: 'error',
        message: '执行失败'
      })
    })
}
/**
 * 根据触发函数名，触发对应事件
 * @param events 事件集合
 * @param triggerType 事件触发方法名
 * @param _this 当前实例
 */
export const sendEventByTriggerType = (events: WidgetEvent[], triggerType: string, _this: any) => {
  const _events = filter(
    events,
    (e: WidgetEvent) => e.eventType === 'send' && e.triggerType === triggerType
  )
  _events.forEach((e) => {
    sendEvent(e, _this)
  })
}
/**
 * 执行立即执行事件
 * @param events 事件集合
 * @param _this 当前Vue实例
 */
export const runPromptlyEvent = (events: WidgetEvent[], _this: any) => {
  const _events = filter(events, (e) => e.eventType === 'send' && e.action === 'promptlyFilters')
  if (_events.length > 0 && !hasDefaultSelect(_this)) {
    _events.forEach((e) => {
      sendEvent(e, _this)
    })
  }
}

const hasDefaultSelect = (_this: any) => {
  const defaultSelectAttr = _this.item.prop?.selectedByDefault || _this.item.prop?.hasDefault
  if (defaultSelectAttr) {
    if (isString(defaultSelectAttr)) {
      return defaultSelectAttr === '1'
    }
    return defaultSelectAttr
  }
  return false
}
/**
 * 组件初次加载时，执行对应得接收方法，主要是为了避免组件加载顺序不一致，导致得监听时效问题
 * @param events 组件交互集合
 * @param _this 当前组件this域
 */
export const initByReceiveEvent = (events: WidgetEvent[], _this: any) => {
  // 初始化时需要在到交互池中找一次数据
  const eventStore = useEvent()
  // 过滤除所有监听并且是加载触发得事件
  const promptlyEvents = events.filter(
    (e: WidgetEvent) => e.eventType === 'receive' && e.action === 'promptlyFilters'
  )
  if (promptlyEvents.length > 0) {
    promptlyEvents.forEach((e: WidgetEvent) => {
      // 获得交互池中当前事件得状态
      const pool = eventStore.mutualPool[e.receiveEventKey!]
      if (pool && pool.status === 'ok') {
        // 执行对应得方法
        if (_this[e.performOperation!]) {
          // 赋值绑定得值
          if (e.reviceBindValue && e.reviceBindValue.trim() !== '') {
            _this[e.reviceBindValue] = pool.value
          }
          // 执行绑定得方法
          _this[e.performOperation!].bind(_this)({
            data: pool.value,
            receiveEvent: e
          })
        }
      }
    })
  }
}
/**
 * 列事件，并更新store中对应得值
 * @param event 列事件
 * @param _this Vue对象
 */
export const colEvent = (event: any, _this: any) => {
  const eventStore = useEvent()
  const projectStore = useProject()
  const val = event.bindValue
  // if (isValidKey(event.bindValue!, _this)) {
  //   val = _this[event.bindValue]
  // }
  // 更新线程池中得值
  // eventStore.updateMutualPool(event.key, val)
  // TODO: 如果是跳转和弹窗方法，需要特殊处理
  if (event.linkBehavior && event.linkBehavior === 'runIn') {
    // 获得当前页面ID
    const sourcePageId = projectStore.currentPage.id
    eventStore.changePageParams(event.key, {
      sourcePageId: sourcePageId,
      targetPageId: event.pageId,
      value: val
    })
    // 判断当前状态
    if (window.location.href.indexOf('preview') !== -1) {
      const pageIds = event.pageId.split(',')
      if (pageIds.length === 1) {
        let url = 'preview'
        if (event.pageType && event.pageType === 'bigScreen') {
          url = 'bigScreen'
        }
        _this.$router.push({
          path: `/${url}/${projectStore.currentProject.id}/${event.pageId}`
        })
      } else {
        // TODO: 弹窗下钻选择
        // 如果绑定了多个页面，则弹出页面选择框
        eventStore.changeJumpPageIds(event.pageId)
        eventStore.changeJumpPageTypes(event.pageType)
        eventStore.changeShowMultipJumpType('runIn')
        eventStore.changeShowMultipJumpDialog(true)
      }
    } else {
      // TODO: 暂不支持在编辑模式下使用
    }
  } else if (event.linkBehavior && event.linkBehavior === 'dialog') {
    // TODO: 弹窗处理
    // 获得当前页面ID
    const sourcePageId = projectStore.currentPage.id
    eventStore.changePageParams(event.key, {
      sourcePageId: sourcePageId,
      targetPageId: event.pageId,
      value: val
    })
    // 处于预览或者发布状态
    const _pageIds = event.pageId.split(',')
    if (_pageIds.length === 1) {
      // 处于预览或者发布状态
      dialogEvent.emit(event.key, event.pageId)
    } else {
      // 如果绑定了多个页面，则弹出页面选择框
      eventStore.changeJumpPageIds(event.pageId)
      eventStore.changeShowMultipJumpType('dialog')
      eventStore.changeShowMultipJumpDialog(true)
    }
  } else {
    // 触发方法
    widgetEvent.emit(event.key, val)
  }
}
/**
 * 切割值，根据指定规则更新公共参数中对应得值
 * @param val 指定得值
 */
const splitBindGloablFun = (val: string) => {
  if (val !== '') {
    const eventStore = useEvent()
    const _vals = val.split(',')
    _vals.forEach((_val: string) => {
      // const _vs = _val.split(':')
      // if (_vs.length === 2) {
      //   eventStore.updateCommonParametersByLabel(_vs[0], _vs[1])
      // }
      const ix = _val.indexOf(':')
      if (ix > -1) {
        eventStore.updateCommonParametersByLabel(_val.slice(0, ix), _val.slice(ix + 1))
      }
    })
  }
}
