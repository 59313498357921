interface COMMONPAGEITEM {
  displayName: string // 显示名称
  path: string // 路由路径
  name: string // 权限名称
  hidden?: boolean // 是否隐藏
}
// 组件数据类型名称映射
export const sourceType2Label = {
  LocalData: '静态数据',
  TableSource: '服务器数据',
  ExportDataSource: '导出数据源',
  CustomApi: 'API接口',
  LogicFlowSource: '逻辑流'
}

export const RULE_FORM: TableColumn = {
  key: '',
  prop: '',
  label: '',
  align: 'left',
  filter: '',
  sortable: false,
  defSort: 'no',
  width: '',
  filterValueType: undefined,
  filterOperatorType: undefined,
  filter2: '',
  filterOperatorType2: undefined,
  isCanClick: false,
  className: '',
  groupName: '',
  showOverflow: false,
  displayHide: false,
  filterSelect: {
    type: 'sql',
    id: '',
    name: '',
    keyField: '',
    valueField: '',
    localData: []
  },
  filterTime: {
    model: 'point',
    type: 'date'
  },
  waringList: [],
  labelList: [],
  highlightList: []
}

export const COMMON_PAGES: COMMONPAGEITEM[] = [
  {
    displayName: '用户管理',
    path: 'system-user',
    name: 'UserManager'
  },
  {
    displayName: '角色管理',
    path: 'system-role',
    name: 'RoleManager'
  },
  {
    displayName: '权限管理',
    path: 'system-permission',
    name: 'PermissionManager'
  },
  {
    displayName: '系统日志',
    path: 'system-logs',
    name: 'Journal'
  },
  {
    displayName: '告警配置',
    path: 'system-alarm',
    name: 'AlarmManager'
  },
  {
    displayName: '配置管理',
    path: 'system-setting',
    name: 'SettingManager'
  },
  {
    displayName: '归属组织',
    path: 'system-belong',
    name: 'BelongPlace'
  },
  {
    displayName: 'Token管理',
    path: 'token-setting',
    name: 'TokenManager'
  },
  {
    displayName: '工作流配置',
    path: 'system-workflow',
    name: 'WorkflowManager'
  },
  {
    displayName: '消息中心',
    path: 'user-message',
    name: 'UserMessage',
    hidden: true
  }
]

// 行业下拉框
export const INDUSTRYOPTIONS = [
  {
    label: '教育',
    options: [{ value: '教育', label: '教育' }]
  },
  {
    label: '农业',
    options: [{ value: '农业', label: '农业' }]
  },
  {
    label: '房地产',
    options: [{ value: '房地产', label: '房地产' }]
  },
  {
    label: '建筑工程',
    options: [{ value: '建筑工程', label: '建筑工程' }]
  },
  {
    label: '金融',
    options: [
      { value: '保险', label: '保险' },
      { value: '消费金融', label: '消费金融' },
      { value: '银行', label: '银行' },
      { value: 'PEVC', label: 'PEVC' },
      { value: '券商', label: '券商' },
      { value: '公募基金', label: '公募基金' },
      { value: '期货', label: '期货' },
      { value: '其他金融服务', label: '其他金融服务' }
    ]
  },
  {
    label: '零售和电商',
    options: [
      { value: '电商', label: '电商' },
      { value: '百货商场', label: '百货商场' },
      { value: '其他零售和电商服务', label: '其他零售和电商服务' }
    ]
  },
  {
    label: '本地生活服务',
    options: [
      { value: '餐饮', label: '餐饮' },
      { value: '酒店', label: '酒店' },
      { value: '线下服务', label: '线下服务' },
      { value: 'O2O', label: 'O2O' }
    ]
  },
  {
    label: '服装和纺织',
    options: [{ value: '服装和纺织', label: '服装和纺织' }]
  },
  {
    label: '软件和信息技术',
    options: [
      { value: 'IT基础设施', label: 'IT基础设施' },
      { value: '企业管理软件', label: '企业管理软件' },
      { value: '信息安全', label: '信息安全' },
      { value: '营销与增长', label: '营销与增长' },
      { value: '人工智能与大数据', label: '人工智能与大数据' },
      { value: 'IT运维和开发服务', label: 'IT运维和开发服务' },
      { value: '垂直行业应用', label: '垂直行业应用' },
      { value: '基础和工具软件', label: '基础和工具软件' }
    ]
  },
  {
    label: '文娱和社交',
    options: [
      { value: '视频和直播', label: '视频和直播' },
      { value: '游戏', label: '游戏' },
      { value: '社交媒体', label: '社交媒体' },
      { value: '影综娱', label: '影综娱' }
    ]
  },
  {
    label: '汽车',
    options: [
      { value: '智能汽车技术', label: '智能汽车技术' },
      { value: '新能源汽车', label: '新能源汽车' },
      { value: '传统整车', label: '传统整车' },
      { value: '汽车零部件', label: '汽车零部件' },
      { value: '汽车服务', label: '汽车服务' }
    ]
  },
  {
    label: '消费电子及零部件',
    options: [
      { value: '手机数码产品', label: '手机数码产品' },
      { value: '芯片/半导体', label: '芯片/半导体' },
      { value: '其他零部件', label: '其他零部件' }
    ]
  },
  {
    label: '家电',
    options: [{ value: '家电', label: '家电' }]
  },
  {
    label: '传媒',
    options: [
      { value: '新闻电视媒体', label: '新闻电视媒体' },
      { value: '广告营销', label: '广告营销' }
    ]
  },
  {
    label: '医疗和健康',
    options: [
      { value: '生物科技', label: '生物科技' },
      { value: '中成药和保健品', label: '中成药和保健品' },
      { value: '制药和制品', label: '制药和制品' },
      { value: '医药流通和销售', label: '医药流通和销售' },
      { value: '医疗机构和服务', label: '医疗机构和服务' },
      { value: '医疗器械', label: '医疗器械' }
    ]
  },
  {
    label: '制造业',
    options: [
      { value: '化工', label: '化工' },
      { value: '电气设备', label: '电气设备' },
      { value: '通用和专业设备', label: '通用和专业设备' },
      { value: '基础材料和其他', label: '基础材料和其他' },
      { value: '交通运输设备', label: '交通运输设备' },
      { value: '机器人和无人机', label: '机器人和无人机' },
      { value: '航空航天', label: '航空航天' },
      { value: '军工', label: '军工' }
    ]
  },
  {
    label: '能源',
    options: [
      { value: '传统能源', label: '传统能源' },
      { value: '新能源', label: '新能源' },
      { value: '电池', label: '电池' }
    ]
  },
  {
    label: '采矿业',
    options: [
      { value: '金属', label: '金属' },
      { value: '非金属', label: '非金属' },
      { value: '煤炭', label: '煤炭' },
      { value: '石油和天然气', label: '石油和天然气' }
    ]
  },
  {
    label: '通信',
    options: [
      { value: '通信服务供应', label: '通信服务供应' },
      { value: '通信设备供应', label: '通信设备供应' }
    ]
  },
  {
    label: '交通运输',
    options: [
      { value: '物流', label: '物流' },
      { value: '水陆空运输', label: '水陆空运输' },
      { value: '交通基础设施', label: '交通基础设施' }
    ]
  },
  {
    label: '专业服务',
    options: [{ value: '专业服务', label: '专业服务' }]
  },
  {
    label: '公共事业',
    options: [
      { value: '水和环保', label: '水和环保' },
      { value: '事业单位', label: '事业单位' },
      { value: '非盈利组织', label: '非盈利组织' }
    ]
  },
  {
    label: '其他行业',
    options: [{ value: '其他行业', label: '其他行业' }]
  }
]
