import { computed, ref } from 'vue'
import { useWidget } from '@haohan/clw-store'
import { getCompChildren } from '@haohan/clw-utils'

function findEventKey(el: Widget, key: any) {
  return (
    el.events?.length && el.events.find((event) => event.eventType === 'send' && event.key === key)
  )
}
function findodeByKey(trees: Widget[], key: any) {
  function traverse(nodes: Widget[], key: any): any {
    for (const node of nodes) {
      // console.log(node)
      if (findEventKey(node, key)) {
        return node
      }
      const children = getCompChildren(node)
      if (children.length) {
        for (const childObj of children) {
          const isEnd = traverse(childObj.list, key)
          if (isEnd) {
            return isEnd
          }
        }
      }
    }
    return false
  }
  return traverse(trees, key)
}

const activePanelIx = ref()
const mappingTabsKey = ref()
const activePanelKey = ref()

export function usePanel() {
  const widgetStore = useWidget()

  const widgetList = computed(() => widgetStore.widgetDataList)

  function setActivePanelFn(el: Widget, ix: number) {
    // console.log('setActivePanelFn', el, ix, widgetList.value)
    const eventKey = el.events?.find((event) => event.eventType === 'receive')?.receiveEventKey
    if (eventKey) {
      const findNode = findodeByKey(widgetList.value, eventKey)
      // console.log('eventKey', eventKey, findNode)
      if (findNode) {
        mappingTabsKey.value = findNode.key
        activePanelIx.value = ix
      }
    }
  }
  function setActiveIx(el: Widget, ix: number) {
    activePanelKey.value = el.key
    activePanelIx.value = ix
  }
  return {
    activePanelIx,
    setActivePanelFn,
    mappingTabsKey,
    widgetList,
    setActiveIx,
    activePanelKey
  }
}
