// 公共样式属性
const styleProp: WidgetAttribute = {
  'z-index': 100,
  top: 0,
  left: 0
}
// 组件级功能属性
const chartCompAttr: WidgetAttribute = {
  isLock: false,
  events: [],
  mutualSetting: true,
  dataSourceSetting: true,
  dataSourceType: 'LocalData',
  dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi'],
  // 自定义数据处理函数
  customDataHandle: ''
}
// 公共属性
const chartCommonProp: WidgetAttribute = {
  timingOpen: false,
  timingTime: 5
}
// 公共数据属性
const dataProp: WidgetAttribute = {
  // 数据集
  tableId: '',
  tableName: '',
  pageSize: 10,
  queryTimeType: 'systime',
  startInterval: 0,
  endtInterval: 0,
  apiDataPath: '' // 当数据类型为API时，记录API数据的路径
}

const threeComp: Widget[] = [
  // 地球
  {
    imageSrc: 'BigScreenThreeEarth',
    thumbnail: '',
    title: '地球',
    type: 'BigScreenThreeEarth',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [],
        // 可绑定得值
        bindValues: []
      }
    },
    prop: {
      lines: [{ sourceKey: 'source', targetKey: 'target', valueKey: 'value' }], // lines 线的集合
      speed: ''
    },
    styleProp: {
      height: 500,
      width: 600
    },
    dataProp: {
      defaultData: [
        { source: '安徽', target: '上海', value: 57 },
        { source: '安徽', target: '北京', value: 100 },
        { source: '北京', target: '上海', value: 120 },
        { source: '北京', target: '浙江', value: 100 },
        { source: '河南', target: '新疆', value: 115 }
      ]
    }
  },
  // 图片列表
  {
    imageSrc: 'BigScreenThreePictureList',
    thumbnail: '',
    title: '3D图片列表',
    type: 'BigScreenThreePictureList',
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: 600,
      height: 500
    },
    prop: {
      automatic: true,
      radius: 10,
      rotateX: 0.4,
      groupRotationY: 0.005,
      maxZoomSize: 2,
      title: 'title',
      text: 'text'
    },
    // 数据部分
    dataProp: {
      defaultData: [
        {
          title: '1号',
          text: '一段描述文字'
        },
        {
          title: '2号',
          text: '一段描述文字'
        },
        {
          title: '3号',
          text: '一段描述文字'
        },
        {
          title: '4号',
          text: '一段描述文字'
        },
        {
          title: '5号',
          text: '一段描述文字'
        }
      ]
    }
  },
  // 圆形指标
  {
    imageSrc: 'BigScreenThreeCircleCard',
    thumbnail: '',
    title: '3D圆形指标',
    type: 'BigScreenThreeCircleCard',
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: 600,
      height: 500
    },
    prop: {
      automatic: true,
      radius: 10,
      rotateX: 0.2,
      groupRotationY: 0.005,
      maxZoomSize: 2,
      title: 'title',
      text: 'text',
      value: 'value'
    },
    // 数据部分
    dataProp: {
      defaultData: [
        {
          title: '1号',
          text: '一段描述文字',
          value: 123
        },
        {
          title: '2号',
          text: '一段描述文字',
          value: 123
        },
        {
          title: '3号',
          text: '一段描述文字',
          value: 123
        },
        {
          title: '4号',
          text: '一段描述文字',
          value: 123
        },
        {
          title: '5号',
          text: '一段描述文字',
          value: 123
        },
        {
          title: '6号',
          text: '一段描述文字',
          value: 123
        }
      ]
    }
  }
]

for (let i = 0, len = threeComp.length; i < len; i++) {
  const item = threeComp[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  item.prop = { ...chartCommonProp, ...item.prop }
  if (item.dataProp) {
    item.dataProp = { ...dataProp, ...item.dataProp }
  }
  threeComp[i] = { ...chartCompAttr, ...item }
}

export const threeCompList = threeComp
