import { http } from '@haohan/clw-utils'

const URL = '/api/v1/projects'

export const getAllProject = (params?: any) => {
  return http.request({
    url: URL,
    params: {
      page: 1,
      count: 100000,
      ...params
    },
    method: 'GET'
  })
}

/**
 * 根据项目 id 删除项目, 多个用逗号分隔
 * @param projectIds 699262031932559360,699262031932559361
 * @returns
 */
export const deleteProjectByIds = (projectIds: string) => {
  return http.request({
    url: `${URL}/${projectIds}`,
    method: 'DELETE'
  })
}

export const createProject = (data: any): any => {
  return http.request({
    url: URL,
    data,
    method: 'POST'
  })
}

export const updateProject = (data: any): any => {
  const { id, payload } = data
  return http.request({
    url: `${URL}/${id}`,
    data: payload,
    method: 'PUT'
  })
}

export const importFile = (data: any): any => {
  return http.request({
    url: `${URL}/import`,
    data,
    method: 'POST'
  })
}

// 获取菜单项（包含Page）
export function getMenu(projectId: string, menuId: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}/menus/${menuId}`
  })
}
/**
 * 保存页面参数
 * @param projectId
 * @param data
 * @returns
 */
export function savePage(projectId: string, data: any) {
  if (data.id) {
    return http.request({
      method: 'put',
      url: `${URL}/${projectId}/pages/${data.id}`,
      data
    })
  }
  return http.request({
    method: 'post',
    url: `${URL}/${projectId}/pages`,
    data
  })
}

export function getProject(projectId: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}`
  })
}

export function getAllPageByProject(projectId: string, page = 0) {
  return http.request({
    method: 'post',
    url: `${URL}/${projectId}/pages/query`,
    data: {
      page: page,
      count: 10000
    }
  })
}
/**
 * 更新页面集合
 * @param projectId 项目ID
 * @param pages 所有需要更改得页面
 * @returns 查询Promise
 */
export function updateAllPageByProject(projectId: string, pages: any) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${projectId}/pages`,
    data: pages
  })
}
/**
 * 查询项目下得所有菜单信息
 * @param projectId 项目ID
 * @returns 项目下得所有菜单信息
 */
export function queryMenus(projectId: string, tree = true) {
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}/menus`,
    params: {
      tree
    }
  })
}
/**
 * 删除菜单
 * @param projectId 项目ID
 * @param menuId 菜单ID
 * @returns 空
 */
export function deleteMenus(projectId: string, menuId: string) {
  return http.request({
    method: 'DELETE',
    url: `${URL}/${projectId}/menus/${menuId}`
  })
}
/**
 * 保存菜单
 * @param projectId 项目ID
 * @param data 菜单信息
 * @returns 空
 */
export function saveMenus(projectId: string, data: Menu) {
  if (data.id) {
    return http.request({
      method: 'put',
      url: `${URL}/${projectId}/menus/${data.id}`,
      data
    })
  }
  return http.request({
    method: 'post',
    url: `${URL}/${projectId}/menus`,
    data
  })
}
/**
 * 移动菜单到指定位置
 * @param projectId 项目ID
 * @param targetId 目标菜单ID
 * @param sourceId 源菜单ID
 * @param parentId 父菜单ID
 * @returns
 */
export function move(projectId: string, targetId: string, sourceId: string, parentId: string) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${projectId}/menus/move`,
    data: {
      targetId: targetId,
      sourceId: sourceId,
      parentId: parentId
    }
  })
}
/**
 * 赋值页面
 * @param projectId 项目ID
 * @param meunId 菜单ID
 * @returns
 */
export function copyMenu(projectId: string, meunId: string) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${projectId}/menus/copy/${meunId}`
  })
}
export function saveProject(project: Project) {
  if (project.id) {
    return http.request({
      method: 'put',
      url: `${URL}/${project.id}`,
      data: project
    })
  }
  return http.request({
    method: 'post',
    url: URL,
    data: project
  })
}
/**
 * 更新页面组件信息
 * @param projectId 项目ID
 * @param page 页面信息
 * @returns
 */
export function updatePage(projectId: string, page: any) {
  console.log('保存页面：', page)
  return http.request({
    method: 'PUT',
    url: `${URL}/${projectId}/pages/${page.id}`,
    data: {
      components: page.components,
      datasetIds: page.datasetIds,
      variables: page.variables
    }
  })
}

/**
 * 资源文件列表
 */
export function getImgList(projectId: string, params: any) {
  return http.request({
    method: 'post',
    url: `${URL}/${projectId}/storage/list`,
    data: params
  })
}

/**
 * 获取资源文件
 */
export function getImg(projectId: string, name: string) {
  return http.request({
    method: 'get',
    // responseType: 'arraybuffer',
    url: `${URL}/${projectId}/storage/download/${name}`,
    params: {
      defaultUrl: `${URL}/${projectId}/storage/download/default.png`
    }
  })
}
export function generateProjectBySSE(projectId: string, data: object) {
  return http.request({
    method: 'POST',
    // responseType: 'arraybuffer',
    url: `${URL}/${projectId}/sse/generate`,
    data
  })
}

/**
 * 项目 - 获取关联用户列表
 */
export function getProjectRefUserList(projectId: string, data?: any) {
  return http.request({
    method: 'POST',
    url: `${URL}/${projectId}/user_ref/list`,
    data: data || {
      page: 1,
      count: 100000
    }
  })
}

/**
 * 项目 - 关联新用户
 */
export function createProjectUserRoleRef(projectId: string, data: object) {
  return http.request({
    method: 'POST',
    url: `${URL}/${projectId}/user_ref`,
    data
  })
}

/**
 * 项目 - 关联新用户
 */
export function updateProjectUserRoleRef(projectId: string, refId: string, data: object) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${projectId}/user_ref/${refId}`,
    data
  })
}

/**
 * 项目-删除关联用户
 */
export const deleteProjectUserRef = (projectIds: string, refId: string) => {
  return http.request({
    url: `${URL}/${projectIds}/user_ref/${refId}`,
    method: 'DELETE'
  })
}

/**
 * 创建版本
 */
export function createVersion(pid: string, body: object) {
  return http.request({
    method: 'POST',
    url: `${URL}/${pid}/version`,
    data: body
  })
}

/**
 * 版本列表
 */
export function versionList(pid: string, body: object) {
  return http.request({
    method: 'POST',
    url: `${URL}/${pid}/version/list`,
    data: body
  })
}

/**
 * 版本 删除
 */
export function versionDel(pid: string, vid?: number) {
  return http.request({
    url: `${URL}/${pid}/version/${vid}`,
    method: 'DELETE'
  })
}

/**
 * 版本 还原
 */
export function versionRestore(pid: string, vid?: number) {
  return http.request({
    url: `${URL}/${pid}/version/${vid}/rollback`,
    method: 'GET'
  })
}

/**
 * 版本 复制
 */
export function versionCopy(pid: string, body: object, vid?: number) {
  return http.request({
    url: `${URL}/${pid}/version/restore_new/${vid}`,
    method: 'POST',
    data: body
  })
}

/**
 * 发布包 列表
 */
export function ReleaseList(pid: string, body: object) {
  return http.request({
    method: 'POST',
    url: `${URL}/${pid}/generate/list`,
    data: body
  })
}

/**
 *发布包 下载
 */
// export function ReleaseDown(pid: string, gid?: number | null) {
//   return http.request({
//     url: `${URL}/${pid}/download/${gid}`,
//     method: 'GET'
//   })
// }

/**
 * 发布包 生成
 */
export function ReleaseCreate(pid: string, body: object) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${pid}/generate`,
    data: body
  })
}

/**
 * 发布包 删除
 */
export function ReleaseDel(pid: string, gid?: number | null) {
  return http.request({
    url: `${URL}/${pid}/generate/${gid}`,
    method: 'DELETE'
  })
}
/**
 * 版本 还原
 */
export function checkProjectName(name: string) {
  return http.request({
    url: `${URL}/checkname/${name}`,
    method: 'GET'
  })
}

/**
 * 批量复制菜单项（可跨项目）
 */
export function crossProjectPageCopy(projectId: string, data: any) {
  return http.request({
    method: 'POST',
    url: `${URL}/${projectId}/menus/xcopy`,
    data
  })
}

/**
 * 版本 对比
 */
export function versionDiff(pid: string, sid: number, tid: number) {
  return http.request({
    url: `${URL}/${pid}/version/compare/${sid}/${tid}`,
    method: 'GET'
  })
}
/**
 * 获得当前用户对该项目拥有得权限
 * @param projectId 项目ID
 * @returns 权限
 */
export function queryProjectPermission(projectId: string) {
  return http.request({
    url: `${URL}/${projectId}/user_ref/current_role`,
    method: 'GET'
  })
}

/**
 * 条件模版 添加
 */
export function queryTemplateAdd(pid: string, pageId: string, data: any) {
  return http.request({
    method: 'POST',
    url: `${URL}/${pid}/pages/${pageId}/queryTemplate`,
    data
  })
}
/**
 * 条件模版 列表
 */
export function queryTemplateList(pid: string, pageId: string, data: any) {
  return http.request({
    method: 'POST',
    url: `${URL}/${pid}/pages/${pageId}/queryTemplate/list`,
    data
  })
}
/**
 * 条件模版 删除
 */
export function queryTemplateDel(pid: string, pageId: string, id: any) {
  return http.request({
    method: 'DELETE',
    url: `${URL}/${pid}/pages/${pageId}/queryTemplate/${id}`
  })
}
