<template>
  <div class="un-auth-container">
    <div class="content">
      <img class="un-auth" src="../../assets/401.png" alt="Un Auth" />
      <div class="right">
        <h3 class="title">401</h3>
        <div class="tips">很抱歉，您访问的系统页面token已过期，请联系管理员。</div>
        <div class="divider"></div>
        <div v-if="!hideMenu" class="redirect"
          >即将离开本页，<span class="seconds"> {{ seconds }} </span> 秒自动返回上一页</div
        >
        <el-button v-if="!hideMenu" class="btn" type="primary" @click="goBack">立即返回</el-button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  // import { ROUTE_PATH } from '@/constant/route-path'

  const router = useRouter()
  const route = useRoute()

  // 菜单是否隐藏
  const hideMenu = ref(false)
  watch(
    () => route.query,
    () => {
      // 是否显示头部菜单
      hideMenu.value = route.query?.hideMenu === 'true'
    },
    { immediate: true }
  )

  const goBack = () => {
    if (hideMenu.value) {
      router.push({ path: '/login' })
    } else {
      router.go(-1)
    }
    console.log('跳转')
  }

  // 倒计时
  const seconds = ref(5)
  const timer = ref()
  const startMyInterval = () => {
    clearMyInterval()
    timer.value = setInterval(() => {
      if (seconds.value === 1) {
        clearMyInterval()
        // 如果请求里加了 hiddenMenu=true 隐藏菜单, 就只有401页面 没有返回按钮没有倒计时 只显示401
        if (!hideMenu.value) {
          goBack()
        }
      } else {
        seconds.value--
      }
    }, 1000)
  }
  const clearMyInterval = () => {
    clearInterval(timer.value)
  }
  onMounted(() => {
    startMyInterval()
  })
  onUnmounted(() => {
    clearMyInterval()
  })
</script>

<style scoped lang="scss">
  .un-auth-container {
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
      display: flex;
      .un-auth {
        width: 381px;
        height: 300px;
      }
      .right {
        width: 461px;
        margin-left: 90px;
        .title {
          font-size: 72px;
          font-weight: 400;
          color: #1a1d1f;
        }
        .tips {
          font-size: 18px;
          font-weight: normal;
          color: #1a1d1f;
        }
        .divider {
          margin: 24px 0;
          width: 100%;
          height: 0px;
          opacity: 1;
          border: 1px solid #e5e5e5;
        }
        .redirect {
          font-size: 14px;
          font-weight: normal;
          color: #606266;
          .seconds {
            color: #0076de;
          }
        }
        .btn {
          margin-top: 40px;
        }
      }
    }
  }
</style>
