import { ref, watchEffect, unref, isRef } from 'vue'
// import { transformArray2Object } from '@haohan/clw-utils'
/**
 * 将组件的属性中type为style的属性组合成组件的样式(待兼容旧版本属性)
 * @param options 当前选择组件的属性
 * @returns box: 元素盒子的所有具有真值的样式属性组成的对象
 * @returns retry: 重新执行获取box的函数
 */
export function useStyle(options: any) {
  const box = ref()
  const withPositionBox = ref()
  const positionProp = ['top', 'left']
  const noPx = ['z-index']
  function done() {
    box.value = {}
    withPositionBox.value = {}
    // const styleOptions = unref(options).filter((el: any) => el.type === 'style')
    const optionsValue = unref(options)
    // console.log(optionsValue)
    for (const key in optionsValue) {
      // 当且仅当 值存在且不为假值才可赋值
      if (Object.prototype.hasOwnProperty.call(optionsValue, key)) {
        const kv = optionsValue[key]
        if (positionProp.includes(key)) {
          if (!isNaN(kv) && !noPx.includes(key)) {
            if (+kv) {
              withPositionBox.value[key] = `${kv}px`
            }
          } else {
            withPositionBox.value[key] = kv
          }
        } else if (!isNaN(kv) && !noPx.includes(key)) {
          if (+kv) {
            box.value[key] = `${kv}px`
            withPositionBox.value[key] = `${kv}px`
          }
        } else {
          box.value[key] = kv
          withPositionBox.value[key] = kv
        }
      }
    }
  }

  if (isRef(options)) {
    watchEffect(done)
  } else {
    done()
  }

  return { box, withPositionBox, retry: done }
}
