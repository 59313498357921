<template>
  <div>
    <!-- 表单 -->
    <template v-if="isFormPage">
      <!-- style="background: #fff" -->
      <!-- <el-form
        ref="createFormRef"
        :style="formPageStyle"
        label-position="top"
        :model="globalForm"
        :rules="globalRules"
        label-width="120px"
      >
        <View :key="cnpKey" v-model:widget-data-list="widgetData.list"></View>
        <div
          v-if="widgetData?.list?.length && formButtonVisible"
          class="form-page-submit-container"
        >
          <el-button type="primary" class="btn" :loading="formSubmitLoading" @click="onFormSubmit">
            确定
          </el-button>
          <el-button class="btn cancle"> 取消 </el-button>
        </div>
      </el-form>
      <DialogPage v-model:dialog-visible="dialogVisible" :page-id="pageId"></DialogPage>
      <MultipleJump></MultipleJump> -->
      <FormPage
        v-if="showFormPage"
        :widget-data="widgetData"
        :is-dialog-page="false"
        :key="cnpKey"
      ></FormPage>
    </template>

    <!-- 非表单 -->
    <template v-else>
      <View :key="cnpKey" v-model:widget-data-list="widgetData.list"></View>
    </template>
    <DialogPage v-model:dialog-visible="dialogVisible" :page-id="pageId"></DialogPage>
    <MultipleJump></MultipleJump>
    <WorkFlowOneMore ref="workflowOneMoreRef" :info="workflowMore" @again="workflowAgain" />
  </div>
</template>

<script setup lang="ts">
  import { useRoute, useRouter } from 'vue-router'
  import {
    ref,
    watch,
    computed,
    reactive,
    watchEffect,
    onMounted,
    onUnmounted,
    onActivated,
    onDeactivated,
    nextTick,
    onBeforeUnmount
  } from 'vue'
  import View from '../../View.vue'
  import DialogPage from '../../DialogPage.vue'
  import MultipleJump from '../../MultipleJump.vue'
  import FormPage from '../../form-page/index.vue'
  import { useProject, useEvent, useGlobalStore } from '@haohan/clw-store'
  import { getMenu, getAllPageByProject } from '@haohan/clw-api'
  import {
    getWigetEventAndComParamInfo,
    getRunInEvent,
    getKey,
    widgetEvent,
    dialogEvent,
    getFormPayloadByWidgetList,
    transformInvokePayloadToFormDataFromKeys,
    handleApiAfterFun,
    handleApiResponseData,
    emitter
  } from '@haohan/clw-utils'
  import { cloneDeep } from 'lodash-es'
  import WorkFlowOneMore from '../../WorkFlowOneMore.vue'

  interface WidgetDataInfo {
    list: Widget[]
  }
  const eventStore = useEvent()
  const projectStore = useProject()
  const globalStore = useGlobalStore()
  const route = useRoute()
  const router = useRouter()
  const widgetData = reactive<WidgetDataInfo>({
    list: []
  })
  // 使用该方法强制重新渲染组件
  const cnpKey = ref(getKey())
  // 避免表单渲染早于组件列表返回
  const showFormPage = ref(false)
  /**
   * 重新加载组件信息
   */
  const loadMenuInfo = async () => {
    // TODO: 判断是否更改
    if (projectStore.currentProject.id) {
      await getMenu(projectStore.currentProject.id, route.params.pageId as string).then((res) => {
        // console.log('[ res ]-31', res)
        const _widgetList = res?.data?.page?.components || []
        projectStore.changeCurrentPage(res.data)
        reloadWidget(_widgetList)
        widgetData.list = _widgetList
        showFormPage.value = true
        // widgetStore.changeWidgetDataList(res.data.page.components || [])
      })
    }
  }
  const initPageInfo = (widgetList: Widget[] = []) => {
    // 清楚页面参数集合
    eventStore.clearPageWidgetParam()
    // 获得组件集合中得事件和公共参数集合
    const pageInfo = getWigetEventAndComParamInfo(widgetList)
    // console.log('[ pageInfo ]-85', pageInfo)
    // 初始化线程池
    eventStore.initMutualPoolBySendEvents(pageInfo.sendFilters)
    // 记录组件交互和公共参数信息
    eventStore.changePageInfo(pageInfo)
    eventStore.changeReceiveEvents(pageInfo.receiveFilters)
    eventStore.changeSendEvents(pageInfo.sendFilters)
  }
  const reloadWidget = (widgetList: Widget[] = []) => {
    initPageInfo(widgetList)
    // 清空所有得监听事件
    if (!isOpenKeepAlive.value) {
      widgetEvent.all.clear()
    }
    cnpKey.value = getKey()
  }
  onUnmounted(() => {
    // 清空所有得监听事件
    if (!isOpenKeepAlive.value) {
      widgetEvent.all.clear()
    }
  })
  const loadAllPageRunInSendEvent = () => {
    if (!projectStore.currentProject.id) {
      return
    }
    getAllPageByProject(projectStore.currentProject.id).then((response: any) => {
      const runInEvents: WidgetEvent[] = []
      const pages = response.data.data
      pages.forEach((page: any) => {
        getRunInEvent(page.components, runInEvents)
      })
      // 更新事件
      eventStore.changeAllPageRunInEvents(runInEvents)
    })
  }
  const workflowMore = ref<any>()
  const workflowOneMoreRef = ref<InstanceType<typeof WorkFlowOneMore>>()
  onMounted(async () => {
    // loadAllPageRunInSendEvent()
    globalStore.changeEdieModel(false)
    // 开启 keep-alive 功能
    if (isOpenKeepAlive.value) {
      await loadMenuInfo()
    }

    // 存 widgetEvent 和 dialogEvent
    nextTick(() => {
      setTimeout(() => {
        // widgetEvent
        projectStore.updateActivatedRouteWidgetEvent(
          route.params.pageId as string,
          cloneDeep(widgetEvent)
        )
        // dialogEvent
        projectStore.updateActivatedRouteDialogEvent(
          route.params.pageId as string,
          cloneDeep(dialogEvent)
        )
        // console.log(
        //   'onMounted widgetEventMap----',
        //   projectStore.previewKeepAliveRoute.widgetEventMap
        // )
        // console.log(
        //   'onMounted dialogEventMap----',
        //   projectStore.previewKeepAliveRoute.dialogEventMap
        // )

        // 存下值, permission.ts 用于判断, 刷新浏览跳转
        onStoreIsKeepAlive()
      }, 1000)
    })
    emitter.on('workflow-one-more', handleWorkflowOneMore)
  })

  const handleWorkflowOneMore = (params: any) => {
    workflowMore.value = params
    workflowOneMoreRef.value?.open()
  }

  onBeforeUnmount(() => {
    emitter.off('workflow-one-more', handleWorkflowOneMore)
  })

  function workflowAgain() {
    workflowOneMoreRef.value?.close()
    if (workflowMore.value.isDialogPage) {
      dialogVisible.value = true
    } else {
      projectStore.clearCurrentFormWidgetDataMapForAttr()
    }
  }

  const onStoreIsKeepAlive = () => {
    // 是否开启缓存列表
    const isOpen = projectStore?.currentProject?.settings?.cache === 'true' ? true : false
    projectStore.openKeepAlive(isOpen)
    if (isOpen) {
      projectStore.updateKeepAliveMaxNumber(
        projectStore?.currentProject?.settings?.cacheNumber || 5
      )
      sessionStorage.setItem('isKeepAlive', 'true')
    } else {
      sessionStorage.removeItem('isKeepAlive')
    }
  }

  // 是否开启 keep-alive
  const isOpenKeepAlive = computed(() => projectStore.previewKeepAliveRoute.isOpenKeepAlive)
  // 重新请求当前页组件信息
  watchEffect(() => {
    if (!isOpenKeepAlive.value) {
      loadMenuInfo()
    }
  })
  // 弹窗处理
  const dialogVisible = ref(false)
  const pageId = ref('')
  watch(
    () => eventStore.allPageDialogEvents,
    () => {
      dialogEvent.all.clear()
      eventStore.allPageDialogEvents.forEach((e: any) => {
        dialogEvent.on(e.key, (d: any) => {
          pageId.value = d
          dialogVisible.value = true
        })
      })

      // 表格自定义事件 - 显示弹窗
      dialogEvent.on('table-custom-action-for-dialog', (d: any) => {
        pageId.value = d
        dialogVisible.value = true
      })
    },
    { immediate: true, deep: true }
  )

  // 表单页, 是否展示面包屑
  const isFormPage = computed(() => {
    const pageType = projectStore?.currentPage?.variables?.pageType
    return pageType === 'form'
  })

  // // 表单页, padding
  // const formPageStyle = computed(() => {
  //   const styleProp = projectStore?.currentPage?.variables?.formAttribute?.styleProp
  //   if (styleProp) {
  //     return Object.keys(styleProp)
  //       .map((padding) => {
  //         return `${padding}:${styleProp[padding]}px`
  //       })
  //       .join(';')
  //   }
  //   return {}
  // })

  // // 表单和 rules
  // const globalForm = computed(() => projectStore.currentForm)
  // const globalRules = computed(() => projectStore.currentFormRulesParsed)

  // watch(
  //   () => widgetData.list,
  //   (val) => {
  //     if (projectStore.isFormPage) {
  //       // 重置表单和 rules
  //       projectStore.resetCurrentFormAndRules()
  //       // 更新表单
  //       projectStore.updateCurrentFormByWidgetList(widgetData.list)
  //       // 更新 rules
  //       projectStore.updateCurrentFormRulesByWidgetList(widgetData.list)
  //     }
  //   },
  //   { deep: true }
  // )

  // const formSubmitLoading = ref(false)
  // const createFormRef = ref()
  // const getFormPayload = (list: any, form: any) => {
  //   const { filterNullValues } = projectStore?.currentPage?.variables?.formAttribute
  //   return {
  //     data: getFormPayloadByWidgetList(list, form, filterNullValues)
  //   }
  // }

  // /**
  //  * 表单 - 未配数据源, 隐藏提交按钮
  //  */
  // const formButtonVisible = computed(() => {
  //   const tableId =
  //     projectStore?.currentPage?.variables?.formAttribute?.tableId ||
  //     projectStore?.currentPage?.variables?.formAttribute?.apiUrl
  //   return Boolean(tableId)
  // })

  // /**
  //  * 表单 - 数据集方式
  //  */
  // const datasourceFormSubmit = () => {
  //   // 默认为数据集, 不存在则为自定义 API
  //   const tableId =
  //     projectStore?.currentPage?.variables?.formAttribute?.tableId ||
  //     projectStore?.currentPage?.variables?.formAttribute?.apiUrl
  //   // 数据表, 拼接表的 id: 'dataSourceId;schema;name'
  //   const isDataTable = tableId.includes(';')
  //   const projectId = projectStore.currentProject.id
  //   const payload = getFormPayload(widgetData.list, globalForm.value)
  //   if (!tableId) {
  //     return ElMessage.warning('还未绑定数据集')
  //   }
  //   if (!isDataTable) {
  //     return ElMessage.warning('绑定的数据集不是原始表, 不支持该种方式')
  //   }
  //   formSubmitLoading.value = true
  //   addDataAssemble(projectId, tableId, payload)
  //     .then(() => {
  //       ElMessage.success('新增成功')
  //     })
  //     .finally(() => {
  //       formSubmitLoading.value = false
  //     })
  // }
  // /**
  //  * 表单 - 自定义 API
  //  */
  // const customApiFormSubmit = async () => {
  //   const { tableId } = projectStore?.currentPage?.variables?.formAttribute || {}
  //   const projectId = projectStore.currentProject?.id
  //   const payload = getFormPayload(widgetData.list, globalForm.value)

  //   if (!tableId) {
  //     return ElMessage.warning('还未绑定数据集')
  //   }

  //   formSubmitLoading.value = true

  // enum FORM_DATA_TYPE {
  //   datasource = 'datasource',
  //   api = 'api'
  // }
  // /**
  //  * 表单页提交
  //  */
  // const onFormSubmit = () => {
  //   createFormRef?.value?.validate((valid: boolean) => {
  //     if (!valid) {
  //       ElMessage.warning('校验未通过')
  //       return false
  //     }
  //     const formAttribute = projectStore?.currentPage?.variables?.formAttribute
  //     if (!formAttribute) {
  //       return ElMessage.warning('缺少表单属性')
  //     }
  //     const { dataType } = formAttribute
  //     // 表单 - 数据集方式 | 自定API地址
  //     dataType === FORM_DATA_TYPE.datasource ? datasourceFormSubmit() : customApiFormSubmit()
  //   })
  // }

  /**
   * 更新 '跳转值'
   */
  const updateJumpValue = () => {
    const events = getWigetEventAndComParamInfo(widgetData.list)
    if (events?.comParam?.length) {
      // 跳转值
      const jumpValueKeyList = events?.comParam
        .filter((i) => i.label === '跳转值')
        .map((i) => i.key)

      const updateIndexList = [] as any

      if (jumpValueKeyList?.length) {
        widgetData.list.forEach((comp, index) => {
          const comParamKey = comp?.prop?.comParam
          if (jumpValueKeyList.includes(comParamKey)) {
            // 需要更新组件
            updateIndexList.push(index)
          }
        })
      }

      if (updateIndexList?.length) {
        updateIndexList.forEach((index: number) => {
          const item = cloneDeep(widgetData.list[index])
          // 触发组件中的 watch, 重新 buildOption
          widgetData.list.splice(index, 1, item)
        })
      }
    }
  }

  onActivated(() => {
    // initPageInfo()
    widgetEvent.all.clear()
    console.log('onActivated-------------', route?.path, widgetEvent, eventStore)

    // 选中当前
    const key = route?.params?.pageId as string
    if (key) {
      projectStore.updateActivatedRouteKey(key)
    }

    // 更新 '跳转值'
    updateJumpValue()
  })
  onDeactivated(() => {
    widgetEvent.all.clear()
    console.log('onDeactivated =========', route?.path, widgetEvent)
  })
</script>

<style scoped lang="scss">
  .form-page-submit-container {
    margin-top: 32px;
    text-align: center;
    .btn {
      width: 100px;
    }
    .cancle {
      margin-left: 80px;
    }
  }
</style>
