/**
 * 组件全局参数
 */
export const GLOBAL_CONSTANT = {
  LOAD_DATA_REPEAT_DELAY: 300 // 重复执行加载事件延迟数
}

export const momentValueFormat = {
  datetimerange: 'YYYYMMDDHHmmss',
  minuterange: 'YYYYMMDDHHmm',
  hourrange: 'YYYYMMDDHH',
  daterange: 'YYYYMMDDHH',
  monthrange: 'YYYYMMDDHH',
  datetimepoint: 'HHmmss',
  minutepoint: 'HHmm',
  hourpoint: 'HH',
  datepoint: 'YYYYMMDD',
  monthpoint: 'YYYYMM'
}

/**
 * 时间组件设定类型转换成UI库可识别类型
 */
export const composeType2Type = {
  datetimerange: 'datetimerange',
  minuterange: 'datetimerange',
  hourrange: 'datetimerange',
  daterange: 'daterange',
  monthrange: 'monthrange',
  datetimepoint: 'datetime',
  minutepoint: 'datetime',
  hourpoint: 'datetime',
  datepoint: 'date',
  monthpoint: 'month',
  datetimeSinglepoint: 'datetime'
}
// 时间格式和其对应的值格式
export const composeType2value = {
  datetimerange: 'YYYYMMDDHHmmss',
  minuterange: 'YYYYMMDDHHmm',
  hourrange: 'YYYYMMDDHH',
  daterange: 'YYYYMMDD',
  monthrange: 'YYYYMM',
  datetimepoint: 'HHmmss',
  minutepoint: 'HHmm',
  hourpoint: 'HH',
  datepoint: 'YYYYMMDD',
  monthpoint: 'YYYYMM',
  datetimeSinglepoint: 'YYYYMMDDHHmmss'
}
// 时间格式和其对应的展示格式
export const composeType2ShowFormat = {
  datetimerange: 'YYYY-MM-DD HH:mm:ss',
  minuterange: 'YYYY-MM-DD HH:mm',
  hourrange: 'YYYY-MM-DD HH',
  daterange: 'YYYY-MM-DD',
  monthrange: 'YYYY-MM',
  datetimepoint: 'HH:mm:ss',
  minutepoint: 'HH:mm',
  hourpoint: 'HH',
  datepoint: 'YYYY-MM-DD',
  monthpoint: 'YYYY-MM',
  datetimeSinglepoint: 'YYYY-MM-DD HH:mm:ss'
}

export const mapping4color: any = {
  max: {
    label: '最大值',
    color: '#2B9F9F'
  },
  min: {
    label: '最小值',
    color: '#A25A40'
  },
  average: {
    label: '平均值',
    color: '#097CE2'
  },
  median: {
    label: '中位数',
    color: '#6D91B6'
  }
}

const businessColors = [
  ['rgba(8, 139, 255, 1)', 'rgba(19, 75, 125, 1)'],
  ['rgba(53, 255, 244, 1)', 'rgba(39, 127, 123, 1)'],
  ['rgba(236, 168, 80, 1)', 'rgba(116, 87, 50, 1)'],
  ['rgba(122, 163, 204, 1)', 'rgba(68, 85, 104, 1)'],
  ['rgba(0, 174, 239, 1)', 'rgba(16, 92, 120, 1)'],
  ['rgba(245, 146, 110, 1)', 'rgba(121, 79, 64, 1)'],
  ['rgba(97, 181, 255, 1)', 'rgba(58, 95, 128, 1)'],
  ['rgba(125, 223, 223, 1)', 'rgba(71, 113, 114, 1)'],
  ['rgba(243, 199, 143, 1)', 'rgba(119, 101, 77, 1)'],
  ['rgba(170, 196, 222, 1)', 'rgba(90, 102, 114, 1)'],
  ['rgba(92, 204, 245, 1)', 'rgba(56, 105, 124, 1)'],
  ['rgba(250, 191, 169, 1)', 'rgba(124, 98, 90, 1)']
]
const securityColors = [
  ['rgba(51, 204, 204, 1)', 'rgba(38, 103, 104, 1)'],
  ['rgba(236, 168, 80, 1)', 'rgba(119, 89, 51, 1)'],
  ['rgba(122, 163, 204, 1)', 'rgba(68, 85, 103, 1)'],
  ['rgba(0, 174, 239, 1)', 'rgba(16, 90, 119, 1)'],
  ['rgba(58, 198, 106, 1)', 'rgba(41, 102, 62, 1)'],
  ['rgba(246, 147, 110, 1)', 'rgba(121, 79, 64, 1)'],
  ['rgba(124, 222, 222, 1)', 'rgba(70, 112, 113, 1)'],
  ['rgba(243, 199, 143, 1)', 'rgba(122, 103, 79, 1)'],
  ['rgba(170, 196, 222, 1)', 'rgba(88, 100, 111, 1)'],
  ['rgba(92, 204, 245, 1)', 'rgba(56, 105, 124, 1)'],
  ['rgba(112, 213, 146, 1)', 'rgba(65, 109, 81, 1)'],
  ['rgba(250, 191, 169, 1)', 'rgba(123, 97, 89, 1)']
]
export function genLinearColor(colors: string[][]): any[] {
  return colors.map((color: string[]) => {
    return {
      type: 'linear',
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [
        {
          offset: 0,
          color: color[0]
        },
        {
          offset: 1,
          color: color[1]
        }
      ]
    }
  })
}

export const businessColor = genLinearColor(businessColors)
export const securityColor = genLinearColor(securityColors)
