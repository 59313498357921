<template>
  <div class="my-initiate message-page">
    <div class="top-search">
      <div class="query-box">
        <el-input
          v-model="searinfo.txt"
          placeholder="请输入搜索内容"
          clearable
          @input="inputDebounce"
        />
        <svg-icon class="search" icon-class="search" />
      </div>
      <el-pagination
        background
        small
        layout="prev, pager, next, total"
        :pager-count="5"
        :current-page="searinfo.page"
        :page-size="searinfo.size"
        :total="searinfo.total"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
    <ResultList
      v-if="searinfo?.data?.length"
      v-loading="searinfo.loading"
      :data-list="searinfo.data"
      :page-string="'MyInitiate'"
      @go-detail="handleOpen"
    >
      <template #default="defaultProps">
        <el-button
          v-if="defaultProps.finishTime === null"
          type="danger"
          @click="handleDiscontinue(defaultProps)"
          >终止</el-button
        >
      </template>
    </ResultList>
    <EmptyBox v-else :title="searinfo.txt"></EmptyBox>
    <MyInitiateDiaLog ref="MyInitiateDiaLogRef" :page-string="'MyInitiate'"></MyInitiateDiaLog>
  </div>
</template>
<script lang="ts">
  export default {
    name: 'MyInitiate'
  }
</script>
<script setup lang="ts">
  import ResultList from './components/ResultList.vue'
  import MyInitiateDiaLog from './components/MyInitiateDiaLog.vue'
  import EmptyBox from '../../components/EmptyBox/EmptyBox.vue'

  import { debounce } from 'lodash-es'
  import { ref, reactive, onBeforeMount, computed } from 'vue'
  import { ElMessageBox, ElMessage } from 'element-plus'
  import { popverList, popverTime } from './constant'
  import { queryFlowInst, stopFlowInst } from '@haohan/clw-api'
  import { useProject } from '@haohan/clw-store'
  import { getTaskInfo } from '../../utils/workflow-tool'

  const projectStore = useProject()
  const projectId = computed(() => {
    return projectStore.currentProject?.id
  })

  // const popverListRef = ref('全部')
  // const visibleList = ref(false)
  // function popverListChange(label) {
  //   popverListRef.value = label
  //   visibleList.value = false
  //   getList()
  // }

  // const popverTimeRef = ref('更新时间')
  // const visibleTime = ref(false)
  // function handleChangeTime(label) {
  //   popverTimeRef.value = label
  //   visibleTime.value = false
  //   getList()
  // }

  const MyInitiateDiaLogRef = ref(null)

  function handleOpen(row) {
    // console.log(id)
    // 处理row数据，增加显示信息
    MyInitiateDiaLogRef?.value.open({ ...row, taskInfo: getTaskInfo('MyInitiate', row) })
  }

  const handleDiscontinue = (row) => {
    ElMessageBox.confirm(`您确认要终止“${row.flowDef?.name}”表单吗？`, '', {
      confirmButtonText: '终止',
      cancelButtonText: '再想想',
      type: 'warning',
      customClass: 'custom-message-box-confirm',
      cancelButtonClass: 'custom-message-box-confirm-cancelBtn',
      confirmButtonClass: 'custom-message-box-confirm-confirmBtn'
    }).then(async () => {
      const res = await stopFlowInst(projectId.value, row.id)
      if (res.status === 200) {
        ElMessage.success('终止成功')
        getList()
      }
    })
  }

  const searinfo = reactive({
    txt: '',
    data: [
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // }
    ],
    page: 1,
    size: 10,
    total: 0,
    loading: false
  })
  function getList() {
    // console.log(1)
    searinfo.loading = true
    const params = {
      keyword: searinfo.txt,
      page: searinfo.page,
      size: searinfo.size
    } as any
    queryFlowInst(projectId.value, params).then((res) => {
      console.log(res)
      const { data } = res
      searinfo.total = data.totalItems || 0
      searinfo.data = data.data
      searinfo.loading = false
    })
  }

  const inputDebounce = debounce(() => {
    searinfo.page = 1
    getList()
  }, 200)

  function pageChange(val: number) {
    searinfo.page = val
    getList()
  }
  function sizeChange(val: number) {
    searinfo.size = val
    getList()
  }

  onBeforeMount(() => {
    getList()
  })
</script>

<style lang="scss">
  .custom-message-box-confirm {
    padding-bottom: 30px !important;
    .el-message-box__message {
      font-weight: 600;
      font-size: 14px;
      color: #1a1d1f;
    }
    .el-message-box__close {
      color: #0076de;
    }
    .el-message-box__btns {
      display: flex;
      justify-content: center; /* 控制按钮对齐方式 */
      gap: 70px; /* 调整按钮间距 */
      .custom-message-box-confirm-confirmBtn {
        order: -1;
        background: red;
        border: none;
      }
      .custom-message-box-confirm-cancelBtn {
        color: #0076de;
      }
    }
  }
  /*覆盖 Element Plus 样式 */
  .my-initiate-popper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .popver-content-item {
      padding: 0 12px;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
    }
    .active {
      background: #e7f2fc;
      font-size: 14px;
      color: #0076de;
    }
  }
</style>
<style scoped lang="scss">
  .message-page {
    position: relative;
  }
  .top-search {
    position: absolute;
    right: 0px !important;
    .query-select {
      margin-right: 40px;
      display: flex;
      gap: 40px;
      .select-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        .label {
          margin: 0 8px;
          font-size: 14px;
          color: #606266;
        }
        .right-arrow {
          width: 0.5em;
          height: 0.5em;
        }
      }
    }
  }
  .query-box {
    position: relative;
    width: 326px;
    margin-right: 36px;
    :deep() {
      .el-input {
        height: 36px;
        overflow: hidden;
      }
      .el-input__wrapper {
        width: 100%;
        height: 100%;
        background-color: #fff !important;
        padding-left: 34px;
        box-shadow: none;
      }
      .el-input__inner {
        color: #1a1d1f;
      }
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
</style>
