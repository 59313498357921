const TokenKey = 'ClW-Token'
const ThemeKey = 'ClW-theme'

export function getToken(): string {
  // return Cookies.get(TokenKey) as string
  const token = getWithExpiry(TokenKey)
  // 更新过期时间
  if (token !== null) {
    setWithExpiry(TokenKey, token)
  }
  return token
}

export function setToken(token: string, isAuto = false) {
  // console.log('[ token ]-10', token)
  if (isAuto) {
    // return Cookies.set(TokenKey, token, { expires: 100 })
    // 设置100天过期时间
    return setWithExpiry(TokenKey, token, 864000000)
  }
  // 设置半小时过期时间
  return setWithExpiry(TokenKey, token)
  // return Cookies.set(TokenKey, token)
}

export function removeToken() {
  // return Cookies.remove(TokenKey)
  localStorage.removeItem(TokenKey)
}
/**
 * 云视域平台对接portal时默认登录 portal用户
 */
export function setClwPortalToken() {
  console.log('portal 登录')
  // 登录用户为portal
  // return Cookies.set(
  //   TokenKey,
  //   'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJqdGkiOiI4OTkyNjYwNTc1NjEzMTMyODAiLCJpc3MiOiJIQU9IQU5fWkVSTyIsImlhdCI6MTY5NDU2OTMwOCwic3ViIjoiUG9ydGFs55So5oi3IiwiZXhwIjoyNTU2MDI4ODU4fQ._QbjyPCzCCZif9xIYlc5Y3dc83zHIJKgtyli4H9ADsg'
  // )
  return setWithExpiry(
    TokenKey,
    'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJqdGkiOiI4OTkyNjYwNTc1NjEzMTMyODAiLCJpc3MiOiJIQU9IQU5fWkVSTyIsImlhdCI6MTY5NDU2OTMwOCwic3ViIjoiUG9ydGFs55So5oi3IiwiZXhwIjoyNTU2MDI4ODU4fQ._QbjyPCzCCZif9xIYlc5Y3dc83zHIJKgtyli4H9ADsg'
  )
  // 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJqdGkiOiIxIiwiaXNzIjoiSGFvaGFuX1plcm8iLCJpYXQiOjE2MTAzNTU1MTYsInN1YiI6ImFkbWluIiwiZXhwIjoxNjEwMzU5MTc2fQ.qFEHjUTfOYYqap1_t3YcMnJTbubLzzCoMzEL-lv6H5Q'
}
/**
 * 云视域项目对接portal时默认登录admin用户
 */
export function setProjectPortalToken() {
  console.log('portal 登录')
  // 登录用户为admin
  // return Cookies.set(
  //   TokenKey,
  //   'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJqdGkiOiIxIiwiaXNzIjoiSGFvaGFuX1plcm8iLCJpYXQiOjE2MTAzNTU1MTYsInN1YiI6ImFkbWluIiwiZXhwIjoxNjEwMzU5MTc2fQ.qFEHjUTfOYYqap1_t3YcMnJTbubLzzCoMzEL-lv6H5Q'
  // )
  return setWithExpiry(
    TokenKey,
    'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFMyNTYifQ.eyJqdGkiOiIxIiwiaXNzIjoiSGFvaGFuX1plcm8iLCJpYXQiOjE2MTAzNTU1MTYsInN1YiI6ImFkbWluIiwiZXhwIjoxNjEwMzU5MTc2fQ.qFEHjUTfOYYqap1_t3YcMnJTbubLzzCoMzEL-lv6H5Q'
  )
}

export function getTheme() {
  // return Cookies.get(ThemeKey)
  return localStorage.getItem(ThemeKey)
}

export function setTheme(theme?: string) {
  if (theme) {
    localStorage.setItem(ThemeKey, theme)
  }
  // theme && localStorage.setItem(ThemeKey, theme)
}
export function removeTheme() {
  localStorage.removeItem(ThemeKey)
}

// 设置数据并指定过期时间（以毫秒为单位）
function setWithExpiry(key: string, value: string, ttl = 1800000) {
  const now = new Date().getTime()
  const item = {
    value: value, // 实际存储的数据
    expiry: now + ttl // 过期时间 = 当前时间 + ttl（毫秒）
  }
  localStorage.setItem(key, JSON.stringify(item))
  return value
}

// 获取数据并检查是否过期
function getWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key)
  // 如果数据不存在，返回 null
  if (!itemStr) {
    return null
  }

  const item = JSON.parse(itemStr)
  const now = new Date().getTime()

  // 如果数据已过期，删除数据并返回 null
  if (now > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  // 返回未过期的数据
  return item.value
}
