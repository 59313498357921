import axios from 'axios'

/**
 * API
 * time 表示可缓存的时间，默认为 0，在没有清除内存之前永久缓存（浏览器窗口标签关闭，应用程序关闭等会清除内存）
 *
 * 防止重复提交
 * time 设置一个极短的时间，比如 1000，就表示在1000毫秒之内不会重复向服务器发出请求
 */

// 数据存储
export const cache = {
  data: {} as any,
  set(key: any, data: any) {
    this.data[key] = data
  },
  get(key: any) {
    return this.data[key]
  },
  clear(key: any) {
    delete this.data[key]
  }
}

// 建立唯一的key值
export const buildUniqueUrl = (url: string, method: string, params = {}, data = {}) => {
  const paramStr = (obj: object) => {
    if (toString.call(obj) === '[object Object]') {
      return JSON.stringify(
        Object.keys(obj)
          .sort()
          .reduce((result, key) => {
            result[key] = obj[key]
            return result
          }, {})
      )
    }
    return JSON.stringify(obj)
  }
  url += `?${paramStr(params)}&${paramStr(data)}&${method}`
  return url
}

/**
 * @param {object} options
 * @param {number} options.time=0 默认缓存
 */
export default ({ time = 0 } = {}) =>
  async (config: any) => {
    config.url = config.url.replace(/t=\d+&?/, '')
    const index = buildUniqueUrl(config.url, config.method, config.params, config.data)
    let responsePromise = cache.get(index)
    if (!responsePromise) {
      responsePromise = (async () => {
        try {
          const response = await axios.defaults.adapter!(config)
          // console.log('[ response ]-58', response.data)
          return Promise.resolve(response)
        } catch (reason) {
          cache.clear(index)
          return Promise.reject(reason)
        }
      })()
      cache.set(index, responsePromise)
      if (time !== 0) {
        setTimeout(() => {
          cache.clear(index)
        }, time)
      }
    }
    return responsePromise.then((data: any) => JSON.parse(JSON.stringify(data))) // 为防止数据源污染
  }
