import dayjs from 'dayjs'
import { http } from './http'
import { nanoid } from 'nanoid'

/**
 * 格式化浮点数
 */
export const fomatFloat = (num: number, n: number) => {
  let f = parseFloat(`${num}`)
  if (isNaN(f)) {
    return 0
  }
  f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n) // n 幂
  return f
  // let s = f.toString()
  // let rs = s.indexOf('.')
  // // 判定如果是整数，增加小数点再补0
  // if (rs < 0) {
  //   rs = s.length
  //   s += '.'
  // }
  // while (s.length <= rs + n) {
  //   s += '0'
  // }
  // return s
}

/**
 * 格式化时间
 */
export function dealTime(time?: string) {
  return time?.split('.')[0]
}

export function formatTime(time: string, type: string) {
  return dayjs(time).format(type)
}
// 生成随机数坐标
function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min) + min)
}
// 生成随机坐标的不重叠圆
export function drawCircles(maxLength: number, radius: number, max_x: number, max_y: number) {
  const circles = []
  let anti_crash_count = 0
  while (circles.length < maxLength) {
    const circle = {
      x: getRandomNumber(radius, max_x - radius),
      y: getRandomNumber(radius, max_y - radius),
      radius
    }

    let isOverlapping = false

    for (let j = 0; j < circles.length; j++) {
      const previous_circle = circles[j]
      const distance = Math.hypot(circle.x - previous_circle.x, circle.y - previous_circle.y)

      if (distance < circle.radius + previous_circle.radius) {
        isOverlapping = true
        break
      }
    }

    if (!isOverlapping) {
      circles.push(circle)
    }
    anti_crash_count++
    if (anti_crash_count > 10000) {
      break
    }
  }
  return circles
}

// 获取地图json数据
export function getLOcalGeoJson(name: string) {
  return http.request({
    method: 'get',
    url: `/api/v1/map/data/${name}`
  })
}
function sum(x: number, y: number) {
  return x + y
}
function square(x: number) {
  return x * x
}

/**
 * 返回数组标准差
 * @param data 数据数组
 * @returns
 */
export function stddev(data: number[]) {
  const mean = data.reduce(sum) / data.length
  const deviations = data.map(function (x) {
    return x - mean
  })
  return Math.sqrt(deviations.map(square).reduce(sum) / (data.length - 1))
}

/**
 * 返回某个prop是否在obj中
 * @param obj
 * @param prop
 * @returns
 */
export function hasProp(obj: any, prop: string) {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

/**
 * 分组
 * @param arr
 * @param key
 * @returns
 */
export function groupBy(arr: any[], key: string) {
  return arr.reduce((acc, cur) => {
    if (Object.prototype.hasOwnProperty.call(acc, cur[key])) {
      acc[cur[key]].push(cur)
    } else {
      acc[cur[key]] = [].concat(cur)
    }
    return acc
  }, {})
}

export function fillText(text: string, fontSize = 16, width = 100) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')!
  const textWidth = ctx.measureText(text).width
  canvas.width = width > textWidth ? width : Math.round(textWidth * 1.6)
  canvas.height = fontSize
  // console.log(textWidth)

  ctx.font = `${fontSize}px Avenir, Helvetica, Arial, sans-serif`
  ctx.textAlign = 'center'

  // const scaleFactor = Math.min(1, width / textWidth)
  // ctx.translate(width / 2, height / 2)
  // ctx.scale(scaleFactor, 1)
  ctx.fillStyle = '#fff'
  ctx.fillText(text, Math.round(canvas.width / 2), Math.floor(fontSize * 0.85))
  return canvas
}

/**
 * 返回单个颜色或多个颜色的渐变色
 * @param oc
 * @returns
 */
export function composeColor(oc: string | any) {
  if (typeof oc === 'string') {
    return oc
  }
  // console.log(JSON.stringify(oc))
  const { type, angle, list } = oc
  if (list?.length === 0) {
    return ''
  }
  if (list?.length === 1) {
    return list[0].color
  }
  const color = list.map((item: any) => `${item.color} ${item.step}%`).join(',')
  return `linear-gradient(${angle}deg,${color})`
}

/**
 * 获取时间差
 */
export function getTimeDifference(start: any, end?: any) {
  const startDayjs = dayjs(start)
  const endDayjs = dayjs(end)

  // const diffInMilliseconds = endDayjs.diff(startDayjs)
  const diffInMinutes = endDayjs.diff(startDayjs, 'minute')
  const diffInHours = endDayjs.diff(startDayjs, 'hour')
  const diffInDays = endDayjs.diff(startDayjs, 'day')

  if (diffInDays > 0) {
    return `${diffInDays}天`
  } else if (diffInHours > 0) {
    return `${diffInHours}小时`
  }
  return `${diffInMinutes}分钟`
}

/**
 * 密码校验：字母支持大小写
 * 必须包含数字、字母和特殊字符
 * 长度支持8到15位
 */
export function validPassword(str: string) {
  const reg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_]).{8,15}$/
  return reg.test(str)
}

/**
 * 唯一id生成器
 */
export function genNanoid() {
  return nanoid()
}

/**
 * 根据表达式计算值
 */
function evaluateExpression(value1: any, value2: any, operator: string) {
  const operators: { [key: string]: any } = {
    '==': (a: any, b: any) => a == b,
    '!=': (a: any, b: any) => a != b,
    '>': (a: any, b: any) => a > b,
    '<': (a: any, b: any) => a < b
  }
  if (operators[operator]) {
    return operators[operator](value1, value2)
  }
  throw new Error('不支持的操作符: ' + operator)
}
/**
 * 根据受控类型，判断对应的返回是否为真
 */
export function judgeEnslavementFn(arr: any[], data: any) {
  const obj: any = {
    hide: false,
    disable: false
  }
  if (!arr.length) {
    return obj
  }
  for (let ix = 0, len = arr?.length; ix < len; ix++) {
    const { type, condition } = arr[ix]
    const flag =
      condition.length &&
      condition.some((item: any) => evaluateExpression(data[item.key], item.value, item.operator))
    obj[type] = flag
  }
  return obj
}

/**
 * 返回组件中的子组件，兼容容器，面板等组件
 */
export function getCompChildren(child: Widget): any {
  if (child.children && Array.isArray(child.children) && child.children.length > 0) {
    return child.children
  }
  const defaultData = child.dataProp?.defaultData
  if (
    Array.isArray(defaultData) &&
    defaultData.every((item: any) => item.list !== undefined) &&
    defaultData.some((item: any) => item.list.length > 0)
  ) {
    return defaultData
  }
  return []
}

export function hasChildrenFn(el: Widget) {
  return el.children && Array.isArray(el.children) && el.children.length > 0
}
export function hasListComsFn(el: Widget) {
  const defaultData = el.dataProp?.defaultData
  if (
    Array.isArray(defaultData) &&
    defaultData.every((item: any) => item.list !== undefined) &&
    defaultData.some((item: any) => item.list.length > 0)
  ) {
    return true
  }
  return false
}

export function hex2Rgba(hex: string, alpha: number) {
  hex = hex.replace(/^#/, '')
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(function (hexChar) {
        return hexChar + hexChar
      })
      .join('')
  }
  // 解析红、绿、蓝通道的值
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  // 返回RGBA格式的颜色值
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

/**
 * 替代eval函数
 */
export function evalFn(obj: any) {
  return Function('"use strict";return (' + obj + ')')()
}

const panelType = ['HHTabPaneWidget', 'BigScreenPanel']
/**
 * 根据key值返回其panel种的父节点
 */
export function findParentNode(trees: Widget[], key: number | string): Widget | undefined {
  function traverseAndFindParent(
    nodes: Widget[],
    key: number | string,
    parent?: Widget
  ): Widget | undefined {
    for (const node of nodes) {
      if (node.key === key) {
        return parent
      }
      const children = getCompChildren(node)
      if (children.length > 0) {
        const parentNode = findInList(children, key, node)
        if (parentNode) {
          return parentNode
        }
      }
    }
    return undefined
  }
  function findInList(
    listData: { list: Widget[] }[],
    key: number | string,
    parent: Widget
  ): Widget | undefined {
    for (const item of listData) {
      const result = traverseAndFindParent(item.list, key, parent)
      if (result) {
        return result
      }
    }
    return undefined
  }
  const panelTrees = trees.filter((item) => panelType.includes(item.type))
  return traverseAndFindParent(panelTrees, key)
}
/**
 * 查找面板的子组件
 */
export function findPanelChildren(panel: Widget, key: number | string): any {
  function traverseFind(nodes: Widget[], key: any): boolean {
    for (let i = nodes.length - 1; i >= 0; i--) {
      const node = nodes[i]
      // console.log('node.key', node.key)
      if (node.key === key) {
        return true
      }
      const children = getCompChildren(node)
      if (children.length) {
        for (const childObj of children) {
          const isEnd = traverseFind(childObj.list, key)
          if (isEnd) {
            return true
          }
        }
      }
    }
    return false
  }
  const children = panel?.dataProp!.defaultData
  for (let ix = 0, len = children.length; ix < len; ix++) {
    const item = children[ix]
    const isEnd = traverseFind(item.list, key)
    if (isEnd) {
      return { item, ix }
    }
  }
  return {
    item: null,
    ix: -1
  }
}

/**
 * 根据key值删除数组中的元素
 */
export function rmByKey(array: any[], keyVal: any, keyProp?: string) {
  if (keyProp) {
    const ix = array.findIndex((item) => item[keyProp] === keyVal)
    if (ix !== -1) {
      array.splice(ix, 1)
    }
    return array
  }
  const ix = array.findIndex((item) => item === keyVal)
  if (ix !== -1) {
    array.splice(ix, 1)
  }
  return array
}

/**
 * 本地存储
 * 记录用户操作
 */
export class RECORD {
  // record: { [key: string]: string } = {}
  private localKey: string
  constructor(key: string) {
    this.localKey = key
    this._init()
  }
  /**
   * 初始化存储对象
   */
  _init() {
    const record = localStorage.getItem(this.localKey)
    if (!record) {
      localStorage.setItem(this.localKey, JSON.stringify({}))
    }
  }
  /**
   * 获取存储对象
   */
  _getStorage() {
    return JSON.parse(localStorage.getItem(this.localKey) || '{}')
  }
  /**
   * 设置存储对象
   */
  _setStorage(obj: any) {
    localStorage.setItem(this.localKey, JSON.stringify(obj))
  }
  /**
   * 设置记录
   */
  set(key: string, value: any) {
    const record = this._getStorage()
    record[key] = value
    this._setStorage(record)
  }
  /**
   * 获取记录
   */
  get(key: string, dv?: any) {
    const record = this._getStorage()
    return record[key] || dv
  }
  /**
   * 删除记录，恢复默认行为
   */
  remove(key: string) {
    const record = this._getStorage()
    if (key in record) {
      delete record[key]
      this._setStorage(record)
    }
  }
  clear() {
    this._setStorage({})
  }
  has(key: string) {
    const storageObject = this._getStorage()
    return key in storageObject
  }
  getAll() {
    return this._getStorage()
  }
  clearAll() {
    localStorage.clear()
  }
}

/**
 * 清除记录
 */
export function recordClearAll() {
  const keys = ['custom_query', 'table-log']
  for (const item of keys) {
    localStorage.removeItem(item)
  }
}

/**
 * 格式化浮点数
 * 非数字原样返回
 */
export const fomatFloatV2 = (num: any, n = 1) => {
  if (isNaN(parseFloat(`${num}`))) {
    return num
  }
  return Math.round(num * Math.pow(10, n)) / Math.pow(10, n)
}
