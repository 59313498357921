import { compAttr, styleProp, dataProp } from './common-comp-prop'

const list = [
  // 翻页
  {
    imageSrc: 'turn-page',
    thumbnail: '',
    title: '翻页',
    type: 'HHTurnPage',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '翻页',
            value: 'pageChange'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '当前页码',
            value: 'currentPage'
          },
          {
            label: '显示值',
            value: 'showValues'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: []
      }
    },
    styleProp: {
      width: null,
      height: 20
    },
    prop: {
      showTemplate: 'type-1'
    },
    dataProp: {
      defaultData: [6],
      // 显示列
      showCol: ''
    }
  },
  // 刷新
  {
    imageSrc: 'refresh-btn',
    thumbnail: '',
    title: '刷新',
    type: 'HHRefreshBtn',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {}
  },
  // 导出
  {
    imageSrc: 'export-btn',
    thumbnail: '',
    title: '导出',
    type: 'HHExportBtn',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'ExportDataSource',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '监听事件值',
            value: 'receiveEvent'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      title: '导出',
      // 导出文件名
      exportName: '',
      comParam: '',
      comParamLabel: '',
      exportNumber: 1000,
      canExport: [
        {
          text: 'XLSX格式',
          label: 'xlsx',
          value: true
        },
        {
          text: 'CSV格式',
          label: 'csv',
          value: false
        },
        {
          text: 'XML格式',
          label: 'xml',
          value: false
        }
      ]
    },
    dataProp: {
      defaultData: [],
      // cols: [],
      // colType: 'fixed',
      // row2ColInfo: {
      //   fixCol: '', // 固定列
      //   fixColName: '', // 固定列名称
      //   rowCol: '', // 转成列得列
      //   valueCol: '' // 值列
      // },
      // sheet 页
      exportInfos: []
    }
  },
  // 截屏
  {
    imageSrc: 'screenshot-btn',
    thumbnail: '',
    title: '截屏',
    type: 'HHScreenshotBtn',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '点击',
            value: 'click'
          }
        ],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '监听事件值',
            value: 'receiveEvent'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      title: '截屏',
      // 导出文件名
      exportName: ''
    }
  },
  // 资源下载
  {
    imageSrc: 'HHAssetsWidgeIcon',
    thumbnail: '',
    title: '资源下载',
    type: 'HHAssetsWidge',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '下载完成',
            value: 'downOverSend'
          }
        ],
        bindValues: []
      },
      receive: {
        operationTypes: [
          // {
          //   label: '下载',
          //   value: 'downReceive'
          // }
        ]
      }
    },
    prop: {
      txt: '资源下载', // 文案
      size: 'large', // 尺寸
      type: 'primary', // 功能
      style: '', // 风格
      icon: '', // 图标
      fill: true, // 填充
      border: false, // 边框
      underline: false, // 下划线
      // options: [], // 内容
      // status: true, // 状态
      assets: '' // 资源
    },
    styleProp: {
      width: null,
      height: null
    }
  },
  // 报表
  {
    imageSrc: 'report',
    thumbnail: '',
    title: '报表',
    type: 'HHReport',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [],
        // 可绑定得值
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '导出',
            value: 'export'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: 800
    },
    prop: {
      exportName: ''
    },
    dataProp: {
      workbookInfo: {},
      sheetQueryParamInfo: {}
    }
  },
  // 步骤条
  {
    imageSrc: 'HHStep',
    thumbnail: '',
    title: '步骤条',
    type: 'HHStep',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '步骤变化',
            value: 'stepChange'
          }
        ],
        bindValues: [
          {
            label: '当前步骤',
            value: 'currentValue'
          },
          {
            label: '值2',
            value: 'currentValue2'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      pointSize: '', // 圆点大小
      pointBg: true, // 圆点背景
      layout: 'horizontal', // 布局
      titlePos: 'bottom', // 标题位置
      operate: 'default', // 操作 txt
      pointShow: 'num', // 圆内展示 num,icon
      doneColor: '#0076DE', // 已完成颜色
      undoneColor: '#606266', // 待完成颜色
      iconMapping: {} // 图标映射
    },
    dataProp: {
      defaultData: [
        {
          label: '步骤一',
          value: 1,
          value2: '',
          list: []
        },
        {
          label: '步骤二',
          value: 2,
          value2: '',
          list: []
        },
        {
          label: '步骤三',
          value: 3,
          value2: '',
          list: []
        }
      ]
    }
  }
]

for (let i = 0, len = list.length; i < len; i++) {
  const item = list[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  if (item.dataProp) {
    item.dataProp = { ...dataProp, ...item.dataProp }
  }
  list[i] = { ...compAttr, ...item }
}

export default list
