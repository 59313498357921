import { App } from 'vue'
const installFocusDirective = (app: App): void => {
  app.directive('focus', {
    // 当元素挂载时
    mounted(el: any) {
      nextTick(() => {
        // 聚焦元素
        if (el.getElementsByTagName('input').length > 0) {
          el.getElementsByTagName('input')[0].focus()
        }
      })
    }
  })
}
export { installFocusDirective }
