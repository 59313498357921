import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { useProject } from './project'

export const useApp = defineStore({
  id: 'app',
  getters: {
    selectedDateRange: (state) => {
      const project = useProject()
      const globalDefaultTime =
        project.currentProject?.settings?.globalDefaultTime === undefined
          ? -1
          : project.currentProject.settings.globalDefaultTime
      const yesterday = dayjs().add(globalDefaultTime, 'day')
      // console.log('globalDefaultTime', globalDefaultTime)
      if (state.dataRange.length) {
        return state.dataRange
      }
      return [
        yesterday.startOf('date').format('YYYYMMDDHHmmss'),
        yesterday.endOf('date').format('YYYYMMDDHHmmss')
      ]
    }
    // anonymous: (state) => !state.current.username
  },
  state: () => {
    return {
      // selectedDateRange: [`${yesterday}00`, `${yesterday}23`],
      dataRange: [] as string[]
    }
  },
  actions: {
    setDateRange(range: string[]) {
      this.dataRange = range
    }
  }
})
