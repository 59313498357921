import WorkflowMessage from '@haohan/cloud_view_workflow/src/views/settings-dashboard/index.vue'
import WorkflowPage from '@haohan/cloud_view_workflow/src/views/settings-dashboard/WorkFlowPage.vue'

export const previewRouteschildren = [
  {
    path: '',
    name: 'previewLayoutIndex',
    component: () => import('./views/preview/index.vue')
  },
  {
    path: 'userinfo',
    name: 'userinfo',
    component: () => import('./views/userinfo/index.vue')
  },
  {
    path: 'sys',
    name: 'system',
    component: () => import('./views/sys/index.vue'),
    redirect: { name: 'system-user' },
    children: [
      {
        path: 'user',
        name: 'system-user',
        component: () => import('./views/sys/user.vue'),
        meta: { title: '用户管理' }
      },
      {
        path: 'role',
        name: 'system-role',
        component: () => import('./views/sys/role.vue'),
        meta: { title: '角色管理' }
      },
      {
        path: 'power',
        name: 'system-permission',
        component: () => import('./views/sys/permission.vue'),
        meta: { title: '权限管理' }
      },
      {
        path: 'logs',
        name: 'system-logs',
        component: () => import('./views/sys/logs.vue'),
        meta: { title: '日志' }
      },
      {
        path: 'alarm',
        name: 'system-alarm',
        component: () => import('./views/sys/alarm.vue'),
        meta: { title: '告警配置' }
      },
      {
        path: 'setting',
        name: 'system-setting',
        component: () => import('./views/sys/setting.vue'),
        meta: { title: '配置管理' }
      },
      {
        path: 'belong',
        name: 'system-belong',
        component: () => import('./views/sys/belong.vue'),
        meta: { title: '归属地' }
      },
      {
        path: 'token',
        name: 'token-setting',
        component: () => import('./views/sys/token/index.vue'),
        meta: { title: 'Token管理' }
      },
      {
        path: 'workflow',
        name: 'system-workflow',
        component: () => import('./views/sys/WorkFlow.vue'),
        meta: { title: '工作流管理' }
      }
    ]
  },
  {
    path: 'user-message',
    name: 'UserMessage',
    component: WorkflowMessage
  },
  {
    path: 'work-flow-page',
    name: 'WorkflowPage',
    component: WorkflowPage
  }
]

export const previewRoutes: Array<any> = [
  {
    path: '/preview/:projectId/:pageId',
    name: 'previewLayout',
    component: () => import('./layout/index.vue'),
    children: previewRouteschildren
  },
  {
    path: '/pv-login',
    name: 'previewLogin',
    component: () => import('./views/login/login.vue')
  },
  {
    path: '/bigscreen/:projectId/:pageId',
    name: 'bigScreenLayout',
    component: () => import('./layout/BigScreenLayout.vue'),
    children: [
      {
        path: '',
        name: 'bigScreenLayout',
        component: () => import('./views/big-screen/index.vue')
      }
    ]
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import('./views/portal/index.vue')
  }
]
