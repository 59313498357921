import { http, getToken, interfaceService } from '@haohan/clw-utils'
import axios from 'axios'

const URL = '/api/v1/projects'

/**
 * 查询项目下得所有自定义API菜单信息
 * @param projectId 项目ID
 * @returns 项目下得所有自定义API菜单信息
 */
export function queryApiMenus(projectId: string, tree = true) {
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}/interface/tree`,
    params: {
      tree
    }
  })
}

/**
 * 删除菜单
 * @param projectId 项目ID
 * @param menuId 菜单ID
 * @returns 空
 */
export function deleteApi(projectId: string, menuId: string) {
  return http.request({
    method: 'DELETE',
    url: `${URL}/${projectId}/interface/${menuId}`
  })
}

/**
 * 保存自定义 API
 * @param projectId 项目ID
 * @param data 菜单信息
 * @returns 空
 */
export function saveApiMenus(projectId: string, data: any) {
  if (data.id) {
    return http.request({
      method: 'put',
      url: `${URL}/${projectId}/interface/${data.id}`,
      data
    })
  }
  return http.request({
    method: 'post',
    url: `${URL}/${projectId}/interface`,
    data
  })
}

/**
 * 移动菜单到指定位置
 * @param projectId 项目ID
 * @param targetId 目标菜单ID
 * @param sourceId 源菜单ID
 * @param parentId 父菜单ID
 * @returns
 */
export function moveApi(projectId: string, targetId: string, sourceId: string, parentId: string) {
  return http.request({
    method: 'PUT',
    url: `${URL}/${projectId}/interface/move`,
    data: {
      targetId: targetId,
      sourceId: sourceId,
      parentId: parentId
    }
  })
}

/**
 * 复制 API
 */
export function copyApiMenu(projectId: string, menuId: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}/interface/copy/${menuId}`
  })
}

/**
 * 重命名 API
 */
export function renameApiMenu(projectId: string, menuId: string, data: any) {
  return http.request({
    method: 'put',
    url: `${URL}/${projectId}/interface/rename/${menuId}`,
    data
  })
}

/**
 * 获取 API 接口详情
 * @param projectId
 * @param menuId
 * @returns
 */
export function getApiMenu(projectId: string, menuId: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${projectId}/interface/${menuId}`
  })
}

/**
 * 请求自定义API(需要先保存)
 * @param projectId
 * @param menuId
 * @param data 后端需要传 {}  不然报错
 * @returns
 */
export function invokeApiMenu(projectId: string, menuId: string, data: any = {}, config: any = {}) {
  // 由于invoke的自由度高，所以不走统一的http
  const requestData = {
    method: 'POST',
    url: `${URL}/${projectId}/interface/${menuId}/invoke`,
    headers: {
      authorization: getToken(),
      'Content-Type': 'multipart/form-data'
    },
    data: data
    // responseType: 'blob'
  }
  if (config.responseType) {
    requestData['responseType'] = config.responseType
  }
  // return axios(requestData)
  return interfaceService.request(requestData)
}

/**
 * 测试自定义API(不需要先保存, 但是要传参)
 * @param projectId
 * @param menuId
 * @returns
 */
export function testApiMenu(projectId: string, data: any = {}) {
  return http.request({
    method: 'POST',
    url: `${URL}/${projectId}/interface/test`,
    data
  })
}
/**
 * api 测试接口
 */
export function apiTestReq(pid: string, id: string, data: any) {
  return http.request({
    method: 'POST',
    url: `${URL}/${pid}/interface/${id}/test`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      'custom-error-handler': true
    }
  })
}

/**
 * 查询接口响应历史
 */
export function apiResponseHistory(pid: string, id: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${pid}/interface/${id}/response`
  })
}

/**
 * 删除指定接口响应历史
 */
export function deleteApiResponseHistory(pid: string, iId: string, id: any) {
  return http.request({
    method: 'DELETE',
    url: `${URL}/${pid}/interface/${iId}/response/${id}`
  })
}

/**
 * 查询最新的接口响应详情
 */
export function queryLatestApiResponse(pid: string, id: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${pid}/interface/${id}/response/latest`
  })
}

/**
 * 查询指定ID的接口响应详情
 */
export function queryApiResponseDetail(pid: string, interfaceId: string, responseId: string) {
  return http.request({
    method: 'get',
    url: `${URL}/${pid}/interface/${interfaceId}/response/${responseId}`
  })
}
