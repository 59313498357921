<template>
  <div class="my-processed message-page">
    <div class="top-search">
      <div class="query-box">
        <el-input
          v-model="searinfo.txt"
          placeholder="请输入搜索内容"
          clearable
          @input="inputDebounce"
        />
        <svg-icon class="search" icon-class="search" />
      </div>
      <el-pagination
        background
        small
        layout="prev, pager, next, total"
        :pager-count="5"
        :current-page="searinfo.page"
        :page-size="searinfo.size"
        :total="searinfo.total"
        @current-change="pageChange"
        @size-change="sizeChange"
      />
    </div>
    <ResultList
      v-if="searinfo?.data?.length"
      v-loading="searinfo.loading"
      :page-string="'MyProcessed'"
      :data-list="searinfo.data"
      @go-detail="handleOpen"
    >
      <template #default="defaultProps">
        <div
          v-if="
            defaultProps?.info?.type == 'agree' ||
            defaultProps?.info?.type == 'copy' ||
            defaultProps?.info?.type == 'end'
          "
          class="container"
        >
          <div class="circle success-circle">
            <span class="checkmark">✔</span>
          </div>
          <div class="description success-description">{{ defaultProps?.info?.text }}</div>
        </div>

        <div v-if="defaultProps?.info?.type == 'apply'" class="container">
          <div class="circle running-circle">
            <span class="checkmark">✔</span>
          </div>
          <div class="description running-description">{{ defaultProps?.info?.text }}</div>
        </div>

        <div v-if="defaultProps?.info?.type == 'refuse'" class="container">
          <div class="circle fail-circle">
            <span class="checkmark">✖</span>
          </div>
          <div class="description fail-description">{{ defaultProps?.info?.text }}</div>
        </div>
      </template>
    </ResultList>
    <EmptyBox v-else :title="searinfo.txt"></EmptyBox>
    <MyInitiateDiaLog ref="MyInitiateDiaLogRef" :page-string="'MyProcessed'"></MyInitiateDiaLog>
  </div>
</template>
<script lang="ts">
  export default {
    name: 'MyProcessed'
  }
</script>
<script setup lang="ts">
  import ResultList from './components/ResultList.vue'
  import EmptyBox from '../../components/EmptyBox/EmptyBox.vue'
  import { debounce } from 'lodash-es'
  import { ref, reactive, onBeforeMount, computed } from 'vue'
  import { queryMyAttended } from '@haohan/clw-api'
  import { useProject, useUser } from '@haohan/clw-store'
  import MyInitiateDiaLog from './components/MyInitiateDiaLog.vue'
  import { getTaskInfo } from '../../utils/workflow-tool'

  const projectStore = useProject()
  const userStore = useUser()
  const projectId = computed(() => {
    return projectStore.currentProject?.id
  })
  const MyInitiateDiaLogRef = ref(null)

  function handleOpen(row) {
    // 这里getTaskInfo传入 MyInitiate，跟我发起的保持一致
    MyInitiateDiaLogRef?.value.open({ ...row, taskInfo: getTaskInfo('MyInitiate', row) })
  }

  const searinfo = reactive({
    txt: '',
    data: [
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // },
      // {
      //   id: 'e55e3765-06bc-11ef-8191-00ffd4c7a997',
      //   name: '流程test1',
      //   createTime: '2024-04-30 14:43:04'
      // }
    ],
    page: 1,
    size: 10,
    total: 0,
    loading: false
  })
  function getList() {
    searinfo.loading = true
    const params = {
      keyword: searinfo.txt,
      page: searinfo.page,
      size: searinfo.size
    } as any
    queryMyAttended(projectId.value, params).then((res) => {
      console.log(res)
      const { data } = res
      searinfo.total = data.totalItems || 0
      searinfo.data = getItemRightTextInfo(data.data)
      searinfo.loading = false
    })
  }

  const getItemRightTextInfo = (data: any[]): any => {
    return data.map((item: any) => {
      const info = {
        type: '',
        text: ''
      }
      // 查找到最近的一个我已处理的节点信息
      const task = item.tasks.find((tk: any) => tk.assigneeUserId === userStore.current.id)
      if (task) {
        if (task.comment.type === '驳回') {
          info.type = 'refuse'
          info.text = '我已拒绝'
        } else if (task.comment.type === '同意') {
          info.type = 'agree'
          info.text = '我已同意'
        } else if (task.comment.type === '终止') {
          info.type = 'end'
          info.text = '我已终止'
        } else if (task.comment.type === '抄送') {
          info.type = 'copy'
          info.text = '已抄送'
        } else {
          info.type = 'apply'
          info.text = '我已填写'
        }
      }
      return {
        ...item,
        info
      }
    })
  }
  const inputDebounce = debounce(() => {
    searinfo.page = 1
    getList()
  }, 200)
  function pageChange(val: number) {
    searinfo.page = val
    getList()
  }
  function sizeChange(val: number) {
    searinfo.size = val
    getList()
  }

  onBeforeMount(() => {
    getList()
  })
</script>

<style scoped lang="scss">
  .container {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    .circle {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }
    .checkmark {
      color: white;
      font-size: 18px;
      font-weight: bold;
    }
    .description {
      font-size: 14px;
    }

    .success-circle {
      background-color: #4bca81;
    }
    .success-description {
      color: #4bca81;
    }
    .running-circle {
      background-color: #097adf;
    }
    .running-description {
      color: #097adf;
    }

    .fail-circle {
      background-color: #f8423b;
    }
    .fail-description {
      color: #f8423b;
    }
  }

  .message-page {
    position: relative;
  }
  .top-search {
    position: absolute;
    right: 0px !important;
  }
  .query-box {
    position: relative;
    width: 326px;
    margin-right: 36px;
    :deep() {
      .el-input {
        height: 36px;
        overflow: hidden;
      }
      .el-input__wrapper {
        width: 100%;
        height: 100%;
        background-color: #fff !important;
        padding-left: 34px;
        box-shadow: none;
      }
      .el-input__inner {
        color: #1a1d1f;
      }
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
</style>
