import { compAttr, styleProp, dataProp } from './common-comp-prop'

const list = [
  {
    imageSrc: 'row-container',
    thumbnail: '',
    title: '容器',
    type: 'HHGrid',
    mutualSetting: true,
    dataSourceSetting: false,
    children: [
      {
        list: [],
        span: '24'
      }
    ],
    eventOption: {
      send: {},
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '显示隐藏',
            value: 'showOrHide'
          }
        ],
        bindValues: []
      }
    },
    prop: {
      hideBg: false,
      bgColor: '',
      radiusShow: 'all'
    },
    styleProp: {
      'border-top-left-radius': 12,
      'border-top-right-radius': 12,
      'border-bottom-right-radius': 12,
      'border-bottom-left-radius': 12
    }
  },
  // 文本框
  {
    imageSrc: 'input',
    thumbnail: '',
    title: '文本框',
    type: 'FormInput',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          },
          {
            label: '清空',
            value: 'clear'
          }
        ],
        bindValues: [
          {
            label: '输入值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      tableColumn: '', // 数据列
      type: 'text', // text,password,phone,email,url,idCard,port
      label: '文本框', // 标签
      placeholder: '',
      status: 'normal', // 字段状态, normal | readonly | disabled
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'blur' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { max: 20, message: '您的字数超出限制!', trigger: 'blur' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      comParam: '',
      comParamLabel: ''
    }
  },
  // 文本域
  {
    imageSrc: 'textarea',
    thumbnail: '',
    title: '文本域',
    type: 'FormTextarea',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '输入值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      tableColumn: '', // 数据列
      // type: 'text', // 类型 text,password,search
      label: '文本域', // 标签
      rows: 1, // 默认行数
      placeholder: '',
      status: 'normal', // 字段状态, normal | readonly | disabled
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'blur' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { max: 20, message: '您的字数超出限制!', trigger: 'blur' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      comParam: '',
      comParamLabel: ''
    }
  },
  // 计数器
  {
    imageSrc: 'count',
    thumbnail: '',
    title: '计数器',
    type: 'FormCount',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '当前值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      tableColumn: '', // 数据列
      // type: 'text', // 类型 text,password,search
      label: '计数器', // 标签
      placeholder: '',
      status: 'normal', // 字段状态, normal | readonly | disabled
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'blur' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { min: 20, max: 100, message: '数值不在范围!', trigger: 'blur' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      precision: 0, // 保留小数位数
      allowNegative: false, // 允许负数
      displayFormat: '', // 显示格式, 千位分割符、百分比
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      comParam: '',
      comParamLabel: ''
    }
  },
  // 时间
  {
    imageSrc: 'time',
    thumbnail: '',
    title: '日期',
    type: 'FormDatetimePicker',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '开始时间-结束时间',
            value: 'dateTime'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '时间范围切换',
            value: 'changeTimeDuration'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      shortcuts: [], // 时间区间, 昨天 近三天 近一周 近一月 这种快捷方式
      timeLimit: null, // 时间区间天数
      tableColumn: '', // 数据列
      model: 'range', // 类型 range/point
      type: 'YYYYMMDDHHmmss', // 时间选择的粒度
      label: '日期', // 标签
      placeholder: '',
      status: 'normal', // 字段状态, normal | readonly | disabled
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'blur' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { min: 20, max: 100, message: '数值不在范围!', trigger: 'blur' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      comParam: '',
      comParamLabel: ''
    }
  },
  // 单选
  {
    imageSrc: 'radio',
    thumbnail: '',
    title: '单选',
    type: 'FormRadio',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '选择值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      tableColumn: '', // 数据列
      type: 'date', // date | daterange | time | timerange
      label: '单选', // 标签
      placeholder: '',
      status: 'normal', // 字段状态, normal | readonly | disabled
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'change' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { min: 20, max: 100, message: '数值不在范围!', trigger: 'blur' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      comParam: '',
      comParamLabel: ''
    },
    dataProp: {
      // 数据部分
      // options: [
      //   { label: '苹果', value: 1 },
      //   { label: '橘子', value: 2 }
      // ],
      tableId: '', // 数据集 - tableId
      tableName: '', // 数据集 - tableName
      apiDataPath: '',
      defaultData: [
        { label: '苹果', value: 1 },
        { label: '橘子', value: 2 }
      ],
      labelCol: '', // 显示列
      xAxisField: '', // 数据列
      cols: [],
      pageSize: 100000
    }
  },
  // 多选
  {
    imageSrc: 'checkbox',
    thumbnail: '',
    title: '多选',
    type: 'FormCheckbox',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '选择值',
            value: 'inputValue'
          },
          {
            label: '选择值的字符串形式',
            value: 'stringValue'
          }
        ]
      },
      receive: {
        operationTypes: [
          {
            label: '重新加载',
            value: 'reloadByEvent'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      tableColumn: '', // 数据列
      type: 'date', // date | daterange | time | timerange
      label: '多选', // 标签
      placeholder: '',
      status: 'normal', // 字段状态, normal | readonly | disabled
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'change' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { min: 1, max: 6, message: '个数不在范围!', trigger: 'change' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      comParam: '',
      comParamLabel: ''
    },
    dataProp: {
      // 数据部分
      // options: [
      //   { label: '苹果', value: 1 },
      //   { label: '橘子', value: 2 }
      // ],
      tableId: '', // 数据集 - tableId
      tableName: '', // 数据集 - tableName
      apiDataPath: '',
      defaultData: [
        { label: '苹果', value: 1 },
        { label: '橘子', value: 2 }
      ],
      labelCol: '', // 显示列
      xAxisField: '', // 数据列
      cols: [],
      pageSize: 100000
    }
  },
  // 下拉框
  {
    imageSrc: 'select',
    thumbnail: '',
    title: '下拉',
    type: 'FormSelect',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '选择值',
            value: 'selectValue'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '设置值',
            value: 'setValue'
          },
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      filterable: false, // 是否支持远程搜索
      tableColumn: '', // 数据列
      multipleLimit: 1, // 最多选几个
      type: 'date', // date | daterange | time | timerange
      label: '下拉', // 标签
      placeholder: '',
      status: 'normal', // 字段状态, normal | readonly | disabled
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'change' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { min: 1, max: 6, message: '个数不在范围!', trigger: 'change' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      comParam: '',
      comParamLabel: ''
    },
    dataProp: {
      // 数据部分
      // options: [
      //   { label: '苹果', value: 1 },
      //   { label: '橘子', value: 2 }
      // ],
      tableId: '', // 数据集 - tableId
      tableName: '', // 数据集 - tableName
      apiDataPath: '',
      defaultData: [
        { label: '苹果', value: 1 },
        { label: '橘子', value: 2 }
      ],
      labelCol: '', // 显示列
      xAxisField: '', // 数据列
      cols: [],
      pageSize: 100000
    }
  },
  // 标题
  {
    imageSrc: 'text',
    thumbnail: '',
    title: '标题',
    type: 'FormTitle',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        // 可触发类型
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          },
          {
            label: '点击',
            value: 'clickText'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示值',
            value: 'showText'
          }
        ]
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '设置值',
            value: 'setValue'
          },
          {
            label: '重新加载',
            value: 'reloadByEvent'
          },
          {
            label: '仅接收值',
            value: 'onlyReceiveEvent'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示文本',
            value: 'eventValue'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      title: '标题样例',
      textAlign: 'left',
      fontSize: 14,
      color: '#000',
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      comParam: '',
      comParamLabel: ''
    }
  },
  // 穿梭框
  {
    imageSrc: 'transfer',
    thumbnail: '',
    title: '穿梭框',
    type: 'FormTransfer',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {},
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      tableColumn: '', // 数据列
      type: 'date', // date | daterange | time | timerange
      label: '穿梭框', // 标签
      placeholder: '',
      status: 'normal', // 字段状态, normal | readonly | disabled
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'change' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { min: 20, max: 100, message: '数值不在范围!', trigger: 'blur' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      width: null, // 宽度 默认自适应
      height: 320, // 高度
      filterable: true, // 支持过滤
      titleLeft: '左侧列表', // 左侧标题
      titleRight: '右侧列表', // 右侧标题
      buttonTextLeft: '', // < 往左侧按钮
      buttonTextRight: '', // > 往右侧按钮
      groupOnly: false, // 组内单选
      childOnly: false, // 仅子节点
      groupField: '', // 按什么字段来分组
      comParam: '',
      comParamLabel: ''
    },
    dataProp: {
      tableId: '', // 数据集 - tableId
      tableName: '', // 数据集 - tableName
      apiDataPath: '',
      defaultData: [
        { label: '苹果', value: 1 },
        { label: '橘子', value: 2 }
      ],
      labelCol: '', // 显示列
      xAxisField: '', // 数据列
      cols: [],
      pageSize: 100000
    }
  },
  // 上传
  {
    imageSrc: 'HHUpload',
    thumbnail: '',
    title: '上传',
    type: 'FormUpload',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
    // 自定义数据处理函数
    customDataHandle: '',
    eventOption: {},
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      tableColumn: '', // 数据列
      type: 'date', // date | daterange | time | timerange
      label: '上传', // 标签
      placeholder: '',
      status: 'normal', // 字段状态, normal | readonly | disabled
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'change' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { min: 20, max: 100, message: '数值不在范围!', trigger: 'blur' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      buttonText: '点击上传文件', // 上传按钮文案
      fileLimit: '1', // 上传文件数
      fileTypes: '', // 格式支持
      fileSize: null, // 大小上限
      filePath: '' // 文件上传到的路径 例如: /home/haohan
    },
    dataProp: {
      tableId: '', // 数据集 - tableId
      tableName: '', // 数据集 - tableName
      apiDataPath: '',
      defaultData: [
        { label: '苹果', value: 1 },
        { label: '橘子', value: 2 }
      ],
      labelCol: '', // 显示列
      xAxisField: '', // 数据列
      cols: [],
      pageSize: 100000
    }
  },
  // 条件组件
  {
    imageSrc: 'custom-query',
    thumbnail: '',
    title: '条件组件',
    type: 'FormCondition',
    mutualSetting: true,
    dataSourceSetting: false,
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '值变化',
            value: 'valueChange'
          }
        ],
        bindValues: [
          {
            label: '当前值',
            value: 'inputValue'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    formProp: {
      tableColumn: '', // 数据列
      label: '条件组件', // 标签
      required: false, // 必填
      requiredRule: { required: true, message: '该项为必填项!', trigger: 'blur' }, // 必填规则
      limited: false, // 限制字数
      limitedRule: { min: 20, max: 100, message: '数值不在范围!', trigger: 'blur' }, // 限制字数规则
      default: '', // 默认值
      tips: '', // tooltips 问号内容
      customRules: [], // 自定义规则
      optionButtonText: '+ 添加条件', // 添加条件按钮文字
      conditions: [],
      associateType: 'hidden', // 数据联动, 是隐藏还是显示该组件 hidden | display
      associateOptions: [], // 数据联动条件
      comParam: '',
      comParamLabel: ''
    }
  },
  // 标签页
  {
    imageSrc: 'tab',
    thumbnail: '',
    title: '标签页',
    type: 'FormTabs',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '页签变化',
            value: 'tabChange'
          }
        ],
        bindValues: [
          {
            label: '当前页签',
            value: 'currentValue'
          },
          {
            label: '值2',
            value: 'currentValue2'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: 46
    },
    prop: {
      span: 24,
      showType: 'button',
      textAlign: 'left',
      hasTurnPage: false,
      pageSize: 10
    },
    dataProp: {
      defaultData: [
        {
          label: '数据趋势',
          value: '数据趋势',
          value2: '',
          list: []
        },
        {
          label: '数据详情',
          value: '数据详情',
          value2: '',
          list: []
        }
      ]
      // options: []
    }
  },
  // 面板
  {
    imageSrc: 'pane',
    thumbnail: '',
    title: '面板',
    type: 'FormTabPane',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    eventOption: {
      send: {
        triggerTypes: [],
        bindValues: []
      },
      receive: {
        // 可操作方法
        operationTypes: [
          {
            label: '切换显示',
            value: 'changeShowTab'
          }
        ],
        // 可绑定得值
        bindValues: [
          {
            label: '显示Tab',
            value: 'currentValue'
          }
        ]
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {},
    dataProp: {
      defaultData: [
        {
          label: '数据趋势',
          value: '数据趋势',
          value2: '',
          list: []
        },
        {
          label: '数据详情',
          value: '数据详情',
          value2: '',
          list: []
        }
      ]
      // options: []
    }
  },
  // 步骤条
  {
    imageSrc: 'HHStep',
    thumbnail: '',
    title: '步骤条',
    type: 'FormStep',
    mutualSetting: true,
    dataSourceSetting: true,
    dataSourceType: 'LocalData',
    eventOption: {
      send: {
        triggerTypes: [
          {
            label: '步骤变化',
            value: 'stepChange'
          }
        ],
        bindValues: [
          {
            label: '当前步骤',
            value: 'currentValue'
          },
          {
            label: '值2',
            value: 'currentValue2'
          }
        ]
      },
      receive: {
        operationTypes: []
      }
    },
    styleProp: {
      width: null,
      height: null
    },
    prop: {
      pointSize: '', // 圆点大小
      pointBg: true, // 圆点背景
      layout: 'horizontal', // 布局
      titlePos: 'bottom', // 标题位置
      operate: 'default', // 操作 txt
      pointShow: 'num', // 圆内展示 num,icon
      doneColor: '#0076DE', // 已完成颜色
      undoneColor: '#606266', // 待完成颜色
      iconMapping: {} // 图标映射
    },
    dataProp: {
      defaultData: [
        {
          label: '步骤一',
          value: 1,
          value2: '',
          list: []
        },
        {
          label: '步骤二',
          value: 2,
          value2: '',
          list: []
        },
        {
          label: '步骤三',
          value: 3,
          value2: '',
          list: []
        }
      ]
    }
  }
]

for (let i = 0, len = list.length; i < len; i++) {
  const item = list[i]
  item.styleProp = { ...styleProp, ...item.styleProp }
  // if (item.dataProp) {
  //   item.dataProp = { ...dataProp, ...item.dataProp }
  // }
  list[i] = { ...compAttr, ...item }
}

export default list
