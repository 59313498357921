<template>
  <el-header>
    <div class="title">
      {{ props.title }}
    </div>
    <!-- 头部横向菜单 -->
    <slot></slot>
    <ToolControl
      v-if="project.currentProject?.settings?.verticalMenu !== 'true'"
      flex-direction="row"
    />
  </el-header>
</template>

<script lang="ts" setup>
  import { useProject } from '@haohan/clw-store'
  import ToolControl from './ToolControl.vue'

  const props = defineProps({
    title: {
      type: String,
      default: ''
    }
  })
  const project = useProject()
</script>

<style lang="scss" scoped>
  .el-header {
    --el-header-height: 52px;
    --el-header-padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--custom-menu-bg-color);
    color: var(--custom-menu-text-color);
    z-index: 1000;
    .title {
      min-width: 160px;
      font-size: 18px;
    }
    // .menu-list {
    //   flex: 1;
    //   padding: 0 20px;
    //   box-sizing: border-box;
    //   overflow: hidden;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    // }
  }
</style>
