import { chartXaisProp, genChartSetting, genRegularSetting } from './common-comp-prop'
import { getKey } from '@haohan/clw-utils'
import dayjs from 'dayjs'

/**
 * 属性prop 含有toolbox功能的组件用
 */
const propForToolbox: WidgetAttribute = {
  toolbox: {
    show: true,
    feature: {
      saveAsImage: {
        title: '保存为图片',
        name: `chart${dayjs(new Date()).format('YYYYMMDDHHmmss')}`
      }
    }
  }
}

// 柱状图
const chartBar = {
  imageSrc: 'bar',
  thumbnail: '',
  title: '柱状图',
  type: 'HHBarChart',
  // 优先级最高,如果不存在则默认使用type
  componentType: 'HHMixChart',
  // 固定图形类型,为了让图形公共使用混合图形的设置组件
  fixedType: 'bar',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...chartXaisProp,
    ...propForToolbox,
    // 数据组类型
    seriesType: 'fix',
    // 动态类型-系列名
    dynamicSerieNameCol: '',
    // 动态类型-系列值
    dynamicSerieValueCol: '',
    selectedByDefault: false,
    dataZoom: '',
    dataZoomLimit: 10,
    valueCols: [{ key: getKey(), name: '会员数', col: 'value', xAxisIndex: '0', yAxisIndex: '0' }],
    xAxisField: 'name',
    rotate: 0
  },
  dataProp: {
    defaultData: [
      {
        name: '一月',
        value: '81'
      },
      {
        name: '二月',
        value: '38'
      },
      {
        name: '三月',
        value: '53'
      },
      {
        name: '四月',
        value: '20'
      },
      {
        name: '五月',
        value: '62'
      }
    ]
  }
}
// 趋势图
const chartLine = {
  imageSrc: 'line',
  thumbnail: '',
  title: '趋势图',
  type: 'HHLineChart',
  // 优先级最高,如果不存在则默认使用type
  componentType: 'HHMixChart',
  // 固定图形类型,为了让图形公共使用混合图形的设置组件
  fixedType: 'line',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...chartXaisProp,
    ...propForToolbox,
    // 数据组类型
    seriesType: 'fix',
    // 动态类型-系列名
    dynamicSerieNameCol: '',
    // 动态类型-系列值
    dynamicSerieValueCol: '',
    selectedByDefault: false,
    dataZoom: '',
    dataZoomLimit: 10,
    valueCols: [{ key: getKey(), name: '变化率', col: 'value', xAxisIndex: '0', yAxisIndex: '0' }],
    xAxisField: 'name',
    rotate: 0
  },
  dataProp: {
    defaultData: [
      {
        name: '11-01',
        value: '39'
      },
      {
        name: '11-02',
        value: '17'
      },
      {
        name: '11-03',
        value: '82'
      },
      {
        name: '11-04',
        value: '38'
      },
      {
        name: '11-05',
        value: '59'
      }
    ]
  }
}
// 饼图
const chartPie = {
  imageSrc: 'pie',
  thumbnail: '',
  title: '饼图',
  type: 'HHPieChart',
  // 固定图形类型,为了让图形公共使用混合图形的设置组件
  fixedType: 'pie',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        },
        {
          label: '点击对象',
          value: 'clickParams'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换数据',
          value: 'changeCol'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...propForToolbox,
    selectedByDefault: false,
    valueCols: [{ key: getKey(), name: '名称', col: 'value', xAxisIndex: '0', yAxisIndex: '0' }],
    xAxisField: 'name'
  },
  dataProp: {
    defaultData: [
      { name: '一月', value: 895 },
      { name: '二月', value: 350 },
      { name: '三月', value: 216 },
      { name: '四月', value: 107 },
      { name: '五月', value: 23 }
    ]
  }
}
// 雷达图
const chartRadar = {
  imageSrc: 'radar',
  thumbnail: '',
  title: '雷达图',
  type: 'HHRadarChart',
  fixedType: 'radar',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...propForToolbox,
    radar: {},
    axisStyle: 'solid',
    axisLabelShow: true,
    axisNameShow: true,
    areaStyleShow: false,
    valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
    xAxisField: 'name'
  },
  dataProp: {
    defaultData: [
      { name: '一月', value: 62 },
      { name: '二月', value: 73 },
      { name: '三月', value: 66 },
      { name: '四月', value: 37 },
      { name: '五月', value: 75 }
    ]
  }
}
// 散点图
const chartScatter = {
  imageSrc: 'scatter',
  thumbnail: '',
  title: '散点图',
  type: 'HHScatterChart',
  // 优先级最高,如果不存在则默认使用type
  componentType: 'HHMixChart',
  // 固定图形类型,为了让图形公共使用混合图形的设置组件
  fixedType: 'scatter',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...chartXaisProp,
    ...propForToolbox,
    // 数据组类型
    seriesType: 'fix',
    selectedByDefault: false,
    dataZoom: '',
    dataZoomLimit: 10,
    // 散点图类型，在普通散点图的基础上，扩展出多种散点图样式
    scatterType: 'normal',
    // 各种不同散点图类型所需要的参数值
    scatterTypeParam: {
      col: '',
      sizeBase: 1
    },
    valueCols: [{ key: getKey(), name: '名称', col: 'value', xAxisIndex: '0', yAxisIndex: '0' }],
    xAxisField: 'name',
    rotate: 0
  },
  dataProp: {
    defaultData: [
      {
        name: '11-01',
        value: '6'
      },
      {
        name: '11-02',
        value: '12'
      },
      {
        name: '11-03',
        value: '14'
      },
      {
        name: '11-04',
        value: '22'
      },
      {
        name: '11-05',
        value: '38'
      }
    ]
  }
}
// 仪表盘图
const chartGauge = {
  imageSrc: 'gauge',
  thumbnail: '',
  title: '仪表盘',
  type: 'HHGaugeChart',
  fixedType: 'gauge',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 300
  },
  prop: {
    ...propForToolbox,
    showStyle: '',
    axisLineWidth: 10,
    tickShow: true,
    tickPosition: 'top',
    valueCols: [{ key: getKey(), name: '名称', col: 'value' }],
    xAxisField: 'name',
    unit: '°C'
  },
  dataProp: {
    defaultData: [{ name: '温度', value: 23.67 }]
  }
}
// 桑基图
const chartSankey = {
  imageSrc: 'sankey',
  thumbnail: '',
  title: '桑基图',
  type: 'HHSanKeyChart',
  // 固定图形类型,为了让图形公共使用混合图形的设置组件
  fixedType: 'sankey',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...propForToolbox,
    sankeys: [],
    dataOrgType: 'col2col',
    sankeyObj: {
      source: 'source',
      target: 'target',
      value: 'value'
    },
    selectedByDefault: false,
    repeat: false
  },
  dataProp: {
    defaultData: [
      {
        source: 'a',
        target: 'a1',
        value: 113
      },
      {
        source: 'a',
        target: 'b1',
        value: 57
      },
      {
        source: 'a',
        target: 'a2',
        value: 58
      },
      {
        source: 'b',
        target: 'b1',
        value: 202
      },
      {
        source: 'b1',
        target: 'a1',
        value: 16
      },
      {
        source: 'b1',
        target: 'c',
        value: 32
      }
    ]
  }
}
// 地图
const chartMap = {
  imageSrc: 'map',
  thumbnail: '',
  title: '地图',
  type: 'HHMapChart',
  // 固定图形类型,为了让图形公共使用混合图形的设置组件
  fixedType: 'map',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        },
        {
          label: '点击对象',
          value: 'clickParams'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换数据',
          value: 'changeCol'
        },
        {
          label: '选中',
          value: 'selectItem'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        },
        {
          label: '地图切换',
          value: 'switchMap'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        },
        {
          label: '选中',
          value: 'selectValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...propForToolbox,
    selectedByDefault: false,
    mapName: '中国',
    areaCol: 'name',
    areaValueCol: 'value',
    selectedMode: 'none',
    labelShow: false,
    showLegendSymbol: false,
    zoom: 1,
    apply: 'area',
    lines: [],
    hasCounty: false,
    center: ['50%', '50%'], // 控制地图位置
    showVisualMap: true // 控制视觉映射组件
  },
  dataProp: {
    defaultData: [
      { name: '江西', value: 113 },
      { name: '安徽', value: 57 },
      { name: '台湾', value: 58 },
      { name: '香港', value: 202 },
      { name: '澳门', value: 220 },
      { name: '海南', value: 32 },
      { name: '广东', value: 73 },
      { name: '广西', value: 144 },
      { name: '云南', value: 48 },
      { name: '湖南', value: 65 },
      { name: '贵州', value: 32 },
      { name: '重庆', value: 131 },
      { name: '四川', value: 29 },
      { name: '湖北', value: 38 },
      { name: '福建', value: 143 },
      { name: '浙江', value: 51 },
      { name: '上海', value: 87 },
      { name: '江苏', value: 90 },
      { name: '河南', value: 170 },
      { name: '山东', value: 63 },
      { name: '黑龙江', value: 23 },
      { name: '吉林', value: 193 },
      { name: '辽宁', value: 75 },
      { name: '天津', value: 99 },
      { name: '北京', value: 199 },
      { name: '河北', value: 45 },
      { name: '山西', value: 139 },
      { name: '陕西', value: 23 },
      { name: '宁夏', value: 159 },
      { name: '内蒙古', value: 78 },
      { name: '甘肃', value: 80 },
      { name: '青海', value: 98 },
      { name: '西藏', value: 100 },
      { name: '新疆', value: 115 }
    ]
  }
}
// 混合图
const chartMix = {
  imageSrc: 'mixChart',
  thumbnail: '',
  title: '混合图',
  type: 'HHMixChart',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...chartXaisProp,
    ...propForToolbox,
    selectedByDefault: false,
    // 数据组类型
    seriesType: 'fix',
    // 动态类型-系列名
    dynamicSerieNameCol: '',
    // 动态类型-系列值
    dynamicSerieValueCol: '',
    valueCols: [
      { key: getKey(), name: '会员数', col: 'value', xAxisIndex: '0', yAxisIndex: '0' },
      { key: getKey(), name: '变化率', col: 'rate', xAxisIndex: '0', yAxisIndex: '0' }
    ],
    xAxisField: 'name',
    series: [
      {
        name: '会员数',
        type: 'bar',
        xAxisIndex: '0',
        yAxisIndex: '0',
        barWidth: 9,
        barMaxWidth: 9,
        barMinWidth: 1,
        stack: '',
        label: { show: false, position: 'top' },
        showRadius: true
      },
      {
        name: '变化率',
        type: 'line',
        xAxisIndex: '0',
        yAxisIndex: '0',
        showSymbol: true,
        lineStyle: { type: 'solid' },
        label: { show: false, position: 'top' },
        smooth: true
      }
    ],
    rotate: 0
  },
  dataProp: {
    defaultData: [
      {
        name: '一月',
        value: 81,
        rate: 39
      },
      {
        name: '二月',
        value: 38,
        rate: 17
      },
      {
        name: '三月',
        value: 53,
        rate: 77
      },
      {
        name: '四月',
        value: 20,
        rate: 66
      },
      {
        name: '五月',
        value: 62,
        rate: 32
      }
    ]
  }
}
// 热力图
const chartHeatMap = {
  imageSrc: 'heatMap',
  thumbnail: '',
  title: '热力图',
  type: 'HHHeatMapChart',
  // 固定图形类型,为了让图形公共使用混合图形的设置组件
  fixedType: 'heatMap',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '切换显示值',
          value: 'changeShowValue'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        },
        {
          label: '排序值',
          value: 'dataSort'
        },
        {
          label: '过滤值',
          value: 'dataFilter'
        },
        {
          label: '数据查询页',
          value: 'pageValue'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...chartXaisProp,
    ...propForToolbox,
    selectedByDefault: false,

    // 数据组类型
    seriesType: 'fix',
    // 动态类型-系列名
    dynamicSerieNameCol: '',
    // 动态类型-系列值
    dynamicSerieValueCol: '',
    xField: 'xField',
    yField: 'yField',
    valueField: 'valueField',
    series: [
      {
        name: '',
        type: 'heatmap',
        data: [],
        label: {
          show: false
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ],
    xAxis: [
      {
        type: 'category',
        position: 'top',
        data: [],
        splitArea: {
          show: true
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          overflow: 'truncate',
          height: 40,
          interval: 0
        }
      }
    ],
    yAxis: [
      {
        type: 'category',
        data: [],
        splitArea: {
          show: true
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          overflow: 'truncate',
          width: 50
        }
      }
    ],
    visualMap: {
      show: true,
      min: 0,
      max: 10,
      calculable: true,
      orient: 'horizontal',
      left: 'center',
      top: 'bottom',
      bottom: 5,
      inRange: {
        color: []
      },
      // text: [1000, 0],
      itemHeight: 200,
      itemWidth: 7,
      textStyle: {
        fontSize: 12
      }
    },
    // X轴显示文字方向
    textStyle: 'vertical'
  },
  dataProp: {
    defaultData: [
      {
        xField: '一月',
        yField: '北京',
        valueField: 67
      },
      {
        xField: '二月',
        yField: '北京',
        valueField: 24
      },
      {
        xField: '三月',
        yField: '北京',
        valueField: 8
      },
      {
        xField: '四月',
        yField: '北京',
        valueField: 19
      },
      {
        xField: '五月',
        yField: '北京',
        valueField: 10
      },
      {
        xField: '一月',
        yField: '上海',
        valueField: 52
      },
      {
        xField: '二月',
        yField: '上海',
        valueField: 64
      },
      {
        xField: '三月',
        yField: '上海',
        valueField: 123
      },
      {
        xField: '四月',
        yField: '上海',
        valueField: 15
      },
      {
        xField: '五月',
        yField: '上海',
        valueField: 35
      },
      {
        xField: '一月',
        yField: '广州',
        valueField: 115
      },
      {
        xField: '二月',
        yField: '广州',
        valueField: 117
      },
      {
        xField: '三月',
        yField: '广州',
        valueField: 8
      },
      {
        xField: '四月',
        yField: '广州',
        valueField: 5
      },
      {
        xField: '五月',
        yField: '广州',
        valueField: 38
      },
      {
        xField: '一月',
        yField: '深圳',
        valueField: 96
      },
      {
        xField: '二月',
        yField: '深圳',
        valueField: 117
      },
      {
        xField: '三月',
        yField: '深圳',
        valueField: 8
      },
      {
        xField: '四月',
        yField: '深圳',
        valueField: 5
      },
      {
        xField: '五月',
        yField: '深圳',
        valueField: 38
      },
      {
        xField: '一月',
        yField: '天津',
        valueField: 84
      },
      {
        xField: '二月',
        yField: '天津',
        valueField: 64
      },
      {
        xField: '三月',
        yField: '天津',
        valueField: 142
      },
      {
        xField: '四月',
        yField: '天津',
        valueField: 97
      },
      {
        xField: '五月',
        yField: '天津',
        valueField: 85
      }
    ]
  }
}
// 玉玦图
const chartJade = {
  imageSrc: 'jadge',
  thumbnail: '',
  title: '玉玦图',
  type: 'HHJadeChart',
  // 固定图形类型,为了让图形公共使用混合图形的设置组件
  fixedType: 'jade',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '名称',
          value: 'clickParamName'
        },
        {
          label: '值',
          value: 'clickParamValue'
        },
        {
          label: '点击对象',
          value: 'clickParams'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 200
  },
  prop: {
    ...propForToolbox,
    selectedByDefault: false,
    polar: {
      radius: ['0%', '75%'],
      center: ['50%', '50%']
    },
    radiusAxis: {
      data: []
    },
    angleAxis: {},
    hasBg: false,
    isColor: false,
    valueCols: [{ key: getKey(), name: '名称', col: 'value', xAxisIndex: '0', yAxisIndex: '0' }],
    xAxisField: 'name',
    valueShowType: 'rate' // rate,value
  },
  dataProp: {
    defaultData: [
      { name: '北京', value: 36 },
      { name: '上海', value: 24 },
      { name: '广州', value: 12 },
      { name: '深圳', value: 20 },
      { name: '天津', value: 8 }
    ]
  }
}
// 进度图
const process = {
  imageSrc: 'process',
  thumbnail: '',
  title: '进度图',
  type: 'HHProcess',
  mutualSetting: true,
  dataSourceSetting: true,
  dataSourceType: 'LocalData',
  dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi'],
  eventOption: {
    send: {
      triggerTypes: [
        {
          label: '值变化',
          value: 'valueChange'
        }
      ],
      bindValues: [
        {
          label: '显示值',
          value: 'showVal'
        }
      ]
    },
    receive: {
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      bindValues: []
    }
  },
  styleProp: {
    width: null,
    height: 280
  },
  prop: {
    val: '',
    showVal: '',
    type: 'circle', // circle,line
    title: '',
    unit: '%',
    img: '',
    icon: '',
    barWidth: 18,
    bgShow: false,
    borderShow: false,
    levelShow: false,
    levels: [],
    color: '#20a0ff'
  },
  dataProp: {
    defaultData: [{ value: 82.13 }]
  }
}
// 水波图
const chartLiquid = {
  imageSrc: 'liquidFill',
  thumbnail: '',
  title: '水波图',
  type: 'HHLiquidChart',
  fixedType: 'liquidFill',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [],
      // 可绑定得值
      bindValues: []
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 330
  },
  prop: {
    ...propForToolbox,
    selectedByDefault: false,
    levelShow: false,
    levels: [],
    color: '#1684F2',
    bgShow: false,
    bgColor: '#F4F5F8',
    valueCol: 'value',
    series: {
      type: 'liquidFill',
      center: ['50%', '50%'],
      radius: '80%',
      label: {
        show: true,
        fontSize: 30,
        color: '#294D99'
      },
      data: []
    },
    unit: '%'
  },
  dataProp: {
    defaultData: [{ value: 42.76 }]
  }
}
// 气泡图
const chartGraph = {
  imageSrc: 'graph',
  thumbnail: '',
  title: '气泡图',
  type: 'HHGraphChart',
  fixedType: 'graph',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: []
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: []
    }
  },
  styleProp: {
    width: null,
    height: 330
  },
  prop: {
    ...propForToolbox,
    val: 'value',
    showVal: '',
    series: {
      type: 'graph',
      data: []
    },
    showStyle: 'fix',
    xAxisField: 'name'
  },
  dataProp: {
    defaultData: [
      { name: '北京', value: 236 },
      { name: '上海', value: 141 },
      { name: '广州', value: 83 }
    ]
  }
}
// 关系图
const chartRelation = {
  imageSrc: 'relation',
  thumbnail: '',
  title: '关系图',
  type: 'HHRelationChart',
  fixedType: 'graph',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [
        {
          label: '点击',
          value: 'click'
        }
      ],
      // 可绑定得值
      bindValues: []
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '显示隐藏',
          value: 'showOrHide'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: []
    }
  },
  styleProp: {
    width: null,
    height: 330
  },
  prop: {
    ...propForToolbox,
    sourceKey: 'source',
    targetKey: 'target',
    val: 'value',
    group: 'group',
    symbolSize: '',
    // label: '',
    labelVisible: false,
    // labelPosition: 'inside',
    rootWidth: 50,
    nodeWidth: 30,
    repulsion: 400,
    series: {
      type: 'graph'
    }
  },
  dataProp: {
    defaultData: [
      {
        source: 'a',
        target: 'a1',
        value: 113,
        group: 'A'
      },
      {
        source: 'a',
        target: 'b1',
        value: 57,
        group: 'A'
      },
      {
        source: 'a',
        target: 'a2',
        value: 58,
        group: 'A'
      },
      {
        source: 'b',
        target: 'b1',
        value: 202,
        group: 'B'
      },
      {
        source: 'b1',
        target: 'a1',
        value: 16,
        group: 'C'
      },
      {
        source: 'b1',
        target: 'c',
        value: 32,
        group: 'C'
      }
    ]
  }
}

// 统计数据
const comparison = {
  imageSrc: 'chain-comparison',
  thumbnail: '',
  title: '统计数据',
  type: 'HHChainComparison',
  mutualSetting: true,
  dataSourceSetting: true,
  dataSourceType: 'LocalData',
  dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
  // 自定义数据处理函数
  customDataHandle: '',
  eventOption: {
    send: {
      // 可触发类型
      triggerTypes: [],
      // 可绑定得值
      bindValues: []
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: []
    }
  },
  styleProp: {
    width: null,
    height: 125,
    color: null
  },
  prop: {
    title: '请输入标题',
    currentValue: '',
    chainRatioValue: '',
    showTemplate: 'type-1',
    valueColor: '#0076DE'
  },
  dataProp: {
    defaultData: [
      {
        currentValue: 52,
        chainRatioValue: 12.89
      }
    ],
    cols: []
  }
}
// 排序
const sort = {
  imageSrc: 'sort',
  thumbnail: '',
  title: '排序',
  type: 'HHSortWidge',
  mutualSetting: true,
  dataSourceSetting: true,
  dataSourceType: 'LocalData',
  dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
  // 自定义数据处理函数
  customDataHandle: '',
  eventOption: {
    send: {
      triggerTypes: [
        {
          label: '点击',
          value: 'itemClick'
        }
      ],
      bindValues: [
        {
          label: '点击名称',
          value: 'clickItemNameValue'
        },
        {
          label: '点击值',
          value: 'clickItemValue'
        }
      ]
    },
    receive: {
      // 可操作方法
      operationTypes: [
        {
          label: '重新加载',
          value: 'reloadByEvent'
        },
        {
          label: '仅接收值',
          value: 'onlyReceiveEvent'
        }
      ],
      // 可绑定得值
      bindValues: [
        {
          label: '过滤时间',
          value: 'filterRange'
        }
      ]
    }
  },
  styleProp: {
    width: null,
    height: 600
  },
  prop: {
    title: '默认标题',
    unit: '单位',
    columnCount: 1,
    columnGap: null,
    hasIcon: false,
    valueStyle: 'dot2',
    dotNum: 2,
    valuePosition: 'center',
    textColor: '#0076de',
    bgColor: '#fff',
    titleColor: '#000',
    singleHeight: 8,
    showStyle: 'horizontal',
    color: [
      '#1684F2',
      '#2AC2F2',
      '#2AC16A',
      '#BAE86D',
      '#FDE031',
      '#FDAC23',
      '#F56975',
      '#E389BD',
      '#E644A2',
      '#BE5EDB',
      '#9D7CE6',
      '#7888F4',
      '#85D1EB',
      '#7BF6D9'
    ],
    hasDefault: false
  },
  dataProp: {
    defaultData: [
      {
        name: '手机',
        value: 5934,
        img: ''
      },
      {
        name: '平板电脑',
        value: 5521,
        img: ''
      },
      {
        name: 'PC',
        value: 4351,
        img: ''
      },
      {
        name: '智能音箱',
        value: 2910,
        img: ''
      },
      {
        name: '电视',
        value: 1076,
        img: ''
      }
    ],
    // 名称列
    name: '',
    // 数值列
    val: ''
  }
}
// 瓦片地图
const olMap = {
  imageSrc: 'ol-map',
  thumbnail: '',
  title: '瓦片地图',
  type: 'HHOlMap',
  mutualSetting: true,
  dataSourceSetting: true,
  dataSourceType: 'LocalData',
  dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
  eventOption: {
    send: {
      triggerTypes: [],
      bindValues: []
    },
    receive: {
      operationTypes: [
        {
          label: '设置中心点',
          value: 'setFocusZb'
        },
        {
          label: '重新加载',
          value: 'reloadByEvent'
        }
      ],
      bindValues: []
    }
  },
  prop: {
    mapServerUrl: 'http://10.10.3.28:11080/', // 瓦片地图服务器地址
    zoom: 9, // 默认缩放层级
    maxZoom: 10, // 最大显示层级
    minZoom: 7, // 最小显示层级
    defaultCenterCoord: '116.38779, 39.91184', // 默认中心点坐标，格式为 1111,2222
    markStyle: 'point', // none 无, point 点，line 线段
    pointColorInterval: {
      enable: false,
      interval: []
    }
  },
  styleProp: {
    width: null,
    height: 400
    // 'padding-top': 0,
    // 'padding-bottom': 0,
    // 'padding-left': 0,
    // 'padding-right': 0
  },
  dataProp: {
    defaultData: [
      {
        longitude: '116.37205441', // 经度
        latitude: '39.86402431', // 纬度
        value: '10' // 数值
      },
      {
        longitude: '116.33161017', // 经度
        latitude: '39.83948167', // 纬度
        value: '50' // 数值
      },
      {
        longitude: '116.31193874', // 经度
        latitude: '39.78653620', // 纬度
        value: '100' // 数值
      }
    ],
    longitudeCol: 'longitude', // 经度字段
    latitudeCol: 'latitude', // 纬度字段
    valueCol: 'value' // 数值字段
  }
}

// // 图表类组件属性完善
// function each(list: any[]): Widget[] {
//   const rt = []
//   for (let i = 0, len = list.length; i < len; i++) {
//     const item = list[i]
//     item.styleProp = { ...styleProp, ...item.styleProp }
//     item.prop = { ...chartCommonProp, ...item.prop }
//     if (item.dataProp) {
//       item.dataProp = { ...chartDataProp, ...item.dataProp }
//     }
//     list[i] = { ...chartCompAttr, ...item }
//     rt.push(list[i])
//   }
//   return rt
// }
// // 常规组件属性完善
// function regularEach(list: any): Widget[] {
//   const rt = []
//   for (let i = 0, len = list.length; i < len; i++) {
//     const item = list[i]
//     item.styleProp = { ...styleProp, ...item.styleProp }
//     if (item.dataProp) {
//       item.dataProp = { ...dataProp, ...item.dataProp }
//     }
//     list[i] = { ...compAttr, ...item }
//     rt.push(list[i])
//   }
//   return rt
// }

const chartBarItem = genChartSetting(chartBar)
const chartLineItem = genChartSetting(chartLine)
const chartPieItem = genChartSetting(chartPie)
const chartRadarItem = genChartSetting(chartRadar)
const chartScatterItem = genChartSetting(chartScatter)
const chartGaugeItem = genChartSetting(chartGauge)
const chartSankeyItem = genChartSetting(chartSankey)
const chartMapItem = genChartSetting(chartMap)
const chartMixItem = genChartSetting(chartMix)
const chartHeatMapItem = genChartSetting(chartHeatMap)
const chartJadeItem = genChartSetting(chartJade)
const processItem = genRegularSetting(process)
const chartLiquidItem = genChartSetting(chartLiquid)
const chartGraphItem = genChartSetting(chartGraph)
const chartRelationItem = genChartSetting(chartRelation)
const comparisonItem = genRegularSetting(comparison)
const sortItem = genRegularSetting(sort)
const olMapItem = genRegularSetting(olMap)

export const chartTypeMapping = {
  全部: [
    chartBarItem,
    chartLineItem,
    chartPieItem,
    chartRadarItem,
    chartScatterItem,
    chartGaugeItem,
    chartSankeyItem,
    chartMapItem,
    chartMixItem,
    chartHeatMapItem,
    chartJadeItem,
    processItem,
    chartLiquidItem,
    chartGraphItem,
    chartRelationItem,
    comparisonItem,
    sortItem,
    olMapItem
  ],
  比较类: [
    chartBarItem,
    chartLineItem,
    chartPieItem,
    chartRadarItem,
    sortItem,
    chartGraphItem,
    chartHeatMapItem
  ],
  占比类: [chartPieItem, chartJadeItem, chartLiquidItem, processItem],
  趋势类: [chartLineItem, chartScatterItem],
  区间类: [chartGaugeItem, chartHeatMapItem],
  流程类: [chartSankeyItem],
  关联类: [chartSankeyItem, chartRelationItem],
  时间类: [chartLineItem, chartScatterItem],
  地图类: [chartMapItem],
  其他: [chartMixItem],
  折线图: [chartLineItem, chartMixItem],
  柱状图: [chartBarItem, sortItem, chartMixItem],
  散点图: [chartScatterItem, chartGraphItem, processItem, chartMixItem],
  饼图: [chartPieItem, chartJadeItem, chartLiquidItem],
  雷达图: [chartRadarItem],
  力导向图: [chartRelationItem]
}

export const chartFunType = {
  全部: '全部',
  比较类: '比较类',
  占比类: '占比类',
  趋势类: '趋势类',
  区间类: '区间类',
  流程类: '流程类',
  关联类: '关联类',
  时间类: '时间类',
  地图类: '地图类',
  其他: '其他'
}
export const chartType = {
  全部: '全部',
  折线图: '折线图',
  柱状图: '柱状图',
  散点图: '散点图',
  饼图: '饼图',
  雷达图: '雷达图',
  力导向图: '力导向图'
}
