import { forEach as ForEach, concat, remove, findIndex, keys } from 'lodash-es'
import { getAllPageByProject } from '@haohan/clw-api'
import { getDialogEvent } from '@haohan/clw-utils'
import { useProject } from './project'
import { readSendEvents } from '@haohan/clw-hooks'
import { useUser } from './user'
import { defineStore } from 'pinia'
interface State {
  commonParameters: {
    [propName: string]: CommonParameter
  }
  allPageRunInEvents: WidgetEvent[]
  allPageDialogEvents: any[]
  pageInfo: PageInfo
  sendEvents: WidgetEvent[]
  receiveEvents: WidgetEvent[]
  mutualPool: MutualPool
  pageParams: PageEventParam
  jumpPageIds: string
  jumpPageTypes: string
  showMultipJumpDialog: boolean
  showMultipJumpInfo: { [propName: string]: any }
  showMultipJumpType: string
  pageWidgetParam: { [propName: string]: any }
  formParamsFromTableCustomAction: { [propName: string]: any }
}
interface KetValue {
  key: string
  value: string
}
export const useEvent = defineStore({
  id: 'event',
  getters: {
    // anonymous: (state) => !state.current.username
  },
  state: (): State => {
    return {
      // 全局全局变量
      // key 全局变量ID
      // value 全局变量值
      commonParameters: {},
      // 保存当前项目中跳转事件
      allPageRunInEvents: [],
      // 保存当前项目中弹窗事件
      allPageDialogEvents: [],
      pageInfo: { sendFilters: [], receiveFilters: [], comParam: [], widgetDataIdKeys: [] },
      // 只存放当前页面中所有的发送事件
      sendEvents: [],
      // 只存放当前页面中所有的接收事件
      receiveEvents: [],
      mutualPool: {},
      // 保存跳转、下钻等事件行为时得参数信息
      pageParams: {},
      // 供跳转多选得页面ID集合
      jumpPageIds: '',
      jumpPageTypes: '',
      // 显示多选跳转框
      showMultipJumpDialog: false,
      showMultipJumpInfo: { x: 0, y: 0 },
      showMultipJumpType: '',
      /**
       * 存放页面组件需要在该页面全局使用得参数值
       * key: 组件key_参数名
       * value: {
       *    value: 参数值
       *    name: 显示得参数名称
       * }
       */
      pageWidgetParam: {},
      // 表格点击行, 传递给弹窗表单的参数
      formParamsFromTableCustomAction: {}
    }
  },
  actions: {
    loadCommonParameters(project: any) {
      const params: any = {}
      const _cp: any[] = []
      // 加载系统全局变量
      // 判断是否有时间全局变量
      if (_cp.findIndex((p: any) => p.label === '全局时间显示值') === -1) {
        // 添加默认全局变量
        _cp.push({
          key: 'show_time_param',
          label: '全局时间显示值',
          value: '',
          readonly: true,
          type: 'system'
        })
      }
      // 加载用户信息全局变量
      const userStore = useUser()
      _cp.push({
        key: 'current_username',
        label: '当前用户名(显示名)',
        value: userStore.current.displayName ? userStore.current.displayName : '', // 这里暂时取显示名，如有需要再更改
        readonly: true,
        type: 'system'
      })
      _cp.push({
        key: 'current_user_group',
        label: '当前用户角色',
        value: userStore.current.roles
          ? userStore.current.roles.map((m: any) => m.name).join(',')
          : '',
        readonly: true,
        type: 'system'
      })
      // 获取用户自定义的全局变量
      if (
        project.settings &&
        project.settings.comParams &&
        project.settings.comParams.trim() !== ''
      ) {
        const comParams = JSON.parse(project.settings.comParams)
        comParams.forEach((cp: any) => {
          _cp.push({ ...cp, type: 'user' })
        })
      }
      _cp.forEach((cp: any) => {
        params[cp.key] = cp
      })
      this.commonParameters = params
    },
    async initAllPageDialogEvent() {
      const project = useProject()
      const id = project.currentProject.id
      const resp = await getAllPageByProject(id)
      const list = resp?.data?.data || []
      const dialogEvents: any[] = []
      list.forEach((page: any) => {
        getDialogEvent(page.components, dialogEvents)
      })
      // 更新弹窗事件
      const newEvent: any[] = []
      this.allPageDialogEvents.forEach((rie: any) => {
        if (dialogEvents.findIndex((p) => p.key === rie.key) === -1) {
          newEvent.push(rie)
        }
      })
      this.allPageDialogEvents = newEvent.concat(dialogEvents)
    },
    deleteCommonParameters(keys: string[]) {
      let deleteKeys = keys
      if (keys instanceof String) {
        deleteKeys = keys.split(',')
      }
      deleteKeys.forEach((key) => {
        delete this.commonParameters[key]
      })
    },
    addCommonParameters(commonParameters: CommonParameter[]) {
      commonParameters.forEach((p) => {
        this.commonParameters[p.key] = p
      })
    },
    updateCommonParameters(key: string, value: string) {
      this.commonParameters[key].value = value
    },
    updateCommonParametersByLabel(label: string, value: string) {
      keys(this.commonParameters).forEach((key: string) => {
        const cp = this.commonParameters[key]
        if (cp.label === label) {
          cp.value = value
        }
      })
    },
    changePageInfo(pageInfo: PageInfo) {
      this.pageInfo.comParam = pageInfo.comParam
      this.pageInfo.sendFilters = pageInfo.sendFilters
      this.pageInfo.receiveFilters = pageInfo.receiveFilters
      this.pageInfo.widgetDataIdKeys = pageInfo.widgetDataIdKeys
    },
    changeSendEvents(sendEvents: WidgetEvent[]) {
      this.sendEvents = sendEvents
    },
    changeReceiveEvents(receiveEvents: WidgetEvent[]) {
      this.receiveEvents = receiveEvents
    },
    insertReceiveEvents(receiveEvents: WidgetEvent[]) {
      this.receiveEvents = concat(this.receiveEvents, receiveEvents)
    },
    deleteReceiveEvents(receiveEvents?: WidgetEvent[]) {
      if (receiveEvents) {
        remove(this.receiveEvents, (e: WidgetEvent) => {
          return findIndex(receiveEvents, (_e: WidgetEvent) => _e.key === e.key) !== -1
        })
      } else {
        this.receiveEvents = []
      }
    },
    changeAllPageRunInEvents(allPageRunInEvents: WidgetEvent[]) {
      this.allPageRunInEvents = allPageRunInEvents
    },
    removeMutualPool(key: string) {
      delete this.mutualPool[key]
    },
    clearMutualPool() {
      const keys = Object.keys(this.mutualPool)
      ForEach(keys, (k: string) => {
        delete this.mutualPool[k]
      })
    },
    initMutualPoolByPageComponents(components: Widget[]) {
      const sendEvents: WidgetEvent[] = []
      readSendEvents(components, sendEvents)
      this.initMutualPool(sendEvents)
      console.log('initMutualPoolByPageComponents', sendEvents, this.mutualPool)
    },
    initMutualPoolBySendEvents(sendEvents: WidgetEvent[]) {
      this.initMutualPool(sendEvents)
    },
    initMutualPool(events: WidgetEvent[]) {
      // 清空交互池
      this.clearMutualPool()
      ForEach(events, (e) => {
        this.mutualPool[e.key] = {
          key: e.key,
          value: '',
          // 不是立即触发得，则默认都是ok
          status: e.action === 'promptlyFilters' ? 'waiting' : 'ok',
          sendEvent: e
        }
      })
    },
    updateMutualPool(key: string, value: string, status: MutualPoolDataType = 'ok') {
      if (this.mutualPool[key]) {
        this.mutualPool[key].status = status
        this.mutualPool[key].value = value
      }
    },
    updateMutualPoolStatus(key: string, status: MutualPoolDataType = 'ok') {
      if (this.mutualPool[key]) {
        this.mutualPool[key].status = status
      }
    },
    // 交互池插入交互
    insertMutualPoolBySendEvents(sendEvents: WidgetEvent[]) {
      ForEach(sendEvents, (e) => {
        this.mutualPool[e.key] = {
          key: e.key,
          value: '',
          // 不是立即触发得，则默认都是ok
          status: e.action === 'promptlyFilters' ? 'waiting' : 'ok',
          sendEvent: e
        }
      })
    },
    // 按发送事件删除交互池中的指定内容
    deleteMutualPoolBySendEvents(sendEvents: WidgetEvent[]) {
      ForEach(sendEvents, (e) => {
        delete this.mutualPool[e.key]
      })
    },
    /**
     * 更新页面参数值
     * @param key 页面参数key，一般为事件key
     * @param data 页面参数数据
     */
    changePageParams(key: string, data: PageEventParamData) {
      this.pageParams[key] = data
    },
    updateCommonParameterValue(keyValue: KetValue) {
      let updateKeyValues = [keyValue]
      if (keyValue instanceof Array) {
        updateKeyValues = keyValue
      }
      updateKeyValues.forEach((kv: KetValue) => {
        if (this.commonParameters[kv.key]) {
          this.commonParameters[kv.key].value = kv.value
        }
      })
    },
    changeJumpPageIds(jumpPageIds: any) {
      this.jumpPageIds = jumpPageIds
    },
    changeJumpPageTypes(jumpPageTypes: any) {
      this.jumpPageTypes = jumpPageTypes
    },
    changeShowMultipJumpDialog(showMultipJumpDialog: any) {
      this.showMultipJumpDialog = showMultipJumpDialog
    },
    changeShowMultipJumpInfo(showMultipJumpInfo: any) {
      this.showMultipJumpInfo = showMultipJumpInfo
    },
    changeShowMultipJumpType(showMultipJumpType: any) {
      this.showMultipJumpType = showMultipJumpType
    },
    clearPageWidgetParam() {
      const keys = Object.keys(this.pageWidgetParam)
      ForEach(keys, (k: string) => {
        delete this.pageWidgetParam[k]
      })
    },
    /**
     * 更新页面参数值
     * @param key 页面参数得key
     * @param value 页面参数值对象
     */
    updatePageWidgetParam(key: string, value: any) {
      this.pageWidgetParam[key] = value
    },
    /**
     * 表格点击自定义操作, 传递给弹窗表单的参数
     * @param key 事件key
     * @param data 数据
     */
    updateFormParamsFromTable(key: string, data: any) {
      this.formParamsFromTableCustomAction[key] = data
    },
    insertWidgetDataIdKeys(widgetDataIdKeys: string[]) {
      this.pageInfo.widgetDataIdKeys = concat(this.pageInfo.widgetDataIdKeys, widgetDataIdKeys)
    }
  }
})
