<template>
  <div class="message-box">
    <header>
      <div class="navs">
        <template v-for="(v, k) in NAVS" :key="k">
          <div
            class="item"
            :class="{ active: currentComp === k, 'has-unread': v.hasUnread }"
            @click="changeNav(k)"
          >
            <span class="dot"></span>
            {{ v.label }}
          </div>
          <span v-if="v.label !== '我已处理'" class="line">|</span>
        </template>
      </div>
    </header>
    <main>
      <component :is="navComps[currentComp]" @change-tab="changeTab"></component>
    </main>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'SettingsDashBoard'
  }
</script>

<script lang="ts" setup>
  import { ref, watch, onBeforeMount } from 'vue'
  import { useRoute } from 'vue-router'
  // import AllMessage from './AllMessage.vue'
  import ToDoMessage from './ToDoMessage.vue'
  import MyInitiate from './MyInitiate.vue'
  import MyProcessed from './MyProcessed.vue'
  // import CopyMe from './CopyMe.vue'
  import { queryStatus } from '@haohan/clw-api'
  import { useProject } from '@haohan/clw-store'

  const projectStore = useProject()
  const projectId = computed(() => {
    return projectStore.currentProject?.id
  })

  const route = useRoute()
  const NAVS = ref({
    ToDoMessage: { label: '我的待办', hasUnread: true },
    MyInitiate: { label: '我发起的', hasUnread: false },
    MyProcessed: { label: '我已处理', hasUnread: false }
    // CopyMe: { label: '抄送我的', hasUnread: false }
  })
  const navComps = {
    // AllMessage,
    ToDoMessage,
    MyInitiate,
    MyProcessed
    // CopyMe
  }

  const currentComp = ref('ToDoMessage')
  function changeNav(k: string) {
    currentComp.value = k
  }

  watch(
    () => route.query.tab,
    (nv) => {
      // console.log(nv, currentComp.value)
      if (nv && currentComp.value !== nv) {
        changeNav(nv)
      } else {
        changeNav('ToDoMessage')
      }
    },
    {
      immediate: true
    }
  )
  const type4Tab: any = {
    PROC_START: '', //工作流定时开启
    PROC_STOP: '', //工作流定时结束
    TODO: 'ToDoMessage', //收到待办
    TODO_OUT_OF_DATE: 'ToDoMessage', //待办即将过期
    TODO_EXPIRED: 'ToDoMessage', //待办已过期
    FLOW_MANUAL_END: '', //发起人工作流手动停止
    FLOW_END_ACCEPT: 'MyInitiate', //工作流结束，通过
    FLOW_END_REJECT: 'MyInitiate', //工作流结束，驳回
    CC: 'MyProcessed' //已处理
  }
  function changeTab(tab: string) {
    changeNav(type4Tab[tab] || 'AllMessage')
  }

  function querTabStatus() {
    const params = {
      page: 1,
      size: 10000
    }
    queryStatus(projectId.value, params).then((res) => {
      // console.log(res)
      NAVS.value.ToDoMessage.hasUnread = res.data.body > 0 ? true : false
    })
  }
  onBeforeMount(() => {
    querTabStatus()
  })
</script>

<style lang="scss" scoped>
  .message-box {
    padding: 0 30px;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    height: 48px;
  }
  .navs {
    display: flex;
    font-size: 14px;
    .item {
      position: relative;
      color: var(--font-color-title);
      cursor: pointer;
      .dot {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        top: -2px;
        right: -4px;
        background-color: transparent;
      }
      &.active {
        color: var(--font-color-clickable);
      }
      &.has-unread {
        .dot {
          background-color: #f01d1d;
        }
      }
    }
    .line {
      color: #999;
      padding: 0 20px;
    }
  }
  :deep() {
    .top-search {
      display: flex;
      align-items: center;
      position: absolute;
      top: -52px;
      right: 0;
    }
    .query-box {
      position: relative;
      width: 326px;
      margin-right: 42px;
      .el-input {
        height: 36px;
        overflow: hidden;
      }
      .el-input__wrapper {
        width: 100%;
        height: 100%;
        // border-radius: 27px;
        // border: 1px solid var(--border-color-third);
        background-color: #fff !important;
        padding-left: 34px;
        box-shadow: none;
        // &.is-focus {
        //   border-color: var(--border-color-focus);
        // }
      }
      .el-input__inner {
        color: #1a1d1f;
      }
      .svg-icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
      }
    }
  }
</style>
