import { http } from '@haohan/clw-utils'

const URL = '/api/v1/messages'

/**
 * 查看消息
 * @returns
 */
export function queryMessageListReq(params: any) {
  return http.request({
    url: `${URL}/query`,
    method: 'POST',
    data: params
  })
}
/**
 * 消息改成已读
 * @returns
 */
export function makeMessageReadReq(id: any) {
  return http.request({
    url: `${URL}/${id}/read`,
    method: 'PUT'
  })
}
/**
 * 消息删除
 * @returns
 */
export function delMessageReq(id: any) {
  return http.request({
    url: `${URL}/${id}`,
    method: 'DELETE'
  })
}

/**
 * 我已处理 - 查询
 * @returns
 */
// export function queryComplete(projectId: string, params: any) {
//   return http.request({
//     url: `/api/v1/projects/${projectId}/flowTask/complete`,
//     method: 'get',
//     params
//   })
// }
export function queryMyAttended(projectId: string, params: any) {
  return http.request({
    url: `/api/v1/projects/${projectId}/flowInst/myAttended`,
    method: 'get',
    params
  })
}
/**
 * 我发起的 - 查询
 * @returns
 */
export function queryFlowInst(projectId: string, params: any) {
  return http.request({
    url: `/api/v1/projects/${projectId}/flowInst`,
    method: 'get',
    params
  })
}

/**
 * 详情弹框 - 任务列表
 * @returns
 */
export function queryTasks(projectId: string, id: string, params: any) {
  return http.request({
    url: `/api/v1/projects/${projectId}/flowInst/${id}/tasks`,
    method: 'get',
    params
  })
}

/**
 * 填写弹框 - 填写
 * @returns
 */
export function addWrite(projectId: string, id: string, params: any) {
  return http.request({
    url: `/api/v1/projects/${projectId}/flowTask/${id}/write`,
    method: 'post',
    data: params
  })
}

/**
 * 我发起的 - 终止
 * @returns
 */
export function stopFlowInst(projectId: string, id: string) {
  return http.request({
    url: `/api/v1/projects/${projectId}/flowInst/${id}/stop`,
    method: 'post'
  })
}

/**
 * 我的待办 - 查询
 * @returns
 */
export function queryTodos(projectId: string, params: any) {
  return http.request({
    url: `/api/v1/projects/${projectId}/flowTask/todos`,
    method: 'get',
    params
  })
}

/**
 * 我的待办 - 拒绝
 * @returns
 */
export function rejectFlowTask(projectId: string, id: string, data: any) {
  return http.request({
    url: `/api/v1/projects/${projectId}/flowTask/${id}/reject`,
    method: 'post',
    data
  })
}

/**
 * 我的待办 - 同意
 * @returns
 */
export function acceptFlowTask(projectId: string, id: string, data: any) {
  return http.request({
    url: `/api/v1/projects/${projectId}/flowTask/${id}/accept`,
    method: 'post',
    data
  })
}

/**
 * 我的待办 - 红点
 * @returns
 */
export function queryStatus(projectId: string, data: any) {
  return http.request({
    url: `/api/v1/projects/${projectId}/flowTask/todos/count`,
    method: 'get',
    params: data
  })
}
