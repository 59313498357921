import { Item } from '../layout'

export function createRouterByMenus(projectId: string, menus: Menu[], _router: Item[] = []) {
  menus.forEach((e) => {
    if (e.type === 'MENU') {
      const m = {
        path: `/${
          e.variables && e.variables.pageType === 'bigScreen' ? 'bigscreen' : 'preview'
        }/${projectId}/${e.id}`,
        meta: {
          title: e.title,
          hidden: e.hidden,
          icon: e.variables.icon,
          id: e.id
        },
        children: [],
        type: e.type
      }
      if (e.children && e.children.length > 0) {
        m.children = []
        createRouterByMenus(projectId, e.children, m.children)
      }
      _router.push(m)
    } else {
      _router.push({
        path: `/${
          e.variables && e.variables.pageType === 'bigScreen' ? 'bigscreen' : 'preview'
        }/${projectId}/${e.id}`,
        meta: {
          title: e.title,
          hidden: e.hidden,
          icon: e.variables.icon,
          id: e.id
        },
        type: e.type
      })
    }
  })
  return _router
}
