import { cloneDeep as cloneDeepLodash, has } from 'lodash-es'
import { useMessage } from '@haohan/clw-hooks'
import { exeCustomFun } from './widget'

export function getElSize(el: Element) {
  const style = window.getComputedStyle(el)
  return {
    width: parseFloat(style.getPropertyValue('width')),
    height: parseFloat(style.getPropertyValue('height'))
  }
}

// export function useState<T>(initialState: T): [Ref<T>, (value: T) => T] {
//   const state = ref(initialState) as Ref<T>
//   const setState = (value: T): T => {
//     state.value = value
//     return value
//   }
//   return [state, setState]
// }

function createEventListenerFunction(type: 'addEventListener' | 'removeEventListener') {
  return <K extends keyof HTMLElementEventMap>(el: HTMLElement, events: K | K[], handler: any) => {
    if (!el) {
      return
    }
    if (typeof events === 'string') {
      events = [events]
    }
    events.forEach((e) => el[type](e, handler, { passive: false }))
  }
}

export const addEvent = createEventListenerFunction('addEventListener')

export const removeEvent = createEventListenerFunction('removeEventListener')

/**
 * 返回唯一Key
 */
export const getKey = () => {
  return Date.parse(`${new Date()}`) + '_' + Math.ceil(Math.random() * 99999)
}

export const cloneDeep = (value: any) => cloneDeepLodash(value)

export function isValidKey(
  key: string | number | symbol,
  object: object
): key is keyof typeof object {
  return key in object
}

/**
 * 将数值转为含有千分位分隔符得值
 * @param num 数值
 * @returns 千分位分隔符
 */
export function numberFormat(num: string): string {
  const res = num.toString().replace(/\d+/, function (n) {
    // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ','
    })
  })
  return res
}

/**
 * 保存当前页面为 png
 * @param data 流文件
 * @param filename 图片名称
 */
export const saveImage = function (data: string, filename: string) {
  const aLink = document.createElement('a')
  aLink.style.display = 'none'
  aLink.href = data
  aLink.download = `${filename}.jpg`
  document.body.appendChild(aLink)
  aLink.click()
  document.body.removeChild(aLink)
}

const hasChildrenComps = ['FormStep']
/**
 * 把 widgetList(包含容器) 扁平化
 * @param widgetList 编辑页的 widgetData.list
 * @param newList 扁平化后的数组
 * @returns newList
 */
export const getFlatFormPageWidgetDatalist = (widgetList: Widget[], newList: Widget[]) => {
  if (!widgetList?.length || !Array.isArray(newList)) {
    return []
  }
  widgetList.forEach((widget) => {
    // 表单的容器组件
    if (widget?.children?.length) {
      widget?.children.forEach((child) => {
        if (child?.list?.length) {
          getFlatFormPageWidgetDatalist(child.list, newList)
        }
      })
    } else if (hasChildrenComps.includes(widget.type)) {
      const comp = widget.dataProp.defaultData
      comp.forEach((child) => {
        if (child?.list?.length) {
          getFlatFormPageWidgetDatalist(child.list, newList)
        }
      })
    } else {
      // 表单的其他组件
      newList.push(widget)
    }
  })
  return newList
}

const isString = (str: string) => {
  return Object.prototype.toString.call(str) === '[object String]'
}

/**
 * 表单页的组件数据联动
 */
export const getWidgetVsibleForFormAssociate = (widget: Widget, currentForm: any) => {
  const { formProp } = widget
  // 添加了关联条件
  if (formProp?.associateOptions?.length) {
    // 是否符合其中任意一个
    let isMatchAnyCondition = false

    formProp?.associateOptions.forEach((option: any) => {
      const { key, value, operator } = option
      const compValue = currentForm[key] // projectStore.currentForm[key]
      // '等于'
      if (operator === 'equal') {
        if (compValue == value) {
          isMatchAnyCondition = true
        }
      }
      // '不等于'
      if (operator === 'notEqual') {
        if (compValue !== value) {
          isMatchAnyCondition = true
        }
      }
      // '包含'
      if (operator === 'include') {
        // 都是数组的情况
        if (Array.isArray(value) && Array.isArray(compValue)) {
          // 取交集
          const list = value.filter((item) => {
            return compValue.includes(item)
          })
          if (list?.length) {
            isMatchAnyCondition = true
          }
        }
        // 字符串的情况, 比如 checkbox 勾选转字符串后
        if (Array.isArray(value) && isString(compValue)) {
          if (compValue.includes(value[0])) {
            isMatchAnyCondition = true
          }
        }
      }
      // '不包含'
      if (operator === 'exclude') {
        if (Array.isArray(value) && !value.includes(compValue)) {
          // 取交集
          const list = value.filter((item) => {
            return compValue.includes(item)
          })
          if (!list?.length) {
            isMatchAnyCondition = true
          }
        }
      }
    })

    // 隐藏
    if (formProp?.associateType === 'hidden') {
      // 符合条件隐藏, 不符合显示
      return isMatchAnyCondition ? false : true
    }
    // 显示
    if (formProp?.associateType === 'display') {
      // 符合条件显示, 不符合隐藏
      return isMatchAnyCondition ? true : false
    }
  }

  // 默认显示
  return true
}

/**
 * 移除对象中值为 null 或 ''
 */
export const removeEmpty = (obj: object) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== ''))
}

/**
 * 表单传递给接口的参数
 * @param widgetList 页面组件数据
 * @param form projectStore.currentForm
 * @returns 传给接口的数据还需要单独再去不同地方封装下 这里只是返回表单的数据
 * 比如接口需要 { data: payload }, 当前方法只能得到 payload
 */
const ignoreComps = ['FormTitle', 'FormStep']
export const getFormPayloadByWidgetList = (
  widgetList: Widget[],
  form: { [propName: string]: any },
  filterNullValues = false
) => {
  if (!Array.isArray(widgetList) || !widgetList?.length || !form || !Object.keys(form)?.length) {
    return {}
  }
  let payload = {} as any
  let flatList: any[] = []

  // widgetList
  flatList = getFlatFormPageWidgetDatalist(widgetList, flatList)
  // console.log(JSON.stringify(flatList))
  // console.log(form)
  flatList.forEach((widget: any) => {
    const { type: widgetType } = widget // FormTitle
    const { tableColumn, type, model, label } = widget?.formProp || {}
    const key = widget.key

    // 排除组件
    if (!ignoreComps.includes(widgetType)) {
      // form
      const visible = getWidgetVsibleForFormAssociate(widget, form)

      // 数据关联后, 隐藏的组件 不传给接口
      if (tableColumn && visible) {
        payload[tableColumn] = form[key] === null ? '' : form[key]
      }

      // 隐藏的需要重置, !payload[tableColumn] 为了解决多个输入框绑定同一个字段, 然后空的那个覆盖掉了有值的
      if (tableColumn && !visible && !payload[tableColumn]) {
        payload[tableColumn] = ''
      }

      // 未在表单中绑定数据列
      if (!tableColumn && visible) {
        useMessage({ message: `${label} 还未绑定数据列`, type: 'warning' })
      }
    }
  })
  // 只有为 true 才过滤 null 和 ''
  if (filterNullValues) {
    payload = removeEmpty(payload)
  }
  return payload
}

/**
 * 工作流表单传递给接口的参数
 * @param widgetList 页面组件数据
 * @param form projectStore.currentForm
 * @returns 传给接口的数据还需要单独再去不同地方封装下 这里只是返回表单的数据
 * 比如接口需要 { data: payload }, 当前方法只能得到 payload
 */
export const getWorkFlowFormPayloadByWidgetList = (
  widgetList: Widget[],
  form: { [propName: string]: any },
  filterNullValues = false
) => {
  if (!Array.isArray(widgetList) || !widgetList?.length || !form || !Object.keys(form)?.length) {
    return {}
  }
  let payload = {} as any
  let flatList: any[] = []

  // widgetList
  flatList = getFlatFormPageWidgetDatalist(widgetList, flatList)
  flatList.forEach((widget: any) => {
    const { type: widgetType } = widget // FormTitle
    const { tableColumn, type, model, label } = widget?.formProp || {}
    const key = widget.key

    // 排除标题组件
    if (widgetType !== 'FormTitle') {
      // form
      const visible = getWidgetVsibleForFormAssociate(widget, form)

      // 数据关联后, 隐藏的组件 不传给接口
      if (tableColumn && visible) {
        payload[tableColumn] = form[key] === null ? '' : form[key]
      }

      // 隐藏的需要重置, !payload[tableColumn] 为了解决多个输入框绑定同一个字段, 然后空的那个覆盖掉了有值的
      if (tableColumn && !visible && !payload[tableColumn]) {
        payload[tableColumn] = ''
      }

      // 未在表单中绑定数据列
      if (!tableColumn && visible) {
        useMessage({ message: `${label} 还未绑定数据列, 请绑定后再提交`, type: 'warning' })
        payload.checkState = true
      }
    }
  })
  // 只有为 true 才过滤 null 和 ''
  if (filterNullValues) {
    payload = removeEmpty(payload)
  }
  return payload
}

/**
 * 根据后端返回流生成文件并下载
 * @param data 流
 * @param fullName 文件.zip
 */
export const generateFile = (data: Blob, fullName: string) => {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([data]))
  link.download = fullName || '未命名文件'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * 复制
 * @param text 复制的内容
 */
export const copyTextToClipboard = (text: string) => {
  const promise = new Promise((resolve, reject) => {
    // 创建一个textarea元素
    const textarea = document.createElement('textarea')
    textarea.value = text
    textarea.style.position = 'fixed' // 确保在可视区域内
    document.body.appendChild(textarea)
    textarea.select()

    try {
      // 尝试执行复制命令
      const successful = document.execCommand('copy')
      if (successful) {
        // 如果复制成功，则触发回调函数
        resolve(text)
      } else {
        reject(new Error('复制失败'))
      }
    } catch (error) {
      reject(error)
    } finally {
      // 最后，清除textarea元素
      document.body.removeChild(textarea)
    }
  })

  // 返回Promise对象
  return promise
}

/**
 * invoke 接口需要的参数是 FormData
 * @param ks 定义接口的 key
 * @param payload json
 * @returns FormData
 *
 例如:
 定义的API是
    {
      "data": [${body}]
    }

  浏览器发出去的则为

  body: (binary)

  ------WebKitFormBoundaryAR2eebTpBDyrkoSq
  Content-Disposition: form-data; name="body"; filename="blob"
  Content-Type: application/json

  {"body":"{\"name\":\"1\",\"account\":\"1\",\"email_type\":\"1\"}"}
  ------WebKitFormBoundaryAR2eebTpBDyrkoSq--
 */
// 升级2.6.3版本后，原先旧API里的 ${body}，统一变为了 REQUEST_BODY
// TODO:需要测试原先项目的兼容性 REQUEST_BODY
export const transformInvokePayloadToFormDataFromKeys = (
  ks: any[],
  payload = {},
  fileInfo?: any
) => {
  const keys = ks || []
  const formParams = payload as any
  const formData = new FormData()
  // 创建 API 时候 body 中的 {"name": "${name}", "value": "${value}", "type": "${type}"}  => keys: ['name', 'value', 'type']
  const obj = {} as any
  keys.forEach((k: any) => {
    if (has(formParams, k)) {
      obj[k] = formParams[k]
    }
  })
  // 针对2.6.3版本之前的API接口进行兼容性处理
  if (keys.includes('REQUEST_BODY') && !obj.REQUEST_BODY) {
    obj.REQUEST_BODY = JSON.stringify(formParams)
  }
  // 适配curl请求
  if (Object.keys(obj).length !== 0) {
    formData.append(
      'body', // 这个是固定的就叫做 body
      new Blob([JSON.stringify(obj)], {
        type: 'application/json'
      })
    )
  }
  if (fileInfo) {
    Object.keys(fileInfo).forEach((key: any) => {
      if (fileInfo[key]) {
        formData.append(key, fileInfo[key])
      }
    })
  }
  console.log('transformInvokePayloadToFormDataFromKeys.....没定义keys', [JSON.stringify(obj)])
  return formData
}
/**
 * 将返回数据结果按照后置操作进行处理后，返回
 * @param after API接口定义中的后置操作
 * @param response 返回的结果
 * @returns 返回处理后的数据结果
 */
export const handleApiAfterFun = (after: string, response: any) => {
  if (after && after !== '') {
    try {
      return exeCustomFun(after, response)
    } catch (error: any) {
      console.log('error', error)
      return error.message
    }
  }
  return response
}
/**
 * 返回逻辑流参数值
 * @param params 逻辑流参数
 * @param data 数据对象
 * @returns
 */
export const transformLogicFlowParams = (params: any[], data: any = {}) => {
  const _result: any = {}
  params.forEach((p: any) => {
    const { name } = p
    if (data[name]) {
      _result[name] = data[name]
    }
  })
  return _result
}
/**
 * 获取页面路径中的ID
 * @param path 路径
 * @returns 返回路径中的ID
 */
export const getMenuIdByPath = (path: string) => {
  if (path !== '' && path.includes('preview') && !path.includes('sys')) {
    const _p = path.split('/')
    return _p[_p.length - 1]
  }
  return ''
}

/**
 * 获得当前项目下可选择的API环境变量
 * @param envs 环境列表
 * @param envId 当前项目环境ID
 */
export function handleEnvironmentVariables(envs: any[], envId: any) {
  // 获得全局变量以及当前环境变量集合
  const _ev = envs.filter((v: any) => v.name === '全局变量' || v.id === envId)

  // 如果只有一个环境变量集合，则直接返回
  if (_ev.length === 1) {
    return [...(_ev[0].content || [])]
  }
  // 则要进行去重处理
  const currentEnvironmentVars = <any[]>[]
  // 获得非全局变量环境
  const noGlobalEnv = _ev.find((e: any) => e.name !== '全局变量')
  if (noGlobalEnv) {
    currentEnvironmentVars.push(...(noGlobalEnv.content || []))
  }
  // 获得全局变量环境值
  const globalEnv = _ev.find((e: any) => e.name === '全局变量')
  if (globalEnv) {
    globalEnv.content.forEach((e: any) => {
      if (currentEnvironmentVars.findIndex((v: any) => v.key === e.key) === -1) {
        currentEnvironmentVars.push(e)
      }
    })
  }
  return currentEnvironmentVars
}
/**
 * 返回指定条数的数据
 * @param data 数据
 * @param pageSize 条数
 * @param page 当前页
 * @returns
 */
export function getDataByPageSize(data: any, pageSize: number, page: number = 1) {
  if (Array.isArray(data)) {
    // 计算开始索引和结束索引
    const startIndex = (page - 1) * pageSize
    // 确保endIndex不超过数组长度
    const endIndex = Math.min(startIndex + pageSize, data.length)
    return data.slice(startIndex, endIndex)
  }
  return data
}
/**
 * 处理API接口中，文件上传内容
 * @param api 接口信息
 * @param formData form表单
 * @returns
 */
export function handleApiUploadFile(api: any, formData: any) {
  return new Promise((resolve, reject) => {
    // 获得 Body里的内容，并判断是否有文件
    const fileParam: any[] = []
    if (api.body && api.body.body) {
      fileParam.push(...api.body.body.filter((v: any) => v.type === 'FILE'))
    }
    if (fileParam.length !== 0) {
      // 创建一个隐藏的文件输入元素
      const input = document.createElement('input')
      input.type = 'file'
      input.style.display = 'none'

      // 监听文件选择的change事件
      input.addEventListener('change', (event: any) => {
        formData.append('file', event.target.files[0])
        resolve({})
      })

      // 将input元素添加到文档中并触发点击
      document.body.appendChild(input)
      input.click()

      // 移除input元素
      input.remove()
    } else {
      resolve({})
    }
  })
}
