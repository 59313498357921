<template>
  <el-config-provider :locale="locale">
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
  </el-config-provider>
  <CustomColorPicker v-if="showPicker" id="custom-picker" class="picker" />
</template>
<script setup lang="ts">
  import { useGlobalStore } from '@haohan/clw-store'
  import { CustomColorPicker } from '@haohan/clw-platform-public'

  // Element-Plus 国际化
  import zhCn from 'element-plus/es/locale/lang/zh-cn'
  const locale = zhCn
  const global = useGlobalStore()
  const showPicker = computed(() => global?.colorPicker.show)
  onMounted(() => {
    document.body.addEventListener('click', (e) => {
      const dom = document.querySelector('#custom-picker')
      if (dom?.contains(e.target as HTMLElement)) {
        return false
      }
      global.upColorPickerProp('show', false)
    })
  })
</script>
<style>
  #app {
    height: 100%;
  }
  .custom-picker-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    pointer-events: none;
    z-index: 1;
  }
</style>
