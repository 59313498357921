function limitXY(val: number, min: number, max: number) {
  return val < min ? min : val > max ? max : val
}

export default class Move {
  dom: HTMLElement
  // domInfo: DOMRect
  pointer: HTMLElement
  startMove: boolean
  x: number
  y: number
  direction: string //all,v,h
  events: any
  opt: any

  constructor(dom: HTMLElement, opt: any = { direction: 'all' }) {
    this.dom = dom
    const domInfo = this.getInfo()
    this.pointer = dom.querySelector('.indicator')!
    this.startMove = false
    this.x = opt.tx * domInfo.width || 0
    this.y = opt.ty * domInfo.height || 0
    this.direction = opt.direction
    this.events = {}
    this.opt = opt

    this.init()
  }

  init() {
    this.initEvent()
    this.setPosition(this.x, this.y)
  }

  initEvent() {
    this.dom.addEventListener('mousedown', this.mousedownFn)
    this.dom.addEventListener('dragstart', this.nodrag)
  }

  nodrag(e: MouseEvent) {
    e.preventDefault()
  }
  mousedownFn = (e: MouseEvent) => {
    // console.log('mousedownFn')
    e.stopPropagation()
    this.startMove = true
    this.move(e)
    this.emit('down', { ...this.getXY(), ...this.getInfo() })
    document.body.addEventListener('mousemove', this.mousemoveFn)
    document.body.addEventListener('mouseup', this.mouseupFn)
  }
  mousemoveFn = (e: MouseEvent) => {
    // 只有先鼠标按下，才能移动
    if (this.startMove) {
      // console.log('mousemoveFn')
      this.move(e)
      this.emit('move', { ...this.getXY(), ...this.getInfo() })
    }
  }
  mouseupFn = (e: MouseEvent) => {
    // console.log('mouseupFn')
    this.move(e)
    this.emit('up', { ...this.getXY(), ...this.getInfo() })
    this.startMove = false
    document.body.removeEventListener('mousemove', this.mousemoveFn)
    document.body.removeEventListener('mouseup', this.mouseupFn)
  }

  /**
   * 移动
   * @param e
   * @param isDown 是否是鼠标按下触发的移动
   */
  move(e: MouseEvent) {
    const { clientX, clientY } = e
    const { width, height, left, top } = this.getInfo()
    if (this.direction !== 'v') {
      this.x = limitXY(clientX - left, 0, width)
    }
    if (this.direction !== 'h') {
      this.y = limitXY(clientY - top, 0, height)
    }
    this.setPosition(this.x, this.y)
    // this.emit('move', { ...this.getXY(), ...this.getInfo(), isDown })
  }

  setPosition(x: number, y: number) {
    let pointer = this.pointer
    if (this.opt.pointer) {
      pointer = this.dom.querySelector(this.opt.pointer)!
    }
    if (pointer) {
      pointer.style.left = `${x}px`
      pointer.style.top = `${y}px`
    }
  }

  getXY() {
    return {
      x: this.x,
      y: this.y
    }
  }
  setXY({ tx = 0, ty = 0 }) {
    const domInfo = this.getInfo()
    this.x = limitXY(tx * domInfo.width, 0, domInfo.width)
    this.y = limitXY(ty * domInfo.height, 0, domInfo.height)
    this.setPosition(this.x, this.y)
    this.emit('move', { ...this.getXY(), ...domInfo })
  }

  getInfo() {
    const { width, height, left, top } = this.dom.getBoundingClientRect()
    return {
      width: width,
      height: height,
      top: top,
      left: left
    }
  }

  on(eventName: string, fn: Function) {
    if (!Object.prototype.hasOwnProperty.call(this.events, eventName)) {
      this.events[eventName] = fn
    }
  }
  emit(eventName: string, ...args: any[]) {
    if (Object.prototype.hasOwnProperty.call(this.events, eventName)) {
      this.events[eventName](...args)
    }
  }
  off(eventName: string) {
    if (Object.prototype.hasOwnProperty.call(this.events, eventName)) {
      delete this.events[eventName]
    }
  }
  // once(eventName: string, fn: Function) {
  //   const onceFn = (...args: any[]) => {
  //     fn(...args)
  //     this.off(eventName)
  //   }
  //   this.on(eventName, onceFn)
  // }
  getAll() {
    // console.log('getAll')
    return { ...this.getXY(), ...this.getInfo() }
  }

  destroy() {
    this.dom.removeEventListener('mousedown', this.mousedownFn)
    this.dom.removeEventListener('dragstart', this.nodrag)
  }
}
