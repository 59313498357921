<template>
  <div
    class="ctrl"
    :class="{ 'is-collapse': isCollapse }"
    :style="{ flexDirection: props.flexDirection }"
  >
    <el-popover
      v-if="hasPage('UserMessage')"
      :placement="placement"
      :width="310"
      :teleported="false"
      trigger="click"
    >
      <template #reference>
        <div class="message" :class="{ 'has-unread': hasUnread }">
          <el-tooltip effect="dark" content="消息中心" :placement="placement">
            <el-space>
              <svg-icon icon-class="message-tip" :class="{ active: hasMessage }" />
              <span v-show="!props.isCollapse">消息中心</span>
            </el-space>
          </el-tooltip>
          <span class="dot"></span>
        </div>
      </template>
      <div class="message-box">
        <div class="msg-header">
          <h3>消息中心</h3>
          <el-link type="primary" @click="goMessage('')">查看全部</el-link>
        </div>
        <div class="msg-body">
          <div v-if="messageList.length === 0" class="list-empty">暂无未读消息</div>
          <template v-else>
            <div
              v-for="item in messageList"
              :key="item.id"
              class="item"
              @click="goWotkflow(item.tag?.type)"
            >
              <div class="item-title">{{ dealMsg(item) }}</div>
              <div class="time">{{ item.createDate }}</div>
              <svg-icon icon-class="delete" @click.stop="delRow(item.id)" />
            </div>
          </template>
        </div>
      </div>
    </el-popover>

    <el-tooltip
      v-if="hasPage('WorkflowManager')"
      effect="dark"
      content="工作流"
      :placement="placement"
    >
      <div class="message" :class="{ 'has-unread': false }">
        <el-space @click="goWotkflow('TODO')">
          <svg-icon :class="{ active: haswf }" icon-class="wotkflow-icon" />
          <span v-show="!props.isCollapse">工作流</span>
        </el-space>
        <span class="dot"></span>
      </div>
    </el-tooltip>

    <el-tooltip effect="dark" content="个人中心" :placement="placement">
      <el-space :class="{ active: hasInfo }" @click="goInfo">
        <svg-icon :class="{ active: hasInfo }" icon-class="admin" />
        <span v-show="!props.isCollapse">{{
          currentUser.displayName || currentUser.username
        }}</span>
      </el-space>
    </el-tooltip>

    <el-tooltip v-if="hasSettings" effect="dark" content="设置" :placement="placement">
      <el-space @click="goSys">
        <svg-icon icon-class="setting" :class="{ active: hasSys }" />
        <span v-show="!props.isCollapse">设置</span>
      </el-space>
    </el-tooltip>

    <el-tooltip effect="dark" content="退出" :placement="placement">
      <el-space @click="logout">
        <svg-icon icon-class="logout" />
        <span v-show="!props.isCollapse">退出</span>
      </el-space>
    </el-tooltip>
  </div>
</template>

<script lang="ts" setup>
  import { ref, computed, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { useUser, useProject, useGlobalStore } from '@haohan/clw-store'
  import { queryMessageListReq, delMessageReq } from '@haohan/clw-api'
  import { COMMON_PAGES, emitter, getTimeDifference } from '@haohan/clw-utils'
  import { ElMessage } from 'element-plus'

  const props = defineProps({
    isCollapse: {
      type: Boolean,
      default: false
    },
    flexDirection: {
      type: String,
      default: 'row'
    }
  })

  const placement = computed(() => {
    return props.flexDirection === 'row' ? 'bottom' : 'right'
  })

  const user = useUser()
  const project = useProject()
  const router = useRouter()
  const route = useRoute()
  const projectId = ref('')
  const globalStore = useGlobalStore()

  // 是否开启 keep-alive
  const isOpenKeepAlive = computed(() => project.previewKeepAliveRoute.isOpenKeepAlive)

  const currentUser = computed(() => {
    return user.current
  })
  const currentProject = computed(() => {
    return project.currentProject
  })

  const isPlatform = computed(() => {
    return globalStore.isPlatform
  })

  const hasSettings = computed<boolean>(() => {
    return (
      currentProject.value?.settings?.comPages?.trim() !== '' &&
      (isPlatform.value ||
        currentUser.value?.permissions.findIndex((p: any) =>
          COMMON_PAGES.map((el) => el.name).includes(p.page)
        ) > -1)
    )
  })
  function hasPage(page: string) {
    return (
      currentProject.value?.settings?.comPages?.trim() !== '' &&
      currentProject.value?.settings?.comPages?.includes(page)
    )
  }
  const hasInfo = computed(() => route.path.indexOf('userinfo') > -1)
  const hasSys = computed(() => route.path.indexOf('sys') > -1)
  const hasMessage = computed(() => route.path.indexOf('user-message') > -1)
  const haswf = computed(() => route.path.indexOf('work-flow-page') > -1)
  // 开启缓存列表, 走带前缀的
  const cacheRoutePrefix = 'c-'

  function goInfo() {
    const originName = 'userinfo'
    // const name = isOpenKeepAlive.value ? cacheRoutePrefix + originName : originName
    router.push({
      name: originName,
      params: { projectId: projectId.value, pageId: route.params.pageId }
    })
  }
  function goSys() {
    // 开启缓存列表, 走带前缀的
    const originName = 'system'
    // const name = isOpenKeepAlive.value ? cacheRoutePrefix + originName : originName
    router.push({
      name: originName,
      params: { projectId: projectId.value, pageId: route.params.pageId }
    })
  }
  const showMessageFlag = ref(false)
  const messageList = ref<any[]>([])
  const hasUnread = computed(() => messageList.value.some((item) => item.status === 'UN_READ'))
  async function queryMessageFn() {
    if (!hasPage('UserMessage')) {
      return
    }
    const params = {
      page: 1,
      count: 1000,
      orders: [
        {
          mode: 'DESC',
          expression: 'id'
        }
      ],
      where: {
        type: 'operate',
        left: 'status',
        operator: '=',
        parameters: {
          type: 'parameter',
          value: 'UN_READ'
        }
      }
    }
    const { data } = await queryMessageListReq(params)
    messageList.value = data.data
  }
  const dealMsg = (item: any) => {
    if (item.tag.type === 'TODO_TIME') {
      const { createTime } = item.tag.variables
      const showTime = getTimeDifference(createTime)
      return item.message.replace(/{time}/, showTime)
    }
    return item.message
  }
  const type4Tab: any = {
    PROC_START: '', //工作流定时开启
    PROC_STOP: '', //工作流定时结束
    TODO: 'ToDoMessage', //收到待办
    TODO_OUT_OF_DATE: 'ToDoMessage', //待办即将过期
    TODO_EXPIRED: 'ToDoMessage', //待办已过期
    FLOW_MANUAL_END: '', //发起人工作流手动停止
    FLOW_END_ACCEPT: 'MyInitiate', //工作流结束，通过
    FLOW_END_REJECT: 'MyInitiate', //工作流结束，驳回
    CC: 'MyProcessed' //已处理
  }
  function goMessage(type?: any) {
    showMessageFlag.value = false
    const originName = 'UserMessage'
    // const name = isOpenKeepAlive.value ? cacheRoutePrefix + originName : originName
    router.push({
      name: originName,
      params: { projectId: projectId.value, pageId: route.params.pageId },
      query: { tab: type4Tab[type] || '' }
    })
  }
  function goWotkflow(type?: any) {
    showMessageFlag.value = false
    const originName = 'WorkflowPage'
    // const name = isOpenKeepAlive.value ? cacheRoutePrefix + originName : originName
    router.push({
      name: originName,
      params: { projectId: projectId.value, pageId: route.params.pageId },
      query: { tab: type4Tab[type] || '' }
    })
  }

  const delRow = async (id: any) => {
    try {
      await delMessageReq(id)
      ElMessage.success('操作成功')
      queryMessageFn()
    } catch (error) {}
  }

  async function logout() {
    await user.logout()
    // 避免登录不同用户看到旧页面
    router.push(`/login`)
  }

  onMounted(() => {
    projectId.value = `${route.params.projectId || project.currentProject.id} `
    queryMessageFn()
    emitter.on('change-message-tab', queryMessageFn)
  })
</script>

<style lang="scss" scoped>
  .ctrl {
    display: flex;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    gap: 20px;
    color: var(--custom-menu-text-color);

    :deep(.el-popper) {
      border-radius: 12px;
      padding: 0;
    }
    .el-space {
      white-space: nowrap;
    }
  }
  .svg-icon {
    width: 18px;
    height: 18px;
    color: var(--custom-menu-text-color);
    cursor: pointer;
    outline: none;
  }

  .message-box {
    .msg-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 54px;
      padding: 0 16px 0 20px;
      h3 {
        font-size: 14px;
      }
    }
    .msg-body {
      max-height: 400px;
      overflow: auto;
      padding: 0 0 10px 20px;
      font-size: 14px;
      .item {
        position: relative;
        margin-bottom: 20px;
        line-height: 1.5;
        padding-right: 40px;
        &:hover {
          .svg-icon {
            display: block;
          }
        }
      }
      .item-title {
        color: #606266;
      }
      .time {
        font-size: 12px;
        color: #a1a2a5;
      }
      .svg-icon {
        position: absolute;
        color: #f00;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        display: none;
      }
    }

    .list-empty {
      font-size: 12px;
      color: #a1a2a5;
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .message {
    position: relative;
    .dot {
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: -2px;
      right: -4px;
      background-color: transparent;
    }
    &.has-unread {
      .dot {
        background-color: #f01d1d;
      }
    }
  }
</style>
