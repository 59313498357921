import { getKey } from '@haohan/clw-utils'
import {
  buildWhereMapping,
  buildMapping2XmlObj,
  buildFormLimit2XmlObj,
  buildCondition2XmlStr,
  getFormLimits,
  getDefaultPermissionByNodeType
} from './utils/workflow-tool'
import { cloneDeep } from 'lodash-es'
/**
 * 节点样式信息
 */
interface NODE_STYLE_PROP {
  headerBgColor: string
  headerTextColor: string
}
/**
 * 基础节点
 */
declare interface BaseNode {
  id: string
  type: WORK_FLOW_NODE_TYPE // 节点类型
  name: string // 节点名称
  useGroups: string[]
  styleProp: NODE_STYLE_PROP
  // prop: any
}
/**
 * 节点类型
 * APPLICATION_NODE 申请节点
 * APPROVED 审批节点
 * WRITE 填写节点
 * CC 抄送节点
 * CONDITIONAL_NODE 条件节点
 * INSERT_DATA 新增数据节点
 * UPDATE_DATA 更新数据节点
 * CONDITIONAL_BRANCH 条件分支
 * MULTIPLE_CONDITIONAL_BRANCH 并行分支
 */
export enum WORK_FLOW_NODE_TYPE {
  APPLICATION_NODE = 'SUBMIT',
  APPROVAL_NODE = 'APPROVED',
  FILL_NODE = 'WRITE',
  CC_NODE = 'CC',
  CONDITIONAL_NODE = 'CONDITIONAL_NODE',
  DATA_ADD_NODE = 'INSERT_DATA',
  DATA_UPDATE_NODE = 'UPDATE_DATA',
  CONDITIONAL_BRANCH = 'CONDITIONAL_BRANCH',
  MULTIPLE_CONDITIONAL_BRANCH = 'MULTIPLE_CONDITIONAL_BRANCH'
}

export class WorkFlowBaseNode implements BaseNode {
  public id: string
  public type: WORK_FLOW_NODE_TYPE
  public name: string
  public isEditName = false
  public useGroups: string[] = []
  public styleProp: NODE_STYLE_PROP
  constructor(
    id: string,
    type: WORK_FLOW_NODE_TYPE,
    name: string,
    styleProp: NODE_STYLE_PROP,
    useGroups: string[] = []
  ) {
    this.id = id
    this.type = type
    this.name = name
    this.styleProp = styleProp
    this.useGroups = useGroups
  }
  // TODO: 这里需要根据不同的节点处理，建议在节点中增加统一的方法，特别要注意要考虑到表单变化的，比如字段权限需要在这个方法中主动执行一次 getFormLimits
  public getDataXml() {
    return ''
  }
}
/**
 * 申请节点
 */
export class WorkFlowApplicationNode extends WorkFlowBaseNode {
  public prop: any = {
    formLimit: <FormLimitItem[]>[], // 字段控制
    formBtnProp: <FormBtnProp>{
      // 按钮文案
      confirmText: '确认',
      cancelText: '取消'
    }
  } // 节点对应属性
  constructor(name = '申请节点') {
    super(getKey(), WORK_FLOW_NODE_TYPE.APPLICATION_NODE, name, {
      headerBgColor: 'linear-gradient(180deg, #717171 0%, #2C2C2E 100%)',
      headerTextColor: '#FFFFFF'
    })
    this.prop.formLimit = getFormLimits([], getDefaultPermissionByNodeType(this.type))
  }
  public getDataXml() {
    // <flowable:settings><![CDATA[{"formLimit":{"user":"VIEW,EDIT"},"buttonStyle":{JSON}}]]></flowable:settings>
    const data = {
      formLimit: buildFormLimit2XmlObj(this.prop.formLimit),
      buttonStyle: this.prop.formBtnProp
    }
    return JSON.stringify(data)
  }
}
/**
 * 审批节点
 */
export class WorkFlowApprovalNode extends WorkFlowBaseNode {
  public prop: any = {
    formLimit: <FormLimitItem[]>[], // 字段控制
    formBtnProp: <FormBtnProp>{
      // 按钮文案
      confirmText: '通过',
      cancelText: '拒绝'
    },
    isFillApprovalComments: <boolean>false // 是否填写审批意见
  } // 节点对应属性
  constructor(name = '审批节点') {
    super(
      getKey(),
      WORK_FLOW_NODE_TYPE.APPROVAL_NODE,
      name,
      {
        headerBgColor: 'linear-gradient( 180deg, #5D85E1 0%, #2355BB 100%)',
        headerTextColor: '#FFFFFF'
      },
      ['经理']
    )
    this.prop.formLimit = getFormLimits([], getDefaultPermissionByNodeType(this.type))
  }
  public getDataXml() {
    // <flowable:settings><![CDATA[{"formLimit":{"user":"VIEW,EDIT"},"remark": true,"buttonStyle":{JSON}}]]>
    const data = {
      formLimit: buildFormLimit2XmlObj(this.prop.formLimit),
      remark: this.prop.isFillApprovalComments,
      buttonStyle: this.prop.formBtnProp
    }
    return JSON.stringify(data)
  }
}
/**
 * 填写节点
 */
export class WorkFlowFillNode extends WorkFlowBaseNode {
  public prop: any = {
    formLimit: <FormLimitItem[]>[], // 字段控制
    formBtnProp: <FormBtnProp>{
      // 按钮文案
      confirmText: '提交',
      cancelText: '取消'
    }
  } // 节点对应属性
  constructor(name = '填写节点') {
    super(
      getKey(),
      WORK_FLOW_NODE_TYPE.FILL_NODE,
      name,
      {
        headerBgColor: 'linear-gradient( 180deg, #5D85E1 0%, #2355BB 100%)',
        headerTextColor: '#FFFFFF'
      },
      ['组员']
    )
    this.prop.formLimit = getFormLimits([], getDefaultPermissionByNodeType(this.type))
  }
  public getDataXml() {
    // <flowable:settings><![CDATA[{"formLimit":{"user":"VIEW,EDIT"},"buttonStyle":{JSON}}]]></flowable:settings>
    const data = {
      formLimit: buildFormLimit2XmlObj(this.prop.formLimit),
      buttonStyle: this.prop.formBtnProp
    }
    return JSON.stringify(data)
  }
}
/**
 * 抄送节点
 */
export class WorkFlowCCNode extends WorkFlowBaseNode {
  public prop: any = {
    formLimit: <FormLimitItem[]>[] // 字段控制
  } // 节点对应属性
  constructor(name = '抄送节点') {
    super(
      getKey(),
      WORK_FLOW_NODE_TYPE.CC_NODE,
      name,
      {
        headerBgColor: 'linear-gradient( 180deg, #5D85E1 0%, #2355BB 100%)',
        headerTextColor: '#FFFFFF'
      },
      ['干系人']
    )
    this.prop.formLimit = getFormLimits([], getDefaultPermissionByNodeType(this.type))
  }
  public getDataXml() {
    // <<flowable:settings><![CDATA[{"formLimit":{"user":"view,modify"}}]]></flowable:settings>
    return JSON.stringify({ formLimit: buildFormLimit2XmlObj(this.prop.formLimit) })
  }
}
/**
 * 新增数据节点
 */
export class WorkFlowDataAddNode extends WorkFlowBaseNode {
  public prop: any = {
    datasourceId: <number>0,
    schema: <string>'',
    table: <string>'',
    mapping: <FormTableColumnMappingItem[]>[] // key-数据库字段，value-表单字段
  } // 节点对应属性
  constructor(name = '添加数据') {
    super(getKey(), WORK_FLOW_NODE_TYPE.DATA_ADD_NODE, name, {
      headerBgColor: 'linear-gradient( 180deg, #F8BCED 0%, #8369F0 100%)',
      headerTextColor: '#FFFFFF'
    })
  }
  public getDataXml() {
    // <flowable:settings><![CDATA[{"datasourceId":1, "schema":"public", "table":"test", "mapping":{"name":"name"},"whereMapping":{"id":"id"}}]]></flowable:settings>
    const _data = { ...this.prop }
    _data.mapping = buildMapping2XmlObj(this.prop.mapping)
    return JSON.stringify(_data)
  }
}
/**
 * 更新数据节点
 */
export class WorkFlowDataUpdateNode extends WorkFlowBaseNode {
  public prop: any = {
    datasourceId: <number>0,
    schema: <string>'',
    table: <string>'',
    mapping: <FormTableColumnMappingItem[]>[], // key-数据库字段，value-表单字段
    whereMapping: <FormTableColumnWhereMappingItem[]>[] // 条件映射 key-数据库字段，value-表单字段
  } // 节点对应属性
  constructor(name = '更新数据') {
    super(getKey(), WORK_FLOW_NODE_TYPE.DATA_UPDATE_NODE, name, {
      headerBgColor: 'linear-gradient( 180deg, #F8BCED 0%, #8369F0 100%)',
      headerTextColor: '#FFFFFF'
    })
  }

  public getDataXml() {
    // <flowable:settings><![CDATA[{"datasourceId":1, "schema":"public", "table":"test", "mapping":{"name":"name"},"whereMapping":{"id":"id"}}]]></flowable:settings>
    const _data = { ...this.prop }
    _data.mapping = buildMapping2XmlObj(this.prop.mapping)
    _data.whereMapping = buildWhereMapping({}, cloneDeep(this.prop.whereMapping))
    return JSON.stringify(_data)
  }
}
/**
 * 条件分支
 */
export class WorkFlowConditionalBranch {
  public id: string
  public name: string
  public useGroups: string[] = []
  public type: WORK_FLOW_NODE_TYPE = WORK_FLOW_NODE_TYPE.CONDITIONAL_BRANCH
  condition: Condition[] = [] // 多行分支中的判定条件集合
  constructor(name = '条件分支') {
    this.id = getKey()
    this.name = name
  }
  conformNodeList:
    | WorkFlowBaseNode[]
    | WorkFlowConditionalBranch[]
    | WorkFlowMultipleConditionalBranch[] = [] // 符合条件
  rejectNodeList:
    | WorkFlowBaseNode[]
    | WorkFlowConditionalBranch[]
    | WorkFlowMultipleConditionalBranch[] = [] // 不符合条件
}

/**
 * 条件节点，为多条件分支使用
 */
export class WorkFlowConditionalNode extends WorkFlowBaseNode {
  condition: Condition[] = [] // 多行分支中的判定条件集合
  conditionNodeList: WORK_FLOW_NODE_CLASS_TYPE = [] // 该条件下的节点集合
  constructor(name = '条件节点') {
    super(getKey(), WORK_FLOW_NODE_TYPE.CONDITIONAL_NODE, name, {
      headerBgColor: 'linear-gradient( 180deg, #CEEDAB 0%, #6AAE92 100%)',
      headerTextColor: '#FFFFFF'
    })
  }
}

/**
 * 条件分支
 */
export class WorkFlowMultipleConditionalBranch {
  public id: string
  public name: string
  public useGroups: string[] = []
  public type: WORK_FLOW_NODE_TYPE = WORK_FLOW_NODE_TYPE.MULTIPLE_CONDITIONAL_BRANCH
  conditionNodeList: WorkFlowConditionalNode[] = [
    new WorkFlowConditionalNode('条件节点1'),
    new WorkFlowConditionalNode('条件节点2')
  ]
  constructor(name = '多条件分支') {
    this.id = getKey()
    this.name = name
  }
}

// export type WORK_FLOW_NODE_CLASS_TYPE =
//   | WorkFlowBaseNode[]
//   | WorkFlowConditionalBranch[]
//   | WorkFlowMultipleConditionalBranch[]
export type WORK_FLOW_BASE_NODE_UNION_TYPE =
  | WorkFlowApprovalNode
  | WorkFlowFillNode
  | WorkFlowCCNode
  | WorkFlowDataAddNode
  | WorkFlowDataUpdateNode
export type WORK_FLOW_CLASS_TYPE =
  | WorkFlowBaseNode
  | WorkFlowConditionalBranch
  | WorkFlowMultipleConditionalBranch
export type WORK_FLOW_NODE_CLASS_TYPE = WORK_FLOW_CLASS_TYPE[]

/**
 * 顺序条件流
 */
export class ConditionSequenceFlow {
  // public isConditionSequenceFlow = false // 是否是条件顺序流
  public isConditionSequenceFlow = true // 是否是条件节点流
  public currentConditionIndex = 0 // 当前条件分支索引
  public parentBpmnNode: BpmnNodeType // 记录Bpmn之前的节点
  public parentNode: WORK_FLOW_CLASS_TYPE // 记录当前直接的节点信息
  public condition: Condition[] = [] // 条件
  public isConditionContrary = false // 是否与条件相反
  public workflowNodes: WORK_FLOW_NODE_CLASS_TYPE // 存储工作流节点信息
  public currentWorkFlowNodeIndex = 0 // 当前生成节点位于工作流节点的索引
  constructor(
    condition: Condition[] = [],
    workflowNodes: WORK_FLOW_NODE_CLASS_TYPE,
    isConditionSequenceFlow = true
  ) {
    this.condition = condition
    this.workflowNodes = workflowNodes
    this.isConditionSequenceFlow = isConditionSequenceFlow
  }
}

/**
 * Bpmn 开始事件
 */
export class BpmnStartEvent {
  public id: string
  public outId: string[]
  constructor(outId: string[]) {
    this.id = `Event_${getKey()}`
    this.outId = outId
  }
  public getXml() {
    const outgoings = this.outId.map((_id) => {
      return `
      <bpmn2:outgoing>${_id}</bpmn2:outgoing>
      `
    })
    return `<bpmn2:startEvent id="${this.id}">${outgoings.join('\n')}</bpmn2:startEvent>`
  }
}
/**
 * Bpmn 结束事件
 */
export class BpmnEndEvent {
  public id: string
  public inId: string[]
  constructor(inId: string[]) {
    this.id = `Event_${getKey()}`
    this.inId = inId
  }
  public getXml() {
    const ingoings = this.inId.map((_id) => {
      return `
      <bpmn2:incoming>${_id}</bpmn2:incoming>
      `
    })
    return `<bpmn2:endEvent id="${this.id}">${ingoings.join('\n')}</bpmn2:endEvent>`
  }
}
/**
 * Bpmn 用户任务节点
 */
export class BpmnUserTask {
  public id: string
  public name: string
  public type: WORK_FLOW_NODE_TYPE
  public useGroups: string[] = []
  public isLastConditionNode = false // 是否是条件分支其中一个分支的最后一个节点
  public data: any // 节点的属性信息,注意存储时，需要调用 JSON.stringify
  constructor(id: string, name: string, type: WORK_FLOW_NODE_TYPE, data: any, useGroups: string[]) {
    this.id = `Activity_${id}`
    this.name = `${this.id}_${name}`
    this.type = type
    // TODO: 这里需要根据不同的节点处理，建议在节点中增加统一的方法，特别要注意要考虑到表单变化的，比如字段权限需要在这个方法中主动执行一次 getFormLimits
    this.data = data
    this.useGroups = useGroups
  }

  public getDataXml() {
    switch (this.type) {
      case WORK_FLOW_NODE_TYPE.APPLICATION_NODE:
        return JSON.stringify({
          formLimit: buildFormLimit2XmlObj(
            getFormLimits(this.data.formLimit, getDefaultPermissionByNodeType(this.type))
          ),
          buttonStyle: this.data.formBtnProp
        })
      case WORK_FLOW_NODE_TYPE.APPROVAL_NODE:
        return JSON.stringify({
          formLimit: buildFormLimit2XmlObj(
            getFormLimits(this.data.formLimit, getDefaultPermissionByNodeType(this.type))
          ),
          remark: this.data.isFillApprovalComments,
          buttonStyle: this.data.formBtnProp
        })
      case WORK_FLOW_NODE_TYPE.FILL_NODE:
        return JSON.stringify({
          formLimit: buildFormLimit2XmlObj(
            getFormLimits(this.data.formLimit, getDefaultPermissionByNodeType(this.type))
          ),
          buttonStyle: this.data.formBtnProp
        })
      case WORK_FLOW_NODE_TYPE.CC_NODE:
        return JSON.stringify({
          formLimit: buildFormLimit2XmlObj(
            getFormLimits(this.data.formLimit, getDefaultPermissionByNodeType(this.type))
          )
        })
      case WORK_FLOW_NODE_TYPE.DATA_ADD_NODE:
        return JSON.stringify({
          ...this.data,
          mapping: buildMapping2XmlObj(this.data.mapping)
        })
      case WORK_FLOW_NODE_TYPE.DATA_UPDATE_NODE:
        return JSON.stringify({
          ...this.data,
          mapping: buildMapping2XmlObj(this.data.mapping),
          whereMapping: buildWhereMapping({}, cloneDeep(this.data.whereMapping))
        })
      default:
        return ''
    }
  }
  public getXml() {
    return `
    <bpmn2:userTask id="${this.id}" name="${this.name}" flowable:type="${
      this.type
    }" flowable:candidateGroups="${this.useGroups.join(',')}">
    <bpmn2:extensionElements>
        <flowable:settings><![CDATA[${this.getDataXml()}]]></flowable:settings>
    </bpmn2:extensionElements>
    </bpmn2:userTask>
    `
  }
  public getXmlOnInAndOutInfo(inNodeId: string, outNodeId: string) {
    return `
    <bpmn2:userTask id="${this.id}" name="${this.name}" flowable:type="${
      this.type
    }" flowable:candidateGroups="${this.useGroups.join(',')}">
    <bpmn2:extensionElements>
        <flowable:settings><![CDATA[${this.data}]]></flowable:settings>
    </bpmn2:extensionElements>
      <bpmn2:incoming>${inNodeId}</bpmn2:incoming>
      <bpmn2:outgoing>${outNodeId}</bpmn2:outgoing>
    </bpmn2:userTask>
    `
  }
}
/**
 * Bpmn 顺序流
 */
export class BpmnSequenceFlow {
  public id: string
  public sourceRef: string
  public targetRef: string
  public condition: Condition[] = []
  public isConditionContrary = false // 是否与条件相反
  public isLastConditionNode = false // 是否是条件分支其中一个分支的最后一个节点
  constructor(
    sourceRef: string,
    targetRef: string,
    condition: Condition[],
    isConditionContrary = false
  ) {
    this.id = `Flow_${getKey()}`
    this.sourceRef = sourceRef
    this.targetRef = targetRef
    this.condition = condition
    this.isConditionContrary = isConditionContrary
  }

  public getXml() {
    // TODO: 解析条件内容，注意 isConditionContrary节点的使用
    let conditionStr = ''
    if (this.condition.length > 0) {
      // <![CDATA[score > 9]]> / ${true} ?
      conditionStr =
        '<conditionExpression xsi:type="tFormalExpression">${' +
        buildCondition2XmlStr(this.condition, this.isConditionContrary) +
        '}</conditionExpression>'
      //   conditionStr = `<conditionExpression xsi:type="tFormalExpression">
      //   \$\{${buildCondition2XmlStr(this.condition, this.isConditionContrary)}\}
      // </conditionExpression>`
    }
    return `<bpmn2:sequenceFlow id="${this.id}" sourceRef="${this.sourceRef}" targetRef="${this.targetRef}">
      ${conditionStr}
    </bpmn2:sequenceFlow>`
  }
}
/**
 * Bpmn 单一网关
 */
export class BpmnExclusiveGateway {
  public id: string
  public name: string
  public isLastConditionNode = false // 是否是条件分支其中一个分支的最后一个节点
  constructor(id: string) {
    this.id = `Gateway_${id}`
    this.name = this.id
  }
  public getXml() {
    return `<bpmn2:exclusiveGateway id="${this.id}" name="${this.name}"></bpmn2:exclusiveGateway>`
  }
}
/**
 * Bpmn节点数组联合类型
 */
export type BpmnNodeType =
  | BpmnEndEvent
  | BpmnStartEvent
  | BpmnExclusiveGateway
  | BpmnSequenceFlow
  | BpmnUserTask
export type BpmnNodeTypes = BpmnNodeType[]
