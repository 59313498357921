<template>
  <div ref="pickerBoxRef" class="color-picker" :style="composeStyle">
    <div class="select-box">
      <el-select v-model="colorType" class="select ui-287" popper-class="cv-theme">
        <el-option label="纯色" value="pure" />
        <el-option label="渐变" value="linear" />
        <!-- <el-option label="径向渐变" value="linear" />
        <el-option label="角度渐变" value="linear" /> -->
      </el-select>
    </div>
    <div v-if="colorType !== 'pure'" class="linear-box">
      <div class="linear-positon-box" :style="{ '--bg': bgc }">
        <div ref="linearPositonRef" class="linear-positon">
          <div
            v-for="(v, ix) in colors"
            :key="ix"
            class="indicator"
            :class="{ active: ix === linearIx }"
            :style="{ left: `${v.step}%` }"
            @mousedown="linearPointerFn($event, ix)"
          />
        </div>
      </div>
      <div class="angle-box">
        <el-input v-model="angle" class="linear-angle" />
      </div>
    </div>
    <div class="panel-box">
      <div class="color-plane-box" :style="{ 'background-color': bgColor }">
        <div ref="colorPlaneRef" class="color-plane">
          <div class="indicator"></div>
        </div>
      </div>
      <div class="ctrl-box">
        <div class="color-gamut-box">
          <div ref="colorGamutRef" class="color-gamut">
            <div class="indicator"></div>
          </div>
        </div>
        <div class="transparency-box">
          <div class="bg"></div>
          <div class="color-transparency-box">
            <div ref="colorTransparencyRef" class="color-transparency">
              <div class="indicator"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="value-box">
      <el-select v-model="type" class="select ui-287" popper-class="cv-theme">
        <el-option label="hex" value="hex" />
        <el-option label="rgb" value="rgb" />
      </el-select>
      <el-input
        v-if="type === 'hex'"
        v-model="colorHex"
        class="input-color"
        @blur="colorHexBlurFn"
      />
      <el-input
        v-else-if="type === 'rgb'"
        v-model="colorRGB"
        class="input-color"
        @blur="colorRGBBlurFn"
      />
      <el-input v-model="transparency" class="input-transparency" @blur="transparencyBlurFn" />
      <!-- <svg-icon icon-class="color-picker" class="color-picker-icon" @click="pipette" /> -->
    </div>
  </div>
</template>

<script setup lang="ts">
  import Move from './move'
  import { hsbToRGB, RGBTohsb, RGBToHex, hexToRGBA, toRGBObject } from './color'
  import { useGlobalStore } from '@haohan/clw-store'
  import { debounce, throttle } from 'lodash-es'
  import { composeColor } from '@haohan/clw-utils'
  import { ref, computed, reactive, onMounted, onBeforeUnmount, watch, markRaw } from 'vue'
  import { ElSelect, ElOption, ElInput } from 'element-plus'

  const global = useGlobalStore()

  const pickerBoxRef = ref()
  const pickerBoxInfo = computed(() => {
    return {
      width: pickerBoxRef.value?.clientWidth,
      height: pickerBoxRef.value?.clientHeight
    }
  })

  let colorPlaneIns
  let colorGamutIns
  let colorTransparencyIns
  let linearPositonIns

  const colorType = ref('pure')
  const type = ref('hex')
  const colorPlaneRef = ref<HTMLElement>()
  const colorGamutRef = ref<HTMLElement>()
  const colorTransparencyRef = ref<HTMLElement>()
  const linearPositonRef = ref<HTMLElement>()
  // 单个颜色
  const color = ref<{ R: number; G: number; B: number }>({ R: 0, G: 0, B: 0 })
  // 渐变色
  const colors = ref<{ color: string; step: number }[]>([])
  // 线性渐变索引
  const linearIx = ref<number>()
  const isPointerDowm = ref(false)
  function linearPointerFn(e: Event, ix: number) {
    // e.stopPropagation()
    // console.log(ix)
    linearIx.value = ix
    isPointerDowm.value = true
  }
  const angle = ref(0)
  const colorHsb = reactive({ h: 0, s: 0, b: 0 })
  const panelParams = ref<any>()
  // 透明度
  const transparency = ref(100)
  const colorHex = ref()
  const colorRGB = ref()
  // 全局颜色，作为输入颜色
  const colorInfo = computed(() => global.colorPicker.color)
  const targetInfo = ref<any>({})

  // 监听全局颜色变化
  watch(
    colorInfo,
    (nv) => {
      // console.log('getcolor', nv)
      if (typeof nv !== 'string') {
        colorType.value = nv.type || 'linear'
        colors.value = nv.list || []
        angle.value = nv.angle || '90'
        if (colors.value.length > 0) {
          linearIx.value = 0
        }
      } else {
        colorType.value = 'pure'
        const { R, G, B, A } = hexToRGBA(nv)
        color.value = { R, G, B }
        transparency.value = Math.round((A / 255) * 100)
      }
    },
    {
      immediate: true
    }
  )
  watch(
    linearIx,
    (nv) => {
      if (nv !== undefined) {
        const item = colors.value[nv]
        // console.log(nv, item)
        const { R, G, B, A } = hexToRGBA(item.color)
        color.value = { R, G, B }
        transparency.value = Math.round((A / 255) * 100)
      }
    },
    { immediate: true }
  )
  watch(
    color,
    () => {
      // console.log('color cjamge')
      const { R, G, B } = color.value
      const { h, s, b } = RGBTohsb(R, G, B)
      colorHsb.h = h
      colorHsb.s = s
      colorHsb.b = b
      colorHex.value = `#${RGBToHex(R, G, B)}`
      colorRGB.value = `rgb(${R},${G},${B})`
    },
    {
      immediate: true
    }
  )
  watch(linearPositonRef, (nv) => {
    // console.log(nv, linearPositonIns)
    if (nv) {
      linearPositonIns = markRaw(
        new Move(linearPositonRef.value!, {
          direction: 'h',
          pointer: '.indicator.active'
        })
      )
      linearPositonIns.on('down', (params: any) => {
        // console.log(params, isPointerDowm.value)
        if (!isPointerDowm.value) {
          const step = Math.round((params.x / params.width) * 100)
          const ix = colors.value.findIndex((v) => v.step > step)
          const fix = ix < 0 ? colors.value.length : ix
          const item = { color: dealColor.value, step }
          colors.value.splice(fix, 0, item)
          linearIx.value = ix < 0 ? colors.value.length + ix : ix
        }
      })
      linearPositonIns.on('up', (params: any) => {
        if (isPointerDowm.value) {
          // console.log(params)
          const step = Math.round((params.x / params.width) * 100)
          colors.value.splice(linearIx.value, 1)
          const ix = colors.value.findIndex((v) => v.step >= step)
          const fix = ix < 0 ? colors.value.length : ix
          const item = { color: dealColor.value, step }
          colors.value.splice(fix, 0, item)
          linearIx.value = ix < 0 ? colors.value.length + ix : ix
          isPointerDowm.value = false
        }
      })
    } else if (linearPositonIns) {
      linearPositonIns.destroy()
    }
  })
  // 监听滚动，更新目标位置
  watch(
    () => global.colorPicker.scroll,
    debounce((nv) => {
      // console.log('22', nv)
      targetInfo.value = global.colorPicker.target?.getBoundingClientRect()
    }, 100),
    {
      immediate: true
    }
  )

  function colorHexBlurFn() {
    const { R, G, B } = hexToRGBA(colorHex.value)
    color.value = { R, G, B }
    planeMove({ R, G, B })
  }
  function colorRGBBlurFn() {
    const { R, G, B } = toRGBObject(colorRGB.value)
    color.value = { R, G, B }
    planeMove({ R, G, B })
  }
  function transparencyBlurFn() {
    colorTransparencyIns.setXY({ ty: transparency.value / 100 })
  }
  function planeMove({ R, G, B }) {
    const { h, s, b } = RGBTohsb(R, G, B)
    colorPlaneIns.setXY({ tx: s / 100, ty: 1 - b / 100 })
    colorGamutIns.setXY({ ty: h / 359 })
  }

  // 最终返回颜色
  const dealColor = computed(() => {
    const { R, G, B } = color.value
    if (transparency.value === 100) {
      return `#${RGBToHex(R, G, B)}`
    }
    const A = Math.round((transparency.value / 100) * 255)
      .toString(16)
      .padStart(2, '0')
    return `#${RGBToHex(R, G, B)}${A}`
  })

  watch(
    () => [dealColor.value, angle.value],
    (nv, ov) => {
      // console.log('up color', nv, ov)
      if (colorType.value === 'pure') {
        global.upColorPickerProp('upColor', dealColor.value)
      } else {
        colors.value[linearIx.value].color = dealColor.value
        global.upColorPickerProp('upColor', {
          type: 'linear',
          list: colors.value,
          angle: angle.value
        })
      }
    }
  )
  // 取色面板位置
  const composeStyle = ref()
  watch(
    targetInfo,
    () => {
      const { top, left, height } = targetInfo.value
      let t1 = 0
      if (top + pickerBoxInfo.value.height > window.innerHeight) {
        t1 = top - (height / 2 + 80) - (top + pickerBoxInfo.value.height - window.innerHeight)
      } else {
        t1 = top - (height / 2 + 80)
      }
      composeStyle.value = {
        visibility: 'visible',
        transform: `translate(${left - pickerBoxInfo.value.width - 10}px, ${t1}px)`
      }
    },
    {
      flush: 'post'
    }
  )
  const bgColor = computed(() => {
    const { R, G, B } = hsbToRGB(colorHsb.h, 100, 100)
    return `rgb(${R},${G},${B})`
  })

  const bgc = computed(() => {
    return composeColor(colorInfo.value)
  })

  /**
   * 根据位置返回 色相h 饱和度s 亮度b 颜色
   * 0-360 0-100 0-100
   * 即HSB/HSV颜色模式
   * @param x
   * @param y
   */
  function getRGB() {
    const { x, y, width, height } = panelParams.value
    const h = colorHsb.h
    const s = Math.round((x / width) * 100)
    const v = Math.round(((height - y) / height) * 100)
    return hsbToRGB(h, s, v)
  }

  // 吸管
  // const colorPipette = new ColorPipette({
  //   container: document.body,
  //   scale: 2,
  //   listener: {
  //     onOk: ({ color }) => {
  //       color.value = color
  //     }
  //   }
  // })
  // function pipette() {
  //   // 原生API，但其兼容性较差
  //   if (window.EyeDropper) {
  //     const eyeDropper = new EyeDropper()
  //     eyeDropper
  //       .open()
  //       .then((resp) => {
  //         color.value = resp.sRGBHex
  //       })
  //       .catch((e) => {})
  //   } else {
  //     colorPipette.start()
  //   }
  // }
  onMounted(() => {
    const tx = colorHsb.s / 100,
      ty = 1 - colorHsb.b / 100
    // console.log(tx, ty)
    colorPlaneIns = markRaw(new Move(colorPlaneRef.value!, { tx, ty }))
    colorPlaneIns.on('move', (params: any) => {
      // console.log(params)
      panelParams.value = params
      color.value = getRGB()
    })
    colorPlaneIns.on('up', (params: any) => {
      // console.log(params)
      panelParams.value = params
      color.value = getRGB()
    })
    if (!panelParams.value) {
      panelParams.value = { ...colorPlaneIns.getAll() }
    }
    colorGamutIns = markRaw(
      new Move(colorGamutRef.value!, { direction: 'v', ty: colorHsb.h / 359 })
    )
    colorGamutIns.on('move', (params: any) => {
      // console.log(params)
      colorHsb.h = Math.round((params.y / params.height) * 359)
      color.value = getRGB()
    })
    colorGamutIns.on('up', (params: any) => {
      // console.log(params)
      colorHsb.h = Math.round((params.y / params.height) * 359)
      color.value = getRGB()
    })
    colorTransparencyIns = markRaw(
      new Move(colorTransparencyRef.value!, {
        direction: 'v',
        ty: transparency.value / 100
      })
    )
    colorTransparencyIns.on('move', (params: any) => {
      // console.log(params)
      transparency.value = Math.round((params.y / params.height) * 100)
    })
    colorTransparencyIns.on('up', (params: any) => {
      // console.log(params)
      transparency.value = Math.round((params.y / params.height) * 100)
    })
  })
  onBeforeUnmount(() => {
    colorPlaneIns.destroy()
    colorGamutIns.destroy()
    colorTransparencyIns.destroy()
  })
</script>

<style lang="scss" scoped>
  .color-picker {
    position: absolute;
    top: 0;
    left: 0;
    width: 340px;
    // height: 340px;
    padding: 24px 30px;
    border-radius: 6px;
    border: 1px solid #b1c7f8;
    background-color: #333;
    z-index: 999;
    visibility: hidden;
    .linear-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0 24px 0;
      .linear-positon-box {
        height: 12px;
        width: 190px;
        border-radius: 6px;
        padding: 0 6px;
        background: var(--bg);
      }
      .linear-positon {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        .indicator {
          background-color: #fff;
          transform: scale(0.8) translateX(-50%);
          z-index: 2;
          pointer-events: auto;
          &.active {
            background-color: transparent;
            transform: scale(1) translateX(-50%);
          }
        }
      }
      .angle-box {
        .el-input {
          width: 50px;
        }
      }
    }
    .panel-box {
      display: flex;
      justify-content: space-between;
      height: 170px;
    }
    .indicator {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: transparent;
      position: absolute;
      top: 0px;
      left: 0px;
      cursor: pointer;
      // user-select: none;
      // touch-action: none;
    }
    .color-plane-box {
      position: relative;
      width: 224px;
      height: 100%;
      padding: 6px;
      border-radius: 6px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)),
        linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 1));
      // background-color: #f00;
    }
    .color-plane {
      position: relative;
      width: 100%;
      height: 100%;
      // border: 1px solid #3f4143;
      .indicator {
        transform: translate(-50%, -50%);
      }
    }
    .ctrl-box {
      display: flex;
      justify-content: space-between;
      width: 35px;
    }
    .color-gamut-box {
      width: 12px;
      height: 100%;
      border-radius: 6px;
      padding: 6px 0;
      background: linear-gradient(
        180deg,
        red,
        rgb(255, 255, 0) 16.66%,
        rgb(0, 255, 0) 33.33%,
        rgb(0, 255, 255) 50%,
        rgb(0, 0, 255) 66.66%,
        rgb(255, 0, 255) 83.33%,
        red
      );
    }
    .color-gamut {
      position: relative;
      width: 100%;
      height: 100%;
      .indicator {
        transform: translateY(-50%);
      }
    }
    .transparency-box {
      position: relative;
      width: 12px;
      height: 100%;
      border-radius: 6px;
      overflow: hidden;
    }
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #fff;
      border-radius: 6px;
      &::after {
        background-image: linear-gradient(45deg, #ddd 25%, #0000 25%),
          linear-gradient(-45deg, #ddd 25%, #0000 25%), linear-gradient(45deg, #0000 75%, #ddd 75%),
          linear-gradient(-45deg, #0000 75%, #ddd 75%);
        background-size: 12px 12px;
        background-position: 0 0, 0 6px, 6px -6px, -6px 0px;
        background-repeat: repeat;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
    .color-transparency-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      padding: 6px 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
    .color-transparency {
      position: relative;
      width: 100%;
      height: 100%;
      .indicator {
        transform: translateY(-50%);
      }
    }
    .value-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .select {
        width: 50px;
      }
      .input-color {
        width: 130px;
      }
      .input-transparency {
        width: 50px;
      }
      .color-picker-icon {
        cursor: pointer;
      }
      :deep() {
        .el-input__inner {
          width: 100%;
        }
      }
    }
    :deep() {
      .el-input {
        .el-input__wrapper {
          border-bottom: 1px solid #3f4143;
          box-shadow: none !important;
        }
      }
      .el-select {
        .el-input {
          .el-input__wrapper {
            border-width: 0;
            box-shadow: none !important;
          }
        }
      }
      .el-input__wrapper {
        padding: 0;
        background-color: transparent;
        border-radius: 0;
        outline: none;
        .el-input__inner {
          color: #fff;
        }
      }
    }
  }
</style>
