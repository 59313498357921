import { cloneDeep } from 'lodash-es'

// 普通组件级功能属性
export const compAttr: WidgetAttribute = {
  isLock: false,
  events: []
}
// 公共样式属性
export const styleProp: WidgetAttribute = {
  'margin-top': 0,
  'margin-bottom': 0,
  'margin-left': 0,
  'margin-right': 0,
  'padding-top': 0,
  'padding-bottom': 0,
  'padding-left': 0,
  'padding-right': 0
}
// 普通组件公共数据属性
export const dataProp: WidgetAttribute = {
  tableId: '',
  tableName: '',
  xAxisField: '',
  pageSize: 10,
  queryTimeType: 'systime',
  startInterval: 0,
  endtInterval: 0,
  apiDataPath: '' // 当数据类型为API时，记录API数据的路径
}
// 图表组件级功能属性
export const chartCompAttr: WidgetAttribute = {
  isLock: false,
  events: [],
  mutualSetting: true,
  dataSourceSetting: true,
  dataSourceType: 'LocalData',
  dataSourceTypes: ['TableSource', 'LocalData', 'CustomApi', 'LogicFlowSource'],
  // 自定义数据处理函数
  customDataHandle: ''
}
// 图表组件公共属性
export const chartCommonProp: WidgetAttribute = {
  color: [
    '#1684F2',
    '#2AC2F2',
    '#2AC16A',
    '#BAE86D',
    '#FDE031',
    '#FDAC23',
    '#F56975',
    '#E389BD',
    '#E644A2',
    '#BE5EDB',
    '#9D7CE6',
    '#7888F4',
    '#85D1EB',
    '#7BF6D9'
  ],
  legend: {
    data: [],
    show: true,
    type: 'plain',
    // 垂直位置
    top: 'bottom',
    // 水平位置
    left: 'center',
    orient: 'horizontal'
  },
  title: {
    show: false,
    text: '',
    // 水平对齐
    left: 'left'
  },
  backgroundColor: '#ffffff',
  series: [],
  // options: [],
  // 数据组
  valueCols: [],
  xAxisField: ''
}
// 图表笛卡尔坐标系属性
export const chartXaisProp: WidgetAttribute = {
  grid: {
    left: 20,
    top: 30,
    right: 20,
    bottom: 46,
    containLabel: true
  },
  xAxis: [
    {
      show: true,
      // 坐标轴名称
      name: '',
      // 坐标轴名称位置 start/center/end
      nameLocation: 'end',
      // 坐标轴类型 value  数值轴/ category 类目轴
      type: 'category',

      position: '',
      offset: 0,
      // 数据翻转
      inverse: false
    }
  ],
  yAxis: [
    {
      show: true,
      // 坐标轴名称
      name: '',
      // 坐标轴名称位置 start/center/end
      nameLocation: 'end',
      // 坐标轴类型 value  数值轴/ category 类目轴
      type: 'value',
      position: '',
      offset: 0,
      // 数据翻转
      inverse: false
    }
  ]
}
// 图表组件公共数据属性
export const chartDataProp: WidgetAttribute = {
  // 数据集
  tableId: '',
  tableName: '',
  pageSize: 10,
  queryTimeType: 'systime',
  startInterval: 0,
  endtInterval: 0,
  apiDataPath: '' // 当数据类型为API时，记录API数据的路径
}

/**
 * 生成图表组件设置
 */
export function genChartSetting(item: any): Widget {
  const copyItem = cloneDeep(item)
  copyItem.styleProp = { ...styleProp, ...copyItem.styleProp }
  copyItem.prop = { ...chartCommonProp, ...copyItem.prop }
  if (copyItem.dataProp) {
    copyItem.dataProp = { ...chartDataProp, ...copyItem.dataProp }
  }
  return { ...chartCompAttr, ...copyItem }
}
/**
 * 生成普通组件设置
 */
export function genRegularSetting(item: any): Widget {
  const copyItem = cloneDeep(item)
  copyItem.styleProp = { ...styleProp, ...copyItem.styleProp }
  if (copyItem.dataProp) {
    copyItem.dataProp = { ...dataProp, ...copyItem.dataProp }
  }
  return { ...compAttr, ...copyItem }
}
/**
 * 绑定值类型
 */
export const VALUE_TYPES = [
  {
    value: 'string',
    label: '字符串'
  },
  {
    value: 'array',
    label: '数组'
  },
  {
    value: 'form',
    label: '表单对象'
  }
]
/**
 * 返回对应值类型对象集合
 * @param keys 需要的值类型key
 * @returns
 */
export const getValueTypes = (keys: string[]) => {
  return VALUE_TYPES.filter((vt: any) => keys.includes(vt.value))
}
